import { theme } from '@prophecy/ui/theme';
import { Text } from '@prophecy/ui/Typography';

export default function DeploymentStepHeader({ title }: { title: string }) {
  return (
    <Text level='xl' weight={theme.fontWeight.medium}>
      {title}
    </Text>
  );
}
