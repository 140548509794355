import { createContext, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppMetadata } from '../../context/appMetadata';
import { useGlobalVariables } from '../../context/globalConfig/securedConfigHook';
import { useMixPanel } from '../../context/mixpanel/context';
import { UserDetails } from '../../context/types';
import { openFabricSetup } from '../Fabric/FabricSetup';
import { useUserFabrics } from '../Fabric/hooks';
import { Entity } from '../types/Entity';
import { getIDEUrl, Private_Routes } from '../url';
import { Onboarding } from '../user-analytics';
import { NEW_PIPELINE_FLOW_ID, PIPELINE_FLOW_ID, SQL_COPILOT_FLOW_ID, SQL_PIPELINE_FLOW_ID } from './constants';
import { useUpdateOnboardingStatus } from './ide-utils';
import { Processes } from './sql/constants';
import { OnboardingStatus } from './types';

type OnboardingValueType = string | boolean;

const OnboardingContext = createContext<OnboardingValueType>(false);
export const OnboardingProvider = OnboardingContext.Provider;

function useOnboardingFlag() {
  return useContext(OnboardingContext);
}
export function isPipelineOnboardingEnabled(onboarding: OnboardingValueType) {
  return onboarding === PIPELINE_FLOW_ID;
}
function isNewPipelineOnboardingEnabled(onboarding: OnboardingValueType) {
  return onboarding === NEW_PIPELINE_FLOW_ID;
}
function isSqlOnboardingEnabled(onboarding: OnboardingValueType) {
  return onboarding === SQL_PIPELINE_FLOW_ID;
}
function isSqlOnboardingCopilotEnabled(onboarding: OnboardingValueType) {
  return onboarding === SQL_COPILOT_FLOW_ID;
}
export function useIsSqlOnboardingEnabled() {
  const onboarding = useOnboardingFlag();
  return isSqlOnboardingEnabled(onboarding);
}
export function useIsSqlOnboardingCopilotEnabled() {
  const onboarding = useOnboardingFlag();
  return isSqlOnboardingCopilotEnabled(onboarding);
}
export function useIsPipelineOnboardingEnabled() {
  const onboarding = useOnboardingFlag();
  return isPipelineOnboardingEnabled(onboarding);
}
export function useIsNewPipelineOnboardingEnabled() {
  const onboarding = useOnboardingFlag();
  return isNewPipelineOnboardingEnabled(onboarding);
}
export function useIsOnboardingEnabled() {
  const isPipelineOnboardingEnabled = useIsPipelineOnboardingEnabled();
  const isSqlOnboardingEnabled = useIsSqlOnboardingEnabled();
  const isSqlOnboardingCopilotEnabled = useIsSqlOnboardingCopilotEnabled();

  return isSqlOnboardingEnabled || isPipelineOnboardingEnabled || isSqlOnboardingCopilotEnabled;
}

export function useOnboardingDetail() {
  const preLoginData = useAppMetadata();
  const onboardingDetail = (preLoginData.user as UserDetails).onboardingDetail;

  if (onboardingDetail?.project) {
    const {
      datasetDirectoryPath,
      fabric,
      sqlFabric,
      sqlProject,
      project: {
        pipelines: [pipeline]
      }
    } = onboardingDetail;
    return { pipeline, fabric: fabric, sqlFabric, sqlProject, datasetDirectoryPath };
  }
  return {
    pipeline: undefined,
    fabric: undefined,
    sqlFabric: undefined,
    sqlProject: undefined,
    datasetDirectoryPath: undefined
  };
}
function useCheckIfNewPipelineOnBoardingEnabled(params: URLSearchParams) {
  const onboardingStr = params.get('onboarding');
  return Boolean(onboardingStr);
}
export function useSparkOnboardingValue(uid: string) {
  const [params] = useSearchParams();
  const isNewPipelineOnboardingEnabled = useCheckIfNewPipelineOnBoardingEnabled(params);
  const { pipeline: demoPipeline, fabric } = useOnboardingDetail();
  const isPipelineOnboardingEnabled = demoPipeline && fabric && uid === demoPipeline._id;
  const onboarding = isNewPipelineOnboardingEnabled
    ? NEW_PIPELINE_FLOW_ID
    : isPipelineOnboardingEnabled
      ? PIPELINE_FLOW_ID
      : false;

  return onboarding;
}

export const useOnBoardingSkipFlow = () => {
  const { pipeline: demoPipeline, sqlProject } = useOnboardingDetail();
  const globalConf = useGlobalVariables();
  const updateOnboardingStatus = useUpdateOnboardingStatus();
  const { track } = useMixPanel();
  const sqlOnboardingUrl = Private_Routes.SQL_IDE.getUrl(
    {
      uid: sqlProject?._id as string
    },
    { entity: Entity.Model as string, name: Processes.CustomerNation.label }
  );
  const sparkOnboardingUrl = getIDEUrl({ uid: demoPipeline?._id as string, entity: Entity.Pipeline });
  const navigate = useNavigate();
  const fabrics = useUserFabrics();
  async function onSkip() {
    const sparkOnboardingStatus = globalConf.getSparkOnboardingStatus;
    const sqlOnboardingStatus = globalConf.getSqlOnboardingStatus;

    const isSqlOnboardingPending = sqlOnboardingStatus === OnboardingStatus.READY_TO_BEGIN;
    const isSparkOnboardingPending = sparkOnboardingStatus === OnboardingStatus.READY_TO_BEGIN;
    if (isSparkOnboardingPending && isSqlOnboardingPending) {
      await updateOnboardingStatus({
        lastFlowCompleted: OnboardingStatus.ONBOARDING_PIPELINE,
        lastFlowCompletedSql: OnboardingStatus.ONBOARDING_PIPELINE
      });
      track(
        Onboarding.onboarding_home.events.onboarding_home_skip,
        {
          onboarding_skip_sql: true,
          onboarding_skip_spark: true,
          onboarding_btn_clicked: true
        },
        Onboarding.onboarding_home.event_category
      );
    } else {
      track(
        Onboarding.onboarding_home.events.onboarding_home_skip,
        {
          onboarding_btn_clicked: true
        },
        Onboarding.onboarding_home.event_category
      );
    }

    openFabricSetup({ navigate, fabrics, globalConf });
  }
  return { onSkip, sqlOnboardingUrl, sparkOnboardingUrl, track, navigate };
};
