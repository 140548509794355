import { __rest } from "tslib";
import { createElement as _createElement } from "react";
import { useControlledState, useLazyEffect, usePersistentCallback } from '@prophecy/utils/react/hooks';
import { clamp, isNil } from 'lodash-es';
import { nanoid } from 'nanoid';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Input } from './Input';
export const NumberInput = forwardRef((_a, inRef) => {
    var { onChange, min, max, onBlur, onPressEnter, value, defaultValue, requiredMin, reactiveMinMax = true, allowDecimal = true } = _a, restProps = __rest(_a, ["onChange", "min", "max", "onBlur", "onPressEnter", "value", "defaultValue", "requiredMin", "reactiveMinMax", "allowDecimal"]);
    const ref = useRef(null);
    useImperativeHandle(inRef, () => ref.current, []);
    // TODO: this is a hack to reset the local state of the Input component as in Input component
    // we do not use the useControlledState
    const [renderId, setRenderId] = useState(() => nanoid());
    const [state = '', setState] = useControlledState({
        value,
        defaultValue,
        onChange: (value) => {
            const _value = Number(value);
            if (!isNil(min) && _value < min)
                return;
            if (!isNil(max) && _value > max)
                return;
            onChange === null || onChange === void 0 ? void 0 : onChange(value);
        }
    });
    const handleMinMax = usePersistentCallback((value) => {
        if (value === '') {
            if (requiredMin && !isNil(min)) {
                setState === null || setState === void 0 ? void 0 : setState(min);
                setRenderId(nanoid());
            }
            return;
        }
        let _value = Number(value);
        const _min = Math.min(min !== null && min !== void 0 ? min : Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
        const _max = Math.max(max !== null && max !== void 0 ? max : Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER);
        const clampedValue = clamp(_value, _min, _max);
        if (clampedValue !== _value) {
            //trigger on change if value is updated
            setState === null || setState === void 0 ? void 0 : setState(clampedValue);
            setRenderId(nanoid());
        }
    });
    function handleChange(_value) {
        const val = _value === '' ? undefined : Number(_value);
        if (requiredMin && !isNil(min) && val === undefined)
            return;
        if (val !== value) {
            setState === null || setState === void 0 ? void 0 : setState(val);
        }
    }
    useLazyEffect(() => {
        var _a;
        if (reactiveMinMax && ref.current !== document.activeElement) {
            handleMinMax(((_a = ref.current) === null || _a === void 0 ? void 0 : _a.value) || '');
        }
    }, [min, max]);
    return (_createElement(Input, Object.assign({}, restProps, { value: state, ref: ref, onBlur: (e) => {
            handleMinMax(e.currentTarget.value);
            onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
        }, onPressEnter: (e) => {
            handleMinMax(e.currentTarget.value);
            onPressEnter === null || onPressEnter === void 0 ? void 0 : onPressEnter(e);
        }, onKeyDown: (e) => {
            var _a;
            if (!allowDecimal && e.key === '.') {
                e.preventDefault();
            }
            (_a = restProps.onKeyDown) === null || _a === void 0 ? void 0 : _a.call(restProps, e);
        }, key: renderId, type: 'number', onChange: handleChange })));
});
