import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import { StyledSwitchButton, StyledToggleSwitchRoot } from './styled';
import { SwitchSize, Variant } from './types';
export const ToggleSwitch = ({ uncheckedLabel, variant = Variant.default, onChange, checked: _checked, size = SwitchSize.md, checkedTooltip, unCheckedTooltip, checkedLabel, disabled }) => {
    const [checked, setToggle] = useControlledState({
        value: _checked,
        defaultValue: false,
        onChange
    });
    return (_jsxs(StyledToggleSwitchRoot, { switchVariant: variant, checked: Boolean(checked), disabled: disabled, children: [_jsx(StyledSwitchButton, { onClick: () => {
                    if (!checked) {
                        setToggle(true);
                    }
                }, switchVariant: variant, switchSize: size, checked: checked, variant: 'plain', role: 'tab', "aria-selected": checked ? 'true' : 'false', tooltip: checkedTooltip, disabled: disabled, children: checkedLabel }), _jsx(StyledSwitchButton, { switchVariant: variant, switchSize: size, onClick: () => {
                    if (checked) {
                        setToggle(false);
                    }
                }, checked: !checked, variant: 'plain', role: 'tab', "aria-selected": !checked ? 'true' : 'false', tooltip: unCheckedTooltip, disabled: disabled, children: uncheckedLabel })] }));
};
