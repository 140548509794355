import { TEAM_TABS } from './types';

export const TEAM_TAB_LABELS = {
  [TEAM_TABS.basic]: 'Basic Info',
  [TEAM_TABS.executionMetrics]: 'Execution Metrics'
};

export const TEAM_STEPS = [
  {
    key: TEAM_TABS.basic,
    label: TEAM_TAB_LABELS[TEAM_TABS.basic]
  },
  {
    key: TEAM_TABS.executionMetrics,
    label: TEAM_TAB_LABELS[TEAM_TABS.executionMetrics]
  }
];
