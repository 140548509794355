import styled, { css } from 'styled-components';
import { Icon } from '../Icons';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { tokens } from './tokens';
import { Placement } from './types';
export const StyledIconWrapper = styled.span `
  user-select: none;
  display: inline-block;
  line-height: 1;
  order: ${({ addOnPlacement, iconPlacement }) => iconPlacement === Placement.left && addOnPlacement !== Placement.left
    ? '1'
    : iconPlacement === Placement.right
        ? '3'
        : '2'};
`;
export const StyledAddOnWrapper = styled.span `
  flex-shrink: 0;
  display: inline-block;
  font-size: ${theme.fontSizes.x14};
  color: ${theme.colors.grey500};
  order: ${({ addOnPlacement, iconPlacement }) => addOnPlacement === Placement.left && iconPlacement !== Placement.left
    ? '1'
    : addOnPlacement === Placement.right
        ? '3'
        : '2'};
  ${({ addOnPlacement }) => addOnPlacement === Placement.left
    ? `
  border-right: 1px solid ${theme.colors.grey300};
  height: 100%;
  
  padding-right: ${theme.spaces.x10};
  `
    : `
  padding-left: ${theme.spaces.x10};
  border-left: 1px solid ${theme.colors.grey300};
  `}
  line-height: ${({ size }) => tokens.Input.size[size].height};
`;
export const StyledInputRoot = styled(Stack) `
  font-family: ${theme.fontFamily.text};
  position: relative;
  color: ${tokens.Input.color};

  /** Style icon base on size */
  ${Icon} {
    color: ${(props) => { var _a; return (_a = tokens.Input[props.variant].iconColor) !== null && _a !== void 0 ? _a : tokens.Input.iconColor; }};
    font-size: ${(props) => tokens.Input.size[props.size].iconSize};
  }
`;
export const StyledLabelWithHint = styled(LabelWithHint) `
  ${Icon} {
    color: ${tokens.Input.iconColor};
    font-size: ${tokens.Input.hintIconSize};
  }
`;
export const inputColorStyles = css `
  background-color: ${(props) => tokens.Input[props.variant].backgroundColor};
  border-radius: ${(props) => tokens.Input.size[props.inputSize].borderRadius};
  border: 1px solid ${({ variant }) => tokens.Input[variant].borderColor};
  color: ${(props) => tokens.Input[props.variant].color};
  box-shadow: ${(props) => tokens.Input.boxShadow};
  cursor: pointer;
  &:hover {
    color: ${(props) => tokens.Input[props.variant].hover.color};
  }
  &:focus-within,
  &[data-active='true'] {
    border-color: ${(props) => tokens.Input[props.variant].focus.borderColor};
    box-shadow: ${(props) => tokens.Input[props.variant].focus.boxShadow};
  }
  &[disabled],
  input[disabled] {
    color: ${tokens.Input.disabled.color};
    background-color: ${tokens.Input.disabled.backgroundColor};
    border-color: ${tokens.Input.disabled.borderColor};
    cursor: not-allowed;
  }
`;
export const inputContainerStyles = css `
  all: unset;
  box-sizing: border-box;
  padding: ${(props) => `${tokens.Input.size[props.inputSize].paddingVertical} ${tokens.Input.size[props.inputSize].padding}`};
  ${(props) => props.type === 'number' && `padding-right: 0;`}
  font-size: ${(props) => tokens.Input.size[props.inputSize].fontSize};
  height: ${(props) => tokens.Input.size[props.inputSize].height};
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${(props) => tokens.Input.size[props.inputSize].gap};

  [type='number']::-webkit-inner-spin-button {
    height: ${(props) => tokens.Input.size[props.inputSize].height};
  }
`;
export const StyledInputContainer = styled(Stack) `
  ${inputContainerStyles}
  ${inputColorStyles}
`;
export const InputWrap = styled(Stack) `
  width: 100%;
  height: 100%;
  order: ${({ addOnPlacement, iconPlacement }) => (addOnPlacement || iconPlacement ? '2' : '1')};
`;
export const StyledInput = styled.input `
  padding: 0;
  display: inline-block;
  line-height: 1;
  height: 100%;
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  color: ${({ addOn }) => (addOn ? `${theme.colors.grey900}` : 'inherit')};
  font-size: 1em;
  font-family: ${theme.fontFamily.text};
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:-webkit-autofill {
    -webkit-background-clip: text;
  }
`;
export const StyledLabel = styled.label `
  font-size: ${tokens.Input.Label.fontSize};
  color: ${tokens.Input.Label.color};
  ${Icon} {
    color: ${tokens.Input.Label.iconColor};
    font-size: ${tokens.Input.Label.iconSize};
  }
`;
export const StyledHelpText = styled(Stack) `
  font-size: ${tokens.Input.HelpText.fontSize};
  color: ${(props) => tokens.Input.HelpText[props.variant].color};
`;
