import { theme } from '../theme';
export const tokens = {
    Drawer: {
        padding: theme.spaces.x12,
        zIndex: theme.zLayer.m,
        Header: {
            height: theme.sizes.x32,
            background: theme.colors.grey25,
            borderColor: theme.colors.grey200
        },
        Content: {
            background: theme.colors.white
        },
        Icon: {
            color: theme.colors.grey400
        }
    },
    DrawerTabHeader: {
        Tab: {
            active: {
                backgroundColor: theme.colors.grey200
            }
        },
        Separator: {
            height: theme.sizes.x24
        }
    }
};
