import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { stopPropagation } from '@prophecy/utils/dom';
import { useState, forwardRef } from 'react';
import { Stack } from '../Layout';
import { BaseColor, theme } from '../theme';
import { StyledButton, StyledClearIcon, StyledNumberContainer } from './styled';
import { Size, Placement } from './types';
function _Tags(_a, forwardRef) {
    var { closable = false, onClose, icon, children, size = Size.m, count, tone = BaseColor.grey, iconPlacement = Placement.left, filled = true, prefix, disabled, countCircle = false } = _a, restProps = __rest(_a, ["closable", "onClose", "icon", "children", "size", "count", "tone", "iconPlacement", "filled", "prefix", "disabled", "countCircle"]);
    const [visible, toggle] = useState(true);
    if (!visible) {
        return null;
    }
    const _onClose = (e) => {
        toggle(false);
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    return (_jsx(StyledButton, Object.assign({ ref: forwardRef, size: size, tone: tone, filled: filled, icon: closable ? _jsx(StyledClearIcon, { type: 'mini', tone: tone, onClick: _onClose, onPointerDown: stopPropagation }) : null, disabled: disabled }, restProps, { iconPlacement: 'right', children: _jsxs(Stack, { direction: 'horizontal', gap: theme.spaces.x8, alignY: 'center', children: [prefix, iconPlacement === Placement.left && icon, children, count !== undefined && (_jsx(StyledNumberContainer, { disabled: disabled, countCircle: countCircle, size: size, tone: tone, children: count })), iconPlacement === Placement.right && icon] }) })));
}
// NOTE: Sadly forwardRef doesn't allow generic types, so we have to typecast it
export const Tags = forwardRef(_Tags);
