function getCursorNode(editor) {
    var _a;
    return (_a = editor.getDomNode()) === null || _a === void 0 ? void 0 : _a.getElementsByClassName('cursor monaco-mouse-cursor-text')[0];
}
export const BlinkCursorActions = {
    Start: 'blinkCursor.start',
    End: 'blinkCursor.end'
};
export function blinkCursorActions(editor) {
    const animationName = 'blinkCursor';
    const startAction = {
        id: BlinkCursorActions.Start,
        label: 'Blink Cursor start',
        run: (editor) => {
            const node = getCursorNode(editor);
            if (node) {
                const animations = node.getAnimations().filter((animation) => animation.id === animationName);
                if (animations.length > 0) {
                    // already running
                    return;
                }
                node.animate([
                    {
                        visibility: 'hidden',
                        opacity: 0
                    },
                    {
                        visibility: 'inherit',
                        opacity: 1
                    },
                    {
                        visibility: 'hidden',
                        opacity: 0
                    }
                ], { id: animationName, duration: 1250, iterations: Infinity });
            }
        }
    };
    const endAction = {
        id: BlinkCursorActions.End,
        label: 'Blink Cursor end',
        run: (editor) => {
            const node = getCursorNode(editor);
            if (node) {
                const animations = node.getAnimations().filter((animation) => animation.id === animationName);
                for (const animation of animations) {
                    animation.cancel();
                }
            }
        }
    };
    editor.addAction(startAction);
    editor.addAction(endAction);
}
