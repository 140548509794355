import { Content } from '@radix-ui/react-dialog';
import styled, { css } from 'styled-components';
import { Button } from '../Button';
import { Stack, StackItem } from '../Layout';
import { slideLeft, slideRight, fadeOut, fadeIn } from '../theme/index';
import { tokens } from './tokens';
export const StyledOverlay = styled.div `
  background-color: ${tokens.SlidingPanel.Overlay.backgroundColor};
  backdrop-filter: blur(4px);
  opacity: 0.3;
  position: fixed;
  inset: 0;
  z-index: ${tokens.SlidingPanel.Overlay.zIndex};
  &[data-state='open'] {
    animation: ${fadeIn(0.3)} ${tokens.SlidingPanel.Content.transition};
  }
  &[data-state='closed'] {
    animation: ${fadeOut(0.3)} ${tokens.SlidingPanel.Content.transition};
  }
`;
const dialogContentStyles = (isContentPanelOpen, disableContentPadding, hasFooter) => css `
  ${disableContentPadding
    ? ''
    : `
        padding: ${tokens.SlidingPanel.Content.paddingY} ${tokens.SlidingPanel.Content.paddingX};
        padding-right: ${isContentPanelOpen ? '0px' : tokens.SlidingPanel.Content.paddingX};
    `}
`;
const dialogFooterStyles = (size, isContentPanelOpen) => css `
  padding: ${tokens.SlidingPanel.Footer.padding};
`;
export const StyledDialog = styled(Content) `
  background-color: ${tokens.SlidingPanel.Content.backgroundColor};
  box-shadow: ${tokens.SlidingPanel.Content.boxShadow};
  position: fixed;
  top: 0;
  z-index: ${tokens.SlidingPanel.Content.zIndex};
  border-radius: ${(props) => tokens.SlidingPanel.borderRadius + ' 0 0 0'};
  width: ${(props) => tokens.SlidingPanel.size[props.size].width};
  min-width: ${(props) => tokens.SlidingPanel.size[props.size].minWidth};
  @media (max-width: calc(${(props) => tokens.SlidingPanel.size[props.size].minWidth} + 60px )) {
    min-width: calc(100vw - 60px);
  }
  ${({ size }) => css `
    &[data-state='open'] {
      animation: ${slideLeft(tokens.SlidingPanel.size[size].width)} ${tokens.SlidingPanel.Content.transition};
    }
    &[data-state='closed'] {
      animation: ${slideRight(tokens.SlidingPanel.size[size].width)} ${tokens.SlidingPanel.Content.transition};
    }
  `}
  right: 0;
  height: 100%;
`;
export const PanelOverflowContainer = styled.div `
  width: 100%;
  height: 100%;
  overflow: auto;
`;
export const PanelContainer = styled(Stack) `
  width: 100%;
  height: 100%;
  min-width: ${(props) => tokens.SlidingPanel.size[props.size].minWidth};
`;
export const StyledCloseButton = styled(Button) `
  position: absolute;
  left: -55px;
  top: 16px;
  background: ${tokens.SlidingPanel.CloseButton.backgroundColor};
  &:focus {
    background: ${tokens.SlidingPanel.CloseButton.backgroundColor};
  }
  &:hover,
  &:active {
    &:not([disabled]) {
      background-color: ${tokens.SlidingPanel.CloseButton.hover.backgroundColor};
      color: inherit;
      border: inherit;
    }
  }
`;
export const StyledContent = styled(StackItem) `
  ${(props) => dialogContentStyles(props.isContentPanelOpen, props.disableContentPadding, props.hasFooter)}
  overflow: auto;
  outline: none;
`;
export const StyledScrollableSection = styled(Stack) `
  overflow: auto;
  padding-right: ${tokens.SlidingPanel.Content.paddingX};
  margin-right: -${tokens.SlidingPanel.Content.paddingX};
`;
export const StyledFooter = styled.div `
  ${(props) => dialogFooterStyles(props.size, props.isContentPanelOpen)}
  border-top: 1px solid ${tokens.SlidingPanel.Footer.borderColor};
  height: ${tokens.SlidingPanel.Footer.height};
  width: 100%;
  position: relative; // To make the drawer component respect the footer width.
  flex-shrink: 0;
  &:empty {
    display: none;
  }
`;
// For cases where footer is used inside content
export const StyledContentFooter = styled(StyledFooter) `
  margin: 0 -${tokens.SlidingPanel.Content.paddingX} -${tokens.SlidingPanel.Content.paddingY};
  flex-shrink: 0;
  width: calc(100% + 2 * ${tokens.SlidingPanel.Content.paddingX});
`;
export const StyledClosePanelButton = styled(Button) `
  position: absolute;
  left: -40px;
  top: ${tokens.SlidingPanel.PanelCloseButton.top};
  z-index: ${tokens.SlidingPanel.PanelCloseButton.zIndex};
  border-radius: ${tokens.SlidingPanel.PanelCloseButton.borderRadius} 0 0
    ${tokens.SlidingPanel.PanelCloseButton.borderRadius};
`;
export const StyledContentPanel = styled(Stack) `
  position: relative;
`;
