import React, { createContext, useContext } from 'react';

import { useMixPanelContext } from './hook';

const MixPanelContext = createContext<ReturnType<typeof useMixPanelContext> | null>(null);

export function useMixPanel() {
  /*const mixpanel = useMixPanelContext();
    const onMount = usePersistentCallback((mixPanelObj) => props?.onMount?.(mixPanelObj));
    const onUnmount = usePersistentCallback((mixPanelObj) => props?.onUnmount?.(mixPanelObj));
    useEffect(() => {
        onMount?.(mixpanel);
        if (onUnmount) {
            return () => {
                onUnmount(mixpanel);
            };
        }
    }, [onUnmount, onMount]);*/
  return useContext(MixPanelContext) as ReturnType<typeof useMixPanelContext>;
}

export function MixPanelProvider({ children }: { children: React.ReactNode }) {
  const mixpanel = useMixPanelContext();
  return <MixPanelContext.Provider value={mixpanel}>{children}</MixPanelContext.Provider>;
}
