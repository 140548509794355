import { theme } from '../theme/index';
export const tokens = {
    TabsRoot: {
        gap: theme.spaces.x8,
        borderColor: theme.colors.grey200,
        borderRadius: theme.radius.l,
        padding: theme.spaces.x12
    },
    TabsList: {
        fontSize: theme.fontSizes.x14,
        padding: theme.outlineWidth,
        fontFamily: theme.fontFamily.text,
        white: {
            borderRadius: theme.radius.m,
            borderColor: theme.colors.grey100,
            backgroundColor: theme.colors.grey50,
            padding: theme.spaces.x2
        }
    },
    TabsTrigger: {
        fontSize: theme.fontSizes.x14,
        color: theme.colors.grey500,
        borderRadius: theme.radius.s,
        transition: theme.transition.m,
        primary: {
            backgroundColor: theme.colors.primary50,
            color: theme.colors.primary700,
            boxShadow: 'none'
        },
        grey: {
            backgroundColor: theme.colors.grey50,
            color: theme.colors.grey700,
            boxShadow: 'none'
        },
        white: {
            backgroundColor: theme.colors.white,
            color: theme.colors.grey700,
            boxShadow: theme.shadows.sm
        },
        disabled: {
            color: theme.colors.grey300,
            borderColor: theme.colors.grey200,
            backgroundColor: theme.colors.white
        },
        size: {
            s: {
                height: theme.sizes.x28,
                padding: theme.sizes.x12,
                margin: theme.spaces.x2,
                fontSize: theme.fontSizes.x13
            },
            m: {
                height: theme.sizes.x40,
                padding: theme.sizes.x14,
                margin: theme.spaces.x4,
                fontSize: theme.fontSizes.x14
            }
        }
    },
    TabsTriggerGroup: {
        backgroundColor: theme.colors.primary50,
        TabsTrigger: {
            primary: {
                color: theme.colors.primary500,
                active: {
                    backgroundColor: theme.colors.primary100,
                    color: theme.colors.primary700
                }
            },
            grey: {
                color: theme.colors.grey500,
                active: {
                    backgroundColor: theme.colors.grey100,
                    color: theme.colors.grey700
                }
            },
            white: {
                color: theme.colors.grey500,
                active: {
                    backgroundColor: theme.colors.grey100,
                    color: theme.colors.grey700
                }
            }
        }
    },
    SwitchTabs: {
        TabsList: {
            background: theme.colors.grey100,
            borderRadius: theme.radius.s,
            fontWeight: theme.fontWeight.medium,
            color: theme.colors.grey500
        },
        TabsTrigger: {
            color: theme.colors.grey500,
            primary: {
                active: {
                    borderColor: theme.colors.purple300,
                    color: theme.colors.purple700,
                    iconColor: theme.colors.purple600
                }
            },
            active: {
                backgroundColor: theme.colors.white,
                borderColor: theme.colors.grey300,
                borderRadius: theme.radius.s,
                color: theme.colors.grey700,
                boxShadow: theme.shadows.sm
            }
        }
    }
};
