import styled, { css } from 'styled-components';
import { MONACO_DECORATION_TOOLTIP_WIDGET_SELECTOR } from '../Editor/tokens';
import { EditorWrapper, ExpressionActionsWrapper, VERTICAL_PADDING_TOP } from '../ExpressionBox/base/components';
import { EXPRESSION_BOX_CODE_BLOCK_CLS, EXPRESSION_BOX_EDITOR_CLS, EXPRESSION_ACTION_WRAPPER_CLS } from '../ExpressionBox/base/constants';
import { CodeWrapper, StyledCodeBlock } from '../ExpressionBox/ExpressionBox';
import { InputWrap, StyledInputContainer, StyledInputRoot } from '../Input/styled';
import { StyledTextArea, StyledTextAreaContainer, TextAreaRoot } from '../Input/TextArea.styled';
import { StyledHandle, StyledSelectArrow, StyledSelectRoot, StyledSelectWrapper } from '../Select/styled';
import { SELECT_CLEAR_CLASS, SELECT_OPEN_CLASS, SELECT_ROOT_CLASS, SELECT_SELECTOR_CLASS } from '../Select/tokens';
import { theme } from '../theme';
import { CUSTOM_ROW_COMPONENT_CLASS } from './components';
import { SelectionActionsContainer } from './styled';
import { Table } from './TableBase';
import { CONFIG_FIELD_CARET_CONTAINER, CONFIG_FIELD_SEARCH_INPUT, CONFIG_FIELD_TAG_CONTAINER, CONFIG_FIELD_TAG_ICON, IGNORE_TABLE_STYLES, ROW_MINIMUM_HEIGHT, TABLE_CELL_CLASS, TABLE_CONTAINER, TABLE_MEASURE_ROW_CLASS, TABLE_ROW_CLASS, TABLE_THEAD_CLASS, tokens } from './tokens';
export const muteExcelTableStyles = `mute-excel-table-styles`;
export const stretchToFlexParent = css `
  min-height: 100%;
  max-height: 100%;
  height: auto;
  align-self: stretch;
`;
const InputSelector = `${StyledInputRoot}:not(.${IGNORE_TABLE_STYLES}) > ${StyledInputContainer}`;
export const tableInputStyles = css `
  border-radius: 0px;
  border-color: transparent;
  box-shadow: none;
  width: calc(100% + 2 * ${tokens.ExcelTable.Cell.padding}) !important; // add minus margin
  margin: 0 -${tokens.ExcelTable.Cell.padding};

  &,
  &:hover:not(:focus-within):not([disabled]) {
    border-color: transparent;
  }

  &:focus-within:not([disabled]) {
    border-color: transparent;
    border-bottom-color: ${tokens.ExcelTable.Input.focus.borderColor};
  }

  &[variant='error'] {
    &,
    &:hover:not([disabled]),
    &:focus-within:not([disabled]) {
      border-color: transparent;
      border-bottom-color: ${tokens.ExcelTable.Input.error.borderColor};
    }
  }
`;
export const ExcelTable = styled(Table) `
  && {
    height: 100%;

    table {
      height: 1px; /** Hack to make input respect height 100% */
    }

    .ui-table-cell-row-hover {
      background-color: ${tokens.Row.backgroundColor};
    }

    .ui-table-row-expand-icon {
      height: 100%;
    }

    /**
    * Note, this is patch so that on the first row, monaco editor tooltips are not hidden by the overflow
    * Monaco do not support rendering the tooltip outside of moanco editor boundary, or changing the placement.
    * So we check if in the first row of table if any monaco tooltip has < -20px top, then transform it to keep
    * below the tag.
    */
    .${TABLE_MEASURE_ROW_CLASS} + .${TABLE_ROW_CLASS} {
      ${MONACO_DECORATION_TOOLTIP_WIDGET_SELECTOR}[style*='top: -2'], ${MONACO_DECORATION_TOOLTIP_WIDGET_SELECTOR}[style*='top: -3'] {
        transform: translateY(50px);
      }
    }

    td:not(.${CUSTOM_ROW_COMPONENT_CLASS},.${muteExcelTableStyles}) {
      ${StyledSelectRoot}, ${StyledHandle}, ${StyledSelectWrapper}, .ui-select, .text-cell {
        ${stretchToFlexParent}
        width: 100%;
        height: 100%;
        min-height: ${({ rowHeight }) => rowHeight || ROW_MINIMUM_HEIGHT}px;
        max-height: 100px;
      }

      ${StyledInputRoot} {
        ${stretchToFlexParent}
        width: 100%;
      }

      ${EditorWrapper} section,  ${CodeWrapper}, ${StyledCodeBlock} {
        min-height: 100%;
        width: 100%;
      }

      ${StyledCodeBlock} {
        overflow-y: hidden;
      }

      ${TextAreaRoot}, ${StyledTextAreaContainer} {
        ${stretchToFlexParent}
      }

      ${StyledTextArea} {
        ${stretchToFlexParent}
      }

      .text-cell {
        padding: ${tokens.ExcelTable.Cell.padding};
      }
      .has-error {
        .${EXPRESSION_BOX_CODE_BLOCK_CLS}, .${EXPRESSION_BOX_EDITOR_CLS} {
          border-color: transparent;
          border-bottom-color: ${tokens.ExcelTable.Input.error.borderColor} !important;
        }
      }
      .${EXPRESSION_BOX_CODE_BLOCK_CLS}, .${EXPRESSION_BOX_EDITOR_CLS}, ${InputSelector}, ${StyledTextAreaContainer} {
        ${tableInputStyles}
      }

      ${ExpressionActionsWrapper} {
        margin-right: -${tokens.ExcelTable.Cell.padding};
      }

      ${StyledSelectWrapper}[variant='error'] {
        .${SELECT_ROOT_CLASS} {
          &,
          &:hover:not([disabled]),
          &:focus-within:not([disabled]) {
            border-color: transparent;
            border-bottom-color: ${tokens.ExcelTable.Input.error.borderColor};
          }
        }
      }

      .monaco-editor,
      .monaco-editor-background,
      .${EXPRESSION_BOX_EDITOR_CLS} {
        background: transparent;
      }

      ${InputSelector} {
        height: auto;
        flex-grow: 1;
        border-radius: 0;
        width: calc(100% + 2 * ${tokens.ExcelTable.Cell.padding}); // add minus margin
        font-size: ${tokens.ExcelTable.Input.fontSize};
        padding: 0;
        margin: 0 -${tokens.ExcelTable.Cell.padding};

        ${InputWrap} {
          height: auto;

          input {
            padding: ${tokens.ExcelTable.Cell.padding};
          }
        }
      }

      .${CONFIG_FIELD_TAG_CONTAINER} {
        padding: 0;
        margin-top: ${theme.spaces.x2};

        .${CONFIG_FIELD_SEARCH_INPUT} input {
          padding: ${theme.spaces.x4} ${theme.spaces.x8};
        }

        .${CONFIG_FIELD_CARET_CONTAINER} {
          top: 0;
        }

        .${CONFIG_FIELD_TAG_ICON} {
          height: ${theme.spaces.x16};
          width: ${theme.spaces.x16};
          top: 0;
        }
      }

      //TODO add a className in all the component spec to disable the style
      .ui-select,
      ${StyledHandle} {
        border-radius: 0;
        font-size: ${tokens.ExcelTable.Input.fontSize};
        align-items: stretch;
        margin: 0 -${tokens.ExcelTable.Cell.padding};
        width: calc(100% + 2 * ${tokens.ExcelTable.Cell.padding});
        border-color: transparent;
        box-shadow: none;

        .${SELECT_SELECTOR_CLASS} {
          height: auto;
          padding-left: ${tokens.ExcelTable.Cell.padding};
        }

        &,
        ${StyledSelectArrow} {
          min-width: auto;
        }

        ${StyledSelectArrow}, .${SELECT_CLEAR_CLASS} {
          height: auto;
          right: calc(${tokens.ExcelTable.Cell.padding} / 2);
        }

        &:hover:not([disabled], .ui-select-disabled),
        &.${SELECT_OPEN_CLASS} {
          border-color: transparent;
          border-bottom-color: ${tokens.ExcelTable.Input.focus.borderColor};
        }

        &[variant='error'] {
          &,
          &:hover:not([disabled]),
          &:focus-within:not([disabled]) {
            border-color: transparent;
            border-bottom-color: ${tokens.ExcelTable.Input.error.borderColor};
          }
        }
      }
    }

    ${({ variant }) => variant === 'secondary' &&
    css `
        .${TABLE_CONTAINER} {
          border: none;
          border-radius: 0;
          overflow: visible;
        }

        .${TABLE_ROW_CLASS}:not(.dummy-row) {
          height: 40px;
        }

        .${TABLE_ROW_CLASS} td,
        .${TABLE_THEAD_CLASS} th {
          border-bottom: 1px solid ${theme.colors.grey200};
        }

        .${TABLE_CELL_CLASS} {
          padding-left: ${theme.spaces.x4};
        }

        .${TABLE_THEAD_CLASS} th {
          background: ${theme.colors.white};
          font-weight: ${theme.fontWeight.medium};
          padding: ${theme.spaces.x12} ${theme.spaces.x4};
        }

        .selection-cell {
          padding: ${theme.spaces.x12} !important;
        }

        ${SelectionActionsContainer} {
          background: ${theme.colors.white};
          border: none;
          border-radius: 0;
          height: ${theme.sizes.x48};
          padding-left: 0;
        }

        .${EXPRESSION_BOX_CODE_BLOCK_CLS} {
          padding-top: ${VERTICAL_PADDING_TOP + 2}px;
        }

        .${EXPRESSION_BOX_EDITOR_CLS} {
          padding-top: ${theme.spaces.x2};
        }

        .${EXPRESSION_ACTION_WRAPPER_CLS} {
          top: ${theme.spaces.x6};
        }
      `}
  }
`;
// add column and column group to table
ExcelTable.Column = Table.Column;
ExcelTable.ColumnGroup = Table.ColumnGroup;
ExcelTable.IconButton = Table.IconButton;
