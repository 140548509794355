import DocumentTitle from '@prophecy/utils/react/DocumentTitle';

import DeploymentStepper from './components/DeploymentStepper';
import DeployProphecy from './components/DeployProphecy';
import PostDeploymentCheck from './components/PostDeploymentCheck';
import PreDeploymentCheck from './components/PreDeploymentCheck';
import { DEPLOYMENT_STEPS } from './constants';
import { DEPLOYMENT_TABS } from './types';

function getStepperContent(key: Exclude<DEPLOYMENT_TABS, 'INFRA_SETUP'>, nextStep: () => void, prevStep: () => void) {
  const stepperContents = {
    [DEPLOYMENT_TABS.DEPLOYMENT_PRE_CHECK]: <PreDeploymentCheck nextStep={nextStep} prevStep={prevStep} />,
    [DEPLOYMENT_TABS.POST_DEPLOY]: <PostDeploymentCheck nextStep={nextStep} prevStep={prevStep} />,
    [DEPLOYMENT_TABS.DEPLOY]: <DeployProphecy nextStep={nextStep} prevStep={prevStep} />
  };

  return stepperContents[key];
}

export default function DeploymentPage() {
  return (
    <>
      <DocumentTitle title='Prophecy Deployment' />
      <DeploymentStepper steps={DEPLOYMENT_STEPS} getStepContent={getStepperContent} />
    </>
  );
}
