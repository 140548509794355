import { Resizable as Resize } from 're-resizable';
import styled from 'styled-components';
import { ResizeIcon } from '../Icons';
import { tokens, RESIZE_HANDLE_CLASS } from './tokens';
export const StyledResizeIcon = styled(ResizeIcon) `
  visibility: hidden;
  transform: ${({ direction }) => (direction === 'top' || direction === 'bottom' ? 'rotate(90deg)' : 'rotate(0deg)')};
`;
export const StyledResize = styled(Resize) `
  .${RESIZE_HANDLE_CLASS} {
    background-color: transparent;
    &:hover,
    &:focus,
    &:active {
      background-color: ${tokens.ResizeHandle.backgroundColor};
      ${StyledResizeIcon} {
        visibility: visible;
      }
    }
  }
`;
