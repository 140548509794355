import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { isCronRange, isCronLastValue, isCronSpecificDayOfWeek } from '@vangware/cron';
import { CronType } from './types';
export function getArrayFromRange(from, to) {
    return Array.from({ length: to - from + 1 }).map((_, i) => i + from);
}
export const Minutes = getArrayFromRange(0, 59);
export const DaysInMonth = getArrayFromRange(1, 31);
export const Seconds = getArrayFromRange(0, 59);
export const Hours24 = getArrayFromRange(0, 23);
export const Hours12 = getArrayFromRange(1, 12);
export const DaysHalf = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
export const DaysFull = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
export const MonthHalf = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
export const MonthFull = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER'
];
export const Weeks = ['first', 'second', 'third', 'fourth', 'last'];
export var DayTime;
(function (DayTime) {
    DayTime["AM"] = "AM";
    DayTime["PM"] = "PM";
})(DayTime || (DayTime = {}));
const WildCards = ['*', '?', 'L', 'W', '#'];
export const cronQuartzDefaults = {
    dayOfMonth: '*',
    dayOfWeek: '?',
    month: '*',
    seconds: 0,
    year: '*',
    hours: '*',
    minutes: '*'
};
export const cronUnixDefaults = {
    dayOfMonth: '*',
    dayOfWeek: '*',
    month: '*',
    hours: '*',
    minutes: '*'
};
export function getCronDefaults(cronType) {
    return cronType === CronType.unix ? cronUnixDefaults : cronQuartzDefaults;
}
/**
 * Function to return array of values from a cron part.
 * Note weekDay and month can have string representation of them, so we we need to convert them to numbers.
 * allValues contains the string values for such cases, from which we derive the numeric for the string representation
 */
export function getValuesFromCronPart(values, allValues = []) {
    let flattendValues = [];
    // if its a wild card no need to convert anything, the cron generator don't support wild characters
    if (typeof values === 'string' && WildCards.includes(values)) {
        flattendValues = [];
    }
    else if (typeof values === 'number') {
        flattendValues = [values];
    }
    else if (typeof values === 'string') {
        // if its a string representation (Weekday and month), convert them to number using allValues
        flattendValues = [allValues.indexOf(values) + 1];
    }
    else if (isCronRange(values)) {
        const { from, to } = values;
        const fromNumber = typeof from === 'string' ? allValues.indexOf(from) + 1 : from;
        const toNumber = typeof to === 'string' ? allValues.indexOf(to) + 1 : to;
        flattendValues = getArrayFromRange(fromNumber, toNumber);
    }
    else if (Array.isArray(values)) {
        // if its array of values loop to each item and convert them to number
        values.forEach((value) => {
            flattendValues = flattendValues.concat(getValuesFromCronPart(value, allValues));
        });
        // for unknown values, no need to parse for now
    }
    else {
        flattendValues = [];
    }
    return flattendValues;
}
export function getRangeFromValues(values, allValues) {
    const valuesIndexes = Object.fromEntries(allValues.map((value, index) => [value, index]));
    let rangeString = '';
    // sort values
    const _values = [...values].sort((a, b) => valuesIndexes[a] - valuesIndexes[b]);
    for (let i = 0, ln = _values.length; i < ln; i++) {
        const value = _values[i];
        const nextValue = _values[i + 1];
        const valueIndex = valuesIndexes[value];
        const nextValueIndex = valuesIndexes[_values[i + 1]];
        // if next value is also on range, continue until the range is ended
        if (rangeString.endsWith('-') && valueIndex + 1 === nextValueIndex) {
            continue;
            // if the next value matches the next index, and range is not started, start the range expression
        }
        else if (valueIndex + 1 === nextValueIndex) {
            rangeString += `${value}-`;
            // at the end of array just append the value
        }
        else if (!nextValue) {
            rangeString += `${value}`;
            // for non range case add values in comma separated pattern
        }
        else {
            rangeString += `${value},`;
        }
    }
    return rangeString;
}
export function renderRangeTag(currentItem, values, allValues) {
    // only return for first item, and cover all the rest of the values as one tag.
    if (!(values === null || values === void 0 ? void 0 : values.length) || currentItem !== values[0]) {
        return _jsx(_Fragment, {});
    }
    return _jsx("span", { children: getRangeFromValues(values, allValues) });
}
export function useTimeValues(cronValue, setCronValue, retainPartKeys, cronType) {
    let hours = cronValue.hours || 0;
    let minutes = cronValue.minutes || 0;
    // if hours and minutes are on different format * or range, reset it to 0,0
    if (typeof hours !== 'number')
        hours = 0;
    if (typeof minutes !== 'number')
        minutes = 0;
    const handleTimeChange = ([hours, minutes]) => {
        // reset all other fields and set hour and minute, along with
        const newCronValue = Object.assign(Object.assign({}, getCronDefaults(cronType)), { hours: hours, minutes: minutes });
        retainPartKeys.forEach((key) => {
            //@ts-ignore - we know the keys are correct, but typescript doesn't know about the union keys
            newCronValue[key] = cronValue[key];
        });
        setCronValue(newCronValue);
    };
    return { hours, minutes, handleTimeChange };
}
export function extractDayAndWeek(dayOfWeek) {
    if (typeof dayOfWeek === 'number') {
        return { day: dayOfWeek };
    }
    else if (typeof dayOfWeek === 'string' && !WildCards.includes(dayOfWeek)) {
        return { day: DaysHalf.indexOf(dayOfWeek) + 1 };
    }
    else if (isCronLastValue(dayOfWeek)) {
        return { day: dayOfWeek.last, week: 5 };
    }
    else if (isCronSpecificDayOfWeek(dayOfWeek)) {
        return dayOfWeek;
    }
    return {};
}
export function toCronDayOfWeek(day, week) {
    if (week === undefined) {
        return day;
    }
    else if (week === 5) {
        return { last: day };
    }
    else {
        return { day: day, week: week };
    }
}
export function to12HourFormat(hour) {
    let hour12 = hour % 12;
    if (hour12 === 0)
        hour12 = 12;
    const dayTime = hour < 12 ? DayTime.AM : DayTime.PM;
    return [hour12, dayTime];
}
export function to24HourFormat(hour, dayTime) {
    if (dayTime === DayTime.PM && hour < 12) {
        return hour + 12;
    }
    else if (dayTime === DayTime.AM && hour === 12) {
        return 0;
    }
    else {
        return hour;
    }
}
