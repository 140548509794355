export function getFileAndFolder(path) {
    const [fileName, ...parts] = path.split('/').reverse();
    const folder = parts.reverse().join('/');
    return [fileName, folder];
}
export const getFileSizeWithUnit = (size) => {
    let sizeText = `${size} bytes`;
    const sizeInKb = 1024;
    const sizeInMB = sizeInKb * 1024;
    const sizeInGB = sizeInMB * 1024;
    if (size >= sizeInKb) {
        if (size < sizeInMB) {
            sizeText = `${Math.round(size / sizeInKb)} KB`;
        }
        else if (size < sizeInGB) {
            sizeText = `${Math.round(size / sizeInMB)} MB`;
        }
        else if (size > sizeInGB) {
            sizeText = `${Math.round(size / sizeInGB)} GB`;
        }
    }
    return sizeText;
};
