import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { filterWith } from '@prophecy/utils/nestedData';
import { useAfterNextRender, useControlledState } from '@prophecy/utils/react/hooks';
import { get } from 'lodash-es';
import { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useImmer } from 'use-immer';
import { Button } from '../Button';
import { CheckboxGroup } from '../Checkbox';
import { Dropdown } from '../Dropdown';
import { SearchSmIcon, FilterFunnelAIcon } from '../Icons';
import { Input } from '../Input';
import { Stack, StackItem } from '../Layout';
import { theme } from '../theme';
import { TABLE_BODY } from './tokens';
import { FilterType } from './types';
const StyledColumnSearchFilter = styled(Stack) `
  position: relative;
`;
export const StyledSearchInput = styled(Input) `
  && {
    position: absolute;
    top: -${theme.spaces.x6};
    right: 0;
    width: 100%;
    max-width: 300px;
    padding: ${theme.spaces.x4} 30px ${theme.spaces.x4} 0;
    box-shadow: none;
  }
`;
const iconCss = css `
  position: relative;
  z-index: ${theme.zLayer.xs};
  cursor: pointer;
  color: ${(props) => (props.filtered ? theme.colors.primary500 : theme.colors.grey500)};
`;
const StyledSearchIcon = styled(SearchSmIcon) `
  ${iconCss}
`;
const StyledFilterIcon = styled(FilterFunnelAIcon) `
  ${iconCss}
`;
const FilterDropdownMenu = styled.div `
  max-height: 400px;
  max-width: 400px;
  overflow-y: auto;
  padding: ${theme.spaces.x12};
`;
const StyleCheckboxGroup = styled(CheckboxGroup) `
  width: 100%;
`;
function SearchInput(props) {
    const inputRef = useRef(null);
    useEffect(() => {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    return _jsx(StyledSearchInput, Object.assign({ ref: inputRef }, props));
}
export function ColumnSearchFilter({ title, value, defaultValue, onChange, showSearchInput = false, searchInputProps }) {
    const [searchStr, setSearchStr] = useControlledState({
        value,
        defaultValue,
        onChange
    });
    const [showSearch, toggleSearch] = useState(false);
    return (_jsxs(StyledColumnSearchFilter, { onClick: (e) => e.stopPropagation(), direction: 'horizontal', align: 'space-between', alignY: 'center', gap: theme.spaces.x12, children: [title && _jsx(StackItem, { grow: '1', children: title }), (showSearchInput || showSearch) && (_jsx(SearchInput, Object.assign({ inputSize: 's' }, searchInputProps, { value: searchStr, onChange: setSearchStr, allowClear: true }))), showSearchInput ? null : (_jsx(StyledSearchIcon, { type: 'default', filtered: !!searchStr, onClick: () => {
                    toggleSearch(!showSearch);
                } }))] }));
}
export function ColumnListFilter({ title, value, defaultValue, options, onChange }) {
    const [selectedOptions = [], setSelectedOptions] = useControlledState({
        value,
        defaultValue,
        onChange
    });
    const columnFilterOverlay = (_jsx(FilterDropdownMenu, { children: _jsx(StyleCheckboxGroup, { options: options, value: selectedOptions, onChange: setSelectedOptions }) }));
    return (_jsxs(Stack, { onClick: (e) => e.stopPropagation(), direction: 'horizontal', align: 'space-between', alignY: 'center', gap: theme.spaces.x12, children: [_jsx(StackItem, { grow: '1', children: title }), _jsx(Dropdown, { overlay: columnFilterOverlay, align: 'end', placement: 'bottom', children: _jsx(Button, { variant: 'plain', icon: _jsx(StyledFilterIcon, { size: 'xs', filtered: !!selectedOptions.length, type: 'default' }) }) })] }));
}
const defaultFilterCondition = {
    search: (property, computedValue, item, value = '') => {
        if (value === '') {
            return true;
        }
        const entryValue = computedValue ? computedValue(item) : get(item, property);
        return Boolean(entryValue === null || entryValue === void 0 ? void 0 : entryValue.toLowerCase().includes(value.toLowerCase()));
    },
    list: (property, computedValue, item, value = []) => {
        if (value.length === 0) {
            return true;
        }
        const entryValue = computedValue ? computedValue(item) : get(item, property);
        return value.includes(entryValue);
    }
};
export function useColumnFilters({ columns, onHeaderClick, container }) {
    const afterNextRender = useAfterNextRender();
    // in memory filters
    const [filters, setFilters] = useImmer({});
    // remove previous applied filter if the column has changed
    useEffect(() => {
        setFilters({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns.map((column, index) => column.key || column.dataIndex || index).join(',')]);
    const addFiltersOnColumns = (updatedColumns) => {
        return updatedColumns.map((column, columnIndex) => {
            let { filter } = column;
            if (!filter)
                return column;
            if (filter === true) {
                filter = { type: FilterType.search };
            }
            const columnKey = column.key;
            const isControlled = filter.value !== undefined;
            /**
             * if filter is defined and uncontrolled, column should have dataIndex and key property, if not filter would not work
             */
            if (!isControlled && !((column.dataIndex && columnKey) || filter.condition)) {
                console.warn('For uncontrolled filter, column should have dataIndex and key property or filter condition should be provided', `{columnKey: ${columnKey}, dataIndex: ${column.dataIndex}}`);
                return column;
            }
            // keeping value as any instead of unknown, as value is mostly a passthrough here.
            // and ts cries when passing unknown to a parameter of specific type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _onChange = (value) => {
                var _a;
                let _filter = filter;
                if (!isControlled || (_filter === null || _filter === void 0 ? void 0 : _filter.forceUncontrolledFilter)) {
                    setFilters((draft) => {
                        draft[columnKey] = (item) => {
                            if (_filter.condition) {
                                return _filter.condition(item, value);
                            }
                            else {
                                return defaultFilterCondition[_filter.type](column.dataIndex, column.computedValue, item, value);
                            }
                        };
                    });
                }
                else {
                    // if filter is switch to controlled from uncontrolled state, we need to reset previous applied filter.
                    setFilters((draft) => {
                        if (draft[columnKey]) {
                            delete draft[columnKey];
                        }
                    });
                    (_a = _filter.onChange) === null || _a === void 0 ? void 0 : _a.call(_filter, value);
                }
                // after applying filter reset the top position on the table
                afterNextRender(() => {
                    var _a, _b;
                    (_b = (_a = container.current) === null || _a === void 0 ? void 0 : _a.querySelector(`.${TABLE_BODY}`)) === null || _b === void 0 ? void 0 : _b.scrollTo({ top: 0 });
                });
            };
            let title = column.title;
            if (filter.type === FilterType.search) {
                title = (_jsx("div", { onClick: () => onHeaderClick === null || onHeaderClick === void 0 ? void 0 : onHeaderClick(column.dataIndex), children: _jsx(ColumnSearchFilter, { title: title, value: filter.value, defaultValue: filter.defaultValue, onChange: _onChange, showSearchInput: filter.showSearchInput, searchInputProps: filter.searchInputProps }) }));
            }
            else if (filter.type === FilterType.list) {
                title = (_jsx("div", { onClick: () => onHeaderClick === null || onHeaderClick === void 0 ? void 0 : onHeaderClick(column.dataIndex), children: _jsx(ColumnListFilter, { title: title, value: filter.value, showSearchInput: filter.showSearchInput, defaultValue: filter.defaultValue, options: filter.options, onChange: _onChange }) }));
            }
            return Object.assign(Object.assign({}, column), { title });
        });
    };
    const filterDataSource = (dataSource) => {
        if (!Object.keys(filters).length)
            return dataSource;
        return filterWith(dataSource, (item) => {
            return Object.values(filters).every((filter) => filter(item));
        });
    };
    return {
        addFiltersOnColumns,
        filterDataSource
    };
}
