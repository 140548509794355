import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useControlledState, usePersistentCallback } from '@prophecy/utils/react/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Ellipsis } from '../Ellipsis';
import { Stack } from '../Layout';
import { TabTone, TabSize } from '../Tabs/types';
import { theme } from '../theme';
import { StyledAnchor, StyledList, StyledFooterAction, AnchorContentContainer, StyledAnchorContainer } from './styled';
const NavItemCLass = 'anchor-nav-item';
export const NavItemContainerClass = 'anchor-nav-container';
const SIDE_NAV_PREFIX = '__sideNavItem';
const searchBoxClassName = 'anchorSearch';
const scrollItemIntoView = (key, itemContainer, showSearch) => {
    const container = itemContainer === null || itemContainer === void 0 ? void 0 : itemContainer.querySelector(`[id="${key}"]`);
    if (container) {
        container.scrollIntoView(showSearch ? { behavior: 'auto', block: 'center' } : true);
    }
};
export const useAnchorScroll = ({ changeAnchor, selectedAnchor, isNavItemSelected, showSearch, disabled, virtualize }) => {
    const contentRef = useRef(null);
    const navItemRef = useRef(null);
    const intersectionItems = useMemo(() => ({}), []);
    const [observer, setObserver] = useState();
    const currentAnchor = useRef(selectedAnchor);
    currentAnchor.current = selectedAnchor;
    // To handle the backscroll to differentiate whether navItem was clicked or intersect worked when scrolling or clicking button
    const hasNavItemSelected = useRef(isNavItemSelected);
    hasNavItemSelected.current = isNavItemSelected;
    useEffect(() => {
        const contentContainer = contentRef.current;
        let options = { root: contentContainer, threshold: 0, rootMargin: '0px' };
        if (contentContainer && !options.root) {
            options.root = contentContainer;
        }
        const _observer = new IntersectionObserver((entries) => {
            var _a, _b;
            entries.forEach((entry) => {
                const id = entry.target.getAttribute('id');
                if (id) {
                    intersectionItems[id] = entry.isIntersecting;
                }
            });
            const selectedItem = currentAnchor.current;
            //const intersectingEntryId = entries.find(entry => entry.isIntersecting)?.target?.id;
            // To check if element is part of visible area the nav bar item click should work to add the active class
            const intersectionItemId = (_a = Object.entries(intersectionItems).find(([id, intersection]) => intersection === true && !!entries.find((entry) => { var _a; return ((_a = entry === null || entry === void 0 ? void 0 : entry.target) === null || _a === void 0 ? void 0 : _a.id) === id; }))) === null || _a === void 0 ? void 0 : _a[0];
            if (intersectionItemId &&
                ((selectedItem && !intersectionItems[selectedItem]) || !hasNavItemSelected.current) &&
                !disabled) {
                if (!virtualize) {
                    scrollItemIntoView(intersectionItemId + SIDE_NAV_PREFIX, navItemRef.current, showSearch);
                }
                const searchValue = (_b = document.querySelector(`.${searchBoxClassName} input`)) === null || _b === void 0 ? void 0 : _b.value;
                if (!searchValue) {
                    changeAnchor(intersectionItemId);
                }
            }
        }, options);
        if (contentContainer && !observer) {
            setObserver(_observer);
        }
        return () => {
            _observer === null || _observer === void 0 ? void 0 : _observer.disconnect();
        };
    }, [
        contentRef,
        virtualize,
        navItemRef,
        intersectionItems,
        observer,
        currentAnchor,
        hasNavItemSelected,
        changeAnchor,
        disabled,
        showSearch
    ]);
    return {
        observer,
        contentRef,
        intersectionItems,
        navItemRef
    };
};
const getUniqueKey = (key) => {
    //Replace special character with _
    return key ? key.replace(/[^a-zA-Z0-9]/g, '_') : 'anchor';
};
const AnchorItem = ({ item, index, observer }) => {
    const anchorItemRef = useRef(null);
    useEffect(() => {
        const item = anchorItemRef === null || anchorItemRef === void 0 ? void 0 : anchorItemRef.current;
        if (!observer || !item)
            return;
        observer === null || observer === void 0 ? void 0 : observer.observe(item);
        return () => {
            observer === null || observer === void 0 ? void 0 : observer.unobserve(item);
        };
    }, [observer]);
    const itemKey = getUniqueKey(item.key);
    return (_jsx(Stack, { ref: anchorItemRef, width: '100%', id: itemKey, className: NavItemContainerClass, children: item.content }, itemKey + index));
};
export function Anchor(_a) {
    var { tone = TabTone.white, size = TabSize.m, items, activeKey, defaultActiveKey, onChange, sidePanelWidth = 220, virtualize = false, showSearch = false, leftPanelActionUI, hideSchemaNames = false, renderContainerItem, renderNavItem, onNavReorder } = _a, restProps = __rest(_a, ["tone", "size", "items", "activeKey", "defaultActiveKey", "onChange", "sidePanelWidth", "virtualize", "showSearch", "leftPanelActionUI", "hideSchemaNames", "renderContainerItem", "renderNavItem", "onNavReorder"]);
    const navVirtualizationControl = useRef(undefined);
    const contentVirtualizationControl = useRef(undefined);
    const [selectedAnchor, onAnchorChange] = useControlledState({
        value: activeKey,
        defaultValue: defaultActiveKey,
        onChange
    });
    const [searchStr, setSearchString] = useState('');
    // To handle the reverse scroll need to specify the button click or autoscroll in content area
    const [isNavItemSelected, setNavItemSelected] = useState(false);
    const filteredDataSource = items.map((rowData, i) => ({
        rowData,
        index: i,
        key: getUniqueKey(rowData.key) + NavItemCLass
    }));
    const containerDataSource = items.map((rowData, i) => ({
        rowData,
        index: i,
        key: getUniqueKey(rowData.key) + NavItemContainerClass
    }));
    const scrollToIndex = usePersistentCallback((key, index, scrollContent = true, scrollNav = true) => {
        var _a, _b;
        if (index !== undefined && index > -1) {
            if (scrollNav) {
                (_a = navVirtualizationControl.current) === null || _a === void 0 ? void 0 : _a.scrollToIndex(index, key);
            }
            if (scrollContent) {
                (_b = contentVirtualizationControl.current) === null || _b === void 0 ? void 0 : _b.scrollToIndex(index, key);
            }
        }
    });
    const { contentRef, navItemRef, observer } = useAnchorScroll({
        isNavItemSelected,
        selectedAnchor,
        showSearch,
        virtualize,
        disabled: hideSchemaNames,
        changeAnchor: (anchor) => {
            onAnchorChange(anchor);
            setNavItemSelected(false);
            if (virtualize) {
                const index = items.findIndex((row) => getUniqueKey(row.key) === anchor);
                scrollToIndex(anchor, index, false);
            }
        }
    });
    const handleAnchorClick = (key, index) => {
        onAnchorChange(key);
        setNavItemSelected(true);
        if (virtualize) {
            scrollToIndex(key, index, true, false);
        }
        else {
            // Scroll item into view after view is rendered so nested item will be opened to fit into the view
            setTimeout(() => {
                scrollItemIntoView(key, contentRef === null || contentRef === void 0 ? void 0 : contentRef.current, showSearch);
            }, 200);
        }
    };
    const clearSearch = usePersistentCallback(() => {
        var _a;
        //Scroll the current active item once search is cleared and after content is rendered to fit into the view
        const key = getUniqueKey((_a = items === null || items === void 0 ? void 0 : items[0]) === null || _a === void 0 ? void 0 : _a.key);
        const selectAnchorIndex = filteredDataSource.findIndex((row) => getUniqueKey(row.rowData.key) === selectedAnchor);
        if (virtualize && key) {
            scrollToIndex(selectedAnchor || key, selectAnchorIndex > -1 ? selectAnchorIndex : 0, false, true);
        }
        else {
            setTimeout(() => {
                scrollItemIntoView(selectedAnchor + SIDE_NAV_PREFIX, navItemRef.current, showSearch);
            }, 200);
        }
    });
    useEffect(() => {
        if (searchStr === '') {
            clearSearch();
        }
    }, [clearSearch, searchStr]);
    const navItemWidth = sidePanelWidth + 'px';
    const renderNavDefaultItem = (record) => {
        const item = record.rowData;
        const itemKey = getUniqueKey(item.key);
        const offsetWidth = 55;
        let contentWidth = sidePanelWidth - offsetWidth;
        if (item.icon) {
            contentWidth -= 20;
        }
        if (onNavReorder) {
            contentWidth -= 30;
        }
        return (_jsx(Stack, { width: '100%', className: NavItemCLass, id: itemKey + SIDE_NAV_PREFIX, children: _jsxs(StyledAnchor, { className: selectedAnchor === itemKey ? 'selected' : '', tone: tone, size: size, onClick: () => handleAnchorClick(itemKey, record.index), children: [_jsx(Stack, { width: contentWidth + 'px', children: _jsx(Ellipsis, { tooltip: true, children: item.title }) }), item.icon] }) }));
    };
    const renderDefaultContainer = (record) => {
        const item = record.rowData;
        return _jsx(AnchorItem, { observer: observer, item: item, index: record.index });
    };
    return (_jsxs(Stack, Object.assign({ width: '100%', height: '100%', gap: theme.spaces.x20, direction: 'horizontal' }, restProps, { children: [hideSchemaNames ? null : (_jsx(Stack, { width: navItemWidth, children: _jsx(StyledAnchorContainer, { tone: tone, size: size, height: '100%', width: '100%', ref: navItemRef, children: _jsx(StyledList, { searchFilter: showSearch
                            ? {
                                searchColumnKey: 'title',
                                forceUncontrolledFilter: true,
                                className: searchBoxClassName,
                                onChange: setSearchString,
                                value: searchStr
                            }
                            : undefined, footer: () => (leftPanelActionUI ? _jsx(StyledFooterAction, { children: leftPanelActionUI }) : null), emptyText: 'No data', getVirtualizationControls: (controls) => (navVirtualizationControl.current = controls), virtualize: virtualize, onReorder: onNavReorder, data: filteredDataSource, renderItem: renderNavItem ? renderNavItem : renderNavDefaultItem }) }) })), _jsx(AnchorContentContainer, { grow: '1', ref: contentRef, children: _jsx(Stack, { width: '100%', height: '100%', children: _jsx(StyledList, { emptyText: 'No data', className: 'container', getVirtualizationControls: (controls) => (contentVirtualizationControl.current = controls), virtualize: virtualize, data: containerDataSource, renderItem: renderContainerItem ? renderContainerItem : renderDefaultContainer }) }) })] })));
}
