import { ProgressBar, Stack, Text, theme } from '@prophecy/ui';
import { useStepper } from '@prophecy/ui/Stepper';
import noop from 'lodash-es/noop';
import { useState } from 'react';
import styled from 'styled-components';

import { DEPLOYMENT_TABS } from '../types';
import DeploymentHeader from './DeploymentHeader';
import DeploymentSuccess from './DeploymentSuccess';
import InfraSetup from './InfraSetup';
import Stepper, { Step } from './Stepper';

type StepWithKey = Step & { key: string };

const StyledProgessBar = styled(ProgressBar)`
  height: 2px;
  flex-shrink: 0;
  z-index: initial;
`;

const Root = styled(Stack).attrs({
  direction: 'vertical'
})`
  background: ${theme.colors.grey50};
  height: 100%;
`;

const CurrentStepContainer = styled(Stack).attrs({
  direction: 'horizontal'
})`
  height: 100%;
  width: ${theme.breakpoints.m};
  margin: 0 auto;
  gap: ${theme.spaces.x48};
  padding-top: 80px;
  max-height: 619px;
`;

const DeploymentStepper = ({
  steps,
  onComplete = () => {},
  getStepContent,
  initialStep
}: {
  steps: StepWithKey[];
  onComplete?: Function;
  getStepContent: (
    key: Exclude<DEPLOYMENT_TABS, 'INFRA_SETUP'>,
    nextStep: () => void,
    prevStep: () => void
  ) => React.ReactNode;
  initialStep?: number;
}) => {
  const [isComplete, setIsComplete] = useState(false);
  const { stepIndex, stepProgress, nextStep, prevStep } = useStepper<Step>(
    steps,
    noop,
    () => {
      setIsComplete(true);
      onComplete();
    },
    initialStep
  );

  const stepKey = steps[stepIndex].key as DEPLOYMENT_TABS;
  const [currentSteps, setCurrentSteps] = useState(steps);

  return (
    <Root>
      <DeploymentHeader title='Prophecy Deployment' />
      <StyledProgessBar variant='loading' value={isComplete ? 100 : stepProgress} width='100%' />
      <CurrentStepContainer>
        {isComplete ? (
          <DeploymentSuccess />
        ) : (
          <>
            {stepKey === DEPLOYMENT_TABS.INFRA_SETUP ? (
              <InfraSetup
                onNeedHelp={(needsHelp) => {
                  if (needsHelp) {
                    // is in the substep of step 1
                    currentSteps[0] = {
                      ...currentSteps[0],
                      description: (
                        <>
                          <Text level='xs'>{steps[0].description}</Text>
                          <Text level='xs'>Setup Kubernetes Cluster</Text>
                        </>
                      )
                    };

                    setCurrentSteps([...currentSteps]);
                  } else {
                    setCurrentSteps([...steps]);
                  }
                }}
                nextStep={nextStep}
                prevStep={prevStep}
              />
            ) : (
              getStepContent(stepKey, nextStep, prevStep)
            )}
            <Stepper currentStep={stepIndex} steps={currentSteps} />
          </>
        )}
      </CurrentStepContainer>
    </Root>
  );
};

export default DeploymentStepper;
