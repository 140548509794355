import { Button } from '@prophecy/ui/Button';
import { CheckIcon } from '@prophecy/ui/Icons';
import { Stack } from '@prophecy/ui/Layout';
import { theme } from '@prophecy/ui/theme';
import { Text } from '@prophecy/ui/Typography';
import { Private_Routes } from 'frontend/core/src/common/url';
import { DeploymentAnalytics } from 'frontend/core/src/common/user-analytics/deployment';
import styled from 'styled-components';

import { useMixPanel } from '../../../context/mixpanel/context';
import { StyledCard } from '../styles';
import DeploymentStepHeader from './DeploymentStepHeader';

const SuccessCard = styled(StyledCard)`
  height: fit-content;
  max-width: 675px;
`;

const SuccessIcon = styled(CheckIcon)`
  color: ${theme.colors.white};
`;

const Circle = styled.div<{ background: string; height: number; width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 50%;
  background-color: ${(props) => props.background};
`;

function SuccessTickIcon() {
  return (
    <Circle height={80} width={80} background={theme.colors.success50}>
      <Circle height={60} width={60} background={theme.colors.success100}>
        <Circle height={40} width={40} background={theme.colors.success500}>
          <SuccessIcon type='default' size='m' />
        </Circle>
      </Circle>
    </Circle>
  );
}

export default function DeploymentSuccess() {
  const { track } = useMixPanel();
  return (
    <SuccessCard>
      <Stack gap={theme.spaces.x32}>
        <SuccessTickIcon />
        <Stack gap={theme.spaces.x40}>
          <Stack gap={theme.spaces.x16}>
            <DeploymentStepHeader title={`You’re all set!`} />
            <Text level='sm'>
              Prophecy is working on completing your installation. You can safely close this dialog. The process will
              keep running on the background and you will be notified after the installation is completed.
            </Text>
          </Stack>
          <div>
            <Button
              onClick={() => {
                track(
                  DeploymentAnalytics.deployment_step_success.events['deployment.step.successDone'],
                  {},
                  DeploymentAnalytics.deployment_step_success.event_category
                );
              }}
              elementType='link'
              to={Private_Routes.Home.getUrl()}
              minWidth={132}>
              Done
            </Button>
          </div>
        </Stack>
      </Stack>
    </SuccessCard>
  );
}
