import { PATH_KEY } from '@prophecy/utils/nestedData';
export var RowPosition;
(function (RowPosition) {
    RowPosition["before"] = "before";
    RowPosition["after"] = "after";
})(RowPosition || (RowPosition = {}));
export var RowActionType;
(function (RowActionType) {
    RowActionType["dropdown"] = "dropdown";
    RowActionType["button"] = "button";
})(RowActionType || (RowActionType = {}));
export var FilterType;
(function (FilterType) {
    FilterType["search"] = "search";
    FilterType["list"] = "list";
})(FilterType || (FilterType = {}));
export var SortColumns;
(function (SortColumns) {
    SortColumns["created"] = "Created";
    SortColumns["modified"] = "Modified";
})(SortColumns || (SortColumns = {}));
export var SortOrder;
(function (SortOrder) {
    SortOrder["asc"] = "Ascending";
    SortOrder["desc"] = "Descending";
    SortOrder["none"] = "None";
})(SortOrder || (SortOrder = {}));
export var LogicalRows;
(function (LogicalRows) {
    LogicalRows["ifGroup"] = "ifGroup";
    LogicalRows["if"] = "if";
    LogicalRows["elseIf"] = "elseIf";
    LogicalRows["else"] = "else";
    LogicalRows["for"] = "for";
})(LogicalRows || (LogicalRows = {}));
export var TableVariant;
(function (TableVariant) {
    TableVariant["primary"] = "primary";
    TableVariant["secondary"] = "secondary";
})(TableVariant || (TableVariant = {}));
export var ScrollPosition;
(function (ScrollPosition) {
    ScrollPosition["top"] = "top";
    ScrollPosition["bottom"] = "bottom";
    ScrollPosition["between"] = "between";
})(ScrollPosition || (ScrollPosition = {}));
