import { Arrow, Content } from '@radix-ui/react-tooltip';
import styled, { css, keyframes } from 'styled-components';
import { theme } from '../theme';
export const tokens = {
    TooltipContent: {
        borderRadius: theme.radius.m,
        fontSize: theme.fontSizes.x12,
        fontWeight: theme.fontWeight.medium,
        padding: `${theme.spaces.x8} ${theme.spaces.x12}`,
        transition: theme.transition.m,
        zIndex: theme.zLayer.xxl,
        default: {
            backgroundColor: theme.colors.grey900,
            color: theme.colors.white
        },
        info: {
            backgroundColor: theme.colors.grey100,
            color: theme.colors.grey900
        },
        warning: {
            backgroundColor: theme.colors.warning100,
            color: theme.colors.warning700
        },
        error: {
            backgroundColor: theme.colors.error50,
            color: theme.colors.grey900
        },
        success: {
            backgroundColor: theme.colors.success100,
            color: theme.colors.grey900
        }
    },
    Arrow: {
        offset: theme.spaces.x12
    }
};
export const StyledArrow = styled(Arrow) `
  fill: ${(props) => tokens.TooltipContent[props.variant].backgroundColor};
`;
const appear = keyframes `
  0% { 
    opacity: 0;
  }
  100% {
    opacity: 1;
  };
`;
export const tooltipAnimation = css `
  animation: ${appear} ${tokens.TooltipContent.transition} forwards;
`;
export const StyledContent = styled(Content) `
  font-family: ${theme.fontFamily.text};
  font-size: ${tokens.TooltipContent.fontSize};
  font-weight: ${tokens.TooltipContent.fontWeight};
  padding: ${tokens.TooltipContent.padding};
  background: ${(props) => tokens.TooltipContent[props.variant].backgroundColor};
  border-radius: ${tokens.TooltipContent.borderRadius};
  color: ${(props) => tokens.TooltipContent[props.variant].color};
  z-index: ${tokens.TooltipContent.zIndex};
  max-width: 80vw;
  ${tooltipAnimation}
`;
