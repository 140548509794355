import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import { Ellipsis } from '../Ellipsis';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { renderWithTooltip } from '../Tooltip';
import { Text } from '../Typography';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { StyledRoot, StyledRadioItem, StyledRadio, StyledOptionsWrap, StyledRadioLabel, StyledGroupIconSection, StyledCheckMark, StyledMiniDotIcon } from './styled';
import { tokens } from './tokens';
import { Tone, Size, Placement } from './types';
function Radio(_a) {
    var { checked, key, value, disabled, children, tone = Tone.primary, size = Size.s, hint, tooltip, disableBorder = true, alignY, checkboxPlacement = Placement.left, showCheckmark = false } = _a, restProps = __rest(_a, ["checked", "key", "value", "disabled", "children", "tone", "size", "hint", "tooltip", "disableBorder", "alignY", "checkboxPlacement", "showCheckmark"]);
    //radioItem class used in Radio box to apply the hover effect
    let radioUI = (_jsx(StyledRadioLabel, { size: size, disabled: disabled, level: 'sm', children: children }));
    if (tooltip) {
        radioUI = _jsx(_Fragment, { children: renderWithTooltip(_jsx(Stack, { width: '100%', children: radioUI }), tooltip) });
    }
    if (hint) {
        radioUI = _jsx(LabelWithHint, { hint: hint, children: radioUI });
    }
    return (_jsxs(StyledRadioItem, Object.assign({}, restProps, { checkboxPlacement: checkboxPlacement, disableBorder: disableBorder, showCheckmark: showCheckmark, value: value, alignY: alignY, id: key || value, disabled: disabled, children: [checked ? (showCheckmark ? (_jsx(StyledCheckMark, { checkboxPlacement: checkboxPlacement, disabled: disabled, checked: checked, size: size, type: 'solid' })) : (_jsx(StyledRadio, { className: 'radioItem', checkboxPlacement: checkboxPlacement, tone: tone, size: size, checked: checked, disabled: disabled, children: _jsx(StyledMiniDotIcon, { size: 's', type: 'mini' }) }))) : (_jsx(StyledRadio, { className: 'radioItem', checkboxPlacement: checkboxPlacement, tone: tone, size: size, checked: checked, disabled: disabled })), radioUI] })));
}
RadioGroup.Radio = Radio;
function Item(_a) {
    var { key, value, asChild, label, headingWithBorder, icon, helpText, disabled, isCard, hint, tooltip, children } = _a, restProps = __rest(_a, ["key", "value", "asChild", "label", "headingWithBorder", "icon", "helpText", "disabled", "isCard", "hint", "tooltip", "children"]);
    const getChildrenUI = () => {
        if (isCard) {
            let childUi = (_jsxs(Stack, { align: 'center', gap: theme.spaces.x4, width: '100%', height: '100%', children: [icon && (_jsx(StyledGroupIconSection, { align: 'center', alignY: 'center', children: icon })), _jsxs(Stack, { gap: theme.spaces.x4, align: 'center', alignY: 'center', padding: `0 0 ${theme.spaces.x8} 0`, width: '100%', children: [_jsx(Stack, { direction: 'horizontal', width: '100%', align: 'center', padding: `0 ${theme.spaces.x10}`, children: _jsx(Ellipsis, { tooltip: true, tooltipProps: { variant: 'info' }, title: label, children: _jsx(Text, { level: 'sm', weight: theme.fontWeight.medium, tone: disabled ? tokens.RadioItem.disabled.color : theme.colors.grey900, children: label }) }) }), helpText && (_jsx(Stack, { direction: 'horizontal', gap: theme.spaces.x2, align: 'center', alignY: 'center', width: '100%', children: _jsx(Text, { level: 'xs', tone: disabled ? tokens.RadioItem.disabled.color : tokens.RadioItem.subTextColor, children: helpText }) }))] })] }));
            if (tooltip) {
                childUi = _jsx(_Fragment, { children: renderWithTooltip(childUi, tooltip) });
            }
            return childUi;
        }
        else {
            return children;
        }
    };
    let itemUI = (_jsx(StyledRadioItem, Object.assign({}, restProps, { headingWithBorder: headingWithBorder, isCard: isCard, value: value, id: key || value, disabled: disabled, children: getChildrenUI() })));
    if (tooltip && !isCard) {
        itemUI = _jsx(_Fragment, { children: renderWithTooltip(_jsx(Stack, { width: '100%', children: itemUI }), tooltip) });
    }
    if (hint) {
        itemUI = (_jsx(LabelWithHint, { hint: hint, children: _jsx(Stack, { width: '100%', children: itemUI }) }));
    }
    return itemUI;
}
RadioGroup.Item = Item;
const renderOptions = (options, selection, tone = Tone.white, size = Size.s, showCheckmark, disabled) => {
    if (!options || (options && options.length < 1)) {
        return null;
    }
    let _options = options;
    if (typeof _options[0] === 'string' || typeof _options[0] === 'number') {
        _options = _options.map((option) => {
            return {
                label: String(option),
                value: String(option),
                disabled
            };
        });
    }
    return _options.map((_option) => {
        const option = _option;
        return (_jsx(Radio, { disableBorder: true, checked: selection === option.value, value: option.value, tone: tone, showCheckmark: showCheckmark, size: size, hint: option.hint, disabled: option.disabled || disabled, children: option.label }, option.key || option.value));
    });
};
export function RadioGroup(_a) {
    var { name, defaultValue, disabled, value, options, onChange, orientation, wrap = true, gap = tokens.RadioGroup.gap, children, width = 'auto', tone = Tone.primary, size = Size.s, showCheckmark = false } = _a, restProps = __rest(_a, ["name", "defaultValue", "disabled", "value", "options", "onChange", "orientation", "wrap", "gap", "children", "width", "tone", "size", "showCheckmark"]);
    const [selection, setSelection] = useControlledState({
        value: value,
        defaultValue: defaultValue,
        onChange: (value) => onChange === null || onChange === void 0 ? void 0 : onChange(value)
    });
    return (_jsx(StyledRoot, Object.assign({ name: name, defaultValue: selection, value: selection, onValueChange: setSelection, orientation: orientation }, restProps, { dir: 'ltr', children: _jsx(StyledOptionsWrap, { direction: orientation ? orientation : 'horizontal', gap: gap, wrap: wrap, width: width, alignY: orientation === 'vertical' ? 'stretch' : 'center', children: options && options.length > 0
                ? renderOptions(options, selection, tone, size, showCheckmark, disabled)
                : children }) })));
}
