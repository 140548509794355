import { DropletIcon, NewIconVariant } from '@prophecy/ui/Icons';
import { BaseColor } from '@prophecy/ui/theme';

import { DatabricksProcessKind } from '../../pages/Job/types';
import { EntityIconMap, EntityToneMap } from '../Entity/EntityIcons';
import { Entity } from '../types/Entity';

export const JobCategoryMap = {
  Pipeline: {
    iconName: DatabricksProcessKind.Pipeline,
    icon: EntityIconMap[Entity.Pipeline].icon,
    iconType: EntityIconMap[Entity.Pipeline].type,
    tone: EntityToneMap[Entity.Pipeline]
  },
  Script: {
    iconName: DatabricksProcessKind.Script,
    icon: DropletIcon,
    iconType: NewIconVariant.extended,
    tone: BaseColor.blue
  },
  Model: {
    iconName: DatabricksProcessKind.Model,
    icon: EntityIconMap[Entity.Model].icon,
    iconType: EntityIconMap[Entity.Model].type,
    tone: EntityToneMap[Entity.Model]
  },
  DLTPipeline: {
    iconName: DatabricksProcessKind.DLTPipeline,
    icon: EntityIconMap[Entity.Pipeline].icon,
    iconType: EntityIconMap[Entity.Pipeline].type,
    tone: BaseColor.orange
  }
} as const;

export const TerminalProcessExecutionStatuses = [
  'SUCCEEDED',
  'ERROR',
  'FAILED',
  'TIMEDOUT',
  'CANCELLED',
  'SKIPPED',
  'UPSTREAM_SKIPPED'
] as const;

export type TerminalProcessExecutionStatus = (typeof TerminalProcessExecutionStatuses)[number];
