import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { renderWithTooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { StyledLabel, StyledHelpText, StyledLabelWithHint } from './styled';
import { TextAreaRoot, StyledTextAreaContainer, StyledTextArea } from './TextArea.styled';
import { tokens } from './tokens';
import { InputVariants, ResizeDirection } from './types';
import { TriggerKind, useInput } from './useInput';
export const TextArea = forwardRef((_a, ref) => {
    var { label, helpText, maxLength, className, style, disabled, variant = InputVariants.primary, value, resize = ResizeDirection.vertical, onChange, placeholder, placeholderSuggestion, delay = 250, hint, tooltip, onBlur, onFocus, renderTextArea = (node) => node } = _a, restProps = __rest(_a, ["label", "helpText", "maxLength", "className", "style", "disabled", "variant", "value", "resize", "onChange", "placeholder", "placeholderSuggestion", "delay", "hint", "tooltip", "onBlur", "onFocus", "renderTextArea"]);
    const { _value, valueLength, handleChange, handleBlur } = useInput({
        value,
        onChange,
        delay,
        onBlur
    });
    const _onFocus = (e) => {
        if (placeholderSuggestion) {
            e.currentTarget.value = placeholderSuggestion;
            e.currentTarget.select();
            handleChange(e, TriggerKind.Auto);
        }
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
    };
    const labelUI = label ? _jsx(StyledLabel, { children: label }) : null;
    let textAreadUI = renderTextArea(_jsx(StyledTextAreaContainer, { variant: variant, disabled: disabled, children: _jsx(StyledTextArea, Object.assign({ ref: ref, disabled: disabled, value: _value, resize: resize, onChange: (e) => handleChange(e, TriggerKind.User), onBlur: handleBlur, onFocus: _onFocus, placeholder: placeholderSuggestion || placeholder }, restProps)) }));
    if (tooltip) {
        textAreadUI = _jsx(_Fragment, { children: renderWithTooltip(textAreadUI, tooltip) });
    }
    if (!label && hint) {
        textAreadUI = _jsx(LabelWithHint, { hint: hint, children: textAreadUI });
    }
    return (_jsxs(TextAreaRoot, { style: style, className: className, gap: tokens.Root.gap, alignY: 'stretch', children: [hint && label ? (_jsx(StyledLabelWithHint, { level: 'xs', hint: hint, children: labelUI })) : (labelUI), textAreadUI, helpText && (_jsxs(StyledHelpText, { variant: variant, direction: 'horizontal', align: 'space-between', children: [helpText, maxLength && maxLength > 0 ? _jsx("span", { children: valueLength + '/' + maxLength }) : null] }))] }));
});
