import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { removeTrailingSlash } from '@prophecy/utils/string';
import { escapeRegExp } from 'lodash-es';
import { useEffect, useMemo, useRef } from 'react';
import { Ellipsis } from '../Ellipsis';
import { ChevronRightIcon, FolderIcon, FileDIcon } from '../Icons';
import { Stack, StackItem } from '../Layout';
import { List } from '../Table/List';
import { theme } from '../theme';
import { Text } from '../Typography/Text';
import { FileCell, StyledColumn } from './styled';
function sortNodes(nodes) {
    return [...nodes].sort((a, b) => {
        if (a.isDir && !b.isDir)
            return -1;
        if (!a.isDir && b.isDir)
            return 1;
        return a.name.localeCompare(b.name);
    });
}
const FileItem = ({ record, onFileClick, selectedPath }) => {
    const file = record.rowData;
    if (file.hidden) {
        return null;
    }
    return (_jsx(FileCell, { "aria-label": file.path, onClick: () => onFileClick(file), selected: Boolean(selectedPath.match(new RegExp(`^${escapeRegExp(removeTrailingSlash(file.path))}($|/)`))), children: _jsxs(Stack, { direction: 'horizontal', align: 'space-between', alignY: 'center', gap: theme.spaces.x8, children: [_jsx(StackItem, { shrink: '0', children: file.isDir ? _jsx(FolderIcon, { size: 'xs', type: 'default' }) : _jsx(FileDIcon, { size: 'xs', type: 'default' }) }), _jsx(StackItem, { grow: '1', children: _jsx(Ellipsis, { tooltip: true, children: _jsx(Text, { level: 'sm', children: removeTrailingSlash(file.name) }) }) }), file.isDir ? (_jsx(StackItem, { shrink: '0', children: _jsx(ChevronRightIcon, { size: 'xxs', type: 'default' }) })) : null] }) }));
};
export const FileStack = ({ index, virtualize, selectedPath, sortFiles, emptyText, onFileClick, directoryColumnWidth, files }) => {
    const _files = sortFiles ? sortNodes(files) : files;
    const fileDataSource = useMemo(() => _files.map((rowData, i) => ({ rowData, key: i + 'fileSystem' })), [_files]);
    const listVirtualizationControl = useRef(undefined);
    useEffect(() => {
        var _a;
        const currentIndex = fileDataSource === null || fileDataSource === void 0 ? void 0 : fileDataSource.findIndex((row) => row.rowData.path === selectedPath);
        if (currentIndex > -1) {
            (_a = listVirtualizationControl.current) === null || _a === void 0 ? void 0 : _a.scrollToIndex(currentIndex, '');
        }
    }, [selectedPath, listVirtualizationControl, fileDataSource]);
    return (_jsx(StyledColumn, { directoryColumnWidth: directoryColumnWidth, children: _jsx(List, { getVirtualizationControls: (controls) => (listVirtualizationControl.current = controls), emptyText: emptyText, virtualize: virtualize, data: fileDataSource, renderItem: (record) => _jsx(FileItem, { record: record, selectedPath: selectedPath, onFileClick: onFileClick }) }) }, index));
};
