import { theme } from '../theme';
export const tokens = {
    Display: {
        fontFamily: theme.fontFamily.text,
        fontWeight: theme.fontWeight.normal,
        letterSpacing: '0em',
        level: {
            '2xl': {
                fontSize: theme.fontSizes.x72,
                lineHeight: theme.lineHeight.x90
            },
            xl: {
                fontSize: theme.fontSizes.x60,
                lineHeight: theme.lineHeight.x72
            },
            lg: {
                fontSize: theme.fontSizes.x48,
                lineHeight: theme.lineHeight.x60
            },
            md: {
                fontSize: theme.fontSizes.x36,
                lineHeight: theme.lineHeight.x44
            },
            sm: {
                fontSize: theme.fontSizes.x30,
                lineHeight: theme.lineHeight.x38
            },
            xs: {
                fontSize: theme.fontSizes.x24,
                lineHeight: theme.lineHeight.x32
            }
        }
    },
    Text: {
        fontFamily: theme.fontFamily.text,
        fontWeight: theme.fontWeight.normal,
        letterSpacing: '0em',
        level: {
            xl: {
                fontSize: theme.fontSizes.x20,
                lineHeight: theme.lineHeight.x30
            },
            lg: {
                fontSize: theme.fontSizes.x18,
                lineHeight: theme.lineHeight.x28
            },
            md: { fontSize: theme.fontSizes.x16, lineHeight: theme.lineHeight.x24 },
            sm15: { fontSize: theme.fontSizes.x15, lineHeight: theme.lineHeight.x22 },
            sm: {
                fontSize: theme.fontSizes.x14,
                lineHeight: theme.lineHeight.x20
            },
            sm13: {
                fontSize: theme.fontSizes.x13,
                lineHeight: theme.lineHeight.x19
            },
            xs: {
                fontSize: theme.fontSizes.x12,
                lineHeight: theme.lineHeight.x18
            },
            xxs: {
                fontSize: theme.fontSizes.x10,
                lineHeight: theme.lineHeight.x16
            }
        }
    },
    Code: {
        fontFamily: theme.fontFamily.code,
        fontWeight: theme.fontWeight.normal,
        letterSpacing: '0em',
        level: {
            sm: {
                fontSize: theme.fontSizes.x14,
                lineHeight: theme.lineHeight.x20
            },
            sm13: {
                fontSize: theme.fontSizes.x13,
                lineHeight: theme.lineHeight.x19
            }
        }
    },
    CodeBlock: {
        backgroundColor: theme.colors.grey100,
        borderColor: theme.colors.grey200,
        borderRadius: theme.radius.xxs
    }
};
