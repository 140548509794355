import { nanoid } from 'nanoid';
export const DOUBLE_ID_SEPARATOR = '$$';
export function doubleId(currentId) {
    let constantPart = nanoid();
    let variablePart = nanoid();
    if (currentId) {
        constantPart = currentId.split(DOUBLE_ID_SEPARATOR)[0];
    }
    return `${constantPart}${DOUBLE_ID_SEPARATOR}${variablePart}`;
}
