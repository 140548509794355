import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable import/no-webpack-loader-syntax */
import diamondBlueUrl from '!url-loader!./../assets/gems/diamond_blue.svg';
import diamondPurple from '!url-loader!./../assets/gems/diamond_purple.svg';
import dipyramidUrl from '!url-loader!./../assets/gems/dipyramid.svg';
import dodecahedronUrl from '!url-loader!./../assets/gems/dodecahedron.svg';
import doubleTerminatedUrl from '!url-loader!./../assets/gems/double_terminated.svg';
import halfRoseUrl from '!url-loader!./../assets/gems/half_rose.svg';
import hexagonUrl from '!url-loader!./../assets/gems/hexagon.svg';
import hexagonGreenUrl from '!url-loader!./../assets/gems/hexagon_green.svg';
import hexagonOrangeUrl from '!url-loader!./../assets/gems/hexagon_orange.svg';
import loosePentagonUrl from '!url-loader!./../assets/gems/loose_pentagon.svg';
import pearBlueUrl from '!url-loader!./../assets/gems/pear_blue.svg';
import pearDarkBlueUrl from '!url-loader!./../assets/gems/pear_dark_blue.svg';
import pentagonUrl from '!url-loader!./../assets/gems/pentagon.svg';
import radiantGreenUrl from '!url-loader!./../assets/gems/radiant_green.svg';
import radiantOrangeUrl from '!url-loader!./../assets/gems/radiant_orange.svg';
import trillionGreenUrl from '!url-loader!./../assets/gems/trillion_green.svg';
import trillionOrangeUrl from '!url-loader!./../assets/gems/trillion_orange.svg';
import wingUrl from '!url-loader!./../assets/gems/wing.svg';
export function DiamondBlue(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: diamondBlueUrl, alt: 'DiamondBlueImage' }));
}
export function DiamondPurple(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: diamondPurple, alt: 'DiamondPurpleImage' }));
}
export function Dipyramid(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: dipyramidUrl, alt: 'DipyramidImage' }));
}
export function Dodecahedron(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: dodecahedronUrl, alt: 'DodecahedronImage' }));
}
export function DoubleTerminated(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: doubleTerminatedUrl, alt: 'DoubleTerminatedImage' }));
}
export function HalfRose(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: halfRoseUrl, alt: 'HalfRoseImage' }));
}
export function Hexagon(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: hexagonUrl, alt: 'HexagonImage' }));
}
export function LoosePentagon(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: loosePentagonUrl, alt: 'LoosePentagonImage' }));
}
export function PearDarkBlue(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: pearDarkBlueUrl, alt: 'PearDarkBlueImage' }));
}
export function PearBlue(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: pearBlueUrl, alt: 'PearBlueImage' }));
}
export function Pentagon(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: pentagonUrl, alt: 'PentagonImage' }));
}
export function RadiantGreen(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: radiantGreenUrl, alt: 'RadiantGreenImage' }));
}
export function RadiantOrange(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: radiantOrangeUrl, alt: 'RadiantOrangeImage' }));
}
export function TrillionGreen(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: trillionGreenUrl, alt: 'TrillionGreenImage' }));
}
export function TrillionOrange(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: trillionOrangeUrl, alt: 'TrillionOrangeImage' }));
}
export function Wing(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: wingUrl, alt: 'WingImage' }));
}
export function HexagonGreen(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: hexagonGreenUrl, alt: 'HexagonGreenImage' }));
}
export function HexagonOrange(_a) {
    var props = __rest(_a, []);
    return _jsx("img", Object.assign({}, props, { src: hexagonOrangeUrl, alt: 'HexagonOrangeImage' }));
}
