import { theme } from '../theme';
export const tokens = {
    IndicatorRoot: {
        gap: theme.spaces.x8,
        fontSize: theme.fontSizes.x14,
        size: {
            s: {
                size: '6px'
            },
            m: {
                size: '10px'
            }
        },
        info: {
            backgroundColor: theme.colors.primary500
        },
        success: {
            backgroundColor: theme.colors.success500
        },
        error: {
            backgroundColor: theme.colors.error500
        },
        ghost: {
            backgroundColor: theme.colors.grey500
        },
        warning: {
            backgroundColor: theme.colors.warning500
        }
    }
};
