export enum UserTypes {
  Enterprise = 'Enterprise',
  CeFree = 'CeFree',
  CeLicenseAcquired = 'CeLicenseAcquired',
  DatabricksPartner = 'DatabricksPartner',
  EnterpriseTrial = 'EnterpriseTrial',
  AwsMarketplace = 'AwsMarketplace',
  AzureMarketplace = 'AzureMarketplace',
  ClusterAdmin = 'ClusterAdmin',
  Support = 'Support',
  ProphecyManaged = 'ProphecyManaged',
  Saml = 'Saml',
  AzureAD = 'AzureAD',
  LDAP = 'LDAP'
}
