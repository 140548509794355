import { Card } from '@prophecy/ui/index';
import { Stack } from '@prophecy/ui/Layout';
import { theme } from '@prophecy/ui/theme';
import styled from 'styled-components';

export const StyledAnchor = styled.a.attrs({ target: '_blank' })`
  text-decoration: underline;
  text-underline-offset: ${theme.spaces.x4};
  color: ${theme.colors.grey900};
`;

export const Root = styled(Stack)`
  height: 100%;
`;

export const StyledList = styled.ul`
  margin-left: -${theme.spaces.x20};
  li:not(:last-child) {
    margin-bottom: ${theme.spaces.x12};
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
  padding: ${theme.spaces.x32};
`;
