import { GemSpec } from '../Parser/types';
import { getGemSpec } from './getGemSpec';
import { getGemTypeByProcess } from './getGemTypeByProcess';
import {
  BaseProcess,
  BaseProcessMetadata,
  Connection as GraphConnection,
  GenericGraph,
  GenericGraphProcessType
} from './types';

export function getGemSpecByProcess<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(process: GenericGraphProcessType<G>, gemSpecs: GemSpec[]) {
  const gemType = getGemTypeByProcess(process);
  return getGemSpec(gemSpecs, gemType);
}
