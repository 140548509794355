export enum SentryTags {
  NetworkConnectionDownlink = 'NetworkConnection.downlink',
  NetworkConnectionEffectiveType = 'NetworkConnection.effectiveType',
  NetworkConnectionRtt = 'NetworkConnection.rtt',
  NetworkConnectionSaveData = 'NetworkConnection.saveData',
  NetworkConnectionType = 'NetworkConnection.type',

  /** prophecy.error.ws can be used to tag errors related to ProphecyWSErrorTags or lsp method */
  ProphecyErrorWSType = 'prophecy.error.ws',
  /** prophecy.error.gql can be used to tag errors related to graphql query name */
  ProphecyErrorGqlType = 'prophecy.error.gql',
  /** prophecy.error.http can be used to tag errors related to rest api */
  ProphecyErrorHttpType = 'prophecy.error.http',
  /** prophecy.error can be used to tag in-geneal errors
   * (which can't be covered with ProphecyErrorWSType/ProphecyErrorGqlType/ProphecyErrorHttpType) */
  ProphecyErrorType = 'prophecy.error'
}

// list here tags specific to websocket (ProphecyErrorWSType)
export enum ProphecyWSErrorTags {
  LspDisconnect = 'ws.lsp.disconnect',
  ExecutionDisconnect = 'ws.execution.disconnect'
}

// list here tags specific to http (ProphecyErrorHttpType)
export enum HTTPErrorTags {
  QueryError = 'http.query.error',
  SessionExpire = 'http.query.sessionExpire'
}

// list here tags specific to Gql (ProphecyErrorGqlType)
export enum GqlErrorTags {
  QueryError = 'gql.query.error'
}

// list here custom tags (ProphecyErrorType)
export enum ErrorTags {
  testSqlConnection = 'testSqlConnection',

  // Javascript error
  JavascriptError = 'javascriptError'
}

export type ErrorTagsType =
  | Record<SentryTags.ProphecyErrorType, ErrorTags>
  | Record<SentryTags.ProphecyErrorWSType, ProphecyWSErrorTags | string>
  | Record<SentryTags.ProphecyErrorHttpType, HTTPErrorTags | string>
  | Record<SentryTags.ProphecyErrorGqlType, GqlErrorTags | string>;
