import styled from 'styled-components';
import { Button } from '../Button';
import { Icon, ChevronRightIcon, EditBIcon } from '../Icons';
import { InlineInput } from '../Input';
import { Stack } from '../Layout';
import { tokens } from './tokens';
export const StyledBreadcrumbContainer = styled(Stack) `
  font-family: ${tokens.Crumb.fontFamily};
  color: ${tokens.Crumb.color};
  font-size: ${tokens.ArrowIcon.fontSize};
  min-width: 0;
  width: 100%;
  overflow: hidden;
`;
export const StyledCrumbWrapper = styled(Stack).attrs({ alignY: 'center', direction: 'horizontal' }) `
  flex-shrink: 0;
`;
export const StyledChevronRightIcon = styled(ChevronRightIcon) `
  color: ${tokens.ArrowIcon.color};
  font-size: ${tokens.ArrowIcon.fontSize};
  margin: 0 ${tokens.ArrowIcon.margin};
  width: auto;
  flex-shrink: 0;
`;
export const StyledEditIcon = styled(EditBIcon) `
  margin-left: ${tokens.Crumb.gap};
  cursor: pointer;
  visibility: hidden;
  &&:hover,
  & {
    color: ${tokens.Crumb.active.color};
  }
`;
export const StyledLabel = styled.label `
  max-width: 180px;
  cursor: pointer;
`;
export const StyledInput = styled(InlineInput) `
  max-width: 200px;
  border-radius: ${tokens.Input.radius};
  > div {
    border: none;
    &,
    &:hover,
    &:focus-within {
      background: ${tokens.Input.background}!important;
    }
  }
`;
export const StyledButton = styled(Button) `
  background: ${({ variant }) => { var _a, _b; return (_b = (_a = tokens[variant]) === null || _a === void 0 ? void 0 : _a.background) !== null && _b !== void 0 ? _b : tokens.Crumb.background; }};
  color: ${tokens.Crumb.color};
  display: flex;
  gap: ${tokens.Crumb.gap};
  align-items: center;
  ${Icon} {
    color: inherit;
    font-size: inherit;
  }

  &:hover,
  &:active,
  &[data-active='true'] {
    &:not([disabled]) {
      background: ${({ variant }) => { var _a, _b; return (_b = (_a = tokens[variant]) === null || _a === void 0 ? void 0 : _a.background) !== null && _b !== void 0 ? _b : tokens.Crumb.background; }};
      color: ${tokens.Crumb.hover.color};
    }
    ${Icon} {
      visibility: visible;
    }
  }
  ${({ active, variant }) => {
    var _a, _b, _c, _d, _e, _f;
    return active
        ? `
      
         &:hover,
         &,
        &:active,
        &[data-active='true'] {
          &:not([disabled]) {
              background: ${(_c = (_b = (_a = tokens[variant]) === null || _a === void 0 ? void 0 : _a.active) === null || _b === void 0 ? void 0 : _b.background) !== null && _c !== void 0 ? _c : tokens.Crumb.background};
              color: ${(_f = (_e = (_d = tokens[variant]) === null || _d === void 0 ? void 0 : _d.active) === null || _e === void 0 ? void 0 : _e.color) !== null && _f !== void 0 ? _f : tokens.Crumb.active.color};
          }
        }
    `
        : '';
}}
`;
