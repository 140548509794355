import { Alert, theme } from '@prophecy/ui';
import { addHours, addMinutes } from '@prophecy/utils/date';
import { useTimedFlag } from '@prophecy/utils/react/hooks';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';

import { useAppMetadata } from '../../context/appMetadata';
import { FAILED, RESTORE_FAILED, UPGRADE_FAILED } from './constants';

const AlertWrapper = styled.div`
  position: fixed;
  bottom: 60px;
  width: calc(100% - 60px);
  left: 60px;
  z-index: ${theme.zLayer.m};
  display: flex;
  justify-content: center;
  user-select: none;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

export function MaintenanceNotification({ isAbout }: { isAbout?: boolean }) {
  const updateInfo = useAppMetadata()?.maintenanceUpdateInfo;
  const {
    updateAvailable,
    timeLeftHours = 0,
    timeLeftMinutes = 0
  } = updateInfo
    ? updateInfo
    : {
        updateAvailable: false,
        timeLeftHours: 0,
        timeLeftMinutes: 0
      };

  const [showNotice, toggleNotice] = useState<boolean>(true);
  const [snoozeNotice, toggleSnooze] = useTimedFlag(15 * 60 * 1000);

  const now = new Date();
  const updateHour = addHours(timeLeftHours, now).getHours();
  const updateMins = addMinutes(timeLeftMinutes + 1, now);
  const isDayChanged = updateHour > 23;

  useEffect(() => {
    if (updateAvailable && !snoozeNotice && timeLeftHours < 6) {
      toggleNotice(true);
      return;
    }
  }, [updateAvailable, snoozeNotice, timeLeftHours]);
  let timeLeftString = '';
  if (timeLeftHours === 0 && timeLeftMinutes === 0) {
    timeLeftString = 'a few seconds';
  } else {
    timeLeftString =
      timeLeftHours > 0
        ? `${timeLeftHours} ${timeLeftHours > 1 ? 'hours' : 'hour'}${
            timeLeftMinutes > 0 ? ` and ${timeLeftMinutes} ${timeLeftMinutes > 1 ? 'minutes' : 'minute'}` : ''
          }`
        : `${timeLeftMinutes} ${timeLeftMinutes > 1 ? 'minutes' : 'minute'}`;
  }

  if (timeLeftString !== 'a few seconds') {
    timeLeftString = `${timeLeftString} and few seconds`;
  }

  let description: React.ReactNode = (
    <div>
      <p>
        Prophecy is going to undergo an update in {timeLeftString} ({updateMins.toLocaleTimeString()}
        {isDayChanged ? ' tomorrow' : ' today'}).
      </p>
      <p>Please save your changes as you may lose them if not saved after the upgrade.</p>
      <p> Something special is coming! 🎉</p>
    </div>
  );
  if (isAbout) {
    if (updateInfo?.upgrade_status?.status === UPGRADE_FAILED || updateInfo?.upgrade_status?.status === FAILED) {
      description = (
        <div>
          <p>
            Oops, the update failed. We could not complete the update. Please contact Prophecy team for further
            assistance.
          </p>
        </div>
      );
    } else if (updateInfo?.upgrade_status?.status === RESTORE_FAILED) {
      description = (
        <div>
          <p>
            Oops, the roll back failed. We could not roll back to the last state. Please contact Prophecy team for
            further assistance.
          </p>
        </div>
      );
    } else {
      description = null;
    }
  }

  if (
    ((!updateAvailable || !showNotice || snoozeNotice || timeLeftHours > 5) && !isAbout) ||
    !description ||
    (isAbout && snoozeNotice)
  ) {
    return null;
  }

  return (
    <Draggable>
      <AlertWrapper>
        <Alert key={'maintenanceNotification'} closable={true} onClose={() => toggleSnooze()}>
          {description}
        </Alert>
      </AlertWrapper>
    </Draggable>
  );
}
