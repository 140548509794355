import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { emailInitials, nameInitials } from '@prophecy/utils/string';
import isEmail from 'validator/lib/isEmail';
import { renderWithTooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { Root, Text, Image } from './styled';
export function Avatar(_a) {
    var { text, icon, src, hint, tooltip } = _a, rest = __rest(_a, ["text", "icon", "src", "hint", "tooltip"]);
    let avatarUI = null;
    if (src) {
        avatarUI = _jsx(ImageAvatar, Object.assign({ src: src }, rest));
    }
    else if (text) {
        avatarUI = _jsx(TextAvatar, Object.assign({ text: text }, rest));
    }
    else if (icon) {
        avatarUI = _jsx(IconAvatar, Object.assign({ icon: icon }, rest));
    }
    if (tooltip) {
        avatarUI = _jsx(_Fragment, { children: renderWithTooltip(_jsx("div", { children: avatarUI }), tooltip) });
    }
    if (hint) {
        avatarUI = (_jsx(LabelWithHint, { hint: hint, children: _jsx("div", { children: avatarUI }) }));
    }
    return avatarUI;
}
function TextAvatar(_a) {
    var { size, text } = _a, rest = __rest(_a, ["size", "text"]);
    const initial = isEmail(text) ? emailInitials(text) : nameInitials(text);
    return (_jsx(Root, Object.assign({ size: size }, rest, { children: _jsx(Text, { size: size, children: initial }) })));
}
function IconAvatar(_a) {
    var { size, icon } = _a, rest = __rest(_a, ["size", "icon"]);
    return (_jsx(Root, Object.assign({ size: size }, rest, { children: _jsx("span", { children: icon }) })));
}
function ImageAvatar(_a) {
    var { size, src } = _a, rest = __rest(_a, ["size", "src"]);
    return (_jsx(Root, Object.assign({ size: size }, rest, { children: _jsx(Image, { src: src }) })));
}
