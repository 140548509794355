import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { KeyCodes } from '@prophecy/utils/keyCodes';
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { castArray, cloneDeep, isEqual, noop, uniqueId } from 'lodash-es';
import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createEditor, Editor, Range, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import styled, { css } from 'styled-components';
import { StyledInputContainer, StyledLabel } from '../Input/styled';
import { InputActionBarPopup } from '../InputActionBarPopup/InputActionBarPopup';
import { Stack } from '../Layout/Stack';
import { theme } from '../theme/theme';
import { CompositeType } from './types';
const DefaultInitialValue = [
    {
        children: [{ text: '' }]
    }
];
const toTemplate = (values, tagRender) => {
    const descendants = [];
    let currentElement;
    values.forEach((entry, i) => {
        if (i === 0 || (entry.type === CompositeType.text && entry.value === '\n')) {
            currentElement = { children: [] };
            descendants.push(currentElement);
        }
        if (entry.value === '\n')
            return;
        const key = nanoid();
        currentElement.children.push(entry.type === CompositeType.text
            ? { text: entry.value ? entry.value.toString() : '' }
            : Object.assign(Object.assign({}, entry), { key, render: tagRender(key), children: [{ text: entry.textContent || '' }] }));
    });
    return descendants.length ? descendants : DefaultInitialValue;
};
const fromTemplate = (template) => {
    const nodes = [];
    template.forEach((node) => {
        const children = node.children || [node];
        children.forEach((node) => {
            var _a;
            if (node.type === CompositeType.custom) {
                const _b = node, { render, children } = _b, restNode = __rest(_b, ["render", "children"]);
                nodes.push(Object.assign(Object.assign({}, restNode), { textContent: (_a = children === null || children === void 0 ? void 0 : children[0]) === null || _a === void 0 ? void 0 : _a.text }));
            }
            else {
                const { text } = node;
                nodes.push({ type: CompositeType.text, value: text });
            }
        });
        if (nodes.length)
            nodes.push({ type: CompositeType.text, value: '\n' });
    });
    // remove the last \n
    return nodes.slice(0, -1);
};
const removeKeys = (values) => {
    return (values ? castArray(values) : []).map((_a) => {
        var { key } = _a, rest = __rest(_a, ["key"]);
        return rest;
    });
};
const normalizeValues = (values) => {
    const newValues = [];
    let lastValue;
    values.forEach((currentValue) => {
        if ((lastValue === null || lastValue === void 0 ? void 0 : lastValue.type) === CompositeType.text &&
            currentValue.type === CompositeType.text &&
            lastValue.value !== '\n' &&
            currentValue.value !== '\n') {
            lastValue.value += currentValue.value;
        }
        else if (currentValue.type !== CompositeType.text || currentValue.value) {
            lastValue = currentValue;
            newValues.push(currentValue);
        }
    });
    return newValues;
};
const validateNumber = (template) => {
    let allText = '';
    let numText = '';
    const updatedTemplate = cloneDeep(template);
    updatedTemplate.forEach((node) => {
        const children = node.children || [node];
        children.forEach((node) => {
            const textNode = node;
            if (textNode.text !== undefined) {
                allText += textNode.text;
                textNode.text = textNode.text.replace(/[^-.0-9]/g, '');
                numText += textNode.text;
            }
        });
    });
    return allText.length !== numText.length ? updatedTemplate : undefined;
};
const validateNonComposite = (allowText, template) => {
    const updatedTemplate = cloneDeep(template);
    let hasCustomNode = false;
    const textNodes = [];
    let hasText = false;
    updatedTemplate.forEach((node) => {
        const children = node.children || [node];
        children.forEach((node) => {
            const textNode = node;
            if (textNode.text !== undefined) {
                textNodes.push(textNode);
                if (!hasText)
                    hasText = Boolean(textNode.text.length);
            }
            else {
                hasCustomNode = true;
            }
        });
    });
    if ((hasCustomNode || !allowText) && hasText) {
        textNodes.forEach((node) => (node.text = ''));
        return updatedTemplate;
    }
    return undefined;
};
const RelativeDiv = styled.div `
  position: relative;
`;
const InputContainer = styled(StyledInputContainer) `
  display: block;
  padding: ${({ $asInput }) => $asInput ? `${theme.spaces.x6} ${theme.spaces.x12} ${theme.spaces.x6}` : theme.spaces.x12};
  cursor: text;

  ${({ $asInput, $rows = 4 }) => {
    return $asInput
        ? css `
          white-space: nowrap;
          overflow-x: scroll;
          overflow-y: hidden;

          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* Internet Explorer 10+ */
          &::-webkit-scrollbar {
            display: none !important;
          }

          p {
            white-space: pre;
          }
        `
        : css `
          height: ${parseInt(theme.fontSizes.x16) * $rows + parseInt(theme.sizes.x24)}px;
          overflow: auto;
          resize: vertical;
        `;
}}
`;
const StyledEditable = styled(Editable) `
  overflow: auto;
  &:focus-visible {
    outline: none;
  }
`;
const NonEditableTag = styled.div `
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
`;
const EdiableNode = styled.span `
  white-space: pre;
`;
// Try rerendering the component if any error comes up
class SlateErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { renderId: uniqueId() };
    }
    static getDerivedStateFromError(error) {
        // try to recreate slate ui if any error comes up.
        return { renderId: uniqueId() };
    }
    render() {
        return _jsx(React.Fragment, { children: this.props.children }, this.state.renderId);
    }
}
const withConfig = (editor) => {
    const { isInline, isVoid, markableVoid } = editor;
    const isCustom = (element) => {
        return element.type === CompositeType.custom;
    };
    const isEditable = (element) => {
        return Boolean(element.editable);
    };
    editor.isInline = (element) => {
        return isCustom(element) ? true : isInline(element);
    };
    editor.isVoid = (element) => {
        return isCustom(element) && !isEditable(element) ? true : isVoid(element);
    };
    editor.markableVoid = (element) => {
        return isCustom(element) ? false : markableVoid(element);
    };
    // Not being used, but working logic uncomment if needed in future
    /* editor.deleteBackward = (...args) => {
      const selection = editor.selection;
      if (!selection) return;
  
      const [start] = Range.edges(selection);
      const nodes = Editor.previous(editor, { at: start.path });
      if ((nodes?.[0] as Descendant)?.deletable === false && start.offset === 0) return;
  
      deleteBackward(...args);
    };
  
    editor.deleteForward = (...args) => {
      const selection = editor.selection;
      if (!selection) return;
  
      const [, end] = Range.edges(selection);
      const currentNode = Editor.node(editor, end.path);
      const nodes = Editor.next(editor, { at: end.path });
      if ((nodes?.[0] as Descendant)?.deletable === false && end.offset === (currentNode?.[0] as BaseText).text.length)
        return;
  
      deleteForward(...args);
    }; */
    return editor;
};
const findPath = (editor, key, prevPath = []) => {
    var _a;
    for (let i = 0; i < editor.children.length; i++) {
        const node = editor.children[i];
        if (node.key === key) {
            return [...prevPath, i];
        }
        if ((_a = node.children) === null || _a === void 0 ? void 0 : _a.length) {
            const path = findPath(node, key, [...prevPath, i]);
            if (path)
                return path;
        }
    }
    return undefined;
};
const findNodeAtPath = (editor, path, predicate) => {
    var _a;
    let index = -1;
    let node = editor;
    do {
        index += 1;
        node = (_a = node === null || node === void 0 ? void 0 : node.children) === null || _a === void 0 ? void 0 : _a[path[index]];
    } while (node && !predicate(node));
    return node;
};
const getEditorNode = (value, tagRender, extraProps) => {
    const key = nanoid();
    return Object.assign({ type: CompositeType.custom, value, render: tagRender(key), children: [{ text: '' }], key }, extraProps);
};
const insertConfig = (editor, value, tagRender, extraProps) => {
    Transforms.insertNodes(editor, getEditorNode(value, tagRender, extraProps));
    Transforms.move(editor);
};
const Element = ({ attributes, children, element }) => {
    var _a;
    switch (element.type) {
        case CompositeType.custom:
            const el = element;
            // editable span inside a non-editable span is hack to capture keyboard events for a span
            const editableNode = el.editable ? _jsx(EdiableNode, { children: children }) : undefined;
            return (_jsxs(NonEditableTag, { contentEditable: Boolean(el.editable), children: [(_a = el.render) === null || _a === void 0 ? void 0 : _a.call(el, el.value, editableNode), !el.editable && children] }));
        default: {
            // if (type === 'password') {
            //   const valueLength = contentLength; // TODO: support **** for password type
            //   return <span {...attributes}>{Array(valueLength).fill('*')}</span>;
            // }
            return _jsx("p", Object.assign({}, attributes, { children: children }));
        }
    }
};
export var CompositeElementType;
(function (CompositeElementType) {
    CompositeElementType["text"] = "text";
    CompositeElementType["number"] = "number";
    CompositeElementType["password"] = "password";
})(CompositeElementType || (CompositeElementType = {}));
const validateTemplate = (value, rules) => {
    for (const rule of rules) {
        if (rule.condition) {
            const updatedTemplate = rule.validate(value);
            if (updatedTemplate)
                return updatedTemplate;
        }
    }
};
export const CompositeTextarea = (_a) => {
    var { label, value: _value, onChange, children, tagRender, asInput, allowComposite = true, allowTyping = true, rows, variant = 'primary', type = CompositeElementType.text } = _a, restProps = __rest(_a, ["label", "value", "onChange", "children", "tagRender", "asInput", "allowComposite", "allowTyping", "rows", "variant", "type"]);
    const containerRef = useRef(null);
    const [target, setTarget] = useState();
    const inputValue = useMemo(() => (_value ? castArray(_value) : []), [_value]);
    const [allowNew, setAllowNew] = useState(allowComposite || !(inputValue === null || inputValue === void 0 ? void 0 : inputValue.length));
    const updateNodes = usePersistentCallback((template) => {
        let totalNodes = editor.children.length;
        // remove existing nodes, except 1
        for (let i = 0; i < totalNodes - 1; i++) {
            Transforms.removeNodes(editor, {
                at: [totalNodes - i - 1]
            });
        }
        // add new nodes
        for (const value of template) {
            Transforms.insertNodes(editor, value, {
                at: [editor.children.length]
            });
        }
        // remove the last node that was leftover from before
        Transforms.removeNodes(editor, { at: [0] });
    });
    const currentNodes = useRef(inputValue || []);
    const customRender = usePersistentCallback((key) => (value, editableNode) => {
        return tagRender(value, {
            onSelect: (value, extraProps) => {
                const path = findPath(editor, key);
                Transforms.setNodes(editor, getEditorNode(value, customRender, extraProps), { at: path });
            },
            editableNode,
            onClose: () => {
                updateNodes(toTemplate(normalizeValues(currentNodes.current.filter((item) => item.key !== key)), customRender));
            }
        });
    });
    useEffect(() => {
        updateNodes(toTemplate(inputValue, customRender));
    }, [updateNodes, inputValue, customRender]);
    const initialValue = useMemo(() => {
        return toTemplate(inputValue, customRender);
    }, [inputValue, customRender]);
    const onSelect = usePersistentCallback((value, extraProps) => {
        if (!target)
            return;
        Transforms.select(editor, target);
        insertConfig(editor, value, customRender, extraProps);
        setTarget(undefined);
    });
    const editor = useMemo(() => withConfig(withHistory(withReact(createEditor()))), []);
    const renderElement = useCallback((props) => _jsx(Element, Object.assign({}, props, { type: type })), [type]);
    const handleChange = usePersistentCallback((value) => {
        const updatedTemplate = validateTemplate(value, [
            {
                condition: type === CompositeElementType.number,
                validate: validateNumber
            },
            {
                condition: !allowComposite,
                validate: validateNonComposite.bind(null, true)
            },
            {
                condition: !allowTyping,
                validate: validateNonComposite.bind(null, false)
            }
        ]);
        if (updatedTemplate) {
            updateNodes(updatedTemplate);
            return;
        }
        const { selection } = editor;
        if (selection) {
            const [start, end] = Range.edges(selection);
            setTarget(Editor.range(editor, start, end));
        }
        else {
            setTarget(undefined);
        }
        const newValue = normalizeValues(fromTemplate(value));
        // when removing nodes we remove them one by one
        // which triggeres multiple onChange which has old value
        // and then new value which causes infinite loop when sent to
        // backend on didChange updates
        if (!isEqual(newValue, currentNodes.current)) {
            currentNodes.current = newValue;
            const outputValue = removeKeys(newValue);
            onChange === null || onChange === void 0 ? void 0 : onChange((allowComposite ? outputValue : outputValue[0]));
            setAllowNew(allowComposite || !outputValue.length);
        }
    });
    const handleKeyDown = usePersistentCallback((e) => {
        // if running in input mode do not allow new line
        if (e.key === KeyCodes.Enter && asInput) {
            e.preventDefault();
            return;
        }
    });
    const handlePaste = usePersistentCallback((e) => {
        const text = e.clipboardData.getData('Text');
        Transforms.insertText(editor, asInput ? text.replace(/\n/g, '') : text);
        e.preventDefault();
    });
    const menu = children === null || children === void 0 ? void 0 : children(onSelect);
    const showMenu = useMemo(() => {
        if (!(target === null || target === void 0 ? void 0 : target.anchor.path))
            return true;
        return !findNodeAtPath(editor, target.anchor.path, (node) => node.type === CompositeType.custom);
    }, [editor, target]);
    return (_jsxs(Stack, { gap: theme.spaces.x6, children: [label && _jsx(StyledLabel, { children: label }), _jsx(RelativeDiv, { ref: containerRef, children: _jsx(InputContainer, { variant: variant, inputSize: 'm', type: 'text', "$asInput": asInput, "$rows": rows, children: _jsx(SlateErrorBoundary, { children: _jsx(Slate, { editor: editor, initialValue: initialValue, onChange: handleChange, children: _jsx(InputActionBarPopup, { renderActionBar: noop, placementOffset: 15, overlay: showMenu && allowNew && menu, children: _jsx(StyledEditable, Object.assign({ renderElement: renderElement, onKeyDown: handleKeyDown, onPasteCapture: handlePaste }, restProps)) }) }) }) }) })] }));
};
