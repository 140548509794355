import { toast } from '@prophecy/ui';
import { useQueryClient } from '@tanstack/react-query';
import { Entity } from 'frontend/core/src/common/types/Entity';
import { Private_Routes } from 'frontend/core/src/common/url';
import { useUser } from 'frontend/core/src/context/user';
import { useGraphQlMutation } from 'frontend/core/src/data/util';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { QueryKeys } from '../../../common/queries/common';
import { EntityEvents } from '../../../common/user-analytics';
import { useTranspilerImportURL } from '../../../common/utils';
import { useMixPanel } from '../../../context/mixpanel/context';
import { CreateTeamQuery } from './../../UserSettings/queries/team';
import { TEAM_TABS } from './../types';
import { FormContexts } from './useFormContext';
import useUpdateExecutionMetrics from './useUpdateExecutionMetrics';
import useUpdateInfoAspect from './useUpdateInfoAspect';

const useCreateTeam = (formContexts: FormContexts) => {
  const adminID = useUser()?.user?.id;
  const { track } = useMixPanel();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { isTranspiler, transpilerImportURl, getCompleteUrl } = useTranspilerImportURL();

  const addTeamMutation = useGraphQlMutation(CreateTeamQuery);

  const basicForm = formContexts[TEAM_TABS.basic];
  const executionMetricsForm = formContexts[TEAM_TABS.executionMetrics];

  const { updateDescription, isLoading: isDescriptionLoading } = useUpdateInfoAspect(basicForm);
  const { updateExecutionMetrics, isLoading: isExecutionMetricsLoading } =
    useUpdateExecutionMetrics(executionMetricsForm);

  const resetModel = useCallback(async () => {
    await queryClient.invalidateQueries([QueryKeys.Team]);
  }, [queryClient]);

  const backToTeams = useCallback(() => {
    if (isTranspiler) {
      navigate(transpilerImportURl);
    } else {
      navigate(Private_Routes.Settings.tab.getUrl({ tab: 'teams' }));
    }
  }, [navigate, isTranspiler, transpilerImportURl]);

  const createTeam = useCallback(async () => {
    const name = basicForm.getValues('name');

    await addTeamMutation.mutateAsync(
      {
        name,
        adminID
      },
      {
        onSuccess: async (resp) => {
          const id = resp.addTeam?._id;
          if (id) {
            track(EntityEvents[Entity.Team].metadata.teamCreateSuccess, { id, adminId: adminID, name });
            await Promise.all([updateDescription(id), updateExecutionMetrics(id)]);
            toast.success({ content: 'Team created successfully.' });
            resetModel();
            if (isTranspiler) {
              navigate(getCompleteUrl(id, 'teamId'));
            } else {
              backToTeams();
            }
          } else {
            track(EntityEvents[Entity.Team].metadata.teamCreationFailed, { adminId: adminID, name });
            toast.error({ content: Entity.Team + ' creation failed.' });
          }
        }
      }
    );
  }, [
    addTeamMutation,
    adminID,
    navigate,
    getCompleteUrl,
    isTranspiler,
    basicForm,
    track,
    updateDescription,
    updateExecutionMetrics,
    resetModel,
    backToTeams
  ]);

  return {
    backToTeams,
    createTeam,
    isLoading: addTeamMutation.isLoading || isDescriptionLoading || isExecutionMetricsLoading
  };
};

export default useCreateTeam;
