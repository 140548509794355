import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { newLineToBr } from '@prophecy/utils/react/nodes';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import { Dialog } from '../Dialog';
import { Type as DialogType } from '../Dialog/types';
import { Ellipsis, MultilineEllipsis } from '../Ellipsis';
import { CheckIcon, CopyFIcon } from '../Icons';
import { Stack, StackItem } from '../Layout';
import { theme } from '../theme';
import { Text } from '../Typography/Text';
import { StyledButton, ToastCopyContainer, StyledSuccessIcon, DialogCopyContainer } from './styled';
import { toast } from './Toast';
import { Variant as ToastTypes } from './types';
export var MessageType;
(function (MessageType) {
    MessageType["info"] = "info";
    MessageType["error"] = "error";
    MessageType["success"] = "success";
    MessageType["warning"] = "warning";
})(MessageType || (MessageType = {}));
const StyledText = styled(Text) `
  word-break: break-all;
  position: relative;
`;
const StyledContent = styled(Stack) `
  max-height: calc(70vh - 170px); // 170px is the height of fixed items + gaps in between + padding.
  overflow: auto;
`;
const LinkButton = styled(Button) `
  text-decoration: underline;
  text-underline-offset: ${theme.spaces.x4};
  width: max-content;
  padding: 0;
`;
const DetailContainer = styled(Stack) `
  width: 100%;
  height: 100%;
  position: relative;
`;
const CopyButtonContainer = styled(Stack) `
  position: absolute;
  right: ${theme.spaces.x8};
  top: ${theme.spaces.x4};
  z-index: 1;
`;
const MessageTypeDisplayMap = {
    [MessageType.info]: DialogType.info,
    [MessageType.error]: DialogType.alert,
    [MessageType.success]: DialogType.success,
    [MessageType.warning]: DialogType.warning
};
const MessageTypeToastMap = {
    [MessageType.info]: ToastTypes.info,
    [MessageType.error]: ToastTypes.error,
    [MessageType.success]: ToastTypes.success,
    [MessageType.warning]: ToastTypes.error
};
const DEFAULT_DURATION = 10000;
const activeToastMap = {
    [MessageType.info]: new Map(),
    [MessageType.error]: new Map(),
    [MessageType.success]: new Map(),
    [MessageType.warning]: new Map()
};
function CopyToClipBoard({ content, variant }) {
    const [showCopied, setShowCopied] = useState(false);
    const onCopyToClipboard = (content) => {
        var _a;
        let messageContent = '';
        if (typeof content === 'string') {
            messageContent += content;
        }
        else if (React.isValidElement(content)) {
            const iterateOverChildren = (children) => {
                if (!children) {
                    return;
                }
                React.Children.forEach(children, (child) => {
                    if (typeof child === 'string') {
                        messageContent += child;
                    }
                    else if (React.isValidElement(child)) {
                        iterateOverChildren(child.props.children);
                    }
                });
            };
            iterateOverChildren((_a = content === null || content === void 0 ? void 0 : content.props) === null || _a === void 0 ? void 0 : _a.children);
        }
        if (messageContent) {
            navigator.clipboard.writeText(messageContent);
            setShowCopied(true);
            setTimeout(() => {
                setShowCopied(false);
            }, 1000);
        }
    };
    return (_jsx(_Fragment, { children: showCopied ? (_jsx(StyledSuccessIcon, { onClick: () => onCopyToClipboard === null || onCopyToClipboard === void 0 ? void 0 : onCopyToClipboard(content), icon: _jsx(CheckIcon, { type: 'default', size: 'xs' }), variant: 'link' })) : (_jsx(StyledButton, { tooltip: 'Copy to Clipboard', onClick: () => onCopyToClipboard === null || onCopyToClipboard === void 0 ? void 0 : onCopyToClipboard(content), icon: _jsx(CopyFIcon, { type: 'default', size: 'xs' }), variant: variant })) }));
}
export function MessageDetail({ content, detail }) {
    if (!content || (typeof content === 'string' && !content.trim()))
        return null;
    const _content = newLineToBr(content);
    return (_jsxs(StyledContent, { gap: '1rem', children: [_jsx(StyledText, { level: 'sm', children: _content }), ' ', detail ? (_jsxs(StyledText, { level: 'sm', children: [_jsx(DialogCopyContainer, { children: _jsx(CopyToClipBoard, { content: detail, variant: 'link' }) }), newLineToBr(detail)] })) : null] }));
}
function ShowMore({ children, defaultShow = false }) {
    const [show, setShow] = useState(defaultShow);
    return show ? (_jsx(_Fragment, { children: children })) : (_jsx(LinkButton, { size: 's', variant: 'link', onClick: () => setShow(true), children: "More Details" }));
}
function base(messageType, config) {
    const { content, title = _jsx(Text, { level: 'sm', children: "Details" }), detail, detailText, onDetailButtonClick, duration = DEFAULT_DURATION, detailButtonText = 'More..', copyToClipBoard = false, width: _width, collapseDetail = false } = config;
    if (!content || (typeof content === 'string' && !content.trim()))
        return;
    // if content is too long or detail is present, add More button
    const hasDetailsToShow = detail || (typeof content === 'string' && content.length > 120);
    const showMoreButton = hasDetailsToShow || Boolean(onDetailButtonClick);
    const _content = newLineToBr(content);
    const width = _width || (hasDetailsToShow ? 500 : 400);
    //width - More button width +padding + border
    const contentWidth = showMoreButton ? width - 210 : width - 90;
    const showDetailModal = () => {
        const dialogType = MessageTypeDisplayMap[messageType];
        const copyButton = (_jsx(DialogCopyContainer, { children: _jsx(CopyToClipBoard, { content: detailText || detail || content, variant: 'link' }) }));
        let detailToRender = null;
        if (detail && typeof detail === 'string') {
            detailToRender = (_jsxs(StyledText, { level: 'sm', children: [copyButton, newLineToBr(detail)] }));
        }
        else if (detail) {
            detailToRender = (_jsxs(DetailContainer, { children: [detail, _jsx(CopyButtonContainer, { children: copyButton })] }));
        }
        Dialog[dialogType]({
            children: (_jsxs(StyledContent, { gap: theme.spaces.x8, children: [_jsxs(StyledText, { level: 'sm', children: [!detail && copyButton, _content] }), ' ', detailToRender && _jsx(ShowMore, { defaultShow: !collapseDetail, children: detailToRender })] })),
            title,
            size: 'l',
            iconPlacement: 'right',
            closeButton: null
        });
    };
    const messageContent = (_jsxs(Stack, { direction: 'horizontal', gap: theme.spaces.x12, alignY: 'center', align: 'space-between', width: '100%', children: [_jsx(Stack, { width: contentWidth + 'px', children: showMoreButton ? (_jsx(Ellipsis, { tooltip: true, tooltipProps: { variant: 'info', overlayStyle: { width, wordBreak: 'break-all' } }, children: _content })) : (_jsx("p", { children: _jsx(MultilineEllipsis, { lines: 2, expandable: true, symbol: '... more', collapsible: true, children: _content }) })) }), showMoreButton && (_jsx(StackItem, { shrink: '0', children: _jsx(Button, { variant: 'secondaryGrey', danger: messageType === MessageType.error, size: 's', onClick: () => {
                        if (hasDetailsToShow)
                            showDetailModal();
                        onDetailButtonClick === null || onDetailButtonClick === void 0 ? void 0 : onDetailButtonClick();
                    }, children: detailButtonText }) })), copyToClipBoard && (_jsx(StackItem, { shrink: '0', children: _jsx(ToastCopyContainer, { children: _jsx(CopyToClipBoard, { content: content, variant: 'linkGrey' }) }) }))] }));
    const toastType = MessageTypeToastMap[messageType];
    /**
     * find existing toast with same content and close it
     */
    if (activeToastMap[messageType].has(content)) {
        const activeToast = activeToastMap[messageType].get(content);
        if (activeToast) {
            activeToast.close();
        }
    }
    const currentToast = toast[toastType]({
        content: messageContent,
        duration,
        width: `${width}`,
        onClose: () => {
            activeToastMap[messageType].delete(content);
        }
    });
    activeToastMap[messageType].set(content, currentToast);
    return currentToast;
}
function error(config) {
    return base(MessageType.error, Object.assign({ copyToClipBoard: true }, config));
}
function info(config) {
    return base(MessageType.info, config);
}
function success(config) {
    return base(MessageType.success, config);
}
function warning(config) {
    return base(MessageType.warning, config);
}
export const message = { error, info, success, warning };
