import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFocusTrap } from '@prophecy/utils/react/focus';
import { useControlledState } from '@prophecy/utils/react/hooks';
import { Root, Trigger, Portal, Arrow } from '@radix-ui/react-hover-card';
import { theme } from '../theme';
import { HoverCardContent } from './styled';
export function HoverCard({ overlay, visible, onVisibleChange, children, overlayClassName, overlayStyle, arrow, placement: side = 'bottom', placementOffset: sideOffset = parseInt(theme.spaces.x8, 10), align, alignOffset }) {
    const [_visible, toggleVisible] = useControlledState({
        value: visible,
        onChange: onVisibleChange
    });
    const container = useFocusTrap(Boolean(_visible));
    return (_jsxs(Root, { open: _visible, onOpenChange: toggleVisible, children: [_jsx(Trigger, { asChild: true, children: children }), _jsx(Portal, { children: _jsx(HoverCardContent, { asChild: true, className: overlayClassName, style: overlayStyle, side: side, align: align, sideOffset: sideOffset, alignOffset: alignOffset, ref: container, tabIndex: -1, children: _jsxs("div", { children: [arrow && _jsx(Arrow, {}), overlay] }) }) })] }));
}
