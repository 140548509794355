import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ReactComponent as EmptyBox } from '../assets/images/empty.svg';
import { Stack } from '../Layout';
import { StyledEmptyWrap, StyledImageWrap, StyledEmptyDescription } from './styled';
import { tokens } from './tokens';
import { EmptySize } from './type';
// TODO: Default image need to be taken from the design
export function Empty(_a) {
    var { image = _jsx(EmptyBox, {}), size = EmptySize.m, description = 'No Data', children } = _a, rest = __rest(_a, ["image", "size", "description", "children"]);
    return (_jsxs(StyledEmptyWrap, Object.assign({ alignY: 'center' }, rest, { children: [_jsx(StyledImageWrap, { size: size, children: image }), children || description ? (_jsxs(Stack, { alignY: 'center', gap: tokens.Description.gap, children: [_jsx(StyledEmptyDescription, { elementType: 'p', level: 'sm', children: description }), children] })) : null] })));
}
