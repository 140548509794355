import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isNodeEvnProduction } from '@prophecy/utils/env';
import { uniqueId } from 'lodash-es';
import { forwardRef, useMemo } from 'react';
import { XCircleIcon } from '../Icons';
import { renderWithTooltip } from '../Tooltip';
import { Text } from '../Typography/Text';
import { StyledInputContainer, StyledInput, StyledIconWrapper, StyledLabel, InputWrap, StyledHelpText, StyledInputRoot, StyledAddOnWrapper, StyledLabelWithHint } from './styled';
import { tokens } from './tokens';
import { InputVariants, Placement, InputSize } from './types';
import { TriggerKind, useInput } from './useInput';
export const Input = forwardRef((_a, ref) => {
    var { variant = InputVariants.primary, type = 'text', value, iconPlacement = Placement.right, addOnPlacement = Placement.left, icon, addOn, title, maxLength, label, inputSize = InputSize.m, placeholder, disabled, min, max, hint, tooltip, delay = 0, preFormat, onChange, onPressEnter, onClear, onKeyPress, onBlur, helpText, allowEscapeSequence, style, className, allowClear, onClick, id } = _a, restProps = __rest(_a, ["variant", "type", "value", "iconPlacement", "addOnPlacement", "icon", "addOn", "title", "maxLength", "label", "inputSize", "placeholder", "disabled", "min", "max", "hint", "tooltip", "delay", "preFormat", "onChange", "onPressEnter", "onClear", "onKeyPress", "onBlur", "helpText", "allowEscapeSequence", "style", "className", "allowClear", "onClick", "id"]);
    const inputId = useMemo(() => id || uniqueId('input'), [id]);
    const { showClear, setFocus, _value, setRef, handleChange, handleBlur, handleKeyPress, handleClear, valueLength } = useInput({
        value,
        onChange,
        delay,
        allowEscapeSequence,
        onBlur,
        onPressEnter,
        onKeyPress,
        preFormat,
        ref,
        allowClear,
        onClear
    });
    const showIcon = icon && !(showClear && iconPlacement === Placement.right);
    // validation for props, this will be trimmed on production build
    if (!isNodeEvnProduction()) {
        if (inputSize !== InputSize.l && title) {
            throw new Error('title prop is only available for large size input');
        }
    }
    let inputUI = (_jsxs(InputWrap, { align: 'start', alignY: 'start', direction: 'vertical', addOnPlacement: addOn ? addOnPlacement : undefined, iconPlacement: icon ? iconPlacement : undefined, "data-placeholder": placeholder, "data-value": _value, children: [title && (_jsx(Text, { level: 'xxs', color: tokens.Input.Title.color, children: title })), _jsx(StyledInput, Object.assign({}, restProps, { ref: setRef, id: inputId, type: type, maxLength: maxLength, min: min, max: max, addOn: addOn, value: _value, placeholder: placeholder, onChange: (e) => handleChange(e, TriggerKind.User), onBlur: handleBlur, disabled: disabled, onKeyPress: handleKeyPress }))] }));
    if (tooltip) {
        inputUI = _jsx(_Fragment, { children: renderWithTooltip(inputUI, tooltip) });
    }
    if (!label && hint) {
        inputUI = _jsx(StyledLabelWithHint, { hint: hint, children: inputUI });
    }
    const labelUI = label ? _jsx(StyledLabel, { htmlFor: inputId, children: label }) : null;
    return (_jsxs(StyledInputRoot, { variant: variant, size: inputSize, direction: 'vertical', gap: tokens.Root.gap, style: style, className: className, onClick: (e) => {
            onClick === null || onClick === void 0 ? void 0 : onClick(e);
            setFocus();
        }, children: [hint && label ? (_jsx(StyledLabelWithHint, { level: 'xs', hint: hint, children: labelUI })) : (labelUI), _jsxs(StyledInputContainer, { type: type, variant: variant, inputSize: inputSize, disabled: disabled, children: [inputUI, showClear && (_jsx(StyledIconWrapper, { iconPlacement: iconPlacement, addOnPlacement: addOn ? addOnPlacement : undefined, children: _jsx(XCircleIcon, { type: 'solid', onClick: handleClear }) })), showIcon && (_jsx(StyledIconWrapper, { iconPlacement: iconPlacement, addOnPlacement: addOn ? addOnPlacement : undefined, children: icon })), addOn && (_jsx(StyledAddOnWrapper, { size: inputSize, iconPlacement: icon ? iconPlacement : undefined, addOnPlacement: addOnPlacement, children: addOn }))] }), helpText && (_jsxs(StyledHelpText, { variant: variant, direction: 'horizontal', align: 'space-between', children: [helpText, type !== 'number' && maxLength && maxLength > 0 ? _jsx("span", { children: valueLength + '/' + maxLength }) : null] }))] }));
});
