import { useEffect, useState } from 'react';
let insertTextOnEditor = () => { };
export const useEditorInsertText = (editor, monaco) => {
    const [editorValue, setEditorValue] = useState('');
    const onEditorValueChange = (value) => {
        setEditorValue(value);
    };
    useEffect(() => {
        if (editor && monaco) {
            const _insertTextOnEditor = (value, prepareValue) => {
                const selection = editor === null || editor === void 0 ? void 0 : editor.getSelection();
                const position = selection === null || selection === void 0 ? void 0 : selection.getStartPosition();
                if (position) {
                    const editorValue = (editor === null || editor === void 0 ? void 0 : editor.getValue()) || '';
                    value = prepareValue ? prepareValue({ insertText: value, position, code: editorValue }) : value;
                    const range = new monaco.Range(position.lineNumber, position.column, position.lineNumber, position.column);
                    editor === null || editor === void 0 ? void 0 : editor.executeEdits('', [{ range, text: value }]);
                    // update local state with the new editor value
                    setEditorValue(editorValue);
                    editor === null || editor === void 0 ? void 0 : editor.focus();
                }
            };
            // store the insertTextOnEditor function in the global scope on mount
            insertTextOnEditor = _insertTextOnEditor;
            editor.onDidFocusEditorWidget(() => {
                // on focus, update the insertTextOnEditor function with the current editor instance, as there can be multiple instances of the editor
                insertTextOnEditor = _insertTextOnEditor;
            });
        }
    }, [editor, monaco]);
    return { editorValue, onEditorValueChange };
};
export { insertTextOnEditor };
