import { Content } from '@radix-ui/react-dialog';
import styled from 'styled-components';
import { Button } from '../Button';
import { StackItem } from '../Layout';
import { RadialIconContainer } from '../Misc/RadialIconContainer';
import { fadeOut, fadeIn } from '../theme';
import { Text } from '../Typography/Text';
import { tokens } from './tokens';
import { IconPlacement } from './types';
export const StyledOverlay = styled.div `
  background-color: ${tokens.Dialog.Overlay.backgroundColor};
  z-index: ${tokens.Dialog.Overlay.zIndex};
  position: fixed;
  opacity: 0.3;
  inset: 0;

  /**
  Commenting out animation for now, causing chrome glitch
  &[data-state='open'] {
    animation: ${fadeIn(0.3)} ${tokens.Dialog.Content.transition};
    animation-fill-mode: forwards;
  }
  &[data-state='closed'] {
    animation: ${fadeOut(0.25)} ${tokens.Dialog.Content.transition};
    animation-fill-mode: forwards;
  }
   */
`;
export const StyledDialog = styled(Content) `
  background-color: ${tokens.Dialog.Content.backgroundColor};
  box-shadow: ${tokens.Dialog.Content.boxShadow};
  position: fixed;
  z-index: ${tokens.Dialog.Content.zIndex};
  &.topLayer {
    z-index: ${tokens.Dialog.Overlay.topLayerIndex};
  }
  font-family: ${tokens.Dialog.Content.fontFamily};
  font-size: ${tokens.Dialog.Content.fontSize};
  border-radius: ${tokens.Dialog.Content.borderRadius};
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: ${(props) => tokens.Dialog.size[props.size].width + 'px'};
  transition: ${tokens.Dialog.Content.transition};
  max-height: 85vh;
  overflow: auto;
`;
export const StyledIconContainer = styled(RadialIconContainer) `
  ${({ iconPlacement }) => (iconPlacement === IconPlacement.right ? 'order: 2;' : '')}
`;
export const StyledContent = styled(StackItem) `
  padding: ${tokens.Dialog.Content.padding};
`;
export const StyledChildrenWrap = styled.div `
  overflow: auto;
`;
export const StyledFooter = styled.div `
  padding: ${tokens.Dialog.Content.footerPadding} ${tokens.Dialog.Content.padding};
  border-top: 1px solid ${tokens.Dialog.Footer.borderColor};
`;
const StyledButton = styled(Button) `
  flex: ${({ isBlock }) => (isBlock ? '1' : 'unset')};
  margin-top: 10px;
`;
export const OkButton = styled(StyledButton) `
  order: 1;
`;
export const CancelButton = StyledButton;
export const StyledLabel = styled(Text) `
  color: ${tokens.Dialog.Content.subHeadingColor};
  margin-bottom: 10px;
`;
