import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import RCCascader from 'rc-cascader';
import { ChevronRightIcon } from '../Icons';
import { createCustomSelect } from './BaseSelect';
import { tokens } from './tokens';
const CustomCascader = createCustomSelect(RCCascader);
export function Cascader(_a) {
    var { showSearch = true } = _a, props = __rest(_a, ["showSearch"]);
    return (_jsx(CustomCascader, Object.assign({ expandIcon: _jsx(ChevronRightIcon, { type: 'solid', size: 'xs', color: tokens.Menu.iconColor }), showSearch: showSearch }, props)));
}
