import { useForm, UseFormReturn } from 'react-hook-form';

import { BasicFormData, ExecutionMetricsFormData, TEAM_TABS } from '../types';

export type FormContexts = {
  [TEAM_TABS.basic]: UseFormReturn<BasicFormData>;
  [TEAM_TABS.executionMetrics]: UseFormReturn<ExecutionMetricsFormData>;
};

const makeFormContextHook = (tab?: TEAM_TABS) => () => {
  const formContexts: Partial<FormContexts> = {};

  if (!tab || tab === TEAM_TABS.basic) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    formContexts[TEAM_TABS.basic] = useForm<BasicFormData>({
      mode: 'onChange',
      defaultValues: {
        name: '',
        description: ''
      }
    });
  }

  if (!tab || tab === TEAM_TABS.executionMetrics) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    formContexts[TEAM_TABS.executionMetrics] = useForm<ExecutionMetricsFormData>({
      mode: 'onChange',
      defaultValues: {
        pipelineMetricsTable: '',
        componentMetricsTable: '',
        interimsTable: '',
        enabled: true
      }
    });
  }

  return tab ? formContexts[tab] : (formContexts as FormContexts);
};

export default makeFormContextHook() as () => FormContexts;

export const useExecutionMetricsForm = makeFormContextHook(
  TEAM_TABS.executionMetrics
) as () => UseFormReturn<ExecutionMetricsFormData>;
