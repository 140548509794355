export function addWatchableProperty(t, { name, defaultValue }) {
    const privateKey = `_${name}`;
    const watchKey = `watch${name.slice(0, 1).toUpperCase()}${name.slice(1)}`;
    const triggerKey = `trigger${name.slice(0, 1).toUpperCase()}${name.slice(1)}`;
    const listeners = new Set();
    delete t[name];
    Object.defineProperty(t, name, {
        get: () => {
            return t[privateKey];
        },
        set: (value) => {
            t[privateKey] = value;
            t[triggerKey]();
        }
    });
    Object.defineProperty(t, privateKey, {
        value: defaultValue,
        writable: true
    });
    Object.defineProperty(t, watchKey, {
        value: (cb) => {
            listeners.add(cb);
            return () => {
                listeners.delete(cb);
            };
        }
    });
    Object.defineProperty(t, triggerKey, {
        value: () => {
            listeners.forEach((cb) => cb(t[privateKey]));
        }
    });
}
