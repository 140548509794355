import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { afterAnimationFrame } from '@prophecy/utils/dom';
import { useControlledState, usePersistentCallback } from '@prophecy/utils/react/hooks';
import { noop } from 'lodash-es';
import { createContext, createRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { StyledButton } from '../Button/styled';
import { MONACO_DECORATION_TOOLTIP_WIDGET_SELECTOR } from '../Editor/tokens';
import { Stack } from '../Layout';
import { Popover } from '../Popover';
import { theme } from '../theme';
const PopoverTarget = styled.div `
  position: absolute;
  z-index: -${theme.zLayer.xs};
  width: 100%;
  height: ${({ height }) => height};
`;
const PopoverWrap = styled.div `
  position: relative;
  height: 100%;
  &:has(${PopoverTarget}[data-state="open"]) {
    ${MONACO_DECORATION_TOOLTIP_WIDGET_SELECTOR}[style*='top: -2'], ${MONACO_DECORATION_TOOLTIP_WIDGET_SELECTOR}[style*='top: -3'] {
      transform: translateY(50px);
    }
  }
`;
export const ActionBarWrapper = styled(Stack).attrs({ gap: theme.spaces.x8, direction: 'horizontal' }) `
  box-shadow: ${theme.shadows.sm};
  padding: ${theme.spaces.x6};
  border-radius: ${theme.radius.s};
  background: ${theme.colors.white};

  ${StyledButton} {
    font-weight: ${theme.fontWeight.medium};
    padding: ${theme.spaces.x8};
  }
`;
export const InputActionBarContext = createContext({
    actionBarMethods: createRef(),
    isInputActionBarOpen: false
});
export function InputActionBarContextProvider({ actionBarMethods, isInputActionBarOpen, children }) {
    const memoizedContext = useMemo(() => ({ actionBarMethods, isInputActionBarOpen }), [actionBarMethods, isInputActionBarOpen]);
    return _jsx(InputActionBarContext.Provider, { value: memoizedContext, children: children });
}
export function InputActionBarPopup(_a) {
    var { visible, onVisibleChange, className, children, loading, align = 'end', placement = 'top', overlay, placementOffset = 0, renderActionBar, getActionBarMethods, disabled, focusPatch } = _a, rest = __rest(_a, ["visible", "onVisibleChange", "className", "children", "loading", "align", "placement", "overlay", "placementOffset", "renderActionBar", "getActionBarMethods", "disabled", "focusPatch"]);
    const [open, toggleOpen] = useControlledState({
        value: visible,
        onChange: onVisibleChange
    });
    //TODO: Temporary solution until we upgrade the popover component
    // open the action bar popup on 2nd render cycle's end if focus patch is enabled
    const [openFromFocus, setOpenFromFocus] = useState(false);
    useEffect(() => {
        if (openFromFocus) {
            afterAnimationFrame(() => {
                toggleOpen(true);
            });
        }
    }, [openFromFocus, toggleOpen]);
    useEffect(() => {
        if (visible !== undefined && focusPatch) {
            setOpenFromFocus(visible);
        }
    }, [visible, focusPatch]);
    const inputActionBarMethods = useRef({});
    const popoverWrap = useRef(null);
    const _onVisibleChange = (visible) => {
        // open the copilot popup only from focus, but for close, give the control to popover
        if (!visible) {
            toggleOpen(false);
            setOpenFromFocus(false);
        }
    };
    const actionBarOverlay = renderActionBar(inputActionBarMethods.current);
    const memoizedGetActionBarMethods = usePersistentCallback((actionBarMethods) => {
        getActionBarMethods === null || getActionBarMethods === void 0 ? void 0 : getActionBarMethods(actionBarMethods);
    });
    useEffect(() => {
        memoizedGetActionBarMethods(inputActionBarMethods.current);
        return () => {
            memoizedGetActionBarMethods(undefined);
        };
    }, [memoizedGetActionBarMethods]);
    const popoverOverlay = overlay || actionBarOverlay;
    const isInputActionBarOpen = Boolean(popoverOverlay && (open || loading));
    return (_jsxs(PopoverWrap, { ref: popoverWrap, className: className, onFocus: (e) => {
            if (!disabled) {
                if (focusPatch) {
                    setOpenFromFocus(true);
                }
                else {
                    toggleOpen(true);
                }
            }
        }, children: [_jsx(Popover, Object.assign({}, rest, { visible: isInputActionBarOpen, overlayStyle: { background: 'none', padding: '0', boxShadow: 'none', minWidth: 'auto', border: 'none' }, overlay: popoverOverlay || null, onOpenAutoFocus: (e) => e.preventDefault(), onCloseAutoFocus: (e) => e.preventDefault(), onInteractOutside: (e) => {
                    var _a;
                    // if interaction is happening inside popover wrap no need to close the popup
                    if ((_a = popoverWrap.current) === null || _a === void 0 ? void 0 : _a.contains(e.target)) {
                        e.preventDefault();
                    }
                }, onVisibleChange: _onVisibleChange, align: align, hideWhenDetached: true, placementOffset: placementOffset, placement: placement, children: _jsx(PopoverTarget, { height: placementOffset < 0 ? `${-placementOffset}px` : '1px' }) })), _jsx(InputActionBarContextProvider, { actionBarMethods: inputActionBarMethods, isInputActionBarOpen: isInputActionBarOpen, children: children })] }));
}
export function useRegisterActionBarMethod(name, callback) {
    const { actionBarMethods: actionBarRef } = useContext(InputActionBarContext);
    const persistentCallback = usePersistentCallback(callback);
    if (actionBarRef.current) {
        actionBarRef.current[name] = persistentCallback;
    }
    useEffect(() => {
        return () => {
            if (!actionBarRef.current)
                return;
            // as the object reference never change we can safely remove from the callback list
            // eslint-disable-next-line react-hooks/exhaustive-deps
            actionBarRef.current[name] = undefined;
        };
    }, [name, actionBarRef]);
}
export function useInputActionBarOpenState() {
    const { isInputActionBarOpen } = useContext(InputActionBarContext);
    return Boolean(isInputActionBarOpen);
}
export function renderWithActionBarWrapper(renderActionBar = noop) {
    return (methods) => {
        const actionBarButtons = renderActionBar(methods);
        if (!actionBarButtons)
            return null;
        return _jsx(ActionBarWrapper, { children: actionBarButtons });
    };
}
