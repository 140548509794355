import { ObjectLiteral } from '@prophecy/interfaces/generic';
import queryString from 'query-string';

const CSRF_WINDOW_KEY = '__prophecy-csrf-token';
export const CSRF_TOKEN_KEY = 'csrf-token';

const windowStore = window as ObjectLiteral;

export const csrf = {
  key: CSRF_TOKEN_KEY,
  get() {
    return windowStore[CSRF_WINDOW_KEY] as string;
  },
  set(value: string) {
    windowStore[CSRF_WINDOW_KEY] = value;
  },
  delete() {
    windowStore[CSRF_WINDOW_KEY] = null;
  }
};

export function addCSRFQuery(url: string) {
  // if it existing query params append it with existing or add the whole search string
  const csrfQuery = queryString.stringify({ [CSRF_TOKEN_KEY]: csrf.get() });
  return url.includes('?') ? `${url}&${csrfQuery}` : `${url}?${csrfQuery}`;
}
