import { Input, Stack, Switch, theme } from '@prophecy/ui';
import { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { ExecutionMetricsFormData } from '../types';

const ExecutionMetricsForm = ({
  readOnly,
  formContext,
  updateTeamErrors
}: {
  readOnly?: boolean;
  formContext: UseFormReturn<ExecutionMetricsFormData>;
  updateTeamErrors: (e: boolean) => void;
}) => {
  const {
    control,
    formState: { errors, isValid },
    watch
  } = formContext;

  const pipelineMetricsTable = watch('pipelineMetricsTable');
  const componentMetricsTable = watch('componentMetricsTable');
  const interimsTable = watch('interimsTable');

  const isRequired = useMemo(() => {
    return Boolean(pipelineMetricsTable || componentMetricsTable || interimsTable);
  }, [pipelineMetricsTable, componentMetricsTable, interimsTable]);

  useEffect(() => {
    updateTeamErrors(isRequired ? !isValid : false);
  }, [updateTeamErrors, isRequired, isValid]);

  return (
    <>
      <Stack align='start' gap={theme.spaces.x24} direction='vertical' padding={`0 ${theme.outlineWidth}`}>
        <Controller
          render={({ field }) => (
            <Input
              label='Pipeline runs table'
              {...field}
              disabled={readOnly}
              variant={isRequired && errors.pipelineMetricsTable ? 'error' : 'primary'}
              helpText={isRequired ? errors.pipelineMetricsTable?.message : ''}
              placeholder='prophecy.pipeline_runs'
            />
          )}
          control={control}
          name='pipelineMetricsTable'
          rules={{
            required: 'Pipeline metrics table is required'
          }}
        />
        <Controller
          render={({ field }) => (
            <Input
              label='Component runs table'
              {...field}
              disabled={readOnly}
              variant={isRequired && errors.componentMetricsTable ? 'error' : 'primary'}
              helpText={isRequired ? errors.componentMetricsTable?.message : ''}
              placeholder='prophecy.component_runs'
            />
          )}
          control={control}
          name='componentMetricsTable'
          rules={{
            required: 'Component metrics table is required'
          }}
        />
        <Controller
          render={({ field }) => (
            <Input
              label='Interims table'
              {...field}
              disabled={readOnly}
              variant={isRequired && errors.interimsTable ? 'error' : 'primary'}
              helpText={isRequired ? errors.interimsTable?.message : ''}
              placeholder='prophecy.interims'
            />
          )}
          control={control}
          name='interimsTable'
          rules={{
            required: 'Interims table is required'
          }}
        />
        <Controller
          render={({ field }) => (
            <Switch {...field} checked={field.value} disabled={readOnly}>
              Enable Execution Metrics
            </Switch>
          )}
          control={control}
          name='enabled'
        />
      </Stack>
    </>
  );
};

export default ExecutionMetricsForm;
