import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { Dispatch, SetStateAction } from 'react';
import { Context, useContextSelector } from 'use-context-selector';

import { HistoryContext, useHistory } from '../../HistoryManager/context';
import { HistoryType } from '../../HistoryManager/types';
import { frameId } from '../../HistoryManager/utils';
import { PreferenceContextType, SetPreference, SetPreferenceOptions } from './types';

export function useSetPreferenceHistory<T, K extends keyof T>(
  preferences: T,
  setPreference: Dispatch<SetStateAction<T>>,
  persistPreference: (preferences: T) => void
) {
  const updatePreference = (key: K, value: SetStateAction<T[K]>) => {
    setPreference((currentPreferences) => {
      let updatedValue: T[K];
      if (typeof value === 'function') {
        updatedValue = (value as Function)(currentPreferences[key]);
      } else {
        updatedValue = value;
      }

      const updatedPreferences = { ...currentPreferences, [key]: updatedValue };
      persistPreference(updatedPreferences);
      return updatedPreferences;
    });
  };

  return usePersistentCallback(
    (
      key: K,
      value: T[K] | ((prevValue: T[K]) => T[K]),
      { saveInHistory = false, historyMergeId, metadata }: SetPreferenceOptions = {},
      history?: HistoryContext
    ) => {
      updatePreference(key, value);

      if (history && saveInHistory) {
        history.mergeOrTrackChange(
          {
            prevValue: () => updatePreference(key, preferences[key]),
            nextValue: () => updatePreference(key, value),
            metadata: { type: HistoryType.callback, ...metadata }
          },
          historyMergeId ?? frameId()
        );
      }
    }
  );
}

export function usePreference<T, K extends keyof T>(
  Context: Context<PreferenceContextType<T>>,
  key: K
): [T[K], (value: SetStateAction<T[K]>, options?: SetPreferenceOptions) => void] {
  const _setPreference = useContextSelector<PreferenceContextType<T>, SetPreference<T>>(
    Context,
    (context) => context.setPreference
  );

  const history = useHistory();

  const setPreference = usePersistentCallback((value: SetStateAction<T[K]>, options?: SetPreferenceOptions) => {
    _setPreference(key, value, options, history);
  });

  const preference = useContextSelector<PreferenceContextType<T>, T[K]>(Context, (context) => context.preferences[key]);

  return [preference, setPreference];
}
