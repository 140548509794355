import { createBrowserHistory } from 'history';
import { useLocation } from 'react-router-dom';
//export const history = createBrowserHistory();
export const BASE_PATH = process.env.PUBLIC_URL || '';
export const history = createBrowserHistory({
    window
});
/**
 * react-router@v6's history module doesn't support basename
 * https://github.com/remix-run/history/issues/811#issue-648979053
 *
 * functions historyPush/historyReplace can be in place of history.push/history.replace
 */
export function historyPush(to, state) {
    return history.push(BASE_PATH + to, state);
}
export const useLocationPathName = () => {
    const location = useLocation();
    return location.pathname.replace(BASE_PATH, '');
};
export function navigateAndRefresh(to) {
    window.location.href = BASE_PATH + to;
}
export function openInNewWindow(to) {
    window.open(BASE_PATH + to);
}
export function clearStorage() {
    if (window.location.hostname !== 'localhost') {
        localStorage.clear();
        sessionStorage.clear();
    }
}
