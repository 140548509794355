import { memoize } from 'lodash-es';
import { useCallback, useState } from 'react';

export type TabError = boolean | string | (boolean | string)[];

export const useTabErrors = <T extends string>() => {
  const [errors, setErrors] = useState<Record<T, TabError>>({} as Record<T, TabError>);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const makeSetTabError = useCallback(
    memoize((tab: T) => (hasErrors: TabError) => {
      setErrors((prev) => ({ ...prev, [tab]: hasErrors }));
    }),
    []
  );

  return { errors, makeSetTabError };
};
