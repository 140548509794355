import { ProphecyError } from '@prophecy/utils/error';
import { ResultOf, VariablesOf } from 'gql.tada';

import { toFabricObject } from '../../data/apis/api';
import { getUserFabricFields } from '../../data/apis/getUserFabricFields';
import { guessQueryName, makeGraphQlCall, readGraphQlErrorMessage } from '../../data/util';
import { graphql } from '../../graphqlTypes/graphql';
import { captureException } from '../sentry';
import { GqlErrorTags, SentryTags } from '../sentry/SentryTags';

function getQuery(isSqlProject: boolean, hasAirflow: boolean) {
  return graphql(`
  query OnboardingEntitiesQuery($uid: String!) {
    User(uid: $uid) {
      onboardingEntities {
        datasetDirectoryPath
        lastFlowCompleted
        lastFlowCompletedSql
        sqlProject{
            _id
        }
        sqlFabric{
            ${getUserFabricFields(isSqlProject, hasAirflow)}
        }
        project {
          pipelines {
            _id
            name
          }
        }
        fabric {
          ${getUserFabricFields(isSqlProject, hasAirflow)}
        }
      }
    }
  }
`);
}
type QueryType = ReturnType<typeof getQuery>;
export type OnboardingEntitiesQueryResponse = ResultOf<QueryType>;
type OnboardingEntitiesQueryVariables = VariablesOf<QueryType>;
export async function fetchOnboardingDetails(
  payload: OnboardingEntitiesQueryVariables,
  isSqlProject: boolean,
  hasAirflow: boolean
) {
  const OnboardingEntitiesQuery = getQuery(isSqlProject, hasAirflow);

  const queryName = guessQueryName(OnboardingEntitiesQuery as unknown as string);

  try {
    const _response = await makeGraphQlCall<OnboardingEntitiesQueryResponse>(OnboardingEntitiesQuery, payload);

    if (_response.User?.onboardingEntities.fabric) {
      _response.User.onboardingEntities.fabric = toFabricObject(_response.User.onboardingEntities.fabric);
    }
    if (_response.User?.onboardingEntities.sqlFabric) {
      _response.User.onboardingEntities.sqlFabric = toFabricObject(_response.User.onboardingEntities.sqlFabric);
    }

    return _response.User?.onboardingEntities;
  } catch (_errorResponse) {
    let errorMessage = readGraphQlErrorMessage(_errorResponse);
    captureException({
      exception: new ProphecyError(errorMessage),
      errorTags: { [SentryTags.ProphecyErrorGqlType]: queryName || GqlErrorTags.QueryError }
    });

    throw _errorResponse;
  }
}
export const UpdateOnboardingStatusMutation = graphql(`
  mutation UpdateOnboardingStatusMutation($lastFlowCompleted: String, $lastFlowCompletedSql: String) {
    updateLastFlowCompletedOnboarding(
      lastFlowCompleted: $lastFlowCompleted
      lastFlowCompletedSql: $lastFlowCompletedSql
    )
  }
`);
