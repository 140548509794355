import { theme } from '../theme';
export const tokens = {
    ProgressBar: {
        height: theme.sizes.x8,
        width: '400px',
        Content: {
            backgroundColor: theme.colors.grey200,
            transition: theme.transition.m
        },
        loading: {
            backgroundColor: theme.colors.primary500
        },
        success: {
            backgroundColor: theme.colors.success500
        },
        error: {
            backgroundColor: theme.colors.error500
        },
        size: {
            borderRadius: theme.radius.m
        }
    }
};
