import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { preventDefault } from '@prophecy/utils/dom';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Dialog } from '../Dialog';
import { ClockIcon } from '../Icons';
import { Input } from '../Input';
import { StyledLabel } from '../Input/styled';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { CronGenerator } from './CronGenerator';
import { ReadOnlyValue, StyledCronRoot } from './styled';
const ClearLink = styled.a `
  font-family: ${theme.fontFamily.text};
  font-weight: ${theme.fontWeight.normal};
  font-size: ${theme.fontSizes.x12};
  color: ${theme.colors.primary600};
`;
export function CronInput(_a) {
    var { value = '', cronType, onChange, onBlur, min, max, label, allowClear } = _a, restProps = __rest(_a, ["value", "cronType", "onChange", "onBlur", "min", "max", "label", "allowClear"]);
    const [cronValue, setCronValue] = useState(value);
    useEffect(() => {
        setCronValue(value);
    }, [value]);
    const [showDialog, setShowDialog] = useState(false);
    // while the user click the icon, we make sure blur is not triggered
    // so validation or save doesn't trigger at wrong time.
    const preventBlurRef = useRef(false);
    const inputRef = useRef(null);
    const onClose = () => {
        setShowDialog(false);
        preventBlurRef.current = false;
        // bring focus back to the input
        setTimeout(() => {
            var _a;
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        });
    };
    const _onBlur = (e) => {
        if (preventBlurRef.current) {
            return;
        }
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Input, Object.assign({}, restProps, { label: allowClear && value ? (_jsxs(Stack, { direction: 'horizontal', align: 'space-between', children: [_jsx(StyledLabel, { children: label }), _jsx(ClearLink, { onClick: () => {
                                onChange === null || onChange === void 0 ? void 0 : onChange('');
                            }, children: "Clear" })] })) : (label), value: value, onChange: onChange, onBlur: _onBlur, icon: _jsx(ClockIcon, { type: 'default', onPointerDown: () => {
                        preventBlurRef.current = true;
                        setShowDialog(true);
                    } }), ref: inputRef })), showDialog && (_jsxs(Dialog, { size: 'xl', open: true, onOk: () => {
                    // save the cronValue and close the dialog
                    onChange === null || onChange === void 0 ? void 0 : onChange(cronValue);
                    onClose();
                }, onClose: () => {
                    // reset the cronValue and close the dialog
                    setCronValue(value);
                    onClose();
                }, onCloseAutoFocus: preventDefault, okButton: { children: 'Save' }, closeButton: { children: 'Cancel' }, children: [_jsx(StyledCronRoot, { children: _jsx(CronGenerator, { value: cronValue, cronType: cronType, onChange: (value) => setCronValue(value), min: min, max: max }) }), _jsx(ReadOnlyValue, { children: cronValue })] }))] }));
}
