import styled from 'styled-components';
import { Icon } from '../Icons';
import { theme } from '../theme';
import { getColorCode } from '../theme/utils';
export const RadialIconContainer = styled.div.attrs((props) => {
    var _a, _b;
    return {
        size: (_a = props.size) !== null && _a !== void 0 ? _a : theme.sizes.x48,
        gradient: (_b = props.gradient) !== null && _b !== void 0 ? _b : true
    };
}) `
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  padding: ${theme.spaces.x12};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ gradient, tone, disabled }) => {
    if (disabled)
        return theme.colors.grey100;
    else if (gradient)
        return `radial-gradient(${getColorCode(tone, 100)} 50%, ${getColorCode(tone, 50)} 50%)`;
    else
        return getColorCode(tone, 100);
}};
  ${Icon} {
    color: ${({ tone, disabled }) => (disabled ? theme.colors.grey300 : getColorCode(tone, 600))};
    font-size: ${({ size, gradient }) => `calc(${size} * ${gradient ? 0.4 : 0.5})`};
  }
`;
