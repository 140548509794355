import { __rest } from "tslib";
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { castArray, isArray, property } from 'lodash-es';
import RCSelect from 'rc-select';
import { useEffect, useRef } from 'react';
import { Ellipsis } from '../Ellipsis';
import { Stack } from '../Layout';
import { Tags } from '../Tags';
import { createCustomSelect } from './BaseSelect';
import { SelectHandle } from './SelectHandle';
import { tokens } from './tokens';
import { SelectSize } from './types';
import { useGetSelectedOptions } from './useSelectedOptions';
const CustomSelect = createCustomSelect(RCSelect);
const propertiesToCheck = ['value', 'label', 'children'];
const findGroupNameInOptions = (value, options, groupName = '', isFound = false, isOptGroup = false) => {
    //Option can be a string as well for Select.Option in case of optGroup
    if (isArray(options)) {
        options === null || options === void 0 ? void 0 : options.some((option) => {
            var _a;
            if (!isFound) {
                const currentOption = isOptGroup ? option.props : option;
                const childOptions = currentOption.children;
                if (currentOption.value === value) {
                    isFound = true;
                }
                else if (typeof childOptions === 'object' || ((_a = currentOption.options) === null || _a === void 0 ? void 0 : _a.length)) {
                    const childGroup = groupName ? groupName + '.' + currentOption.value : currentOption.value;
                    const childOptions = findGroupNameInOptions(value, currentOption.children || currentOption.options, childGroup, isFound, isOptGroup);
                    isFound = childOptions.isFound;
                    if (isFound) {
                        groupName = childOptions.groupName;
                    }
                }
            }
            return isFound;
        });
    }
    return { isFound, groupName };
};
export const renderSelectInput = ({ groupName, value, options, hasOptGroup, variant = 'primary', className }) => {
    const group = groupName
        ? groupName
        : typeof options === 'object'
            ? findGroupNameInOptions(value, options, groupName, false, hasOptGroup).groupName
            : '';
    return (_jsx(SelectHandle, { size: 'm', variant: variant, className: className, children: _jsxs(Ellipsis, { tooltip: true, children: [value, " ", group ? `(${group})` : ''] }) }));
};
export const defaultFilterOption = (inputValue, option) => {
    if (!option)
        return false;
    if (!inputValue)
        return true;
    return propertiesToCheck.some((property) => {
        const value = option[property];
        if (typeof value === 'string' || typeof value === 'number') {
            return value.toString().toLowerCase().includes(inputValue.toLowerCase());
        }
        return false;
    });
};
export function useValidateDropdownWidth(dropdownMatchSelectWidth, virtual) {
    useEffect(() => {
        if (virtual && !dropdownMatchSelectWidth) {
            console.error(`Virtualization is enabled, but dropdownMatchSelectWidth is not set to true. 
        In virtualization as only few options are rendered at a time, this will cause width of dropdown getting change while scroll as different items might have different width.
        `);
        }
    }, [virtual, dropdownMatchSelectWidth]);
}
export const defaultOptionRender = (option, dropdownMatchSelectWidth) => {
    if (typeof option.label === 'string' && dropdownMatchSelectWidth) {
        return _jsx(Ellipsis, { tooltip: true, children: option.label });
    }
    return option.label;
};
export function Select(_a) {
    var { menuItemSelectedIcon, filterOption = defaultFilterOption, value, onChange, onInputKeyDown, tagRender, renderInput, listItemHeight, dropdownMatchSelectWidth = true, virtual = true, mode, allowUnknown = true, showSearch = true, optionRender } = _a, restProps = __rest(_a, ["menuItemSelectedIcon", "filterOption", "value", "onChange", "onInputKeyDown", "tagRender", "renderInput", "listItemHeight", "dropdownMatchSelectWidth", "virtual", "mode", "allowUnknown", "showSearch", "optionRender"]);
    const getSelectedOptions = useGetSelectedOptions(restProps);
    useValidateDropdownWidth(dropdownMatchSelectWidth, virtual);
    const listItemCurrentHeight = listItemHeight || tokens.Menu.size[restProps.size || SelectSize.m].listItemHeight;
    // `allowUnknown: false` will reset the value if selected value doesnt exist in options,
    // useful when select options change dynamically
    useEffect(() => {
        if (allowUnknown)
            return;
        const selectedOptions = getSelectedOptions(value);
        const options = castArray(selectedOptions);
        const validOptions = options.filter(Boolean);
        if (validOptions.length !== options.length) {
            if (Array.isArray(selectedOptions)) {
                onChange === null || onChange === void 0 ? void 0 : onChange(validOptions.map(property('value')), validOptions);
            }
            else if (value) {
                // RC select also sends undefined as option when clearing
                onChange === null || onChange === void 0 ? void 0 : onChange(undefined, selectedOptions);
            }
        }
    }, [value, allowUnknown, getSelectedOptions, onChange]);
    //Putting a logic for duplicate tag added to show the warning if user is typing the value and pressing enter to add a new tag which already exists in values
    let isDuplicateKeyAdded = useRef(false);
    const handleInputKeyDown = (e) => {
        var _a;
        onInputKeyDown === null || onInputKeyDown === void 0 ? void 0 : onInputKeyDown(e);
        const currentValue = (_a = e === null || e === void 0 ? void 0 : e.currentTarget) === null || _a === void 0 ? void 0 : _a.value;
        if ((e.key === 'Enter' || (e.code === 'Enter' && typeof value === 'object')) && isArray(value)) {
            isDuplicateKeyAdded.current = value.some((v) => v === currentValue);
        }
    };
    const handleChange = (v, option) => {
        if (!isDuplicateKeyAdded.current) {
            onChange === null || onChange === void 0 ? void 0 : onChange(v, option);
        }
        else {
            // set value false if user stop typing and choose other option from list then onChange should work
            isDuplicateKeyAdded.current = false;
        }
    };
    const selectProps = Object.assign(Object.assign({ tagRender: tagRender
            ? tagRender
            : ({ label, value, onClose, closable, disabled }) => {
                return (_jsx(Tags, { icon: null, disabled: disabled, closable: closable, onClose: onClose, children: label }, value));
            }, onInputKeyDown: handleInputKeyDown, value,
        virtual,
        dropdownMatchSelectWidth }, restProps), { listItemHeight: listItemCurrentHeight, onChange: handleChange, optionRender: optionRender !== null && optionRender !== void 0 ? optionRender : ((option) => defaultOptionRender(option, dropdownMatchSelectWidth)), menuItemSelectedIcon: menuItemSelectedIcon !== null && menuItemSelectedIcon !== void 0 ? menuItemSelectedIcon : null, filterOption,
        showSearch,
        mode });
    if (renderInput) {
        const children = (restProps.children || []);
        const options = children.length ? children : restProps.options;
        selectProps.getRawInputElement = () => (_jsx(Stack, { className: restProps.className, children: renderInput({
                value: value || restProps.defaultValue,
                variant: restProps.variant,
                options,
                groupName: '',
                hasOptGroup: !!children.length
            }) }));
    }
    return _jsx(CustomSelect, Object.assign({}, selectProps));
}
Select.Option = RCSelect.Option;
Select.OptGroup = RCSelect.OptGroup;
