import React, { useContext } from 'react';

import { BaseLSPClient } from './BaseLSPClient';
import { LSPKeepAliveClient } from './LSPKeepAliveClient';

const LSPContext = React.createContext<BaseLSPClient<unknown> | null>(null);
export function useBaseLSPClient() {
  return useContext(LSPContext) as LSPKeepAliveClient<string, string>;
}
export function BaseLSPProvider<P, N extends string, M extends string>({
  client,
  children
}: {
  client: BaseLSPClient<P, N, M>;
  children: React.ReactNode;
}) {
  return <LSPContext.Provider value={client as BaseLSPClient<unknown>}>{children}</LSPContext.Provider>;
}
