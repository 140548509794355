import { theme } from '../theme';
export const tokens = {
    Empty: {
        size: {
            s: {
                imageSize: '60px'
            },
            m: {
                imageSize: '100px'
            },
            l: {
                imageSize: '150px'
            }
        }
    },
    Description: {
        color: theme.colors.grey500,
        fontSize: theme.fontSizes.x14,
        gap: theme.spaces.x8
    }
};
