import React from 'react';
import styled from 'styled-components';

export function NoMatch() {
  return (
    <div className='bg-white grow'>
      <h3 className='m-10 p-10 text-center text-6xl'>Client side 404 page - Page not found</h3>
    </div>
  );
}

export const PageContainer = styled.div`
  flex: 1 1 0%;
  align-self: stretch;
  display: flex;
  min-width: 0;
`;

export const StyledRoot = styled.div`
  height: 0;
  display: flex;
  flex-grow: 1;
`;
