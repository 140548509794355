import { theme } from '../theme';
export const tokens = {
    Toast: {
        gap: theme.spaces.x8,
        fontSize: theme.fontSizes.x14,
        color: theme.colors.grey900,
        Content: {
            boxShadow: theme.shadows.md
        },
        loading: {
            backgroundColor: theme.colors.white,
            iconColor: theme.colors.primary500
        },
        success: {
            backgroundColor: theme.colors.white,
            iconColor: theme.colors.success500
        },
        info: {
            backgroundColor: theme.colors.white,
            iconColor: theme.colors.grey500
        },
        error: {
            backgroundColor: theme.colors.error50,
            iconColor: theme.colors.error500
        },
        plain: {
            backgroundColor: theme.colors.white
        },
        warning: {
            backgroundColor: theme.colors.warning50,
            iconColor: theme.colors.warning600
        },
        size: {
            height: theme.sizes.x48,
            borderRadius: theme.radius.m,
            padding: theme.spaces.x12
        }
    }
};
