import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { theme } from '../theme';
export const StyledCard = styled.div `
  background: ${({ background }) => background || theme.colors.white};
  padding: ${theme.spaces.x24};
  border-radius: ${theme.radius.l};
  ${({ hasHoverEffect: isHoverEffect }) => isHoverEffect
    ? ''
    : `
  transition: ${theme.transition.s};
  box-shadow: ${theme.shadows.sm};`}
  &:hover:not([disabled]) {
    background: ${({ background }) => background || theme.colors.white};
    box-shadow: ${({ hasHoverEffect: isHoverEffect }) => (isHoverEffect ? theme.shadows.sm : theme.shadows.md)};
  }
`;
export const Card = (_a) => {
    var { hasHoverEffect: isHoverEffect, children, background } = _a, restProps = __rest(_a, ["hasHoverEffect", "children", "background"]);
    return (_jsx(StyledCard, Object.assign({ hasHoverEffect: isHoverEffect, background: background }, restProps, { children: children })));
};
