export const ide = {
  inActivityDisconnect: 'ide.job.inactivity.disconnect',
  errorDisconnect: 'ide.job.error.disconnect',
  netWorkChangeDisconnect: 'ide.job.networkChange.disconnect',
  changeFabric: 'change.job.fabric',
  runJob: 'run.job',
  cancelJob: 'cancel.job',
  addNode: 'add.new.node',
  jobLanding: 'open.job',
  jobConfigDialog: 'change.job.configurations',
  connectCluster: 'connect.job.cluster',
  dagParams: 'change.dag.level.params',
  editUDF: 'edit.functions'
};

export const metadata = {
  createJobLanding: 'metadata.create.job',
  jobCreationFailed: 'job.entity.creation.failed',
  jobCreationSuccess: 'job.entity.creation.successful',
  jobDescriptionUpdate: 'aspects.updated.for.job',
  jobDescriptionUpdateFailed: 'aspects.update.failed.for.job'
};
