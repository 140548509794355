import { DEPLOYMENT_TABS } from './types';

export const DEPLOYMENT_STEPS = [
  {
    key: DEPLOYMENT_TABS.INFRA_SETUP,
    title: 'Infrastructure  setup',
    description: 'Check your Kubernets cluster deployment'
  },
  {
    key: DEPLOYMENT_TABS.DEPLOYMENT_PRE_CHECK,
    title: 'Deployment pre-check',
    description: 'Update Cloudformation or Terraform Templates'
  },
  {
    key: DEPLOYMENT_TABS.DEPLOY,
    title: 'Deployment Prophecy',
    description: 'Gather cluster info and grant access to Prophecy'
  },
  {
    key: DEPLOYMENT_TABS.POST_DEPLOY,
    title: 'Post-deployment check',
    description: 'Gather cluster info and grant access to Prophecy'
  }
];
