import { Markdown, toast } from '@prophecy/ui';
import { Dialog } from '@prophecy/ui/Dialog';
import { FileDiagnostic, RangeDefinition } from '@prophecy/ui/Editor/types';
import { matchesPropertyPath } from '@prophecy/utils/data';
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { useEffect, useState } from 'react';

import { ABOVE_ALL_CLS, SIDEBAR_ALLOWED_OVERLAY_CLS } from '../common/constants';
import { IDEEntity } from '../common/types/Entity';
import { EntityEvents } from '../common/user-analytics';
import { TrackEventType } from '../context/mixpanel/types';
import { LogCategory } from '../generic-components/Logger/LogCategory';
import { DiagnosticLogMetadata, Log } from '../generic-components/Logger/types';
import { useBaseLSPClient } from './base/BaseLSPContext';
import { LSP } from './base/types';
import {
  LSP_SEVERITY_KEYS,
  LSP_TO_MONACO_SEVERITY,
  LSP_Severity_To_LogType,
  MonacoSeverityToLogType
} from './constants';
import { DiagnosticType } from './types';
import type { Diagnostic, Range } from './types';

const lspToMonacoSeverity = (severity: LSP_SEVERITY_KEYS) => LSP_TO_MONACO_SEVERITY[severity];

export function lspToMonacoRange(range: Range): RangeDefinition {
  return {
    from: {
      line: range.start.line + 1,
      column: range.start.character + 1
    },
    to: { line: range.end.line + 1, column: range.end.character + 1 }
  };
}
function monacoRangeToLogRange(range: RangeDefinition): Range {
  return {
    start: {
      line: range.from.line,
      character: range.from.column
    },
    end: { line: range.to.line, character: range.to.column }
  };
}

export function lspToMonacoDiagnostics<P extends Pick<Diagnostic, 'range' | 'severity'>>(diagnostics: P[]) {
  return diagnostics.map(lspToMonacoDiagnostic);
}
export function lspToMonacoDiagnostic<P extends Pick<Diagnostic, 'range' | 'severity'>>({
  severity,
  range,
  ...rest
}: P) {
  return {
    ...rest,
    severity: lspToMonacoSeverity(severity),
    range: lspToMonacoRange(range)
  };
}

export function useDiagnosticsForPath(
  propertyPath: string[],
  ignorePaths: string[] = [],
  getLocalProperty?: (propertyPath: string) => string
) {
  const [diagnostics, setDiagnostics] = useState<Diagnostic[]>([]);
  const lspClient = useBaseLSPClient();
  const propertyPaths = propertyPath;

  const handleDiagnostics = usePersistentCallback((params: { diagnostics: Diagnostic[] }[]) => {
    const errors: Diagnostic[] = [];
    const diagnostics = pickRecentDiagnostics(params);

    diagnostics.forEach((diagnostic) => {
      if (diagnostic.property) {
        if (ignorePaths.some((path) => matchesPropertyPath(diagnostic.property, path))) {
          return;
        }
        if (propertyPaths.some((path) => matchesPropertyPath(diagnostic.property, path))) {
          let property = diagnostic.property;
          // for components we follow localized property
          if (getLocalProperty) {
            property = getLocalProperty(property);
          }
          errors.push({ ...diagnostic, property });
        }
      }
    });
    setDiagnostics(errors);
  });

  // diagnostics should be updated if propertyPath changes
  const propertyPathsDepKey = propertyPaths.join();
  useEffect(() => {
    //LspClient will be undefined in case of metadata expectations layout over rest api call for diagnostics
    return lspClient?.listen(LSP.Notification.propertiesPublishDiagnostics, {
      options: { latest: false },
      callback: function ({ params }) {
        handleDiagnostics(params);
      }
    });
  }, [handleDiagnostics, lspClient, propertyPathsDepKey]);

  return diagnostics;
}

export function useDiagnosticsLogs() {
  const diagnostics = useDiagnosticsForPath(['']);
  return propertyDiagnosticsToLog(diagnostics);
}

export function sortFixableLogs(logs: Log[]) {
  return logs.sort((a, b) => {
    const asIsFixable = Boolean((a.metadata as DiagnosticLogMetadata)?.diagnostic?.diagnosticFix);
    const bsIsFixable = Boolean((b.metadata as DiagnosticLogMetadata)?.diagnostic?.diagnosticFix);

    if (asIsFixable && !bsIsFixable) {
      return -1;
    }
    if (!asIsFixable && bsIsFixable) {
      return 1;
    }
    return 0;
  });
}

function pickRecentDiagnostics(params: { diagnostics: Diagnostic[] }[]): Diagnostic[] {
  // pick the most recent propertiesPublishDiagnostics notification
  const diagnostics = params[params.length - 1]?.diagnostics as Diagnostic[];
  return diagnostics || [];
}

export function propertyDiagnosticsToLog(diagnostics: Diagnostic[]): Log[] {
  return diagnostics.map((d) => {
    const type = LSP_Severity_To_LogType[d.severity];
    const { line, character: column } = d.range.start;
    return {
      category: LogCategory.diagnostics,
      type,
      message: d.diagnosticType === DiagnosticType.Markdown ? <Markdown>{d.message}</Markdown> : d.message,
      multiline: d.diagnosticType === DiagnosticType.Markdown,
      metadata: { property: d.property, line, column, diagnostic: d, isStale: d.metadata?.isStale },
      source: d.source,
      range: d.range
    };
  });
}
export function fileDiagnosticsToLog(filePath: string, diagnostics: FileDiagnostic[]): Log[] {
  return diagnostics.map((d) => {
    const type = MonacoSeverityToLogType[d.severity];
    const { line, column } = d.range.from;
    return {
      category: LogCategory.diagnostics,
      type,
      message: d.message,
      metadata: { filePath, line, column },
      range: monacoRangeToLogRange(d.range)
    };
  });
}

export function ideLSPConnectionAlert(
  reconnectLSPClient: () => void,
  isActorDead: boolean,
  track: TrackEventType,
  entity: IDEEntity,
  networkChanged?: boolean,
  uid?: string
) {
  const dialogOverlayCls = `${SIDEBAR_ALLOWED_OVERLAY_CLS} ${ABOVE_ALL_CLS}`;
  const dialogCls = ABOVE_ALL_CLS;
  const isInactive = document.hidden;

  if (isInactive && !isActorDead) {
    track(EntityEvents[entity].ide.inActivityDisconnect, { uid, entity });
    document.addEventListener(
      'visibilitychange',
      () => {
        toast.info({
          content: 'Prophecy disconnected due to a period of inactivity. Attempting to reconnect...',
          width: `500px`,
          duration: 5000,
          placement: 'top'
        });
        reconnectLSPClient();
      },
      { once: true }
    );

    return;
  }

  const showNetworkMessage = networkChanged && !isActorDead;

  track(
    showNetworkMessage ? EntityEvents[entity].ide.netWorkChangeDisconnect : EntityEvents[entity].ide.errorDisconnect,
    {
      uid,
      entity
    }
  );

  Dialog.alert({
    title: showNetworkMessage ? 'Prophecy got disconnected.' : 'Something went wrong.',
    children: showNetworkMessage ? (
      'Prophecy disconnected due to the network change. As a result, some recent changes may be lost. Please reconnect to continue.'
    ) : (
      <p>
        Prophecy is unable to connect so some changes may be lost.
        <br />
        Please reconnect and reach out to Prophecy Support if the issue persists.
      </p>
    ),
    iconPlacement: 'right',
    className: dialogCls,
    overlayClassName: dialogOverlayCls,
    closable: false,
    okButton: { children: 'Reconnect' },
    onOk: reconnectLSPClient,
    closeButton: null
  });
}
