export enum SentryBreadcrumb {
  Mixpanel = 'MixPanel',
  NetworkConnection = 'NetworkConnection',
  GraphQl = 'GraphQl',
  LSPRequest = 'LSP Request',
  LSPError = 'LSP Error',
  LSPResponse = 'LSP Response',
  LSPNotification = 'LSP Notification',
  ExecutionRequest = 'Execution Request',
  ExecutionNotification = 'Execution Notification'
}
export enum SentryBreadcrumbType {
  wss = 'wss',
  gql = 'gql'
}
