import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
export function LinkComponent(_a) {
    var { to } = _a, restProps = __rest(_a, ["to"]);
    if (restProps.disabled) {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content, no-script-url
        return _jsx("a", Object.assign({ href: 'javascript:void(0)' }, restProps));
    }
    return _jsx(Link, Object.assign({ to: to }, restProps));
}
