import { Button } from '@prophecy/ui/Button';
import { PlusIcon } from '@prophecy/ui/Icons';
import { GhostTextArea } from '@prophecy/ui/Input/GhostTextArea';
import { useLatestState } from '@prophecy/utils/react/hooks';
import { useRef, useState } from 'react';
import styled from 'styled-components';

const AddDescriptionButton = styled(Button)`
  padding: 0px;
`;

const GhostDescription = ({
  value,
  onChange,
  disabled,
  loading,
  onBlur,
  className
}: {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  onBlur?: () => void;
}) => {
  const [showInput, toggle] = useLatestState(Boolean(value));
  const ref = useRef<HTMLTextAreaElement>(null);

  const [inFocus, setFocus] = useState(false);

  function show() {
    toggle(true);
    setTimeout(() => {
      ref.current?.focus();
    }, 10);
  }

  return showInput || inFocus ? (
    <GhostTextArea
      ref={ref}
      value={value}
      onChange={onChange}
      loading={loading}
      className={className}
      onFocus={() => setFocus(true)}
      onBlur={(e) => {
        const value = e.target.value;
        if (!value) {
          toggle(false);
        }
        setFocus(false);
        onBlur?.();
      }}
      disabled={disabled}
    />
  ) : (
    <span>
      <AddDescriptionButton
        onClick={show}
        size='s'
        variant='linkGrey'
        iconPlacement='left'
        disabled={disabled}
        icon={<PlusIcon type='default'></PlusIcon>}>
        Add description
      </AddDescriptionButton>
    </span>
  );
};

export default GhostDescription;
