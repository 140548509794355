export enum TEAM_TABS {
  basic = 'basic',
  executionMetrics = 'executionMetrics'
}

export type BasicFormData = {
  name: string;
  description: string;
  transpilerFlag: boolean;
  entraIdFlag: boolean;
  assumedRoleEnabled: boolean;
};

export type ExecutionMetricsFormData = {
  pipelineMetricsTable?: string;
  componentMetricsTable?: string;
  interimsTable?: string;
  enabled: boolean;
};
