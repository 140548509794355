import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import styled from 'styled-components';
import { Ellipsis } from '../Ellipsis';
import { Stack, StackItem } from '../Layout';
import { theme } from '../theme';
import { renderWithTooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { Text } from '../Typography/Text';
import { RadioGroup } from './RadioGroup';
import { GroupItemCss, StyledCheckMark, StyledCheckPen, StyledContentWrap, StyledRadioLabel } from './styled';
import { tokens } from './tokens';
import { Placement, Size, Tone } from './types';
export const RadioButtonIconContainerClassName = 'radio-icon-container';
export const StyledRadioGroupItem = styled(RadioGroup.Item) `
  ${GroupItemCss}

  ${({ disabled, headingWithBorder, tone, checked }) => disabled || !headingWithBorder
    ? ''
    : `
    &:hover ${StyledContentWrap}{
     border-color: ${checked ? tokens.RadioItem.selected.borderColor : tokens.RadioItem.normal.hover.borderColor};
     background: ${disabled ? tokens.RadioItem.disabled.backgroundColor : tokens.RadioItem.selected.primary.backgroundColor};
     &[data-state='checked'] ${StyledContentWrap}{
      background: ${disabled ? tokens.RadioItem.disabled.backgroundColor : tokens.RadioItem.selected[tone].backgroundColor};
     }
      
    }
  `}
`;
const RadioButtonGroupItem = ({ value, hint, headingWithBorder, tooltip, description, label, icon, width, showCheckmark = false, size = Size.s, activeKey, disabled, tone = Tone.white, checkboxPlacement = Placement.right, variant }) => {
    const checked = activeKey === value;
    const isSmall = size === Size.s;
    const iconContainerUI = icon ? (_jsx(Stack, { padding: `0  ${theme.spaces.x8} 0 0`, className: RadioButtonIconContainerClassName, children: icon })) : null;
    const checkBoxUI = showCheckmark && checked ? (variant === 'secondary' ? (_jsx(StyledCheckPen, { type: 'extended', checkboxPlacement: checkboxPlacement, disabled: disabled, size: size, isButtonGroup: true })) : (_jsx(StyledCheckMark, { type: 'solid', checkboxPlacement: checkboxPlacement, disabled: disabled, size: size, isButtonGroup: true }))) : variant === 'secondary' ? undefined : (_jsx(RadioGroup.Radio, { checked: checked, alignY: icon || description ? 'flex-start' : 'center', disableBorder: true, value: value, checkboxPlacement: checkboxPlacement, tone: tone, showCheckmark: showCheckmark, size: size, disabled: disabled }, value));
    const descriptionUI = description ? (_jsx(Text, { tone: disabled ? tokens.RadioItem.disabled.color : tokens.RadioItem.subTextColor, elementType: 'p', level: isSmall ? 'sm' : 'md', children: description })) : null;
    let itemUI = (_jsxs(StyledRadioGroupItem, { checked: checked, tone: tone, headingWithBorder: headingWithBorder, size: size, value: value, width: width, disabled: disabled, children: [_jsxs(StyledContentWrap, { checked: checked, tone: tone, disabled: disabled, showCheckmark: showCheckmark, checkboxPlacement: checkboxPlacement, direction: 'horizontal', headingWithBorder: headingWithBorder, alignY: icon || description ? 'start' : 'center', children: [checkboxPlacement === Placement.left ? (_jsxs(Stack, { direction: 'horizontal', alignY: 'center', children: [checkBoxUI, iconContainerUI] })) : (iconContainerUI), _jsx(StackItem, { grow: '1', children: _jsxs(Stack, { width: '100%', gap: theme.spaces.x2, direction: 'vertical', alignY: 'start', children: [_jsx(Stack, { width: '100%', direction: 'horizontal', gap: theme.spaces.x6, alignY: 'center', children: _jsx(Ellipsis, { tooltip: true, children: _jsx(StyledRadioLabel, { size: size, disabled: disabled, level: 'sm', children: label }) }) }), headingWithBorder ? null : descriptionUI] }) }), checkboxPlacement === Placement.right && checkBoxUI] }), descriptionUI && headingWithBorder ? (_jsx(Stack, { width: '100%', padding: theme.spaces.x16, children: descriptionUI })) : null] }, value));
    if (tooltip) {
        itemUI = _jsx(_Fragment, { children: renderWithTooltip(_jsx(Stack, { width: '100%', children: itemUI }), tooltip) });
    }
    if (hint) {
        itemUI = (_jsx(LabelWithHint, { hint: hint, children: _jsx(Stack, { width: '100%', gap: theme.spaces.x16, children: itemUI }) }));
    }
    return itemUI;
};
export function RadioButtonGroup(_a) {
    var { options, value, defaultValue, onChange, tone = Tone.white, size = Size.s, showCheckmark = false, disabled, checkboxPlacement = Placement.right, wrap = true, children, headingWithBorder, variant = 'primary' } = _a, restProps = __rest(_a, ["options", "value", "defaultValue", "onChange", "tone", "size", "showCheckmark", "disabled", "checkboxPlacement", "wrap", "children", "headingWithBorder", "variant"]);
    const [selection, setSelection] = useControlledState({
        value,
        defaultValue,
        onChange
    });
    return (_jsx(RadioGroup, Object.assign({ value: selection, gap: theme.spaces.x16, onChange: setSelection, wrap: wrap }, restProps, { children: options && options.length > 0
            ? options.map((option) => {
                return (_jsx(RadioButtonGroupItem, { headingWithBorder: headingWithBorder, hint: option.hint, tooltip: option.tooltip, disabled: option.disabled || disabled, tone: tone, size: size, showCheckmark: showCheckmark, width: restProps.width, label: option.label, value: option.value, activeKey: selection, description: option.description, checkboxPlacement: checkboxPlacement, icon: option.icon, variant: variant }, option.key || option.value));
            })
            : children })));
}
RadioButtonGroup.Item = RadioButtonGroupItem;
