import { Content, List, Root, Trigger } from '@radix-ui/react-tabs';
import styled, { css } from 'styled-components';
import { StackItem, Stack } from '../Layout';
import { theme } from '../theme/index';
import { Box } from '../ViewBox';
import { tokens } from './tokens';
import { TabSize, TabTone } from './types';
export const StyledTabsRoot = styled(Root) `
  height: 100%;
  display: flex;
  flex-direction: ${({ orientation }) => (orientation === 'vertical' ? 'row' : 'column')};
  align-items: ${({ orientation }) => (orientation === 'vertical' ? 'stretch' : 'initial')};
  gap: ${tokens.TabsRoot.gap};
  ${({ bordered }) => bordered
    ? `
    border: 1px solid ${tokens.TabsRoot.borderColor};
    border-radius: ${tokens.TabsRoot.borderRadius};
    padding: ${tokens.TabsRoot.padding};
  `
    : ''}
`;
export const StyledBox = styled(Box) `
  height: 100%;
  position: relative;
`;
export const tabsContainerStyle = css `
  font-family: ${tokens.TabsList.fontFamily};
  font-size: ${tokens.TabsList.fontSize};
  padding: 0 ${tokens.TabsList.padding};
  overflow: auto;
  ${({ tone }) => tone === 'white'
    ? `
      border-radius: ${tokens.TabsList.white.borderRadius};
      background-color: ${tokens.TabsList.white.backgroundColor};
      border: 1px solid ${tokens.TabsList.white.borderColor};
      padding: ${tokens.TabsList.white.padding};
  `
    : ''}

  ${({ orientation }) => orientation === 'vertical' &&
    `
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
  `}

  ${({ stretchTabs }) => stretchTabs &&
    `
    display: flex;
  `}
`;
export const StyledTabsList = styled(List) `
  ${tabsContainerStyle}
`;
export const tabsTriggerStyle = css `
  all: unset;
  height: ${({ size = TabSize.m }) => tokens.TabsTrigger.size[size].height};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${tokens.TabsTrigger.color};
  padding: 0 ${({ size = TabSize.m }) => tokens.TabsTrigger.size[size].padding};
  border-radius: ${tokens.TabsTrigger.borderRadius};
  cursor: pointer;
  transition: ${tokens.TabsTrigger.transition};
  margin: ${({ size = TabSize.m }) => tokens.TabsTrigger.size[size].margin};
  &[aria-selected='true'],
  &.selected {
    background: ${({ tone = TabTone.primary }) => tokens.TabsTrigger[tone].backgroundColor};
    color: ${({ tone = TabTone.primary }) => tokens.TabsTrigger[tone].color};
    box-shadow: ${({ tone = TabTone.primary }) => tokens.TabsTrigger[tone].boxShadow};
  }

  &[disabled] {
    cursor: not-allowed;
    color: ${tokens.TabsTrigger.disabled.color};
    box-shadow: none;
    background: ${tokens.TabsTrigger.disabled.backgroundColor};
    border: none;
    &[aria-selected='true'] {
      background: ${tokens.SwitchTabs.TabsTrigger.active.backgroundColor};
    }
  }

  ${({ orientation }) => orientation === 'vertical' && 'justify-content: flex-start;'}

  ${({ stretchTabs }) => stretchTabs &&
    `
    flex: 1;
  `}
`;
export const StyledTabsTrigger = styled(Trigger) `
  ${tabsTriggerStyle}
  gap: 8px;
`;
export const StyledTabsContentWrap = styled(StackItem) `
  overflow: auto;
  padding: 0 ${theme.outlineWidth};
`;
export const StyledTabsContent = styled(Content) `
  height: 100%;
  padding-top: ${theme.spaces.x12};
`;
export const StyledTabsTriggerGroup = styled.div `
  display: inline-flex;
  align-items: baseline;
  border-radius: ${tokens.TabsTrigger.borderRadius};
  color: ${({ tone }) => tokens.TabsTriggerGroup.TabsTrigger[tone].active.color};

  ${(props) => props.selected &&
    `
    background: ${tokens.TabsTrigger[props.tone].backgroundColor};
    ${StyledTabsTrigger} {
      color: ${tokens.TabsTrigger[props.tone].color};
    }
  `};

  ${StyledTabsTrigger} {
    min-width: 0;
    padding: 0 ${({ size }) => tokens.TabsTrigger.size[size].padding};
    color: ${({ tone }) => tokens.TabsTriggerGroup.TabsTrigger[tone].color};

    &[data-state='active'] {
      background-color: ${({ tone }) => tokens.TabsTriggerGroup.TabsTrigger[tone].active.backgroundColor};
      color: ${({ tone }) => tokens.TabsTriggerGroup.TabsTrigger[tone].active.color};
    }
  }
`;
export const StyledTabListContainer = styled(Stack) `
  flex-shrink: 0;
`;
export const StyledTriggerGroupLabel = styled.label `
  padding-left: ${({ size }) => tokens.TabsTrigger.size[size].padding};
  padding-right: ${({ size }) => tokens.TabsTrigger.size[size].padding};
`;
