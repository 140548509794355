export enum MixPanelContextKeys {
  Project = 'project',
  User = 'user',
  Pipeline = 'pipeline',
  Model = 'model',
  Fabric = 'fabric'
}

export type MixPanelProjectContext = {
  id: string;
  name: string;
  branch: string;
};

export type MixPanelUserContext = {
  id: string;
  email: string;
  type?: string;
};

export type MixPanelPipelineContext = { id: string };
export type MixPanelModelContext = { name: string };
export type MixPanelFabricContext = {
  id: string;
  name: string;
  providerType: string;
  provider: string;
};

export type MixPanelContext = {
  [MixPanelContextKeys.Project]: MixPanelProjectContext;
  [MixPanelContextKeys.User]: MixPanelUserContext;
  [MixPanelContextKeys.Pipeline]: MixPanelPipelineContext;
  [MixPanelContextKeys.Model]: MixPanelModelContext;
  [MixPanelContextKeys.Fabric]: MixPanelFabricContext;
};

export type MixpanelStateType = {
  enabled: boolean;
  isUserIdentified?: boolean;
  isMixPanelLoaded: boolean;
  contexts: Partial<MixPanelContext>;
};

export type TrackEventType = (
  eventName: string,
  props: { [key: string]: unknown },
  event_category?: string
) => Promise<void>;
