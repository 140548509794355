import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isRenderableNode } from '@prophecy/utils/react/nodes';
import { forwardRef, useEffect, useState } from 'react';
import { LoadingDIcon } from '../Icons';
import { StyledLoaderWrap, StyledTip, StyledSpinnerWrap, StyledOverlay } from './styled';
export const Spinner = forwardRef((_a, ref) => {
    var { spinning = true, tip, size, delay, children, overlayClassName } = _a, restProps = __rest(_a, ["spinning", "tip", "size", "delay", "children", "overlayClassName"]);
    const [_spinning, setSpinning] = useState(!!spinning);
    useEffect(() => {
        // for spinning false, updating the state immediately
        if (!spinning || delay === 0) {
            setSpinning(spinning);
            return;
        }
        const timeoutId = setTimeout(() => {
            setSpinning(true);
        }, delay);
        return () => clearTimeout(timeoutId);
    }, [spinning, delay]);
    const spinner = (_jsxs(StyledLoaderWrap, { alignY: 'center', children: [_jsx(LoadingDIcon, { type: 'extended', size: size, spin: true }), isRenderableNode(tip) && _jsx(StyledTip, { children: tip })] }));
    let content = spinner;
    if (children) {
        content = (_jsxs(_Fragment, { children: [children, _spinning && _jsx(StyledOverlay, { className: overlayClassName, children: spinner })] }));
    }
    return (_jsx(StyledSpinnerWrap, Object.assign({}, restProps, { ref: ref, children: content })));
});
