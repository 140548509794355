export const metadata = {
  homeOpen: 'metadata.home.open',
  memberRemoveFromTeam: 'metadata.member.remove'
};

export const home = {
  tabFocusIn: 'user.focus.out',
  tabFocusOut: 'user.focus.in',
  loggedInUserFirstEvent: 'metadata.ui.page.load',
  nonLoggedinUserFirstEvent: 'public.page.loaded'
};
