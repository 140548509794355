import { produceWithoutFreeze } from '@prophecy/utils/data';
import { afterAnimationFrame } from '@prophecy/utils/dom';
import { KeyCodes } from '@prophecy/utils/keyCodes';
import { resolveBindingPath, resolveBindingValue } from 'frontend/core/src/Parser/bindings';
import { last } from 'lodash-es';
import { nanoid } from 'nanoid';
import { Store } from 'redux';

import {
  BaseProcess,
  BaseProcessMetadata,
  BaseState,
  Connection,
  DidChangeEntry,
  GenericGraph,
  Metadata
} from '../../common/types';
import { HistoryContext } from '../../HistoryManager/context';
import { FindOrder, GenericHistoryEntryType, HistoryEntry, HistoryStackEntry } from '../../HistoryManager/types';
import { frameId } from '../../HistoryManager/utils';
import { LSP } from '../base/types';
import { DidActionOptions, DidChangeOptions, DidSaveOptions, LSPHistoryEntryType, LSPMetadata } from './types';

type State = BaseState<
  GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>,
  Metadata
>;

enum KeyActions {
  history = 'history',
  other = 'other'
}

const isBrowserUndoRedoActive = (() => {
  /**
   * We follow two approach for finding if undo redo was active by browser when a didChange came
   *
   * 1st: If undo redo was activated on keydown, and just after that some change came up.
   * For this `latestKeyAction` is used which is true on undo/redo press, but becomes false after the frame
   * This approach is useful to find where didChange happened due to undo, but the element is no longer there,
   * like we replace monaco editor to code block on blur
   *
   * 2nd: If undo redo was activated on keydown, but changed was deferred/debounced,
   * but user is on same input element, without any other change on that input
   * For this lastKeyAction is used which doesn't change until another key is pressed, so deferred/debounce case can be handled
   */

  let lastKeyDownElement: HTMLElement | undefined;
  let latestKeyAction: KeyActions = KeyActions.other;
  let lastKeyAction: KeyActions = KeyActions.other;

  const setKeyAction = (keyAction: KeyActions) => {
    lastKeyAction = keyAction;
    latestKeyAction = keyAction;
  };

  window.addEventListener(
    'keydown',
    (e: KeyboardEvent) => {
      const key = e.key?.toLowerCase();
      const ctrlKeyPressed = e.ctrlKey || e.metaKey;
      const targetElement = e.target as HTMLElement | undefined;
      const tagName = targetElement?.tagName;

      if (!(tagName === 'TEXTAREA' || tagName === 'INPUT')) {
        setKeyAction(KeyActions.other);
        return;
      }

      lastKeyDownElement = targetElement;

      if ((key === KeyCodes.Z || key === KeyCodes.Y) && ctrlKeyPressed) {
        setKeyAction(KeyActions.history);
      } else {
        lastKeyAction = KeyActions.other;
      }
    },
    true
  );

  window.addEventListener('keyup', (e: KeyboardEvent) => {
    /**
     * reset the latestKeyAction after two animation frame
     * Two animation frame is required because during undo we trigger blur on animation frame,
     */
    if (latestKeyAction === KeyActions.history) {
      afterAnimationFrame(() => {
        afterAnimationFrame(() => {
          latestKeyAction = KeyActions.other;
        });
      });
    }
  });

  return () => {
    return (
      latestKeyAction === KeyActions.history ||
      (lastKeyDownElement === document.activeElement && lastKeyAction === KeyActions.history)
    );
  };
})();

const getCurrentInputElementMergeId = () => {
  const currentInputElement = document.activeElement as HTMLElement;
  const tagName = currentInputElement?.tagName;
  if (currentInputElement && (tagName === 'TEXTAREA' || tagName === 'INPUT')) {
    currentInputElement.dataset.historyMergeId = currentInputElement.dataset.historyMergeId || nanoid();
    return `${currentInputElement.dataset.historyMergeId}`;
  }
};

const getParentProperty = (property: string) => {
  if (/\]$/.test(property)) return property.split('[').slice(0, -1).join('[');
  return property.split('.').slice(0, -1).join('.');
};

const getClosestAvailableParent = (originalProperty: string, state: State) => {
  let prevProperty;
  let property = originalProperty;
  let value = resolveBindingValue(state, property, state.root);
  while (value === undefined && prevProperty !== property) {
    prevProperty = property;
    property = getParentProperty(property);
    value = resolveBindingValue(state, property, state.root);
  }

  return { property, value };
};

const getHistoryEntries = (store: Store<State>, payload: DidChangeEntry): [DidChangeEntry, DidChangeEntry] => {
  let prevValue: DidChangeEntry;
  let nextValue: DidChangeEntry;

  let state = store.getState();
  const { property: originalProperty, value } = payload;
  let prevProperty = resolveBindingPath(originalProperty, state.graphPath, state.currentComponentId);
  let prevPropertyValue = undefined;

  // when deleting an entry
  if (value === undefined || value === null) {
    // we always go one level up when deleting, then traverse to closest available parent if necessary
    prevProperty = getParentProperty(prevProperty);
  }

  // when adding an entry traverse to the closest parent that is not undefined in the prevState
  const closestParentPayload = getClosestAvailableParent(prevProperty, state);
  prevProperty = closestParentPayload.property;
  prevPropertyValue = closestParentPayload.value;
  prevValue = { ...payload, property: prevProperty, value: prevPropertyValue };

  let nextPropertyValue;
  store.dispatch({ type: LSP.Method.propertiesDidChange, payload });
  state = store.getState();
  nextPropertyValue = resolveBindingValue(state, prevProperty, state.root);
  nextValue = { ...prevValue, value: nextPropertyValue };

  return [prevValue, nextValue];
};

function mergeDidChangePayload(
  stackEntry: HistoryStackEntry<GenericHistoryEntryType<LSPHistoryEntryType>>,
  newHistoryEntry: HistoryEntry<GenericHistoryEntryType<LSPHistoryEntryType>>
) {
  // as all changes are applied together in a stack entry merge the prev entry with the next one if
  // they have same property paths, so we don't endup sending multiple didChange, but just send one didChange on undo/redo

  const newStackEntry = produceWithoutFreeze(stackEntry, (draft) => {
    const lastEntry = last(draft.entries) as HistoryEntry<LSPHistoryEntryType>;

    // to merge keep the prevValue as the first most entry, and keep the nextValue from incoming entry
    const { property, value } = (newHistoryEntry as HistoryEntry<LSPHistoryEntryType>).nextValue[0];
    // note that on didChange, we store single payload is history entry, so its safe to pick the first element
    if (lastEntry && lastEntry.nextValue?.[0]?.property === property) {
      lastEntry.nextValue[0].value = value;
    } else {
      draft.entries.push(newHistoryEntry);
    }
  });

  return newStackEntry;
}

export const historyDidChange = (
  history: HistoryContext<LSPHistoryEntryType>,
  store: Store<
    BaseState<GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>, Metadata>
  >,
  payload: DidChangeEntry[],
  options: DidChangeOptions = {}
) => {
  if (options.skipHistory) return;

  const browserUndoRedoActive = isBrowserUndoRedoActive();

  // do not capture didChange coming from the browser's undo redo action
  if (browserUndoRedoActive) {
    return;
  }

  if (options.shouldSaveRoot) {
    handleRootHistory(history, store, options);
    return;
  }

  /**
   * if merge id is not coming from top, merge all the changes in one batch.
   * if a didChange is triggered by change of input or text area and historyMergeId is not coming from top,
   * use the element's merge id
   * We do this as browser maintains undo redo history of its own, so we try to merge all
   * changes from one focus session, so our history management doesn't clash with browsers history
   * management
   */

  const historyMergeId = options.historyMergeId || getCurrentInputElementMergeId() || nanoid();

  payload.forEach((change) => {
    const [prevValue, nextValue] = getHistoryEntries(store, change);
    const historyEntry: HistoryEntry<LSPHistoryEntryType> = {
      prevValue: [prevValue],
      nextValue: [nextValue],
      metadata: {
        type: LSP.Method.propertiesDidChange,
        requestId: change.requestId,
        handleHistoryDidUpdate: change.handleHistoryDidUpdate
      }
    };

    history.mergeOrTrackChange(historyEntry, historyMergeId, mergeDidChangePayload);
  });

  return true;
};

export const handleActionTypeMethodHistory = (
  history: HistoryContext<LSPHistoryEntryType>,
  store: Store<
    BaseState<GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>, Metadata>
  >,
  options: DidActionOptions = {},
  requestId: string
) => {
  if (options.shouldReset) {
    history.reset(options.shouldReset);
    return;
  }

  if (options.shouldSaveRoot) {
    handleRootHistory(history, store, options);
    return;
  }

  if (options.skipHistory) return;

  history.mergeOrTrackChange<LSPHistoryEntryType>(
    {
      prevValue: [],
      nextValue: [],
      metadata: { type: LSP.Method.propertiesDidAction, requestId: requestId, handleHistoryDidUpdate: true }
    },
    options.historyMergeId
  );
};

export const handleRootHistory = (
  history: HistoryContext<LSPHistoryEntryType>,
  store: Store<
    BaseState<GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>, Metadata>
  >,
  options: DidActionOptions = {}
) => {
  if (options.skipHistory) return;

  if (options.shouldReset) {
    history.reset(options.shouldReset);
    return;
  }

  let state = store.getState();
  const property = state.root as string;
  const prevValues = [{ property, value: resolveBindingValue(state, property, property) }];
  const nextValues = [{ property, value: undefined }];

  // fill the redo value when doing an undo
  // reason: we are not sure exactly when and how many didUpdate we get from the method
  history.mergeOrTrackChange<LSPHistoryEntryType>(
    {
      prevValue: prevValues,
      nextValue: nextValues,
      metadata: { type: LSP.Method.propertiesDidChange, fillNextChangeOnUndo: true }
    },
    options.historyMergeId
  );
};

export const historyDidSave = (history: HistoryContext<LSPHistoryEntryType>, options: DidSaveOptions = {}) => {
  if (options.skipHistory) return;

  // to support undo for save we need to track the first lsp method happened after the last didSave or manual save point
  const lastDidSaveStackMeta = history.find(({ historyEntry }) => {
    return (
      historyEntry.metadata.type === LSP.Method.propertiesDidSave ||
      (historyEntry.metadata as LSPMetadata).savePointForUndo === true
    );
  });

  const saveStackIndex = lastDidSaveStackMeta ? lastDidSaveStackMeta.params.stackEntryIndex : -1;

  history.findAndMerge(({ stackEntryIndex, stackEntry, historyEntry }) => {
    const historyType = historyEntry.metadata.type;

    // Ignore any stack entry before the last save
    if (stackEntryIndex <= saveStackIndex) return;

    // if any history entry has savePropertyOnUndo property we don't need to add manual save point
    if ((historyEntry.metadata as LSPMetadata).savePropertyOnUndo === true) return stackEntry;

    // if we find any lsp based entry on history entry, add didSave logic on that history entry
    if (historyType === LSP.Method.propertiesDidChange || historyType === LSP.Method.propertiesDidAction) {
      historyEntry.metadata.savePropertyOnUndo = true;
      return stackEntry;
    }
  }, FindOrder.bottom);

  // add new history entry for save
  history.mergeOrTrackChange(
    {
      prevValue: [],
      nextValue: [],
      metadata: { type: LSP.Method.propertiesDidSave }
    },
    options.historyMergeId || frameId()
  );
};
