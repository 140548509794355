export var Size;
(function (Size) {
    Size["s"] = "s";
    Size["m"] = "m";
})(Size || (Size = {}));
export var Tone;
(function (Tone) {
    Tone["primary"] = "primary";
    Tone["white"] = "white";
})(Tone || (Tone = {}));
export var Placement;
(function (Placement) {
    Placement["left"] = "left";
    Placement["right"] = "right";
})(Placement || (Placement = {}));
