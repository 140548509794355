import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useOnMount, usePersistentCallback } from '@prophecy/utils/react/hooks';
import { pascalCase } from '@prophecy/utils/string';
import { parseStringDayOfWeek } from '@vangware/cron';
import styled from 'styled-components';
import { Checkbox } from '../Checkbox';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { Text } from '../Typography/Text';
import { TimePicker } from './TimePicker';
import { tokens } from './tokens';
import { CronType } from './types';
import { DaysHalf, getArrayFromRange, getCronDefaults, getRangeFromValues, getValuesFromCronPart, useTimeValues } from './utils';
const RowLabel = styled(Text) `
  width: 70px;
`;
export function WeekTab({ cronType, cronValue, setCronValue }) {
    const daysOfWeek = getValuesFromCronPart(cronValue.dayOfWeek, DaysHalf);
    const { hours, minutes, handleTimeChange } = useTimeValues(cronValue, setCronValue, ['dayOfWeek', 'dayOfMonth'], cronType);
    const cronDefaults = getCronDefaults(cronType);
    const handleWeekDaySelection = usePersistentCallback((day, checked) => {
        var _a;
        const selectedDaysOfWeek = [...daysOfWeek];
        if (checked) {
            selectedDaysOfWeek.push(day);
        }
        else {
            selectedDaysOfWeek.splice(selectedDaysOfWeek.indexOf(day), 1);
        }
        const rangeString = getRangeFromValues(selectedDaysOfWeek, getArrayFromRange(1, 7));
        const cronWeekDays = (_a = parseStringDayOfWeek(rangeString)) !== null && _a !== void 0 ? _a : '*';
        setCronValue(Object.assign(Object.assign({}, cronDefaults), { hours: hours, minutes: minutes, dayOfWeek: cronWeekDays, dayOfMonth: cronType === CronType.quartz ? '?' : '*' }));
    });
    useOnMount(() => {
        if (!daysOfWeek.length) {
            handleWeekDaySelection(1, true);
        }
    });
    return (_jsxs(Stack, { gap: tokens.CronGenerator.Fields.gap, children: [_jsxs(Stack, { direction: 'horizontal', height: theme.sizes.x40, alignY: 'center', gap: tokens.CronGenerator.Fields.gap, children: [_jsx(RowLabel, { level: 'sm', children: "Repeat on" }), DaysHalf.map((day, index) => (_jsx(Checkbox, { checked: daysOfWeek.includes(index + 1), onChange: (checked) => handleWeekDaySelection(index + 1, checked), children: pascalCase(day) }, day)))] }), _jsxs(Stack, { direction: 'horizontal', alignY: 'center', gap: tokens.CronGenerator.Fields.gap, children: [_jsx(RowLabel, { level: 'sm', children: "Repeat at" }), _jsx(TimePicker, { value: [hours, minutes], onChange: handleTimeChange })] })] }));
}
