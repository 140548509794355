import { Root, Track, Range, Thumb } from '@radix-ui/react-slider';
import styled from 'styled-components';
import { Input } from '../Input';
import { StyledInputContainer } from '../Input/styled';
import { theme } from '../theme';
import { tokens } from './tokens';
export const StyledRoot = styled(Root) `
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: ${theme.sizes.x24};
  margin-top: ${theme.spaces.x32};
`;
export const StyledTrack = styled(Track) `
  padding: 0 ${theme.sizes.x10};
  flex-grow: 1;
  height: ${theme.sizes.x4};
`;
export const TrackContent = styled.div `
  background-color: ${tokens.Track.backgroundColor};
  position: relative;
  width: 100%;
  border-radius: ${theme.radius.xxl};
  height: 100%;
`;
export const StyledRange = styled(Range) `
  position: absolute;
  background-color: ${tokens.Range.backgroundColor};
  border-radius: ${theme.radius.xxl};
  height: 100%;
`;
export const StyledThumb = styled(Thumb) `
  display: block;
  width: ${theme.sizes.x20};
  height: ${theme.sizes.x20};
  background-color: ${tokens.Thumb.backgroundColor};
  box-shadow: ${theme.shadows.sm};
  border-radius: 50%;
  cursor: pointer;
  position: relative;
`;
export const StyledThumbInput = styled(Input) `
  position: abolsute;
  top: -${theme.spaces.x32};
  left: 50%;

  width: ${theme.sizes.x32};
  transform: translateX(-50%);

  ${StyledInputContainer} {
    height: ${theme.sizes.x24};
    padding: 0 ${theme.spaces.x6};
  }

  &::after {
    content: ' ';
    position: absolute;
    height: ${theme.sizes.x6};
    width: 0px;
    top: 25px;
    left: 50%;
    border-left: 1px solid ${theme.colors.grey300};
  }
`;
