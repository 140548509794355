import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled, { createGlobalStyle } from 'styled-components';
import { Badges } from '../Badges';
import { Checkbox } from '../Checkbox/Checkbox';
import { CaretDownIcon } from '../Icons';
import { Stack } from '../Layout/Stack';
import { theme } from '../theme';
import { renderWithTooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { Select } from './Select';
import { SelectHandle } from './SelectHandle';
import { SELECT_ITEM_OPTION_ACTIVE_CLASS, SELECT_ITEM_OPTION_SELECTED_CLASS, SELECT_MENU_ITEM_ACTIVE_CLASS } from './tokens';
const StyledSelectHandle = styled(SelectHandle) `
  width: 100%;
`;
const StyledSelect = styled(Select) `
  min-width: 153px;
`;
const OptionRoot = styled(Stack) `
  min-width: 153px;
`;
const MultiSelectWithCheckboxDropdownCls = 'MultiSelectWithCheckboxDropdownCls';
export const MultiSelectWithCheckboxDropdownGlobalStyles = createGlobalStyle `
  .${MultiSelectWithCheckboxDropdownCls} {
    
    .${SELECT_MENU_ITEM_ACTIVE_CLASS},
    .${SELECT_ITEM_OPTION_ACTIVE_CLASS} {
      background: none;
    }

    .${SELECT_ITEM_OPTION_SELECTED_CLASS} {
      background: none;
    }
  }
`;
const StyledBadge = styled(Badges) `
  height: 24px;
`;
export function MuliSelect(_a) {
    var { value, options, label, onChange, renderOptionLabel = (value) => value, renderOption = (item) => (_jsx(Select.Option, { value: item, children: _jsx(OptionRoot, { direction: 'horizontal', gap: theme.spaces.x6, alignY: 'center', padding: `${theme.spaces.x4} 0`, children: _jsx(Checkbox, { checked: value.includes(item), children: renderOptionLabel(item) }) }) })), hint, tooltip } = _a, rest = __rest(_a, ["value", "options", "label", "onChange", "renderOptionLabel", "renderOption", "hint", "tooltip"]);
    let inputUI = (_jsxs(Stack, { grow: '1', padding: `0 ${theme.spaces.x12} 0 0`, direction: 'horizontal', gap: theme.spaces.x8, align: 'space-between', alignY: 'center', width: 'auto', children: [_jsx("span", { children: label }), _jsxs(Stack, { direction: 'horizontal', gap: theme.spaces.x8, alignY: 'center', children: [value.length ? _jsxs(StyledBadge, { tone: 'primary', children: [value.length, "x"] }) : null, hint ? _jsx(LabelWithHint, { hint: hint, children: null }) : null, _jsx(CaretDownIcon, { type: 'extended' })] })] }));
    if (tooltip) {
        inputUI = _jsx(_Fragment, { children: renderWithTooltip(inputUI, tooltip) });
    }
    return (_jsx(StyledSelect, Object.assign({ dropdownClassName: MultiSelectWithCheckboxDropdownCls, getRawInputElement: () => (_jsx(StyledSelectHandle, { size: 'm', showDownIcon: false, children: inputUI })), mode: 'multiple', value: value, onChange: onChange }, rest, { children: options.map(renderOption) })));
}
