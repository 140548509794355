import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { isAbsoluteURL, LinkTarget } from '@prophecy/utils/url';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
const linkTarget = (href) => {
    return isAbsoluteURL(href) ? LinkTarget._blank : LinkTarget._self;
};
export default function Markdown(_a) {
    var { children: markdownContent, className, remarkPlugins = [] } = _a, restProps = __rest(_a, ["children", "className", "remarkPlugins"]);
    return (_jsx(ReactMarkdown, Object.assign({}, restProps, { className: `ui-typography ${className || ''}`, remarkPlugins: [remarkGfm, ...remarkPlugins], linkTarget: restProps.linkTarget || linkTarget, children: markdownContent.replace(/\n/g, '  \n') })));
}
