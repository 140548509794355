import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { preventAutoCloseTriggerFocus } from '@prophecy/utils/dom';
import { useFocusTrap } from '@prophecy/utils/react/focus';
import { useControlledState } from '@prophecy/utils/react/hooks';
import utilify from '@prophecy/utils/react/utilify';
import { Close as RadixClose, Overlay, Portal, Root, Trigger } from '@radix-ui/react-dialog';
import { noop } from 'lodash-es';
import React, { createContext, useContext, useState } from 'react';
import { XCloseIcon } from '../Icons';
import { Stack, StackItem } from '../Layout';
import { Resizable } from '../Resizable';
import { PanelContainer, PanelOverflowContainer, StyledCloseButton, StyledContent, StyledContentPanel, StyledDialog, StyledFooter, StyledOverlay } from './styled';
import { SlidingPanelSize } from './types';
const DialogContent = React.forwardRef((_a, forwardedRef) => {
    var { size, disableContentPadding = false, footer, children, open, contentPanel, showContentPanel, closable, onClosePanel = noop } = _a, props = __rest(_a, ["size", "disableContentPadding", "footer", "children", "open", "contentPanel", "showContentPanel", "closable", "onClosePanel"]);
    const container = useFocusTrap(open);
    const minWidth = 380;
    const maxWidth = 800;
    const [width, setWidth] = useState(minWidth);
    return (_jsx(Portal, { children: _jsxs(Overlay, { children: [_jsx(StyledOverlay, { "data-state": open ? 'open' : 'closed' }), _jsxs(StyledDialog, Object.assign({}, props, { size: size, ref: forwardedRef, "data-state": open ? 'open' : 'closed', onCloseAutoFocus: preventAutoCloseTriggerFocus, children: [_jsx(PanelOverflowContainer, { children: _jsxs(PanelContainer, { direction: 'horizontal', size: size, children: [_jsx(StackItem, { grow: '1', children: _jsxs(Stack, { direction: 'vertical', height: '100%', width: '100%', align: 'space-between', alignY: 'stretch', children: [_jsx(StyledContent, { isContentPanelOpen: Boolean(showContentPanel), grow: '1', disableContentPadding: disableContentPadding, hasFooter: Boolean(footer), ref: container, tabIndex: -1, children: children }), footer && (_jsx(StyledFooter, { isContentPanelOpen: Boolean(showContentPanel), size: size, children: footer }))] }) }), showContentPanel && (_jsx(Resizable, { direction: 'left', onResizeStop: (e, direction, ref, d) => {
                                            const newWidth = width + d.width;
                                            setWidth(newWidth < minWidth ? minWidth : newWidth > maxWidth ? maxWidth : newWidth);
                                        }, size: {
                                            height: '100%',
                                            width: width
                                        }, children: _jsx(StyledContentPanel, { width: '100%', height: '100%', children: contentPanel }) }))] }) }), closable && (_jsx(Close, { asChild: true, children: _jsx(StyledCloseButton, { variant: 'secondaryGrey', shape: 'circle', size: 'm', icon: _jsx(XCloseIcon, { type: 'default' }) }) }))] }))] }) }));
});
const Close = RadixClose;
export const SlidingPanel = (_a) => {
    var { size = SlidingPanelSize.l, closable = true, children, trigger, footer, closeIcon = _jsx(XCloseIcon, { type: 'default' }), open, disableContentPadding = false, onClose = noop, style, className, onOpenAutoFocus, contentPanel, showContentPanel, onPanelClose = noop } = _a, restProps = __rest(_a, ["size", "closable", "children", "trigger", "footer", "closeIcon", "open", "disableContentPadding", "onClose", "style", "className", "onOpenAutoFocus", "contentPanel", "showContentPanel", "onPanelClose"]);
    const [controlledState, setControlledState] = useControlledState({
        value: open,
        onChange: (value) => {
            if (!value) {
                onClose();
            }
        }
    });
    const [panelControlledState, setPanelControlledState] = useControlledState({
        value: showContentPanel,
        onChange: (value) => {
            if (!value) {
                onPanelClose();
            }
        }
    });
    const onOpenChange = (open) => {
        setControlledState(open);
    };
    const onClosePanel = () => {
        setPanelControlledState(false);
    };
    return (_jsxs(Root, Object.assign({}, restProps, { open: controlledState, onOpenChange: onOpenChange, children: [trigger && _jsx(Trigger, { asChild: true, children: trigger }), _jsx(DialogContent, { onOpenAutoFocus: onOpenAutoFocus, size: size, footer: footer, style: style, disableContentPadding: disableContentPadding, contentPanel: contentPanel, showContentPanel: panelControlledState, className: className, onClosePanel: onClosePanel, closable: closable, open: Boolean(controlledState), children: children })] })));
};
SlidingPanel.Close = Close;
const SlidingPanelContext = createContext({ context: {}, setContext: noop });
export const useSlidingPanelContext = () => useContext(SlidingPanelContext);
export const SlidingPanelProvider = ({ children }) => {
    const [context, setContext] = useState({});
    return _jsx(SlidingPanelContext.Provider, { value: { context, setContext }, children: children });
};
export const slider = utilify(({ props, render, unmount }) => {
    let currentProps = Object.assign(Object.assign({}, props), { open: true });
    function renderComponent(_props) {
        render(_jsx(SlidingPanelProvider, { children: _jsx(SlidingPanel, Object.assign({}, _props, { onClose: () => {
                    var _a;
                    unmount();
                    (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props);
                } })) }));
    }
    renderComponent(currentProps);
    return {
        close: () => renderComponent(Object.assign(Object.assign({}, currentProps), { open: false }))
    };
});
