import { Callable } from '../utils/types';

export enum HistoryType {
  callback = 'callback'
}

export type BaseHistoryEntryType = {
  metadata: { type: string; [key: string]: unknown };
  value: unknown;
};

export type CallbackEntryType = {
  metadata: { type: HistoryType.callback };
  value: Callable | Array<Callable | undefined> | undefined;
};

export type HistoryEntryTypes = CallbackEntryType;

export type HistoryEntry<T extends BaseHistoryEntryType> = {
  metadata: T['metadata'];
  nextValue: T['value'];
  prevValue: T['value'];
};

export type OnActionHandler<
  T extends BaseHistoryEntryType,
  S extends GenericHistoryEntryType<T> = GenericHistoryEntryType<T>
> = (value: S['value'], item: HistoryEntry<S>) => unknown;

export type HistoryStackEntry<T extends BaseHistoryEntryType> = {
  mergeId: string;
  entries: Array<HistoryEntry<T>>;
};

export type GenericHistoryEntryType<T extends BaseHistoryEntryType = BaseHistoryEntryType> = CallbackEntryType | T;

export type GenericHistoryStackEntry<T extends BaseHistoryEntryType> = HistoryStackEntry<GenericHistoryEntryType<T>>;

export type FindParams<T extends BaseHistoryEntryType> = {
  stackEntry: GenericHistoryStackEntry<T>;
  historyEntry: HistoryEntry<GenericHistoryEntryType<T>>;
  stackEntryIndex: number;
  historyEntryIndex: number;
};

export enum FindOrder {
  top = 'top',
  bottom = 'bottom'
}
