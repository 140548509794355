import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useScrollableParent } from '@prophecy/utils/hook';
import { useControlledState } from '@prophecy/utils/react/hooks';
import RCCascader from 'rc-cascader';
import RCSelect from 'rc-select';
import { useEffect, useRef } from 'react';
import { CaretDownIcon, XCircleIcon } from '../Icons';
import { StyledLabel, StyledHelpText } from '../Input/styled';
import { Centered } from '../Layout';
import { Spinner } from '../Spinner';
import { renderWithTooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { useBoxDimension } from '../ViewBox';
import { StyledSelectRoot, StyledSelectWrapper, StyledDropdownContent, StyledDropdown, StyledIconWrap, StyledRightContent, StyledSelectArrow, StyledSelectFooterWrapper, VirtualScrollCss } from './styled';
import { SELECT_ROOT_CLASS, tokens } from './tokens';
import { SelectVariants, SelectSize } from './types';
export function createCustomSelect(Component) {
    return function Select(_a) {
        var { label, helpText, notFoundContent, hint, variant = SelectVariants.primary, size = SelectSize.m, footer, icon, optionCTA, open, tooltip, defaultOpen, showDownIcon = true, onDropdownVisibleChange, style, className, loading, dropdownMatchSelectWidth, value: _value, defaultValue, onChange: _onChange, dropdownRender: _dropdownRender } = _a, restProps = __rest(_a, ["label", "helpText", "notFoundContent", "hint", "variant", "size", "footer", "icon", "optionCTA", "open", "tooltip", "defaultOpen", "showDownIcon", "onDropdownVisibleChange", "style", "className", "loading", "dropdownMatchSelectWidth", "value", "defaultValue", "onChange", "dropdownRender"]);
        const dropdownContent = useRef(null);
        const selectRoot = useRef(null);
        const [value, onChange] = useControlledState({
            value: _value,
            onChange: (value, ...args) => _onChange === null || _onChange === void 0 ? void 0 : _onChange(value, args[0]),
            defaultValue
        });
        const selectRootDim = useBoxDimension(() => (dropdownMatchSelectWidth === true ? selectRoot.current : undefined));
        // change icon and notFound content if loading
        if (loading) {
            icon = _jsx(Spinner, {});
            notFoundContent = _jsx(Spinner, {});
        }
        const [_open, toggle] = useControlledState({
            value: open,
            defaultValue: defaultOpen,
            onChange: onDropdownVisibleChange
        });
        useEffect(() => {
            var _a, _b, _c;
            if (!_open) {
                (_c = (_b = (_a = selectRoot === null || selectRoot === void 0 ? void 0 : selectRoot.current) === null || _a === void 0 ? void 0 : _a.querySelector('input')) === null || _b === void 0 ? void 0 : _b.blur) === null || _c === void 0 ? void 0 : _c.call(_b);
            }
        }, [_open]);
        useScrollableParent({ rootNode: selectRoot === null || selectRoot === void 0 ? void 0 : selectRoot.current, visible: _open, onParentScroll: () => toggle(!_open) });
        const dropdownRender = (menu) => {
            if (!_open) {
                return _jsx(_Fragment, {});
            }
            let dropdownContentWidth;
            if (typeof dropdownMatchSelectWidth === 'number') {
                dropdownContentWidth = dropdownMatchSelectWidth;
            }
            else if (dropdownMatchSelectWidth) {
                dropdownContentWidth = selectRootDim === null || selectRootDim === void 0 ? void 0 : selectRootDim.width;
            }
            return (_jsxs(StyledDropdownContent, { ref: dropdownContent, width: dropdownContentWidth, selectSize: size, children: [_dropdownRender ? _dropdownRender(menu) : menu, footer && _jsx(StyledSelectFooterWrapper, { children: footer })] }));
        };
        const handleRightContent = (_jsxs(StyledRightContent, { direction: 'horizontal', alignY: 'center', gap: tokens.Trigger.sizes[size].gap, onClick: () => toggle(!_open), children: [value ? optionCTA : null, _jsx(StyledSelectArrow, { children: _jsx(CaretDownIcon, { type: 'extended' }) })] }));
        let labelUI = label ? _jsx(StyledLabel, { children: label }) : null;
        if (label && hint) {
            labelUI = _jsx(LabelWithHint, { hint: hint, children: labelUI });
        }
        let baseComponent;
        const commonProps = {
            prefixCls: SELECT_ROOT_CLASS,
            suffixIcon: handleRightContent,
            clearIcon: _jsx(XCircleIcon, { type: 'solid' }),
            open: _open,
            onDropdownVisibleChange: toggle,
            dropdownRender,
            notFoundContent: notFoundContent ? _jsx(Centered, { children: notFoundContent }) : null
        };
        if (Component === RCSelect) {
            baseComponent = _jsx(RCSelect, Object.assign({}, commonProps, restProps, { value: value, onChange: onChange }));
        }
        else {
            baseComponent = (_jsx(RCCascader, Object.assign({}, commonProps, restProps, { value: value, onChange: onChange })));
        }
        let selectUI = (_jsxs(StyledSelectWrapper, { size: size, variant: variant, hasIcon: Boolean(icon), children: [icon && (_jsx(StyledIconWrap, { size: size, absolute: true, children: icon })), baseComponent, _jsx(StyledDropdown, {})] }));
        if (tooltip) {
            selectUI = _jsx(_Fragment, { children: renderWithTooltip(selectUI, tooltip) });
        }
        if (hint && !label) {
            selectUI = _jsx(LabelWithHint, { hint: hint, children: selectUI });
        }
        return (_jsxs(StyledSelectRoot, { gap: tokens.Root.gap, ref: selectRoot, style: style, className: className, children: [_jsx(VirtualScrollCss, {}), labelUI, selectUI, helpText && (_jsx(StyledHelpText, { variant: variant, direction: 'horizontal', align: 'flex-start', children: helpText }))] }));
    };
}
