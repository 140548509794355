import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import React from 'react';
import { Bar } from '../Bar';
import { Stack, StackItem } from '../Layout';
import { CollapsedBar, StyleIconWrap, StyledExpendedItem, StyledExpandedIconWrap } from './styled';
import { tokens } from './tokens';
export function WindowAccordion(_a) {
    var { activeKey, defaultActiveKey, onChange, children } = _a, restProps = __rest(_a, ["activeKey", "defaultActiveKey", "onChange", "children"]);
    const [_activeKey, setActiveKey] = useControlledState({
        value: activeKey,
        defaultValue: defaultActiveKey,
        onChange
    });
    const accordionItems = [];
    React.Children.map(children, (child) => {
        if (!React.isValidElement(child))
            return;
        const { key, props, type } = child;
        const { children } = props, restProps = __rest(props, ["children"]);
        if (type === AccordionItem) {
            accordionItems.push(Object.assign(Object.assign({}, child), { props: Object.assign(Object.assign({}, restProps), { itemKey: key, activeKey: _activeKey, setActiveKey, children }) }));
        }
    });
    // if _activeKey is not set set it to the last item
    if (!_activeKey && accordionItems.length) {
        setActiveKey(accordionItems[accordionItems.length - 1].key);
    }
    return (_jsx(Stack, Object.assign({ gap: tokens.WindowAccordion.gap }, restProps, { height: '100%', width: '100%', direction: 'horizontal', children: accordionItems })));
}
function AccordionItem(_a) {
    var { tone, children, title, icon, itemKey, activeKey, setActiveKey } = _a, restProps = __rest(_a, ["tone", "children", "title", "icon", "itemKey", "activeKey", "setActiveKey"]);
    const isActive = itemKey === activeKey;
    const collapsedTitle = title && typeof title === 'object' && 'collapsed' in title ? title.collapsed : title;
    const expandedTitle = title && typeof title === 'object' && 'expanded' in title ? title.expanded : title;
    if (!isActive) {
        return (_jsxs(Stack, Object.assign({ gap: tokens.WindowAccordion.gap }, restProps, { children: [_jsx(StyleIconWrap, { tone: tone, children: icon }), _jsx(CollapsedBar, { tone: tone, orientation: 'vertical', onClick: () => setActiveKey(itemKey), children: collapsedTitle })] })));
    }
    return (_jsxs(StyledExpendedItem, Object.assign({ gap: tokens.WindowAccordion.gap }, restProps, { children: [_jsx(Bar, { tone: tone, children: _jsxs(Stack, { direction: 'horizontal', gap: tokens.WindowAccordionItem.Header.gap, alignY: 'center', width: '100%', children: [_jsx(StyledExpandedIconWrap, { shrink: '1', tone: tone, children: icon }), _jsx(StackItem, { grow: '1', children: expandedTitle })] }) }), _jsx(StackItem, { grow: '1', children: children })] })));
}
WindowAccordion.Item = AccordionItem;
