import { EdgeData, NodeData } from '@prophecy/ui/Graph/types';
import { useRef } from 'react';
import { useReactFlow } from 'reactflow';

import { UISpecElement } from '../../Parser/types';
import { ReusableSubgraphProperties } from '../../redux/types';
import { spaceToUnderscore } from '../../utils';
import {
  BaseProcess,
  BaseProcessMetadata,
  Connection,
  GenericGraph,
  GenericGraphProcess,
  GenericGraphProcessType
} from '../types';
import { getDialogProcessPath } from '../utils';

export function useReactFlowInstance<N = NodeData, E = EdgeData>() {
  const reactFlowInstance = useReactFlow<N, E>();
  const instanceRef = useRef(reactFlowInstance);
  instanceRef.current = reactFlowInstance;
  return instanceRef;
}
export function isReusableSubgraph<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(process: GenericGraphProcessType<G>) {
  return process.properties ? Boolean((process.properties as ReusableSubgraphProperties).externalId) : false;
}
export function getChangePayloadForLabelUpdate<
  P extends GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(value: string, process: P, graphPath: string) {
  return {
    property: `${getDialogProcessPath(graphPath, process.id)}.metadata`,
    value: {
      ...process.metadata,
      label: value,
      isLabelGrayed: false,
      slug: spaceToUnderscore(value)
    }
  };
}

export function findAtom(
  specs: UISpecElement[],
  finder: (spec: UISpecElement) => boolean | undefined | void,
  checkDeep?: boolean
) {
  const atom = specs.find(finder);
  if (atom) {
    return atom;
  } else {
    let match: UISpecElement | undefined;

    for (const spec of specs) {
      if (spec.contains) {
        match = findAtom(spec.contains, finder, checkDeep);
      } else if (checkDeep && ['Atoms.Table', 'Atoms.CodeTable'].includes(spec.kind)) {
        const columnSpecs = (spec.properties?.columns || [])
          .map((c: { component?: UISpecElement }) => c.component)
          .filter(Boolean);
        match = findAtom(columnSpecs, finder, checkDeep);
      } else if (checkDeep && spec.kind === 'Condition') {
        match = findAtom(
          [].concat(spec.properties?.alternate || [], spec.properties?.consequent || []),
          finder,
          checkDeep
        );
      }
      if (match) break;
    }

    return match;
  }
}
