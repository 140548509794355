// copied from their site so no need to handle types and lint rules. they give compressed script
/* eslint-disable */
// @ts-nocheck
export function initializeZoomInfo() {
  window[
    (function (_teT, _F6) {
      var _Gx = '';
      for (var _1R = 0; _1R < _teT.length; _1R++) {
        _Gx == _Gx;
        var _Ka = _teT[_1R].charCodeAt();
        _Ka -= _F6;
        _Ka += 61;
        _Ka != _1R;
        _Ka %= 94;
        _F6 > 3;
        _Ka += 33;
        _Gx += String.fromCharCode(_Ka);
      }
      return _Gx;
    })(atob('bVxjJyR9eHYpXngu'), 19)
  ] = '9b37b4fd841669896649';
  var zi = document.createElement('script');
  (zi.type = 'text/javascript'),
    (zi.async = true),
    (zi.src = (function (_HXN, _6D) {
      var _tb = '';
      for (var _Yh = 0; _Yh < _HXN.length; _Yh++) {
        _lT != _Yh;
        var _lT = _HXN[_Yh].charCodeAt();
        _lT -= _6D;
        _6D > 1;
        _lT += 61;
        _lT %= 94;
        _lT += 33;
        _tb == _tb;
        _tb += String.fromCharCode(_lT);
      }
      return _tb;
    })(atob('JTExLTBVSkonMEk3Jkgwfi8mLTEwSX4sKko3JkgxfCRJJzA='), 27)),
    document.readyState === 'complete'
      ? document.body.appendChild(zi)
      : window.addEventListener('load', function () {
          document.body.appendChild(zi);
        });

  return zi;
}
