import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { newLineToBr } from '@prophecy/utils/react/nodes';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Icon, EditBIcon, LoadingDIcon } from '../Icons';
import { tokens as iconTokens } from '../Icons/tokens';
import { theme } from '../theme';
import { TextArea } from './TextArea';
import { StyledTextAreaContainer } from './TextArea.styled';
import { tokens } from './tokens';
import { InputVariants } from './types';
const StyledGhostTextArea = styled.div `
  position: relative;

  ${Icon} {
    position: absolute;
    right: 0;
    bottom: ${theme.spaces.x12};
    display: none;
  }

  ${({ loading }) => loading && `${Icon} { display: block; }`}

  &:focus-within,&:hover {
    ${Icon} {
      display: block;
    }
  }
`;
const textAreaStyle = css `
  padding: ${tokens.GhostInput.padding};
  padding-top: 0;
  padding-right: ${iconTokens.Icon.xs.size};
  line-height: ${tokens.GhostDescription.lineHeight};
  font-size: ${tokens.GhostDescription.fontSize};
`;
export const StyledBackgroundText = styled.div `
  ${textAreaStyle}
  visibility: hidden;
  min-height: ${tokens.GhostDescription.minHeight};
  max-height: calc(
    ${({ maxRows }) => `${maxRows} * ${tokens.GhostDescription.lineHeight} + 2 * ${tokens.GhostInput.padding}`}
  );
`;
const StyledTextArea = styled(TextArea) `
  ${StyledTextAreaContainer} {
    position: absolute;
    top: 0%;
    left: 0;
    border-color: transparent;
    box-shadow: none;
    border-radius: 0;
    border-bottom-color: ${tokens.GhostInput.borderColor};
    color: ${tokens.GhostInput.color};
    width: 100%;
    height: 100%;

    &:focus-within {
      color: ${tokens.GhostInput.focus.color};
      border-bottom: 1px solid ${tokens.GhostInput.focus.borderColor};
    }

    textarea {
      ${textAreaStyle}
    }

    textarea:not(:focus) {
      resize: none;
    }

    &[disabled],
    textarea[disabled] {
      cursor: not-allowed;
      background: none;
    }
  }
`;
export const GhostTextArea = forwardRef((_a, ref) => {
    var { variant = InputVariants.primary, loading, className, value, maxRows = 6 } = _a, restProps = __rest(_a, ["variant", "loading", "className", "value", "maxRows"]);
    return (_jsxs(StyledGhostTextArea, { loading: loading, className: className, children: [_jsx(StyledBackgroundText, { maxRows: maxRows, children: newLineToBr(value + '.') }), _jsx(StyledTextArea, Object.assign({ variant: variant, value: value }, restProps, { ref: ref })), loading ? (_jsx(LoadingDIcon, { spin: true, size: 'xs', type: 'extended', color: tokens.GhostInput.loading.iconColor })) : (_jsx(EditBIcon, { type: 'default', size: 'xs', color: tokens.GhostInput.iconColor }))] }));
});
