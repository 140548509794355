import { noop } from 'lodash-es';

import { allOAuthKeys } from './constants';

export const openOauthWindow = (url: string, callback: Function = noop) => {
  let oAuthCallback: ReturnType<typeof window.open> = null;

  const closeWindow = () => {
    window.removeEventListener('message', callbackFunc);
    oAuthCallback?.close();
  };

  const callbackFunc = (event: MessageEvent) => {
    if (event.origin !== window.location.origin) {
      return;
    }

    if (allOAuthKeys.includes(event.data.type)) {
      closeWindow();
      callback(event.data.payload);
    }
  };

  window.addEventListener('message', callbackFunc, false);
  oAuthCallback = window.open(url, '_blank');
  return closeWindow;
};
