import { theme } from '../theme/index';
export const tokens = {
    RadioGroup: {
        gap: theme.spaces.x10,
        padding: theme.spaces.x16,
        borderRadius: theme.radius.m
    },
    RadioItem: {
        color: theme.colors.grey900,
        iconColor: theme.colors.primary400,
        subTextColor: theme.colors.grey600,
        cardShadow: theme.shadows.sm,
        fontWeight: theme.fontWeight.medium,
        normal: {
            backgroundColor: 'transparent',
            borderColor: theme.colors.grey200,
            borderWidth: '1px',
            Icon: {
                borderColor: theme.colors.grey300,
                backgroundColor: theme.colors.white
            },
            hover: {
                borderColor: theme.colors.primary300,
                Icon: {
                    borderColor: theme.colors.primary500,
                    backgroundColor: theme.colors.primary100
                }
            }
        },
        disabled: {
            backgroundColor: theme.colors.grey50,
            cardIconBackground: theme.colors.grey100,
            color: theme.colors.grey500,
            Icon: {
                borderColor: theme.colors.grey300,
                backgroundColor: theme.colors.grey100
            }
        },
        selected: {
            borderWidth: '2px',
            borderColor: theme.colors.primary600,
            Icon: {
                borderColor: theme.colors.primary500,
                backgroundColor: theme.colors.primary100
            },
            primary: {
                backgroundColor: theme.colors.primary25,
                cardIconBackground: theme.colors.primary50
            },
            white: {
                backgroundColor: theme.colors.white,
                cardIconBackground: theme.colors.grey50
            }
        },
        s: {
            size: theme.sizes.x16,
            fontSize: theme.fontSizes.x14,
            radioIconSize: theme.fontSizes.x16
        },
        m: {
            size: theme.sizes.x20,
            fontSize: theme.fontSizes.x16,
            radioIconSize: theme.fontSizes.x20
        },
        borderRadius: '50%',
        marginRight: theme.spaces.x2
    }
};
