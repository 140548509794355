export const MIXPANEL_SESSION = 'prophecy.ui.session';

export const setCookie = (name: string, value: string, maxAgeMinutes: number) => {
  const now = new Date();
  const time = now.getTime();
  const expireTime = time + 1000 * maxAgeMinutes * 60;
  now.setTime(expireTime);
  const maxAgeSegment = '; max-age=' + now.getSeconds();
  document.cookie = encodeURI(name) + '=' + encodeURI(value) + maxAgeSegment;
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts?.pop()?.split(';').shift();
  return '';
};
