import { nanoid } from 'nanoid';
const ProphecyErrorCode = nanoid();
export class ProphecyError extends Error {
    constructor(message, options) {
        super(message, options);
        this._stack = this.stack;
        this.stack = undefined;
        this.cause = (options === null || options === void 0 ? void 0 : options.cause) || ProphecyErrorCode;
    }
    getStack() {
        return this.stack || this._stack;
    }
}
export const prophecyErrorToError = (error) => {
    const newErr = new Error(error.message, { cause: error.cause });
    newErr.stack = error.getStack ? error.getStack() : error.stack;
    return newErr;
};
export const isProphecyError = (error) => {
    return error instanceof ProphecyError || error instanceof Error;
};
