import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { Button } from '../Button';
import { contentStyles, itemStyles, labelStyles } from '../Dropdown/styled';
import { Icon } from '../Icons';
import { Stack, StackItem } from '../Layout';
import { negateAllSpacing, theme } from '../theme';
import { Text } from '../Typography/Text';
import { AUTOCOMPLETE_CLASS, MULTI_SELECT_CLASS, SELECT_ARROW_CLASS, SELECT_CLEAR_CLASS, SELECT_DISABLED_CLASS, SELECT_DROPDOWN_CLASS, SELECT_DROPDOWN_HIDDEN_CLASS, SELECT_ITEM_CLASS, SELECT_ITEM_GROUP_CLASS, SELECT_ITEM_OPTION_ACTIVE_CLASS, SELECT_ITEM_OPTION_CONTENT_CLASS, SELECT_ITEM_OPTION_DISABLED_CLASS, SELECT_ITEM_OPTION_SELECTED_CLASS, SELECT_MENUS_CLASS, SELECT_MENU_CLASS, SELECT_MENU_ITEM_ACTIVE_CLASS, SELECT_MENU_ITEM_CLASS, SELECT_OPEN_CLASS, SELECT_ROOT_CLASS, SELECT_SEARCH_INPUT_CLASS, SELECT_SELECTION_ITEM_CLASS, SELECT_SELECTION_OVERFLOW_CLASS, SELECT_SELECTION_PLACEHOLDER_CLASS, SELECT_SELECTION_SEARCH_CLASS, SELECT_SELECTOR_CLASS, SELECT_SHOW_SEARCH_CLASS, SELECT_VIRTUAL_LIST_HOLDER_INNER, SELECT_VIRTUAL_LIST_SCROLLBAR_SHOW_CLASS, tokens, SELECT_SCROLL_THUMB } from './tokens';
import { SelectSize } from './types';
const getSelectPadding = (props) => {
    const sizeTokens = tokens.Trigger.sizes[props.size];
    const { padding, gap, iconSize } = sizeTokens;
    return props.hasIcon ? `calc(${padding} + ${gap} + ${iconSize})` : padding;
};
const getInputWidth = (props) => {
    const sizeTokens = tokens.Trigger.sizes[props.size];
    const { padding, gap, iconSize } = sizeTokens;
    return props.hasIcon ? `calc(100% - ${padding} - ${gap} - ${iconSize})` : `calc(100% - ${padding})`;
};
export const StyledSelectArrow = styled.div ``;
export const StyledSelectRoot = styled(Stack) `
  font-family: ${theme.fontFamily.text};
  display: inline-flex;
`;
const selectHandleCss = css `
  min-width: ${tokens.Trigger.minWidth};
  cursor: pointer;
  overflow: hidden;
  box-sizing: border-box;
  background: ${(props) => tokens.Trigger[props.variant].backgroundColor};
  border-radius: ${(props) => tokens.Trigger.sizes[props.size].borderRadius};
  border: 1px solid ${(props) => tokens.Trigger[props.variant].borderColor};
  box-shadow: ${tokens.Trigger.boxShadow};
  color: ${(props) => tokens.Trigger[props.variant].color};
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  font-family: ${theme.fontFamily.text};
  font-size: ${(props) => tokens.Trigger.sizes[props.size].fontSize};
  gap: ${(props) => tokens.Trigger.sizes[props.size].gap};

  &:hover {
    color: ${(props) => tokens.Trigger[props.variant].hover.color};
  }

  &:focus-within {
    border-color: ${(props) => tokens.Trigger[props.variant].focus.borderColor};
    box-shadow: ${(props) => tokens.Trigger[props.variant].focus.boxShadow};
  }

  &[disabled],
  &.${SELECT_DISABLED_CLASS} {
    color: ${tokens.Trigger.disabled.color};
    background-color: ${tokens.Trigger.disabled.backgroundColor};
    border-color: ${tokens.Trigger.disabled.borderColor};
    cursor: not-allowed;

    ${Icon} {
      background-color: ${tokens.Trigger.disabled.backgroundColor};
    }
  }
`;
const downArrowCss = css `
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${tokens.Icon.color};
  padding-right: ${(props) => tokens.Trigger.sizes[props.size].padding};

  ${Icon} {
    font-size: ${(props) => tokens.Trigger.sizes[props.size].iconSize};
    background-color: ${(props) => tokens.Trigger[props.variant].backgroundColor};
  }
`;
const Div = forwardRef((_a, ref) => {
    var rest = __rest(_a, []);
    return _jsx("div", Object.assign({ ref: ref }, rest));
});
export const VirtualScrollCss = createGlobalStyle `
  .${SELECT_SCROLL_THUMB}{
    background: ${tokens.Menu.scrollThumbBackground}!important;
    &:hover{
      background: ${tokens.Menu.hoverScrollThumbBackground}!important;
    }
  }
`;
export const StyledSelectWrapper = styled(Div) `
  position: relative;
  display: inline-block;

  .${SELECT_ROOT_CLASS} {
    ${selectHandleCss}

    width: 100%;
    > [aria-live='polite'] {
      position: absolute;
    }
  }

  .${SELECT_DISABLED_CLASS} {
    cursor: not-allowed;
    color: ${tokens.Trigger.disabled.color};

    .${SELECT_SELECTION_PLACEHOLDER_CLASS} {
      color: ${tokens.Trigger.disabled.color};
    }

    ${Icon} {
      opacity: 50%;
    }

    &:hover {
      ${StyledSelectArrow} {
        background: transparent;
      }
    }
  }

  .${SELECT_SELECTION_PLACEHOLDER_CLASS} {
    color: ${theme.colors.grey500};
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }

  .${SELECT_SELECTOR_CLASS} {
    flex: 1;
    padding-left: ${getSelectPadding};
    height: calc(${(props) => tokens.Trigger.sizes[props.size].height} - 2px);
    display: flex;
    min-width: 0;
    align-items: center;
    position: relative;
    .${SELECT_SELECTION_ITEM_CLASS} {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }

  .${MULTI_SELECT_CLASS} {
    .${SELECT_SELECTOR_CLASS} {
      height: auto;
    }

    .${SELECT_SELECTION_SEARCH_CLASS} {
      position: relative;
      width: auto !important; // override the width set by the rc-select
    }

    .${SELECT_SEARCH_INPUT_CLASS} {
      position: absolute;
      width: 100%;
      top: 0;
    }

    .${SELECT_CLEAR_CLASS} {
      position: relative;
      transform: none;
      padding-left: 0;
      right: 0;
      padding-right: ${(props) => tokens.Trigger.sizes[props.size].padding};
    }
  }

  .${SELECT_SELECTION_OVERFLOW_CLASS} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: calc(${(props) => tokens.Trigger.sizes[props.size].height} - 2px);
    padding: calc(${(props) => tokens.Trigger.sizes[props.size].gap} - 1px) 0;
    gap: ${(props) => tokens.Trigger.sizes[props.size].gap};
  }

  .${SELECT_SEARCH_INPUT_CLASS} {
    all: unset;
    height: 100%;
    top: 0;
    position: absolute;
    width: ${getInputWidth};
    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  .${SELECT_OPEN_CLASS}.${SELECT_SHOW_SEARCH_CLASS} {
    .${SELECT_SELECTION_ITEM_CLASS} {
      color: ${tokens.Placeholder.color};
    }

    .${SELECT_SEARCH_INPUT_CLASS}[value=''] {
      width: 1px;
    }
  }

  .${SELECT_ARROW_CLASS} {
    min-height: 100%;
  }

  ${StyledSelectArrow} {
    ${downArrowCss}
  }

  .${SELECT_CLEAR_CLASS} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${(props) => tokens.Trigger.sizes[props.size].padding};

    ${Icon} {
      font-size: ${(props) => tokens.Trigger.sizes[props.size].iconSize};
      background-color: ${(props) => tokens.Trigger[props.variant].backgroundColor};
    }
  }

  .${AUTOCOMPLETE_CLASS} .${SELECT_ARROW_CLASS} {
    display: none;
  }
`;
export const StyledDropdownContent = styled.div `
  ${contentStyles}
  /*To make  boxshadow visible*/
  margin: ${theme.spaces.x12} 0;
  min-width: 100%;
  ${({ width }) => (width ? `width: ${width}px;` : '')}

  .${SELECT_MENUS_CLASS} {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    margin: ${({ selectSize = SelectSize.m }) => negateAllSpacing(tokens.Menu.size[selectSize].padding)};
  }

  .${SELECT_MENU_CLASS} {
    list-style: none;
    margin: 0;
    padding: ${({ selectSize = SelectSize.m }) => tokens.Menu.size[selectSize].padding};
    overflow: auto;
    min-width: ${tokens.Trigger.minWidth};
    pointer-events: auto;
    max-height: ${tokens.Cascader.Menu.height};
    overflow-y: auto;
    &:not(:last-child) {
      border-right: 1px solid ${tokens.Menu.borderColor};
      margin: ${({ selectSize = SelectSize.m }) => tokens.Menu.size[selectSize].padding};
    }
  }

  .${SELECT_ITEM_CLASS}:not(.${SELECT_ITEM_GROUP_CLASS}),
  .${SELECT_MENU_ITEM_CLASS} {
    ${itemStyles}
    min-height: ${tokens.Item.lineHeight};
    white-space: nowrap;
  }

  .${SELECT_ITEM_CLASS}.${SELECT_ITEM_OPTION_DISABLED_CLASS} {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background: transparent;
    }
  }

  .${SELECT_ITEM_GROUP_CLASS} {
    ${labelStyles}
  }

  .${SELECT_MENU_ITEM_ACTIVE_CLASS}, .${SELECT_ITEM_OPTION_ACTIVE_CLASS} {
    background: ${tokens.Item.state.hovered.background};
  }

  .${SELECT_ITEM_OPTION_SELECTED_CLASS} {
    background: ${tokens.Item.state.selected.background};
  }

  .${SELECT_ITEM_OPTION_CONTENT_CLASS} {
    width: 100%;
  }
`;
export const StyledDropdown = createGlobalStyle `
  .${SELECT_DROPDOWN_CLASS} {
    width: auto !important; //override rc inline width
    /**
     * override rc inline position: relative;
     * when the dropdown is relative positioned, the portal parent takes
     * the size of the dropdown overlapping the trigger. 
     * This is visibile when trigger is around top of screen
     * As dropdown is opened in pointer events, it blocks any click events on trigger.
     * Making position absolute fixes it.
     */
    position: absolute !important; 
    display: inline-block;
    z-index: ${tokens.Content.zIndex};
    pointer-events: auto;
    &.${SELECT_DROPDOWN_HIDDEN_CLASS} {
      display: none;
    }


    /** Keep scrollbar always visible. */
    .${SELECT_VIRTUAL_LIST_SCROLLBAR_SHOW_CLASS} {
      display: block !important;
    }

    /**
     * Rc-select applies position absolute as inline style to the inner holder
     * which is not required as positioning is controlled by the transform.
     * This breaks the width of dropdown as we have given width auto, so that the element
     * dropdown element stretches to the width of the content 
     */
    .${SELECT_VIRTUAL_LIST_HOLDER_INNER} {
      position: relative !important;
    }
  }
`;
export const StyledIconWrap = styled.div `
  ${(props) => props.absolute &&
    `
    position: absolute;
    left: ${tokens.Trigger.sizes[props.size].padding};
    top: 50%;
    transform: translateY(-50%);
    z-index: ${theme.zLayer.l};
  `}

  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  ${Icon} {
    font-size: ${(props) => tokens.Trigger.sizes[props.size].iconSize};
  }
`;
export const StyledRightContent = styled(Stack) `
  height: 100%;
`;
export const StyledDownIconWrap = styled.div `
  ${downArrowCss}
`;
export const StyledHandle = styled(Stack) `
  ${selectHandleCss};
  height: ${(props) => tokens.Trigger.sizes[props.size].height};
  padding-left: ${(props) => tokens.Trigger.sizes[props.size].padding};
`;
export const StyledHandleContent = styled(StackItem) `
  display: flex;
  align-items: center;
`;
export const StyledSelectFooterWrapper = styled.div `
  margin: ${tokens.Content.padding} -${tokens.Content.padding} -${tokens.Content.padding};
`;
export const StyledSearchWithNavigationContainer = styled(Stack) `
  background-color: ${theme.colors.white};
  border-radius: 10px;
  color: ${theme.colors.grey500};
  box-shadow: ${theme.shadows.md};
  z-index: ${theme.zLayer.s};
  padding: ${theme.spaces.x10};
`;
export const ToolbarButton = styled(Button) `
  color: ${(props) => (props.isDepthSearch ? theme.colors.primary500 : theme.colors.grey400)};
  border: ${(props) => (props.isDepthSearch ? `1px solid ${theme.colors.primary500}` : 'none')};
  &:hover,
  &:active,
  &:focus {
    color: ${theme.colors.primary600};
    border: ${(props) => (props.isDepthSearch ? `1px solid ${theme.colors.primary500}` : 'none')};
  }
`;
export const StyledSearchButton = styled(Button) `
  color: ${theme.colors.grey500};
  box-shadow: ${theme.shadows.sm};
  border-radius: ${theme.spaces.x4};
  padding: ${theme.spaces.x4};
  z-index: ${theme.zLayer.s};
  &:hover,
  &:active,
  &:focus {
    color: grey;
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadows.md};
  }
`;
export const Info = styled.div `
  font-size: ${theme.sizes.x12};
  font-family: ${theme.fontFamily.text};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const NoResults = styled(Text) `
  font-family: ${tokens.NoResults.fontFamily};
  color: ${tokens.NoResults.color};
`;
export const SelectedItem = styled.span `
  font-size: ${theme.sizes.x10};
  font-family: ${theme.fontFamily.text};
  color: ${theme.colors.primary500};
  max-width: 100px;
  height: ${theme.sizes.x12};
  align-self: baseline;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const ToolbarSection = styled(Stack) `
  margin-left: ${theme.spaces.x10};
`;
