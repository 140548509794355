import { useEffect } from 'react';
import { usePersistentCallback } from './react/hooks';
export const getScrollParent = (node) => {
    while (node) {
        if (node.scrollHeight > node.clientHeight) {
            return node;
        }
        node = node.parentNode;
    }
    return null;
};
export const useScrollableParent = ({ rootNode, visible, onParentScroll }) => {
    const parentElem = getScrollParent(rootNode);
    const _onParentScroll = usePersistentCallback(onParentScroll);
    useEffect(() => {
        if (!visible) {
            return;
        }
        parentElem === null || parentElem === void 0 ? void 0 : parentElem.addEventListener('scroll', _onParentScroll);
        return () => {
            parentElem === null || parentElem === void 0 ? void 0 : parentElem.removeEventListener('scroll', _onParentScroll);
        };
    }, [_onParentScroll, parentElem, visible]);
    return parentElem;
};
