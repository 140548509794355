import { jsx as _jsx } from "react/jsx-runtime";
import { afterAnimationFrame } from '@prophecy/utils/dom';
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { escapeRegExp, isNil, repeat, uniqueId } from 'lodash-es';
import memoizeOne from 'memoize-one';
import { useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import styled, { css } from 'styled-components';
import closeIcon from '../assets/icons/v2.1/mono-tone/mini/mini-x-close.svg';
import { contentStyles, itemStyles, labelStyles } from '../Dropdown/styled';
import { SELECTED_STATE_ATTRIBUTE } from '../Dropdown/tokens';
import { useRegisterActionBarMethod } from '../InputActionBarPopup/InputActionBarPopup';
import { tokens as tagTokens } from '../Tags/tokens';
import { BaseColor, getColorCode, theme } from '../theme';
import { CLOSING_DELIMITER_CLASS, EDITOR_TAG_CLASS, IMG_URL_CLASS_PREFIX, MONACO_TOOLTIP_CLASS, OPENING_DELIMITER_CLASS, TAG_CONTENT_CLASS, TAG_DROPDOWN_WIDGET, TAG_TONE_CLASS_PREFIX } from './tokens';
export const CSS_VARIABLES = {
    borderColor: '--editor-tag-border-color',
    color: '--editor-tag-color',
    backgroundColor: '--editor-tag-background-color',
    closeIconColor: '--editor-tag-icon-color',
    iconUrl: '--editor-tag-icon-url'
};
export const ZERO_WIDTH_SPACE = '\u200B';
const PROPERTY_REGEX = `\\.[\\w$]+`;
const ARRAY_ELEMENT_REGEX = '\\[.*?\\]';
const HIDDEN_DELIMITER = repeat(ZERO_WIDTH_SPACE, 3); // zero width white spaces;
const TagDropdownContainer = styled.div `
  ${contentStyles}
  position: relative;
  top: ${theme.spaces.x6};
  z-index: ${theme.zLayer.s};
`;
export const TagDropdownItem = styled.button `
  all: unset;
  flex: 1;
  ${itemStyles}
`;
export const TagDropdownLabel = styled.div `
  ${labelStyles}
`;
const addedUrls = new Map();
function addImageUrlVariable(imageUrl) {
    if (addedUrls.has(imageUrl)) {
        return addedUrls.get(imageUrl); // Return existing class name if already added
    }
    const className = `${IMG_URL_CLASS_PREFIX}-${uniqueId()}`;
    const style = document.createElement('style');
    style.textContent = `
    .${className} { ${CSS_VARIABLES.iconUrl}: url('${imageUrl}'); }
    .${className}.${className}:before { display: inline-block; }
  `;
    document.head.appendChild(style);
    addedUrls.set(imageUrl, className);
    return className;
}
export const tagDecorationStyles = css `
  ${Object.values(BaseColor).map((tone) => {
    return `
      .${TAG_TONE_CLASS_PREFIX}-${tone} {
        ${CSS_VARIABLES.borderColor}: ${getColorCode(tone, 300)};
        ${CSS_VARIABLES.color}: ${getColorCode(tone, 700)};
        ${CSS_VARIABLES.backgroundColor}: ${getColorCode(tone, 50)};
        ${CSS_VARIABLES.closeIconColor}: ${getColorCode(tone, 500)};
      }
    `;
})}

  .${TAG_CONTENT_CLASS} {
    padding: 0 0 ${theme.spaces.x2};
    padding-left: ${tagTokens.TagsRoot.size.s.padding};
    border: 1px solid var(${CSS_VARIABLES.borderColor});
    border-right: 0;
    height: ${tagTokens.TagsRoot.size.s.height};
    border-radius: ${tagTokens.TagsRoot.borderRadius} 0 0 ${tagTokens.TagsRoot.borderRadius};
    font-size: ${tagTokens.TagsRoot.size.s.fontSize};
    color: var(${CSS_VARIABLES.color});
    background: var(${CSS_VARIABLES.backgroundColor});

    &:before {
      content: '';
      display: none;
      background-color: var(${CSS_VARIABLES.color});
      mask: var(${CSS_VARIABLES.iconUrl});
      mask-size: ${theme.sizes.x14} ${theme.sizes.x14};
      mask-position: center;
      width: ${theme.sizes.x14};
      height: ${theme.sizes.x14};
      margin-top: -1px;
      vertical-align: middle;
      margin-right: ${theme.spaces.x6};
    }

    // if the editor breaks the text into multiple selection
    // then apply padding and border radius only on first decoration,
    // so every element looks like part of one tag
    & + .${TAG_CONTENT_CLASS} {
      padding-left: 0;
      padding-right: 0;
      border-radius: 0;
      border-left: 0;
    }
  }

  .${CLOSING_DELIMITER_CLASS} {
    border: 1px solid var(${CSS_VARIABLES.borderColor});
    border-left: 0;
    background: var(${CSS_VARIABLES.backgroundColor});
    padding: 0 0 ${theme.spaces.x2};
    border-radius: 0 ${tagTokens.TagsRoot.borderRadius} ${tagTokens.TagsRoot.borderRadius} 0;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      align-items: center;
      justify-content: center;
      margin: 0 ${tagTokens.TagsRoot.size.s.padding};
      background-color: var(${CSS_VARIABLES.closeIconColor});
      mask: url(${closeIcon});
      mask-size: ${theme.sizes.x12} ${theme.sizes.x12};
      mask-position: center;
      width: ${theme.sizes.x12};
      height: ${theme.sizes.x12};
      margin-top: -1px;
      vertical-align: middle;
    }
  }

  .${MONACO_TOOLTIP_CLASS} {
    white-space: nowrap;
  }
`;
class TagDropdownWidget {
    constructor({ tagCategories, selectedCategory, position, value, onTagClick, renderTagDropdown, allowTagDropdownOverflow }) {
        this.id = TAG_DROPDOWN_WIDGET;
        this.position = position;
        this.domNode = document.createElement('div');
        this.allowEditorOverflow = Boolean(allowTagDropdownOverflow);
        this.domRoot = ReactDOM.createRoot(this.domNode);
        this.domRoot.render(_jsx(TagDropdownContainer, { className: TAG_DROPDOWN_WIDGET, children: renderTagDropdown
                ? renderTagDropdown({ selectedCategory, tagCategories, onTagClick, value })
                : selectedCategory.options.map((tag, index) => (_jsx(TagDropdownItem, { onClick: () => onTagClick(selectedCategory, tag), [SELECTED_STATE_ATTRIBUTE]: tag.value === value, children: tag.value }, index))) }));
    }
    getId() {
        return this.id;
    }
    getDomNode() {
        return this.domNode;
    }
    getPosition() {
        return this.position;
    }
    destroy() {
        this.domRoot.unmount();
    }
}
function createDelimiterRegex(tagOptions, openingDelimiter = '', closingDelimiter = '') {
    return new RegExp(`${escapeRegExp(openingDelimiter)}(${tagOptions
        .map((value) => `${escapeRegExp(value)}`)
        .join('|')})((${ARRAY_ELEMENT_REGEX}|${PROPERTY_REGEX})*)${escapeRegExp(closingDelimiter)}`, 'g');
}
const createTagDelimiterRegex = memoizeOne((tagCategories) => {
    return tagCategories.map((tag) => {
        return createDelimiterRegex(tag.options.map((tag) => tag.value), tag.openingDelimiter, tag.closingDelimiter);
    });
});
const createHiddenDelimiterRegex = memoizeOne((tagCategories) => {
    return tagCategories.map((tag, index) => {
        const hiddenDelimiterRegex = getHiddenDelimiterForTag(index);
        return createDelimiterRegex(tag.options.map((tag) => tag.value), hiddenDelimiterRegex, hiddenDelimiterRegex);
    });
});
function findTagBeforeCursor(tagLocations, position) {
    return tagLocations.find((location) => {
        return location.range.endLineNumber === position.lineNumber && location.range.endColumn === position.column;
    });
}
function findTagAfterCursor(tagLocations, position) {
    return tagLocations.find((location) => {
        return location.range.endLineNumber === position.lineNumber && location.range.startColumn === position.column;
    });
}
function findTagEnclosingCursor(tagLocations, position) {
    return tagLocations.find((location) => {
        return (location.range.endLineNumber === position.lineNumber &&
            position.column > location.range.startColumn &&
            position.column < location.range.endColumn);
    });
}
function getHiddenDelimiterForTag(tagIndex) {
    return HIDDEN_DELIMITER + repeat(ZERO_WIDTH_SPACE, tagIndex);
}
function hideTagDelimiter(value, tagCategories, delimiterRegex) {
    let updatedValue = value;
    tagCategories.forEach((_, index) => {
        const hiddenDelimiter = getHiddenDelimiterForTag(index);
        updatedValue = updatedValue.replaceAll(delimiterRegex[index], (_, tagValue, propertySuffix, ...args) => {
            return `${hiddenDelimiter}${tagValue}${propertySuffix}${hiddenDelimiter}`;
        });
    });
    return updatedValue;
}
function bringOriginalDelimiter(value, tagCategories, hiddenDelimiterRegex) {
    let updatedValue = value;
    tagCategories.forEach((tag, index) => {
        const { openingDelimiter, closingDelimiter = '' } = tag;
        updatedValue = updatedValue.replaceAll(hiddenDelimiterRegex[index], (_, tagValue, propertySuffix) => {
            return `${openingDelimiter}${tagValue}${propertySuffix}${closingDelimiter}`;
        });
    });
    return updatedValue;
}
function useLastCursorPosition({ monaco, editor, hasTags }) {
    const lastCursorPosition = useRef(null);
    useEffect(() => {
        if (!(monaco && editor && hasTags))
            return;
        editor.onDidFocusEditorText(() => {
            const position = editor.getPosition();
            lastCursorPosition.current = position;
        });
        editor.onDidChangeCursorPosition(() => {
            const position = editor.getPosition();
            lastCursorPosition.current = position;
        });
    }, [editor, monaco, hasTags]);
    return lastCursorPosition;
}
function useDecorateTags({ monaco, editor, tagCategories, hiddenDelimiterRegex }) {
    const tagLocations = useRef([]);
    const appliedDecorations = useRef([]);
    const hasTags = Boolean(tagCategories.length);
    const decorateTags = usePersistentCallback(() => {
        if (!editor || !monaco)
            return;
        const model = editor.getModel();
        if (!model)
            return;
        let match;
        const decorations = [];
        const currentTagLocations = [];
        const modelValue = model.getValue();
        tagCategories.forEach((tagCategory, tagIndex) => {
            const { className, tone: categoryTone, icon: categoryIcon } = tagCategory;
            const tagPattern = hiddenDelimiterRegex[tagIndex];
            while ((match = tagPattern.exec(modelValue))) {
                const fullMatch = match[0];
                const value = match[1] + match[2];
                const tag = tagCategory.options.find((tag) => tag.value === value);
                const fullMatchStartPos = model.getPositionAt(match.index);
                const fullMatchEndPos = model.getPositionAt(match.index + fullMatch.length);
                const hiddenDelimiterLn = HIDDEN_DELIMITER.length + tagIndex;
                const openingDelimStartPos = fullMatchStartPos;
                const openingDelimEndPos = model.getPositionAt(match.index + hiddenDelimiterLn);
                const closingDelimStartPos = model.getPositionAt(match.index + fullMatch.length - hiddenDelimiterLn);
                const closingDelimEndPos = fullMatchEndPos;
                const tone = categoryTone || (tag === null || tag === void 0 ? void 0 : tag.tone);
                const icon = categoryIcon || (tag === null || tag === void 0 ? void 0 : tag.icon);
                // Decoration for complete tag content, this is to track the tag range
                const tagRange = new monaco.Range(fullMatchStartPos.lineNumber, fullMatchStartPos.column, fullMatchEndPos.lineNumber, fullMatchEndPos.column);
                decorations.push({
                    range: tagRange,
                    options: {
                        inlineClassName: `${className} ${EDITOR_TAG_CLASS} ${TAG_TONE_CLASS_PREFIX}-${tone}`,
                        hoverMessage: {
                            value: `${tagCategory.openingDelimiter}${value}${tagCategory.closingDelimiter || ''}`
                        }
                    }
                });
                currentTagLocations.push({ range: tagRange, tagCategory, tagIndex });
                // Decoration for opening delimiter
                decorations.push({
                    range: new monaco.Range(openingDelimStartPos.lineNumber, openingDelimStartPos.column, openingDelimEndPos.lineNumber, openingDelimEndPos.column),
                    options: {
                        inlineClassName: OPENING_DELIMITER_CLASS
                    }
                });
                const iconClass = icon ? addImageUrlVariable(icon) : '';
                // Decoration for tag content
                decorations.push({
                    range: new monaco.Range(fullMatchStartPos.lineNumber, fullMatchStartPos.column + hiddenDelimiterLn, fullMatchEndPos.lineNumber, fullMatchEndPos.column - hiddenDelimiterLn),
                    options: {
                        inlineClassName: `${TAG_CONTENT_CLASS} ${iconClass}`
                    }
                });
                // Decoration for closing delimiter
                decorations.push({
                    range: new monaco.Range(closingDelimStartPos.lineNumber, closingDelimStartPos.column, closingDelimEndPos.lineNumber, closingDelimEndPos.column),
                    options: {
                        inlineClassName: CLOSING_DELIMITER_CLASS
                    }
                });
            }
        });
        tagLocations.current = currentTagLocations;
        appliedDecorations.current = editor.deltaDecorations(appliedDecorations.current, decorations);
    });
    useEffect(() => {
        if (!editor || !hasTags)
            return;
        decorateTags();
        editor.onDidChangeModelContent(() => {
            decorateTags();
        });
    }, [editor, decorateTags, hasTags]);
    return tagLocations;
}
function useEditorCursorCorrection({ monaco, editor, tagLocations, hasTags }) {
    // handle backspace, delete and arrow keys manually around the tag
    const onKeyDown = usePersistentCallback((e) => {
        var _a, _b, _c, _d;
        // no need to handle if tags delimiters are not defined
        if (!editor || !monaco)
            return;
        const position = editor.getPosition();
        const model = editor.getModel();
        const selection = editor.getSelection();
        const getTagOnLeft = () => {
            return (findTagBeforeCursor(tagLocations.current, position) || findTagEnclosingCursor(tagLocations.current, position));
        };
        const getTagOnRight = () => {
            return (findTagAfterCursor(tagLocations.current, position) || findTagEnclosingCursor(tagLocations.current, position));
        };
        // if user press left arrow or right arrow pass through the tag, but only when selection is not in progress
        let moveCursorToPosition = undefined;
        if (e.keyCode === monaco.KeyCode.LeftArrow) {
            moveCursorToPosition = (_a = getTagOnLeft()) === null || _a === void 0 ? void 0 : _a.range.startColumn;
        }
        else if (e.keyCode === monaco.KeyCode.RightArrow) {
            moveCursorToPosition = (_b = getTagOnRight()) === null || _b === void 0 ? void 0 : _b.range.endColumn;
        }
        if (moveCursorToPosition !== undefined) {
            e.preventDefault();
            e.stopPropagation();
            // if selection is in progress set the position using selection or else set the position directly
            if (selection && e.shiftKey) {
                editor.setSelection(Object.assign(Object.assign({}, selection), { positionColumn: moveCursorToPosition }));
            }
            else {
                editor.setPosition(new monaco.Position(position.lineNumber, moveCursorToPosition));
            }
        }
        let removeTagInRange;
        if (e.keyCode === monaco.KeyCode.Backspace) {
            removeTagInRange = (_c = getTagOnLeft()) === null || _c === void 0 ? void 0 : _c.range;
        }
        else if (e.keyCode === monaco.KeyCode.Delete) {
            removeTagInRange = (_d = getTagOnRight()) === null || _d === void 0 ? void 0 : _d.range;
        }
        // if there is selected text, delete and backspace will delete the text instead of deleting nex/previous character,
        // so in such case we don't need to handle custom delete/backspace
        const selectionInProgress = selection && selection.endColumn - selection.startColumn !== 0;
        if (removeTagInRange && !selectionInProgress) {
            e.preventDefault();
            e.stopPropagation();
            // Replace the range with empty string
            model.pushEditOperations([], [
                {
                    range: removeTagInRange,
                    text: null
                }
            ], () => [
                new monaco.Selection(removeTagInRange.startLineNumber, removeTagInRange.startColumn, removeTagInRange.startLineNumber, removeTagInRange.startColumn)
            ]);
            // Set the cursor position
            editor.setPosition(new monaco.Position(position.lineNumber, removeTagInRange.startColumn));
        }
    });
    useEffect(() => {
        if (!editor || !monaco || !hasTags)
            return;
        editor.onKeyDown(onKeyDown);
        // if any user is clicking between the tag, move it out next to the tag
        editor.onMouseUp((e) => {
            const position = editor.getPosition();
            const tagInRange = findTagEnclosingCursor(tagLocations.current, position);
            if (tagInRange) {
                const { endColumn } = tagInRange.range;
                editor.setPosition(new monaco.Position(position.lineNumber, endColumn));
                return;
            }
        });
    }, [editor, monaco, hasTags, onKeyDown, tagLocations]);
}
function useEditorSelection({ editor, monaco, hasTags, tagLocations }) {
    // don't allow cursor position or selection to be placed between a tag
    useEffect(() => {
        if (!editor || !monaco || !hasTags)
            return;
        // filter those selections who falls under tags
        editor.onDidChangeCursorSelection(() => {
            // if there is no tags, no need to do any thing.
            if (!tagLocations.current.length)
                return;
            const selections = editor.getSelections() || [];
            let selectionUpdated;
            // filter selections which is inside the tags, and for selection which is partially inside tags,
            // select the whole tag
            const filteredSelections = selections
                .map((selection) => {
                let updatedSelection = Object.assign({}, selection);
                const { startLineNumber, startColumn, endLineNumber, endColumn } = selection;
                let handledSelectionStart = false;
                let handledSelectionEnd = false;
                const locations = tagLocations.current;
                for (let i = 0, ln = locations.length; i < ln; i++) {
                    const { startLineNumber: tagStartLineNumber, startColumn: tagStartColumn, endColumn: tagEndColumn } = locations[i].range;
                    const isSelectionStartInsideTag = startLineNumber === tagStartLineNumber && startColumn > tagStartColumn && startColumn < tagEndColumn;
                    const isSelectionEndInsideTag = endLineNumber === tagStartLineNumber && endColumn > tagStartColumn && endColumn < tagEndColumn;
                    if (isSelectionStartInsideTag && isSelectionEndInsideTag) {
                        selectionUpdated = true;
                        return undefined;
                    }
                    else if (isSelectionStartInsideTag) {
                        selectionUpdated = true;
                        handledSelectionStart = true;
                        updatedSelection.startColumn = tagStartColumn;
                    }
                    else if (isSelectionEndInsideTag) {
                        selectionUpdated = true;
                        handledSelectionEnd = true;
                        updatedSelection.endColumn = tagEndColumn;
                    }
                    // update cursor position of selection based on the direction we are selecting
                    if (isSelectionStartInsideTag || isSelectionEndInsideTag) {
                        updatedSelection.positionColumn =
                            updatedSelection.positionColumn >= updatedSelection.selectionStartColumn
                                ? tagEndColumn
                                : tagStartColumn;
                    }
                    if (handledSelectionStart && handledSelectionEnd) {
                        return updatedSelection;
                    }
                }
                return updatedSelection;
            })
                .filter(Boolean);
            if (selectionUpdated) {
                editor.setSelections(filteredSelections);
            }
        });
    }, [editor, monaco, hasTags, tagLocations]);
}
function useTagClipboardAction({ monaco, editor, removeDecoration, hideDelimiter, hasTags, changeTagValue, readOnly }) {
    // add custom cut and copy action
    useEffect(() => {
        if (!hasTags || !editor)
            return;
        function updateClipboard(event) {
            var _a;
            // if editor is not focused ignore clipboard event for them
            if (!(editor === null || editor === void 0 ? void 0 : editor.hasWidgetFocus()))
                return;
            const selection = editor.getSelection();
            const selectedText = selection ? editor.getModel().getValueInRange(selection) : '';
            const unDecoratedText = removeDecoration(selectedText);
            (_a = event.clipboardData) === null || _a === void 0 ? void 0 : _a.setData('text/plain', unDecoratedText);
        }
        function paste(event) {
            if (!monaco || !event.clipboardData || !(editor === null || editor === void 0 ? void 0 : editor.getPosition()) || !(editor === null || editor === void 0 ? void 0 : editor.hasWidgetFocus()))
                return;
            const value = event.clipboardData.getData('text/plain');
            if (isNil(value))
                return;
            const updatedValue = hideDelimiter(value);
            const { lineNumber, column } = editor.getPosition();
            changeTagValue(new monaco.Range(lineNumber, column, lineNumber, column), updatedValue);
            event.preventDefault();
            event.stopPropagation();
        }
        if (!readOnly) {
            document.addEventListener('cut', updateClipboard);
            document.addEventListener('paste', paste, true);
        }
        document.addEventListener('copy', updateClipboard);
        return () => {
            if (!readOnly) {
                document.removeEventListener('cut', updateClipboard);
                document.removeEventListener('paste', paste, true);
            }
            document.removeEventListener('copy', updateClipboard);
        };
    }, [editor, monaco, hasTags, readOnly, removeDecoration, hideDelimiter, changeTagValue]);
}
function useTagWidget({ editor, readOnly, tagLocations, hasTags, tagCategories, renderTagDropdown, lastCursorPosition, allowTagDropdownOverflow }) {
    const widgetRef = useRef(null);
    const closeWidget = usePersistentCallback(() => {
        if (editor && widgetRef.current) {
            widgetRef.current.destroy();
            editor.removeContentWidget(widgetRef.current);
            widgetRef.current = null;
        }
    });
    const changeTagValue = usePersistentCallback((range, value) => {
        editor.getModel().pushEditOperations([], [
            {
                range,
                text: value || '',
                forceMoveMarkers: true
            }
        ], () => null);
    });
    const onEditorMouseDown = usePersistentCallback((e) => {
        var _a;
        const targetElement = e.target.element;
        if (!editor || !targetElement || readOnly)
            return;
        // if target element is inside the widget don't close it
        if (targetElement.closest(`.${TAG_DROPDOWN_WIDGET}`)) {
            return;
        }
        // close existing widget if any opened
        closeWidget();
        // Get the mouse click position
        const targetPosition = e.target.position;
        const tagInRange = findTagEnclosingCursor(tagLocations.current, targetPosition);
        if (!tagInRange)
            return;
        if (targetElement.className.includes(TAG_CONTENT_CLASS)) {
            if (targetPosition) {
                const { range, tagCategory } = tagInRange;
                // Update the content widget's position
                const position = {
                    position: {
                        lineNumber: range.startLineNumber,
                        column: range.startColumn
                    },
                    preference: [2]
                };
                const tagValue = ((_a = editor.getModel()) === null || _a === void 0 ? void 0 : _a.getValueInRange(range).replaceAll(ZERO_WIDTH_SPACE, '')) || '';
                const oldRangeValue = `${tagCategory.openingDelimiter}${tagValue}${tagCategory.closingDelimiter || ''}`;
                widgetRef.current = new TagDropdownWidget({
                    position,
                    value: tagValue,
                    tagCategories,
                    selectedCategory: tagCategory,
                    renderTagDropdown,
                    allowTagDropdownOverflow,
                    onTagClick: (newTagCategory, tag) => {
                        const newRangeValue = `${newTagCategory.openingDelimiter}${tag.value}${newTagCategory.closingDelimiter || ''}`;
                        // if we select same tag again don't change anything
                        if (oldRangeValue !== newRangeValue) {
                            changeTagValue(range, `${newTagCategory.openingDelimiter}${tag.value}${newTagCategory.closingDelimiter || ''}`);
                        }
                        // after selection close the widget
                        closeWidget();
                        // and set the position to last set cursor position
                        afterAnimationFrame(() => {
                            if (lastCursorPosition.current) {
                                editor.focus();
                                editor.setPosition(lastCursorPosition.current);
                            }
                        });
                    }
                });
                editor.addContentWidget(widgetRef.current);
            }
        }
        else if (targetElement.className.includes(CLOSING_DELIMITER_CLASS)) {
            changeTagValue(tagInRange.range, '');
        }
    });
    useEffect(() => {
        if (!hasTags || !editor)
            return;
        editor.onMouseDown(onEditorMouseDown);
        editor.onDidBlurEditorWidget(closeWidget);
    }, [editor, hasTags, onEditorMouseDown, closeWidget]);
}
function useRegisterAddTag({ editor, monaco, lastCursorPosition, changeTagValue }) {
    useRegisterActionBarMethod('addTag', (tagCategory, tag, prefix = '', suffix = '') => {
        if (!lastCursorPosition.current || !monaco || !editor)
            return;
        const { lineNumber, column } = lastCursorPosition.current;
        const model = editor.getModel();
        let tagString = `${prefix}${tagCategory.openingDelimiter}${tag.value}${tagCategory.closingDelimiter || ''}${suffix}`;
        if (!model)
            return;
        const lineContent = model.getLineContent(lineNumber);
        const stringIndex = column - 1; // as monaco column starts from 1
        const textBeforeCursor = lineContent.slice(0, stringIndex);
        const textAfterCursor = lineContent.slice(stringIndex);
        /**
         * if we are adding new tag just after another tag, add space before the content so we don't end up merging two tags
         * We can check this by looking into content just before the cursor has hidden delimeter
         */
        if (textBeforeCursor.endsWith(HIDDEN_DELIMITER))
            tagString = ' ' + tagString;
        changeTagValue(new monaco.Range(lineNumber, column, lineNumber, column), tagString);
        /**
         * Set the position after the tag, as we do some manipulation on text to the render tag differently
         * we need to wait for the delimiter manipulation to finish and then set the focus and cursor position.
         */
        setTimeout(() => {
            if (lastCursorPosition.current) {
                const contentAfterAddition = model.getLineContent(lineNumber);
                const newColumnIndex = contentAfterAddition.lastIndexOf(textAfterCursor) + 1;
                editor.focus();
                editor.setPosition(new monaco.Position(lineNumber, newColumnIndex));
            }
        }, 100);
    });
}
export function useTagDecorations(props) {
    const { monaco, editor, value, onChange, tags, readOnly, allowTagDropdownOverflow } = props;
    const { categories: tagCategories, renderTagDropdown } = tags || { categories: [] };
    const hasTags = Boolean(tagCategories.length);
    const removeDecoration = usePersistentCallback((value = '') => {
        return bringOriginalDelimiter(value, tagCategories, hiddenDelimiterRegex).replaceAll(ZERO_WIDTH_SPACE, '');
    });
    const hideDelimiter = usePersistentCallback((value = '') => {
        return hasTags ? hideTagDelimiter(value, tagCategories, delimiterRegex) : value;
    });
    const changeTagValue = usePersistentCallback((range, value) => {
        editor.getModel().pushEditOperations([], [
            {
                range,
                text: value || '',
                forceMoveMarkers: true
            }
        ], () => null);
    });
    const { delimiterRegex, hiddenDelimiterRegex } = useMemo(() => {
        const delimiterRegex = createTagDelimiterRegex(tagCategories);
        const hiddenDelimiterRegex = createHiddenDelimiterRegex(tagCategories);
        return { delimiterRegex, hiddenDelimiterRegex };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(tagCategories)]);
    const lastCursorPosition = useLastCursorPosition({ monaco, editor, hasTags });
    const tagLocations = useDecorateTags({ monaco, editor, tagCategories, hiddenDelimiterRegex });
    useEditorCursorCorrection({ monaco, editor, tagLocations, hasTags });
    useEditorSelection({ editor, monaco, hasTags, tagLocations });
    useTagWidget({
        editor,
        readOnly,
        tagLocations,
        hasTags,
        tagCategories,
        renderTagDropdown,
        lastCursorPosition,
        allowTagDropdownOverflow
    });
    useTagClipboardAction({ monaco, editor, removeDecoration, hideDelimiter, hasTags, changeTagValue, readOnly });
    useRegisterAddTag({ editor, monaco, lastCursorPosition, changeTagValue });
    const updatedValue = useMemo(() => hideDelimiter(value), [hideDelimiter, value]);
    const transformedOnChange = usePersistentCallback((value = '') => {
        onChange(hasTags ? removeDecoration(value) : value);
    });
    return [updatedValue, transformedOnChange, removeDecoration, hasTags];
}
