import { theme } from '../theme';
export const tokens = {
    SlidingPanel: {
        Overlay: {
            backgroundColor: theme.colors.grey900,
            zIndex: theme.zLayer.m
        },
        Content: {
            boxShadow: theme.shadows.xl,
            transition: theme.transition.m,
            backgroundColor: theme.colors.white,
            zIndex: theme.zLayer.m,
            paddingX: theme.spaces.x32,
            paddingY: theme.spaces.x28
        },
        CloseButton: {
            backgroundColor: theme.colors.white,
            hover: {
                backgroundColor: theme.colors.white
            }
        },
        Footer: {
            height: theme.spaces.x80,
            borderColor: theme.colors.grey200,
            padding: theme.spaces.x20
        },
        PanelCloseButton: {
            borderRadius: theme.radius.m,
            zIndex: theme.zLayer.m,
            top: theme.spaces.x24
        },
        borderRadius: theme.radius.xxl,
        size: {
            s: {
                width: '50%',
                minWidth: '700px'
            },
            m: {
                width: '75%',
                minWidth: '810px'
            },
            l: {
                width: '90%',
                minWidth: '950px'
            }
        }
    }
};
