export var InputSize;
(function (InputSize) {
    InputSize["s"] = "s";
    InputSize["m"] = "m";
    InputSize["l"] = "l";
})(InputSize || (InputSize = {}));
export var InlineInputSize;
(function (InlineInputSize) {
    InlineInputSize["m"] = "m";
    InlineInputSize["l"] = "l";
})(InlineInputSize || (InlineInputSize = {}));
export var InputVariants;
(function (InputVariants) {
    InputVariants["primary"] = "primary";
    InputVariants["error"] = "error";
})(InputVariants || (InputVariants = {}));
export var Placement;
(function (Placement) {
    Placement["left"] = "left";
    Placement["right"] = "right";
})(Placement || (Placement = {}));
export var ResizeDirection;
(function (ResizeDirection) {
    ResizeDirection["vertical"] = "vertical";
    ResizeDirection["horizontal"] = "horizontal";
    ResizeDirection["both"] = "both";
    ResizeDirection["none"] = "none";
})(ResizeDirection || (ResizeDirection = {}));
