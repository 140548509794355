import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, forwardRef, useContext, useMemo, useRef } from 'react';
import { Button } from '../Button';
import { CaretDownIcon, CaretUpIcon } from '../Icons';
import { StyledCellRoot } from './styled';
import { TABLE_CELL_CLASS } from './tokens';
export const CUSTOM_ROW_COMPONENT_CLASS = 'ui-table-custom-row';
export const TableRow = forwardRef((rowProps, ref) => {
    var _a;
    const props = getRowComponentProps(rowProps);
    const components = useContext(TableComponentContext);
    const TRElm = ((_a = components === null || components === void 0 ? void 0 : components.body) === null || _a === void 0 ? void 0 : _a.row) || 'tr';
    return _jsx(TRElm, Object.assign({ ref: ref }, props));
});
export const TableComponentContext = createContext(undefined);
function getRowComponentProps(_a) {
    var { children, RowComponent } = _a, rest = __rest(_a, ["children", "RowComponent"]);
    return Object.assign(Object.assign({}, rest), { children: RowComponent ? (_jsx("td", { className: `${TABLE_CELL_CLASS} ${CUSTOM_ROW_COMPONENT_CLASS}`, colSpan: React.Children.count(children), children: RowComponent })) : (children) });
}
export function useTableComponents(components) {
    const componentsRef = useRef(components);
    componentsRef.current = components;
    const tableComponents = useMemo(() => {
        var _a, _b, _c, _d, _e, _f;
        return ({
            table: (_a = componentsRef.current) === null || _a === void 0 ? void 0 : _a.table,
            header: (_b = componentsRef.current) === null || _b === void 0 ? void 0 : _b.header,
            body: {
                wrapper: (_d = (_c = componentsRef.current) === null || _c === void 0 ? void 0 : _c.body) === null || _d === void 0 ? void 0 : _d.wrapper,
                row: TableRow,
                cell: (_f = (_e = componentsRef.current) === null || _e === void 0 ? void 0 : _e.body) === null || _f === void 0 ? void 0 : _f.cell
            }
        });
    }, []);
    return tableComponents;
}
export const WrappedTableCell = (_a) => {
    var { children } = _a, restProps = __rest(_a, ["children"]);
    return (_jsx("td", Object.assign({}, restProps, { children: _jsx(StyledCellRoot, { direction: 'horizontal', alignY: 'center', width: '100%', height: '100%', children: children }) })));
};
export const TableRowExpandButton = (_a) => {
    var { expanded } = _a, rest = __rest(_a, ["expanded"]);
    return (_jsx(Button, Object.assign({}, rest, { variant: 'secondaryGrey', size: 's', icon: expanded ? _jsx(CaretUpIcon, { type: 'extended' }) : _jsx(CaretDownIcon, { type: 'extended' }) })));
};
