import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { List, Trigger } from '@radix-ui/react-tabs';
import { useImmer } from 'use-immer';
import { Button } from '../Button';
import { XCloseIcon } from '../Icons';
import { Stack, StackItem } from '../Layout';
import { theme } from '../theme';
import { StyledDrawer, StyledDrawerHeader, StyledResizable, StyledDrawerContent, DrawerTabRoot, DrawerTab, DrawerTabSeparator } from './styled';
import { tokens } from './tokens';
export function Drawer(_a) {
    var { open, onClose, header, children, maxHeight = '100%', minHeight = '100px', offset = 0 } = _a, restProps = __rest(_a, ["open", "onClose", "header", "children", "maxHeight", "minHeight", "offset"]);
    if (!open)
        return null;
    return (_jsx(StyledResizable, Object.assign({}, restProps, { direction: 'top', maxHeight: maxHeight, minHeight: minHeight, bottomOffset: offset, children: _jsxs(StyledDrawer, { children: [_jsxs(StyledDrawerHeader, { direction: 'horizontal', alignY: 'center', children: [_jsx(StackItem, { grow: '1', children: header }), _jsx(Button, { variant: 'plain', onClick: onClose, icon: _jsx(XCloseIcon, { type: 'default', color: tokens.Drawer.Icon.color }) })] }), _jsx(StyledDrawerContent, { grow: '1', children: children })] }) })));
}
export function DrawerTabHeader({ tabs, activeKey, onChange, className, tabBarExtraContent }) {
    var _a;
    let _activeKey = activeKey || ((_a = tabs[0]) === null || _a === void 0 ? void 0 : _a.key);
    return (_jsx(DrawerTabRoot, { value: _activeKey, onValueChange: onChange, className: className, dir: 'ltr', children: _jsx(List, { asChild: true, children: _jsxs(Stack, { direction: 'horizontal', alignY: 'center', align: 'space-between', children: [(tabBarExtraContent === null || tabBarExtraContent === void 0 ? void 0 : tabBarExtraContent.left) && _jsx(Stack, { padding: `0 ${theme.spaces.x16}`, children: tabBarExtraContent === null || tabBarExtraContent === void 0 ? void 0 : tabBarExtraContent.left }), _jsx(Stack, { direction: 'horizontal', alignY: 'center', children: tabs.map(({ key, tab }, index) => {
                            return (_jsxs(_Fragment, { children: [_jsx(Trigger, { asChild: true, value: key, children: _jsx(DrawerTab, { variant: 'tertiaryGrey', size: 's', children: tab }) }), index !== tabs.length - 1 && _activeKey !== key && _jsx(DrawerTabSeparator, { orientation: 'vertical' })] }));
                        }) }), (tabBarExtraContent === null || tabBarExtraContent === void 0 ? void 0 : tabBarExtraContent.right) && _jsx(Stack, { padding: `0 ${theme.spaces.x16}`, children: tabBarExtraContent === null || tabBarExtraContent === void 0 ? void 0 : tabBarExtraContent.right })] }) }) }));
}
export const defaultDrawerheight = 260;
export const initialDrawerState = {
    drawerHeight: defaultDrawerheight,
    expand: false
};
// TODO: this is not needed anymore as per @kajari, we should remove this after all the components using this are updated
export const useDrawerHeight = () => {
    var _a;
    const [drawerState, setDrawerState] = useImmer(initialDrawerState);
    const rootHeight = (_a = document.getElementById('root')) === null || _a === void 0 ? void 0 : _a.clientHeight;
    const maxHeight = rootHeight ? rootHeight - 169 : document.documentElement.clientHeight - 20;
    const onExpand = usePersistentCallback((height) => {
        setDrawerState((draft) => {
            const isMinHeightReached = height !== undefined && height <= defaultDrawerheight && draft.expand;
            const isMaxHeightReached = height !== undefined && ((height >= maxHeight && !draft.expand) || (height < maxHeight && draft.expand));
            const isExpandToggle = height === undefined;
            if (isExpandToggle || isMinHeightReached || isMaxHeightReached) {
                draft.expand = !draft.expand;
            }
            if (isExpandToggle) {
                draft.drawerHeight = draft.expand ? maxHeight : defaultDrawerheight;
            }
            else {
                draft.drawerHeight = height || defaultDrawerheight;
            }
        });
    });
    return { drawerHeight: drawerState.drawerHeight, maxHeight, onExpand };
};
