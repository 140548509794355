import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { isObject } from 'lodash-es';
import { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { ChevronUpIcon, ChevronDownIcon } from '../Icons';
import { Stack, StackItem } from '../Layout';
import { SortButton } from './styled';
import { tokens } from './tokens';
import { SortOrder } from './types';
export const loadMoreKey = 'loadMoreRow';
const SortButtonsContainer = styled.div `
  ${({ align }) => {
    return (align === 'center' &&
        `
      position: absolute;
      right: ${tokens.Table.sort.right};
      `);
}}
`;
export function ColumnHeaderWithSorting({ columnName, columnTitle, sortable, sortOrder, sortColumn, onSort, align }) {
    const isSortActiveColumn = columnName.toLowerCase() === (sortColumn === null || sortColumn === void 0 ? void 0 : sortColumn.toLowerCase());
    return (_jsxs(Stack, { className: 'column-sort-header-cell' //added to handle cursor type for sorting enabled columns.
        , onClick: (e) => {
            e.stopPropagation();
            onSort(sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc, columnName);
        }, style: { position: align === 'center' ? 'relative' : undefined }, direction: 'horizontal', alignY: 'center', align: 'start', children: [_jsx(StackItem, { children: columnTitle }), sortable && (_jsx(SortButtonsContainer, { align: align, children: _jsxs(Stack, { direction: 'vertical', alignY: 'center', align: 'center', children: [_jsx(SortButton, { variant: 'link', height: 10, size: 'xs', hidden: !isSortActiveColumn, isActive: sortOrder === SortOrder.asc && isSortActiveColumn, onClick: (e) => {
                                e.stopPropagation();
                                onSort(SortOrder.asc, columnName);
                            }, icon: _jsx(ChevronUpIcon, { type: 'default' }) }), _jsx(SortButton, { variant: 'link', size: 'xs', hidden: !isSortActiveColumn, height: 10, isActive: sortOrder === SortOrder.desc && isSortActiveColumn, onClick: (e) => {
                                e.stopPropagation();
                                onSort(SortOrder.desc, columnName);
                            }, icon: _jsx(ChevronDownIcon, { type: 'default' }) })] }) }))] }));
}
export function useColumnSorting(columns, sortConfig) {
    var _a;
    const [sortOrder, setSortOrder] = useState((sortConfig === null || sortConfig === void 0 ? void 0 : sortConfig.sortOrder) || SortOrder.none);
    const [sortColumn, setSortColumn] = useState(((_a = sortConfig === null || sortConfig === void 0 ? void 0 : sortConfig.sortColumn) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) || null);
    const _sortColumn = sortConfig === null || sortConfig === void 0 ? void 0 : sortConfig.sortColumn;
    const _sortOrder = sortConfig === null || sortConfig === void 0 ? void 0 : sortConfig.sortOrder;
    useEffect(() => {
        if (_sortColumn) {
            setSortColumn(_sortColumn || null);
        }
    }, [_sortColumn]);
    useEffect(() => {
        if (_sortOrder) {
            setSortOrder(_sortOrder);
        }
    }, [_sortOrder]);
    const onSort = (order, columnName) => {
        setSortOrder(order);
        setSortColumn(columnName);
    };
    const onHeaderClick = (selectedColumn) => {
        if (selectedColumn === sortColumn) {
            onSort(sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc, selectedColumn);
        }
        else {
            onSort(SortOrder.asc, selectedColumn);
        }
    };
    const currentColumn = columns.find((column) => column.dataIndex === sortColumn);
    const addSortingToColumns = (updatedColumns) => {
        return updatedColumns.map((column) => {
            const { sortable } = column;
            if (sortable) {
                return Object.assign(Object.assign({}, column), { title: (_jsx(ColumnHeaderWithSorting, { columnName: column.dataIndex, columnTitle: column.title, sortable: true, sortOrder: sortOrder, onSort: onSort, sortColumn: sortColumn, align: column.align })) });
            }
            else {
                return column;
            }
        });
    };
    const sortFn = usePersistentCallback((a, b) => {
        var _a, _b;
        if (!sortColumn) {
            return 0;
        }
        if (isObject(currentColumn === null || currentColumn === void 0 ? void 0 : currentColumn.sortable) && ((_a = currentColumn === null || currentColumn === void 0 ? void 0 : currentColumn.sortable) === null || _a === void 0 ? void 0 : _a.sortFn)) {
            return (_b = currentColumn === null || currentColumn === void 0 ? void 0 : currentColumn.sortable) === null || _b === void 0 ? void 0 : _b.sortFn(a[sortColumn], b[sortColumn], sortOrder);
        }
        let lastSortType = 'string';
        /*
         * check if both values are of same type, if not then use last sort type
         * if last sort type is not available then use string as default
         */
        const valueTypeSort = a[sortColumn] instanceof Date || b[sortColumn] instanceof Date
            ? 'date'
            : a[sortColumn] || b[sortColumn]
                ? typeof (a[sortColumn] || b[sortColumn])
                : lastSortType; //handler for empty type
        lastSortType = valueTypeSort;
        const null_date = new Date(0);
        switch (valueTypeSort) {
            case 'boolean':
                return booleanSort(a[sortColumn], b[sortColumn], sortOrder);
            case 'number':
                return sortByNumber(a[sortColumn] || 0, b[sortColumn] || 0, sortOrder);
            case 'date':
                return sortByDate(a[sortColumn] || null_date, b[sortColumn] || null_date, sortOrder);
            case 'string':
                return stringSort(a[sortColumn] || '', b[sortColumn] || '', sortOrder);
            default:
                return 0;
        }
    });
    //method to sort data source based on columnkey and sortOrder
    const sortDataSource = useMemo(() => (dataSource) => {
        if (sortOrder === SortOrder.none || !sortColumn) {
            return dataSource;
        }
        else {
            //Filtering expanded rows before sorting..
            const loadMoreRow = dataSource.find((row) => row.rowKey === loadMoreKey);
            const sortedData = [...dataSource].filter((row) => row.rowKey !== loadMoreKey).sort(sortFn);
            return loadMoreRow ? [...sortedData, loadMoreRow] : sortedData;
        }
    }, [sortColumn, sortFn, sortOrder]);
    return { addSortingToColumns, sortDataSource, onHeaderClick };
}
function sortByNumber(a, b, sortOrder) {
    return sortOrder === SortOrder.asc ? a - b : b - a;
}
function sortByDate(a, b, sortOrder) {
    return sortOrder === SortOrder.asc ? a.getTime() - b.getTime() : b.getTime() - a.getTime();
}
function booleanSort(a, b, sortOrder) {
    return sortOrder === SortOrder.asc ? (a ? 1 : -1) : b ? 1 : -1;
}
function stringSort(a, b, sortOrder) {
    return sortOrder === SortOrder.asc ? a.localeCompare(b) : b.localeCompare(a);
}
