import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { FileStack } from './FileStack';
import { StyledColumns } from './styled';
/**
 * TODO: change the api of directory, it should be nested directory structure, instead of passing the resolved directories list based on selectedPath
 * That logic can also be abstracted in this component, but currently the logic is specific on parent so its kept in parent level
 */
export function FileBrowser({ directoryList, selectedPath, onFileClick, directoryColumnWidth = '200px', className, emptyText = 'No browser data', sortFiles = true, virtualize = false }) {
    const containerRef = useRef(null);
    //Scroll column into view horizontally in the table
    useEffect(() => {
        setTimeout(() => {
            var _a;
            const container = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(`[aria-label="${selectedPath}"]`);
            if (container) {
                container.scrollIntoView(true);
            }
        }, 100);
    }, [selectedPath, containerRef]);
    return (_jsx(StyledColumns, { width: '100%', height: '100%', ref: containerRef, layout: `repeat(auto-fill, minmax(${directoryColumnWidth}, 1fr))`, className: className, children: directoryList.map((files, index) => (_jsx(FileStack, { emptyText: emptyText, sortFiles: sortFiles, virtualize: virtualize, selectedPath: selectedPath, files: files, index: index, directoryColumnWidth: directoryColumnWidth, onFileClick: onFileClick }, index + 'directory'))) }));
}
