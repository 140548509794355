import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useAppMetadata } from '../../context/appMetadata';
import { getAthenaServiceStatus } from '../../data/apis/api';

type DownServicesStatusType = {
  success: boolean;
  isProphecyDown: boolean;
  anyServiceDown: boolean;
  data: {
    services: {
      isPrimary: boolean;
      name: string;
      statusCode: number;
    }[];
  };
};

export const useDownServiceStatus = () => {
  const [downServiceStatus, setDownServiceStatus] = useState<DownServicesStatusType | undefined>();
  const isProphecyDown = downServiceStatus?.isProphecyDown;
  const mins = (isProphecyDown ? 5 : 15) * 60 * 1000;
  const appData = useAppMetadata();
  useQuery<DownServicesStatusType | undefined>(['PropecyDownQuery', 'status'], () => getAthenaServiceStatus(), {
    cacheTime: mins,
    retry: 100000,
    retryDelay: mins,
    refetchInterval: mins,
    onSuccess: async (resp) => {
      // Check maintainance status before checking down status
      await appData.setMaintenanceUpdateInfo();
      setDownServiceStatus(resp);
    }
  });
  return downServiceStatus;
};
