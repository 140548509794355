import { GhostInput, Stack, Switch, Text, theme } from '@prophecy/ui';
import { validateEntityName } from '@prophecy/utils/validation';
import { isImpersonatingUser } from 'frontend/core/src/common/auth/utils';
import GhostDescription from 'frontend/core/src/common/GhostDescription';
import { InlineEditableInput } from 'frontend/core/src/common/styles/InlineEditableInput';
import { isDebugMode } from 'frontend/core/src/utils/localstorage-keys';
import { useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

import { useGlobalVariables } from '../../../context/globalConfig/securedConfigHook';
import { BasicFormData } from '../types';

const FeaturesSectionContainer = styled(Stack)`
  margin-top: ${theme.spaces.x20};
`;

const FeaturesSection = styled.div`
  margin-top: ${theme.spaces.x20};
`;

const BasicForm = ({
  teamId,
  formContext,
  updateTeamErrors
}: {
  teamId?: string;
  formContext: UseFormReturn<BasicFormData>;
  updateTeamErrors: (e: boolean) => void;
}) => {
  const {
    control,
    formState: { errors, isValid }
  } = formContext;

  useEffect(() => {
    updateTeamErrors(!isValid);
  }, [updateTeamErrors, isValid]);

  const InputField = teamId ? InlineEditableInput : GhostInput;
  const isTranspilerEnabled = useGlobalVariables().isImportEnabled || isDebugMode();

  return (
    <>
      <Stack align='start'>
        <Stack align='start' gap={theme.spaces.x12} direction='vertical' padding={`0 ${theme.outlineWidth}`}>
          <Controller
            render={({ field }) => (
              <InputField
                placeholder='Enter team name'
                inputSize='l'
                disabled={!!teamId}
                {...field}
                variant={errors.name ? 'error' : 'primary'}
                helpText={errors.name?.message}
              />
            )}
            control={control}
            name='name'
            rules={{
              required: 'Name is required',
              validate: validateEntityName
            }}
          />
          <Controller
            render={({ field }) => <GhostDescription value={field.value as string} onChange={field.onChange} />}
            control={control}
            name='description'
          />
          {isImpersonatingUser() && (
            <FeaturesSection>
              <Text level='lg'>Features</Text>
              <FeaturesSectionContainer gap={theme.spaces.x8} direction='horizontal'>
                {!isTranspilerEnabled && (
                  <Controller
                    render={({ field }) => (
                      <Switch {...field} checked={field.value}>
                        Transpiler
                      </Switch>
                    )}
                    control={control}
                    name='transpilerFlag'
                  />
                )}

                <Controller
                  render={({ field }) => (
                    <Switch {...field} checked={field.value}>
                      Databricks Entra ID
                    </Switch>
                  )}
                  control={control}
                  name='entraIdFlag'
                />

                <Controller
                  render={({ field }) => (
                    <Switch {...field} checked={field.value}>
                      MWAA Assumed Role Auth
                    </Switch>
                  )}
                  control={control}
                  name='assumedRoleEnabled'
                />
              </FeaturesSectionContainer>
            </FeaturesSection>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default BasicForm;
