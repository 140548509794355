// Code Source: https://github.com/remix-run/react-router/issues/8139#issuecomment-1023105785
import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
export function useBlocker(blocker, when = true) {
    const { navigator } = useContext(NavigationContext);
    useEffect(() => {
        if (!when) {
            return;
        }
        const unblock = navigator.block((tx) => {
            const autoUnblockingTx = Object.assign(Object.assign({}, tx), { retry() {
                    // Automatically unblock the transition so it can play all the way
                    // through before retrying it. TODO: Figure out how to re-enable
                    // this block if the transition is cancelled for some reason.
                    unblock();
                    tx.retry();
                } });
            blocker(autoUnblockingTx);
        });
        return unblock;
    }, [navigator, blocker, when]);
}
/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
export function usePrompt(message, when = true, { onOk, onCancel } = {}) {
    const blocker = useCallback((tx) => {
        let response;
        if (typeof message === 'function') {
            const _message = message(window.location, tx.location, tx.action);
            if (_message) {
                response = window.confirm(_message);
            }
            else {
                // if we don't return any message continue navigation
                tx.retry();
            }
        }
        else {
            response = window.confirm(message);
        }
        if (response) {
            onOk === null || onOk === void 0 ? void 0 : onOk();
            tx.retry();
        }
        else {
            onCancel === null || onCancel === void 0 ? void 0 : onCancel();
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [message, when]);
    return useBlocker(blocker, when);
}
