import { theme } from '../theme';
import { BadgeShade } from './types';
export const tokens = {
    BadgesRoot: {
        borderRadius: theme.radius.xl,
        white: theme.colors.white,
        weight: theme.fontWeight.medium,
        shadeType: {
            [BadgeShade.light]: {
                backgroundShade: 50,
                color: theme.colors.grey700,
                iconShade: 500,
                closeIconShade: 400
            },
            [BadgeShade.dark]: {
                backgroundShade: 500,
                color: theme.colors.white,
                iconShade: 0,
                closeIconShade: 200
            },
            [BadgeShade.medium]: {
                backgroundShade: 100,
                color: theme.colors.grey800,
                iconShade: 600,
                closeIconShade: 500
            }
        },
        size: {
            xs: {
                padding: `1px ${theme.spaces.x6}`,
                height: theme.sizes.x16,
                iconFontSize: theme.fontSizes.x10,
                fontSize: theme.fontSizes.x10
            },
            s: {
                padding: `${theme.spaces.x2} ${theme.spaces.x8}`,
                height: theme.sizes.x22,
                iconFontSize: theme.fontSizes.x13,
                fontSize: theme.fontSizes.x12
            },
            m: {
                padding: `${theme.spaces.x2} ${theme.spaces.x10}`,
                fontSize: theme.fontSizes.x14,
                iconFontSize: theme.fontSizes.x12,
                height: theme.sizes.x24
            },
            l: {
                padding: `${theme.spaces.x4} ${theme.spaces.x12}`,
                sidePadding: theme.spaces.x4,
                fontSize: theme.fontSizes.x14,
                iconFontSize: theme.fontSizes.x14,
                height: theme.sizes.x28
            }
        }
    }
};
export const BadgesComponentNames = {
    container: 'badges.container'
};
