import { theme } from '../theme';
export const tokens = {
    WindowAccordion: {
        gap: theme.spaces.x16
    },
    WindowAccordionItem: {
        Header: {
            gap: theme.spaces.x8
        }
    }
};
