import { ViewType } from '../../LSP/types';

export const ide = {
  switchVisualView: `switch.between.${ViewType.visual}.and.${ViewType.code}`
};
export const metadata = {
  entityOpen: `metadata.entity.open`,
  tabChange: `metadata.tab.change`,
  entityDelete: 'metadata.entity.delete'
};
