import { theme } from '../theme/index';
export const tokens = {
    SwitchRoot: {
        padding: theme.spaces.x2,
        borderRadius: theme.radius.l,
        state: {
            normal: {
                backgroundColor: theme.colors.grey100,
                hover: {
                    backgroundColor: theme.colors.grey200
                }
            },
            selected: {
                backgroundColor: theme.colors.primary500,
                hover: {
                    backgroundColor: theme.colors.primary600
                },
                focus: {
                    backgroundColor: theme.colors.primary500
                }
            }
        }
    },
    Label: {
        weight: theme.fontWeight.medium,
        color: theme.colors.grey700,
        helpTextColor: theme.colors.grey500,
        disabled: {
            color: theme.colors.grey500
        }
    },
    size: {
        md: {
            height: theme.sizes.x20,
            width: theme.sizes.x36,
            SwitchThumb: {
                size: theme.sizes.x16
            }
        },
        lg: {
            height: theme.sizes.x24,
            width: theme.sizes.x44,
            SwitchThumb: {
                size: theme.sizes.x20
            }
        }
    },
    SwitchThumb: {
        backgroundColor: theme.colors.white,
        disabled: {
            backgroundColor: theme.colors.grey50
        }
    },
    ToggleSwitch: {
        color: theme.colors.grey500,
        containerPadding: theme.spaces.x2,
        padding: theme.spaces.x10,
        borderRadius: theme.radius.s,
        gap: theme.spaces.x6,
        fontWeight: theme.fontWeight.medium,
        activeState: {
            borderColor: theme.colors.purple300,
            boxShadow: theme.shadows.sm,
            backgroundColor: theme.colors.white,
            color: theme.colors.purple700
        },
        variant: {
            default: {
                backgroundColor: theme.colors.grey50
            },
            grey: {
                backgroundColor: theme.colors.grey100
            }
        },
        hoverState: {
            color: theme.colors.grey700,
            activeState: {
                color: theme.colors.purple700
            }
        },
        disabledState: {
            borderColor: theme.colors.grey200,
            color: theme.colors.grey300
        },
        iconSize: theme.fontSizes.x16,
        size: {
            md: {
                height: theme.sizes.x28,
                fontSize: theme.fontSizes.x13
            },
            lg: {
                height: theme.sizes.x32,
                iconSize: theme.fontSizes.x18,
                fontSize: theme.fontSizes.x14
            }
        }
    }
};
