import { parseJSON } from '@prophecy/utils/string';
import { noop } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { createContext } from 'use-context-selector';

import { useGraphQlMutation, useGraphQlQuery } from '../../data/util';
import { ViewType } from '../../LSP/types';
import { GetUserPreferenceQuery, SetUserPreferenceQuery } from './queries';
import { PreferenceContextType, SetPreference, UserPreferenceKeys } from './types';
import { usePreference, useSetPreferenceHistory } from './util';

type UserPreferenceValues = Partial<{
  [UserPreferenceKeys.codeViewSplitEditor]: boolean;
  [UserPreferenceKeys.viewType]: ViewType;
  [UserPreferenceKeys.SQLVariantSamplingLimit]: number;
}>;

const UserPreferenceContext = createContext<PreferenceContextType<UserPreferenceValues>>({
  preferences: {},
  setPreference: noop
});

const PreferenceQueryKey = ['USER_PREFERENCE'];

export function useUserPreferenceStore() {
  const [userPreferences, setUserPreferences] = useState<UserPreferenceValues>({});

  const { data: userPreferenceData, isInitialLoading: isLoading } = useGraphQlQuery(
    PreferenceQueryKey,
    GetUserPreferenceQuery,
    {
      refetchInterval: false
    },
    {}
  );

  const { mutateAsync: setUserPreferenceData } = useGraphQlMutation(SetUserPreferenceQuery);

  useEffect(() => {
    if (!userPreferenceData) return;
    const parsedPreference: UserPreferenceValues = parseJSON(userPreferenceData.getUserPreference, {});
    setUserPreferences(parsedPreference);
  }, [userPreferenceData]);

  const persistPreference = (preferences: UserPreferenceValues) => {
    setUserPreferenceData({ userPreference: JSON.stringify(preferences) });
  };

  const setPreference = useSetPreferenceHistory(
    userPreferences,
    setUserPreferences,
    persistPreference
  ) as SetPreference<UserPreferenceValues>;

  return { userPreferences, isLoading, setPreference };
}

export function UserPreferenceProvider({
  children,
  preferences,
  setPreference
}: {
  children: React.ReactNode;
  preferences: UserPreferenceValues;
  setPreference: SetPreference<UserPreferenceValues>;
}) {
  const memoizedContextValue = useMemo(() => {
    return { preferences, setPreference };
  }, [preferences, setPreference]);
  return <UserPreferenceContext.Provider value={memoizedContextValue}>{children}</UserPreferenceContext.Provider>;
}

export function useUserPreference<T extends keyof UserPreferenceValues>(key: T) {
  return usePreference(UserPreferenceContext, key);
}
