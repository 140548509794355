import { theme } from '../theme';
export const tokens = {
    Icon: {
        xxxs: {
            size: theme.sizes.x8
        },
        xxs: {
            size: theme.sizes.x12
        },
        xs: {
            size: theme.sizes.x16
        },
        s: {
            size: theme.sizes.x20
        },
        m: {
            size: theme.sizes.x24
        },
        l: {
            size: theme.sizes.x28
        }
    }
};
export const ICON_CLASS = 'ui-icon';
