export enum OnboardingStatus {
  ONBOARDING_PIPELINE = 'ONBOARDING_PIPELINE', // -> user has completed onboarding pipeline flow
  NEW_PROJECT = 'NEW_PROJECT', // -> user has completed new project creation flow
  NEW_PIPELINE = 'NEW_PIPELINE', // -> user has completed new project's pipeline creation flow
  NEW_FABRIC = 'NEW_FABRIC', // -> user has completed new fabric flow
  UPDATE_FABRIC = 'UPDATE_FABRIC', // -> user has completed update fabric flow

  //NOTE: ^^ not used anymore as update fabric flow is not there
  READY_TO_BEGIN = 'readyToBegin', // -> onboarding is available and user hasn't started
  NOT_SET = '' // -> onboarding not available for the user
}
