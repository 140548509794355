import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useControlledState, useDebounce, usePersistentCallback } from '@prophecy/utils/react/hooks';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { Ellipsis } from '../Ellipsis';
import { Stack } from '../Layout';
import { StyledBreadcrumbContainer, StyledInput, StyledButton, StyledChevronRightIcon, StyledEditIcon, StyledLabel, StyledCrumbWrapper } from './styled';
import { Variant } from './types';
export const CRUMB_ATTR = {
    Index: 'data-crumb-index'
};
// A long delay so onEdit is only called when user blurs from the input
export const DELAY_EDIT_ON_BLUR = Number.MAX_SAFE_INTEGER;
function Crumb(_a) {
    var { editable, variant = Variant.plain, children, value, editableLabel = children, index, onEdit, active, onSelect, editDelay } = _a, restProps = __rest(_a, ["editable", "variant", "children", "value", "editableLabel", "index", "onEdit", "active", "onSelect", "editDelay"]);
    const inputRef = useRef(null);
    const [isEdit, toggleEdit] = useState(false);
    useEffect(() => {
        var _a;
        if (isEdit) {
            (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isEdit]);
    const attr = { [CRUMB_ATTR.Index]: index };
    return (_jsxs(_Fragment, { children: [!isEdit && (_jsx(StyledButton, Object.assign({}, attr, { onClick: () => {
                    onSelect === null || onSelect === void 0 ? void 0 : onSelect(value);
                }, size: 'm', variant: variant }, restProps, { iconPlacement: 'left', active: active === value, children: children ? (_jsxs(Stack, { direction: 'horizontal', alignY: 'center', align: 'space-between', children: [_jsx(StyledLabel, { children: _jsx(Ellipsis, { tooltip: true, title: children, children: children }) }), editable && (_jsx(StyledEditIcon, { type: 'solid', onClick: (e) => {
                                var _a;
                                e.stopPropagation();
                                (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                                toggleEdit(true);
                            } }))] })) : null }))), isEdit && (_jsx(StyledInput, { ref: inputRef, onPressEnter: () => toggleEdit(false), onBlur: () => toggleEdit(false), value: editableLabel, onChange: (editedValue) => onEdit === null || onEdit === void 0 ? void 0 : onEdit(editedValue, index), delay: editDelay }))] }));
}
const BreadcrumbIcon = (props) => (_jsx(StyledChevronRightIcon, Object.assign({}, props, { type: 'default' })));
const renderOptions = ({ onSelect, crumbs = [], separator = _jsx(BreadcrumbIcon, {}), active }) => {
    return crumbs.map((crumb, i) => {
        return (_jsxs(StyledCrumbWrapper, { children: [_jsx(Crumb, Object.assign({}, crumb, { onSelect: onSelect, active: active, index: i })), i < crumbs.length - 1 && separator] }, i));
    });
};
const renderChildren = ({ children = [], onSelect, separator = _jsx(BreadcrumbIcon, {}), active }) => {
    const length = children === null || children === void 0 ? void 0 : children.length;
    return React.Children.map(children, (child, i) => {
        return (_jsxs(StyledCrumbWrapper, { children: [_jsx(Crumb, Object.assign({}, child.props, { onSelect: onSelect, active: active })), i < length - 1 && separator] }));
    });
};
function useCrumbEllipsis({ children, crumbs }) {
    const containerRef = useRef(null);
    const crumbsLength = children ? children.length : crumbs.length;
    const [crumbsToHide, setCrumbsToHide] = useState(0);
    const crumbsWidths = useRef([]);
    const calculateCrumbsWidth = usePersistentCallback(() => {
        const container = containerRef.current;
        if (!container)
            return;
        crumbsWidths.current = Array.from(container.children).map((child, i) => {
            return child.offsetWidth;
        });
    });
    const hideCrumbs = usePersistentCallback(() => {
        const container = containerRef.current;
        const _crumbsWidth = crumbsWidths.current;
        if (!container || !_crumbsWidth.length)
            return;
        const containerWidth = container.offsetWidth;
        const lastElementWidth = _crumbsWidth[crumbsLength - 1] || 0;
        const firstElementWidth = _crumbsWidth[0];
        // for auto growing parent based on children width. if all children is fitting well, then no need to hide anything
        const totalCrumbsWidth = _crumbsWidth.reduce((a, b) => a + b, 0);
        if (totalCrumbsWidth < containerWidth) {
            setCrumbsToHide(0);
            return;
        }
        let occupiedWidth = 0;
        /**
         * We always need to show first and last element, and the ellipsis in between them (45px)
         * So we remove all of there width from container width to figure available space
         */
        const availableWidth = containerWidth - firstElementWidth - lastElementWidth - 45;
        if (availableWidth < 0) {
            /*
            Case when no space is available after accommodating   firstElementWidth+lastElementWidth+45 .
            In such case, show the last element only.
            */
            setCrumbsToHide(crumbsLength - 1);
            return;
        }
        else {
            // find how many elements can fit in available space, and hide the rest
            for (let i = 1; i < crumbsLength - 1; i++) {
                const crumbWidth = _crumbsWidth[i];
                occupiedWidth = occupiedWidth + crumbWidth;
                if (occupiedWidth > availableWidth) {
                    setCrumbsToHide(crumbsLength - i);
                    return;
                }
            }
        }
        setCrumbsToHide(0);
    });
    const debouncedHideCrumbs = useDebounce(() => {
        // first show all crumbs so auto parent taking content width can stretch as much as possible and then add ellipsis in middle to fit, extra crumbs
        setCrumbsToHide(0);
        flushSync(() => { });
        hideCrumbs();
    }, 100);
    useEffect(() => {
        // update crumbs on window resize
        window.addEventListener('resize', debouncedHideCrumbs);
        return () => window.removeEventListener('resize', debouncedHideCrumbs);
    }, [debouncedHideCrumbs]);
    useLayoutEffect(() => {
        // to calculate the width of the crumbs, if they have changed, we need to render them first
        if (crumbsWidths.current.length && crumbsWidths.current.length !== crumbsLength) {
            setCrumbsToHide(0);
            flushSync(() => { });
        }
        // recalculate the width of the crumbs, if we haven't calculated them already
        calculateCrumbsWidth();
        hideCrumbs();
    }, [calculateCrumbsWidth, crumbsLength, hideCrumbs]);
    return { containerRef, crumbsToHide };
}
export const Breadcrumb = (props) => {
    const { onSelect, crumbs = [], active, defaultActive, children, separator = _jsx(BreadcrumbIcon, {}) } = props, restProps = __rest(props, ["onSelect", "crumbs", "active", "defaultActive", "children", "separator"]);
    const [currentCrumb, setActiveValue] = useControlledState({
        value: active,
        defaultValue: defaultActive,
        onChange: onSelect
    });
    const { containerRef, crumbsToHide } = useCrumbEllipsis({ children, crumbs });
    const currentProps = Object.assign(Object.assign({}, props), { active: currentCrumb, onSelect: setActiveValue });
    let crumbsElements = children ? renderChildren(currentProps) : renderOptions(currentProps);
    const crumbsLength = crumbsElements.length - 1;
    if (crumbsToHide > 0) {
        const crumbsToRender = crumbsLength === crumbsToHide && crumbsLength > 0 ? [] : crumbsElements.slice(0, crumbsLength - crumbsToHide);
        crumbsToRender.push(_jsxs(StyledCrumbWrapper, { children: [_jsx("span", { children: "..." }), separator] }), crumbsElements[crumbsLength]);
        crumbsElements = crumbsToRender;
    }
    return (_jsx(StyledBreadcrumbContainer, Object.assign({ ref: containerRef }, restProps, { direction: 'horizontal', alignY: 'center', children: crumbsElements })));
};
Breadcrumb.Crumb = Crumb;
Breadcrumb.Icon = BreadcrumbIcon;
