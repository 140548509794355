import { theme } from '../theme';
export const tokens = {
    TagsRoot: {
        borderRadius: theme.radius.s,
        fontWeight: theme.fontWeight.medium,
        countBorderRadius: theme.radius.xs,
        circleFontSize: theme.fontSizes.x12,
        circleSize: theme.sizes.x20,
        size: {
            s: {
                padding: `${theme.spaces.x2} ${theme.spaces.x8}`,
                countPadding: theme.spaces.x2,
                height: theme.sizes.x20,
                iconFontSize: theme.fontSizes.x13,
                fontSize: theme.fontSizes.x12
            },
            m: {
                padding: `${theme.spaces.x2} ${theme.spaces.x8}`,
                countPadding: theme.spaces.x6,
                fontSize: theme.fontSizes.x14,
                iconFontSize: theme.fontSizes.x14,
                height: theme.sizes.x24
            },
            l: {
                padding: `${theme.spaces.x4} ${theme.spaces.x8}`,
                countPadding: theme.spaces.x6,
                fontSize: theme.fontSizes.x14,
                iconFontSize: theme.fontSizes.x16,
                height: theme.sizes.x28
            }
        }
    }
};
