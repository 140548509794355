import { CheckIcon } from '@prophecy/ui/Icons';
import { Stack } from '@prophecy/ui/Layout';
import { theme } from '@prophecy/ui/theme';
import { Text } from '@prophecy/ui/Typography';
import { ReactNode } from 'react';
import styled from 'styled-components';

const Circle = styled.div<{ isDone?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${theme.sizes.x24};
  height: ${theme.sizes.x24};
  border-radius: 50%;
  background-color: ${(props) => props.isDone && theme.colors.success500};
  border: ${(props) => `1px solid ${props.isDone ? theme.colors.success500 : theme.colors.primary500}`};
  color: ${(props) => (props.isDone ? theme.colors.white : theme.colors.primary600)};
`;

const Root = styled(Stack)`
  min-width: 300px;

  ${Circle} ${Circle} {
    height: 100px;
  }
`;

const StepLine = styled.div<{ isDone?: boolean }>`
  border-left: 1px solid ${(props) => (props.isDone ? theme.colors.success500 : theme.colors.grey300)};
  height: 100%;
  position: relative;
  margin-top: ${theme.spaces.x6};

  &::after {
    content: '';
    width: 100px;
    position: absolute;
    border-left: 1px solid ${(props) => (props.isDone ? theme.colors.success500 : theme.colors.grey300)};
    height: ${theme.spaces.x28};
    bottom: -${theme.spaces.x28};
    left: -1px;
    margin-bottom: ${theme.spaces.x6};
  }
`;

function StepCircle({ stepNumber, isDone }: { stepNumber: number; isDone?: boolean }) {
  return (
    <Circle isDone={isDone}>
      {isDone ? (
        <CheckIcon type='default' size='xs' />
      ) : (
        <Text level='sm' weight={theme.fontWeight.semiBold}>
          {stepNumber}
        </Text>
      )}
    </Circle>
  );
}

export type Step = {
  title: string | ReactNode;
  description?: string | ReactNode;
};

type StepperProps = {
  steps: Step[];
  currentStep: number;
};

export default function Stepper({ steps, currentStep }: StepperProps) {
  return (
    <Root gap={theme.spaces.x28}>
      {steps.map((step, stepIndex) => {
        const isLastStep = stepIndex === steps.length - 1;
        const isStepDone = stepIndex < currentStep;

        return (
          <Stack key={stepIndex} gap={theme.spaces.x16} direction='horizontal'>
            <Stack alignY='center'>
              <Stack>
                <StepCircle isDone={isStepDone} stepNumber={stepIndex + 1} />
              </Stack>
              {!isLastStep && <StepLine isDone={isStepDone} />}
            </Stack>
            <Stack gap={theme.spaces.x6}>
              {typeof step.title === 'string' ? (
                <Text weight={theme.fontWeight.medium} level='sm'>
                  {step.title}
                </Text>
              ) : (
                step.title
              )}
              {typeof step.description === 'string' ? <Text level='xs'>{step.description}</Text> : step.description}
            </Stack>
          </Stack>
        );
      })}
    </Root>
  );
}
