import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Select } from './Select';
export const SelectedOnlyContent = styled.div ``;
const DisplaySelectDropdown = styled.div `
  ${SelectedOnlyContent} {
    display: none;
  }
`;
export const DropdownOnlyContent = styled.div ``;
export const DisplaySelect = styled(Select).attrs({
    dropdownRender: (menu) => _jsx(DisplaySelectDropdown, { children: menu })
}) `
  ${DropdownOnlyContent} {
    display: none;
  }
`;
