import { Callable } from '@prophecy/interfaces/generic';
import { Alert, Button, Dialog, RadioButtonGroup, Stack, theme } from '@prophecy/ui';
import utilify from '@prophecy/utils/react/utilify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { SecuredGlobalConfigType, useGlobalVariables } from '../../context/globalConfig/securedConfigHook';
import { UserFabricStatusKnownStatus } from '../../context/types';
import { Fabric } from '../../redux/types';
import { Private_Routes } from '../url';
import { FabricSelect } from './FabricSelect';
import { useUserFabrics } from './hooks';

const StyledButton = styled(Button)<{ to?: string }>`
  box-shadow: none;
`;

const getFabricUrl = (fabricId?: string) => {
  return fabricId
    ? Private_Routes.Create_Entity.fabric.existing.getUrl({ uid: `${fabricId}` })
    : Private_Routes.Create_Entity.fabric.new.getUrl(undefined);
};

type FabricSetupProps = {
  message?: string;
  onClose?: Callable;
  navigate: (url: string) => void;
  fabrics: Fabric[];
  globalConf: SecuredGlobalConfigType;
};

export const FabricSetupDialog = ({ message, onClose, navigate, fabrics, globalConf }: FabricSetupProps) => {
  const unsetFabricId = globalConf.getUnsetFabricStatusId;
  const [fabricId, setFabricId] = useState(
    unsetFabricId !== UserFabricStatusKnownStatus.NoFabric ? `${unsetFabricId}` : ''
  );

  if (!message && !fabrics.length) {
    navigate(getFabricUrl());
    return null;
  }
  if (unsetFabricId === UserFabricStatusKnownStatus.AlreadyHaveFabric) {
    navigate(Private_Routes.Home.getUrl());
    return null;
  }

  return (
    <Dialog
      open={true}
      title={`Setup Fabric`}
      size='l'
      okButton={{ children: 'Setup' }}
      onOk={() => {
        navigate(getFabricUrl(fabricId));
        onClose?.();
      }}
      onClose={onClose}>
      <Stack gap={theme.spaces.x16} padding={`${theme.spaces.x24} ${theme.outlineWidth}`}>
        {message && <Alert>{message}</Alert>}

        {Boolean(fabrics.length) && (
          <RadioButtonGroup
            wrap={false}
            value={fabricId ? 'update' : 'create'}
            onChange={(value) => setFabricId(value === 'update' ? fabrics[0]?.id : '')}
            gap={theme.spaces.x12}
            options={[
              {
                label: 'Setup team fabric ',
                value: 'update',
                width: '49%'
              },
              {
                label: 'Create new fabric',
                value: 'create',
                width: '49%'
              }
            ]}
            orientation='horizontal'
            width='100%'
          />
        )}

        {Boolean(fabricId) && (
          <FabricSelect label={'* Select Fabric'} value={fabricId} onChange={setFabricId} fabrics={fabrics} />
        )}
      </Stack>
    </Dialog>
  );
};

export const openFabricSetup = utilify<FabricSetupProps, void>(({ props, render, unmount }) => {
  render(
    <FabricSetupDialog
      {...props}
      onClose={() => {
        props.onClose?.();
        unmount();
      }}
    />,
    { unmountOnNavigation: true }
  );
});

export const FabricSetupButton = () => {
  const navigate = useNavigate();
  const fabrics = useUserFabrics();
  const globalConf = useGlobalVariables();

  return (
    <Alert>
      <Stack direction='horizontal' align='space-between'>
        Just one last step! Setup your first development fabric (execution environment) to get started.
        <StyledButton size='s' variant='primary' onClick={() => openFabricSetup({ navigate, fabrics, globalConf })}>
          Setup Fabric
        </StyledButton>
      </Stack>
    </Alert>
  );
};
