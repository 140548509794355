export const home = {
  importPageOpen: 'transpiler.import.open',
  chooseEntities: 'transpiler.import.chooseEntities',
  importStarted: 'transpiler.import.started',
  configurationStarted: 'transpiler.configuration.started',
  importedFileDelete: 'transpiler.import.file.delete',
  uploadedFileDelete: 'transpiler.file.upload.delete',
  entityOpen: 'transpiler.entity.open',
  importedGraphDelete: 'transpiler.import.delete',
  transpilationSuccess: 'transpiler.import.transpilation.success',
  transpilationFailed: 'transpiler.import.transpilation.failed',
  transpilationpreview: 'transpiler.entity.preview',
  pipelinePreview: 'transpiler.entity.pipeline.preview',
  transpilationStarted: 'transpiler.import.transpilation.start'
};
