export const metadata = {
  expectationOpen: 'metadata.expectation.open',
  expectationSave: 'metadata.expectation.save'
};

//Pipeline ide
export const ide = {
  expectationOpen: 'ide.expectation.open',
  createDataset: 'create.dataset',
  expectationIdeOpen: `expectation.ide.open`,
  observationSummaryOpen: 'observation.project.summary.open',
  observationAllSummaryOpen: 'observation.all.summary.open',
  observationDateChange: 'observation.project.summary.observationDate',
  observationAllTabDateChange: 'observation.all.summary.observationDate'
};
