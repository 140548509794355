import { getTabId } from '@prophecy/utils/session';

import { DOMAIN_URL_KEY, isWebsocketCompressionEnabled } from './localstorage-keys';

let serverUrl = localStorage.getItem(DOMAIN_URL_KEY) || window.location.host;
serverUrl = serverUrl.replace(/http(s?):\/\//, '');

const protocol = window.location.protocol;
const websocketProtocol = protocol === 'http:' ? 'ws:' : 'wss:';

export function getCBPlaygroundWSEndPoint() {
  return `${websocketProtocol}//${serverUrl}/api/editor/componentBuilderWs`;
}

export function getAirflowPlaygroundWSEndPoint() {
  return `${websocketProtocol}//${serverUrl}/api/airflow/componentBuilderWs`;
}

export function getWorkflowEditorWSEndPoint() {
  const wsRoute = isWebsocketCompressionEnabled ? 'compressedEditorWs' : 'editorWs';
  return `${websocketProtocol}//${serverUrl}/api/editor/${wsRoute}`;
}
export function getSQLEditorWSEndPoint() {
  const wsRoute = isWebsocketCompressionEnabled ? 'compressedSqlEditorWs' : 'sqlEditorWs';
  return `${websocketProtocol}//${serverUrl}/api/editor/${wsRoute}`;
}
export function getLineageEditorWSEndPoint() {
  const wsRoute = isWebsocketCompressionEnabled ? 'compressedWs' : 'ws';
  return `${websocketProtocol}//${serverUrl}/api/lineage/${wsRoute}`;
}
export function getJobEditorWSEndPoint() {
  const wsRoute = isWebsocketCompressionEnabled ? 'compressedJobWs' : 'jobWs';
  return `${websocketProtocol}//${serverUrl}/api/editor/${wsRoute}`;
}

export function getWorkflowUnitTestWSEndPoint() {
  return `${websocketProtocol}//${serverUrl}/api/editor/unittestWs`;
}
export function getProjectBuildWSEndPoint(projectId: string) {
  return `${websocketProtocol}//${serverUrl}/api/projectBuildWS/${projectId}`;
}
export const hostUrl = `${protocol}//${serverUrl}`;
export function getWorkflowComponentListEndPoint() {
  return `${hostUrl}/api/workflow/getComponentList`;
}

export function getAirflowComponentListEndPoint() {
  return `${hostUrl}/api/airflow/getComponentList`;
}

export function getValidateDependencyEndPoint() {
  return `${hostUrl}/api/workflow/validateCoordinateDependency`;
}

export function getBackupStatusEndpoint() {
  return `${hostUrl}/api/backup/status`;
}

export function getBackupListEndpoint() {
  return `${hostUrl}/api/backup/list`;
}

export function getValidatePyPIDependencyEndPoint() {
  return `${hostUrl}/api/workflow/validatePyPiDependency`;
}
export const APIUrls = {
  GET_UPDATE_INFO: {
    url: `${hostUrl}/athena/api/v1/cluster/updateInfo`,
    method: 'GET'
  },
  GET_MAINTENANCE_WINDOW: {
    url: `${hostUrl}/api/cluster/getMaintenanceWindow`,
    method: 'GET'
  },
  GET_ALL_IDP_CONFIG: {
    url: `${hostUrl}/api/idp/getAllIDPsConfig`,
    method: 'GET'
  },
  SET_IDP_CONFIG: {
    url: `${hostUrl}/api/idp/setIDPConfig`,
    method: 'POST'
  },
  DELETE_GEM: {
    url: `${hostUrl}/api/workflow/deleteComponent`,
    method: 'POST'
  },
  DELETE_AIRFLOW_GEM: {
    url: `${hostUrl}/api/airflow/deleteComponent`,
    method: 'POST'
  },
  GET_USAGE_METRICS: {
    url: `${hostUrl}/api/monitoring/usageMetrics`,
    method: 'GET'
  },
  GET_ATHENA_AUTH: {
    url: `${hostUrl}/api/monitoring/authorizationKey`,
    method: 'GET'
  },
  UPDATE_METRICS_TIME_STAMPE: {
    url: `${hostUrl}/api/monitoring/usageMetrics/updateTimestamp`,
    method: 'POST'
  }
};

function removeProtocol(url: string) {
  return url.replace(/^https?:\/\//, '');
}

export function getGraphQlEndPoint() {
  return `${hostUrl}/api/md/graphql`;
}
export function getGraphQlEndPointNew() {
  return `${hostUrl}/api/md/graphql_async`;
}
export function getMdWSEndPoint() {
  if (isWebsocketCompressionEnabled) return `${websocketProtocol}//${serverUrl}/api/mdws/wsCompressedSubscription/<id>`;
  else {
    return `${websocketProtocol}//${serverUrl}/api/mdws/wsSubscription/<id>`;
  }
}
export function getTranspilerWSEndPoint() {
  return `${websocketProtocol}//${serverUrl}/api/transpiler`;
}

export function getTranspilerEndpoint() {
  return `${hostUrl}/api/transpiler`;
}

export function getFabricWSURL(executionUrl: string, fabricId: string, session: string) {
  return `${websocketProtocol}//${removeProtocol(executionUrl)}${
    isWebsocketCompressionEnabled ? '/compressed' : ''
  }/fabrics/${fabricId}/sessions/${session}`;
}

export function getExecutionFabricWSURL(executionUrl: string, fabricId: string) {
  let urlWithoutProtocol = executionUrl.replace(/^https?:\/\//g, '');
  return `${websocketProtocol}//${urlWithoutProtocol}/fabrics/${fabricId}/job/attach/tab/${getTabId()}`;
}

export function getSQLExecutionWSEndPoint(executionUrl: string, fabricId: string) {
  return `${websocketProtocol}//${removeProtocol(executionUrl)}/fabrics/${fabricId}/sqlWebsocket`;
}

export function getFabricSecretUrl(executionUrl: string, fabricId: string) {
  return `${protocol}//${removeProtocol(executionUrl)}/fabrics/${fabricId}/secrets`;
}

export function getJobRunListUrl(executionUrl: string, fabricId: string) {
  return `${protocol}//${removeProtocol(executionUrl)}/fabrics/${fabricId}/job/databricks/filter`;
}
export function getJobLogListUrl(executionUrl: string, fabricId: string) {
  return `${protocol}//${removeProtocol(executionUrl)}/fabrics/${fabricId}/job/databricks/logs`;
}
export function getUsageMetricsOnPremUrl(controlCenterUrl: string) {
  return `${protocol}//${removeProtocol(controlCenterUrl)}/api/v1/monitoring/usageMetrics`;
}
export function getInternalGitServerUrl() {
  return `${protocol}//gitserver.${removeProtocol(hostUrl)}`;
}

export function getReleaseWebsocket(projectId: string, executionServiceURL?: string) {
  const url = executionServiceURL ? stripHttpsPort(executionServiceURL) : getDefaultExecutionServiceUrl();
  return `${websocketProtocol}${url}/releaselogs/project/${projectId}`;
}

export function getReleaseV2Websocket(projectId: string, deploymentId: string, executionServiceURL?: string) {
  const url = executionServiceURL ? stripHttpsPort(executionServiceURL) : getDefaultExecutionServiceUrl();
  return `${websocketProtocol}${url}/release/project/${projectId}/deployment/${deploymentId}/logs`;
}

const executionServiceDefaultSubDomain = 'execution.dp';
function getDefaultExecutionServiceUrl() {
  return `${executionServiceDefaultSubDomain}.${serverUrl}`;
}

function stripHttpsPort(url: string) {
  return url.replace(':443', '');
}

export function getExecutionServiceEndPoint(executionServiceURL?: string) {
  const url = executionServiceURL
    ? removeProtocol(stripHttpsPort(executionServiceURL))
    : getDefaultExecutionServiceUrl();
  return `${protocol}//${url}`;
}

export function getFabricEndPoint(executionServiceURL?: string, fabricId?: string) {
  return `${getExecutionServiceEndPoint(executionServiceURL)}/fabrics/${fabricId}`;
}
export function isConnectedToDevCluster() {
  return serverUrl.includes('dev.cloud.prophecy.io');
}
