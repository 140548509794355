export var IndicatorVariants;
(function (IndicatorVariants) {
    IndicatorVariants["success"] = "success";
    IndicatorVariants["error"] = "error";
    IndicatorVariants["info"] = "info";
    IndicatorVariants["warning"] = "warning";
    IndicatorVariants["ghost"] = "ghost";
})(IndicatorVariants || (IndicatorVariants = {}));
export var Size;
(function (Size) {
    Size["s"] = "s";
    Size["m"] = "m";
})(Size || (Size = {}));
