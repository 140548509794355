import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import utilify from '@prophecy/utils/react/utilify';
import { Provider, Description, Close, Action } from '@radix-ui/react-toast';
import { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { AlertTriangleIcon, CheckIcon, InfoCircleIcon, LoadingDIcon, XCloseIcon, NewIconVariant } from '../Icons';
import { theme } from '../theme';
import { StyledToastContainer, StyledStack, StyledViewPort, containerStyle, ContentWrapper, ActionWrapper, AbsoluteActionWrapper, CloseButton } from './styled';
import { ToastPlacement, Variant } from './types';
const toastLabel = 'toastRootContainer';
const topId = 'toastTopContainer';
const bottomId = 'toastBottomContainer';
const IconMap = {
    loading: { icon: LoadingDIcon, type: NewIconVariant.extended },
    error: { icon: AlertTriangleIcon, type: NewIconVariant.default },
    success: { icon: CheckIcon, type: NewIconVariant.default },
    info: { icon: InfoCircleIcon, type: NewIconVariant.default },
    warning: { icon: AlertTriangleIcon, type: NewIconVariant.default },
    plain: null
};
export const MAX_TOAST_DURATION = 10000000 * 1000 * 1000;
// max THRESHOLD to avoid closing toast on drag
const MAX_TOAST_SWIPE_THRESHOLD = 100000000;
function useBodyBoundary(enable) {
    const [initialBoundary, setInitialBoundary] = useState();
    const domRef = useRef(null);
    const element = domRef.current;
    useEffect(() => {
        if (!element || !enable)
            return;
        const { top, left, width, height } = element.getBoundingClientRect();
        setInitialBoundary({
            top: -top,
            left: -left,
            right: window.innerWidth - (left + width),
            bottom: window.innerHeight - (top + height)
        });
    }, [element, enable]);
    return { ref: domRef, bound: initialBoundary };
}
export const Toast = (_a) => {
    var { content, duration = 6000, closeable = true, width = '400px', variant = Variant.info, placement = ToastPlacement.bottom, open, isRelative = false, onClose, actions, draggable, showIcon = true, contentStyle } = _a, restProps = __rest(_a, ["content", "duration", "closeable", "width", "variant", "placement", "open", "isRelative", "onClose", "actions", "draggable", "showIcon", "contentStyle"]);
    const { icon: Icon, type: iconType } = IconMap[variant] || {};
    const { ref, bound } = useBodyBoundary(draggable && open);
    const onOpenChange = (open) => {
        if (!open && onClose) {
            onClose();
        }
    };
    if (!open) {
        //Setting the content takes some time so loader shown till the time.
        return null;
    }
    const _duration = closeable ? duration : MAX_TOAST_DURATION;
    const ActionWrapperComponent = variant === Variant.plain ? AbsoluteActionWrapper : ActionWrapper;
    const closeButton = closeable ? (_jsx(Close, { asChild: true, children: _jsx(CloseButton, { icon: _jsx(XCloseIcon, { type: 'default' }), variant: 'linkGrey' }) })) : null;
    const _actions = actions || closeButton ? (_jsxs(ActionWrapperComponent, { children: [actions, closeButton] })) : null;
    const toastContainer = (_jsxs(StyledToastContainer, Object.assign({ "aria-label": 'toast-container' }, restProps, { variant: variant, width: width, open: open, isDraggable: draggable, ref: ref, onOpenChange: onOpenChange, children: [_jsx(Description, { asChild: true, children: _jsxs(StyledStack, { direction: 'horizontal', gap: theme.spaces.x12, alignY: 'center', children: [showIcon && Icon ? (_jsx("span", { children: _jsx(Icon, { type: iconType, className: variant, spin: variant === Variant.loading }) })) : null, _jsx(ContentWrapper, { direction: 'horizontal', gap: theme.spaces.x12, alignY: 'center', style: contentStyle, children: content }), variant !== Variant.plain ? _actions : null] }) }), _jsx(Action, { altText: 'esc', asChild: true }), variant === Variant.plain ? _actions : null] })));
    return (_jsxs(Provider, { duration: _duration, swipeThreshold: MAX_TOAST_SWIPE_THRESHOLD, children: [draggable ? _jsx(Draggable, { bounds: bound, children: toastContainer }) : toastContainer, _jsx(StyledViewPort, { label: toastLabel, isRelative: isRelative, placement: placement })] }));
};
const setTopAndBottomToastContainer = (id, placement = ToastPlacement.bottom) => {
    var _a;
    const wrapper = document.createElement('div');
    wrapper.setAttribute('id', id);
    wrapper.setAttribute('style', containerStyle(placement));
    const currentElement = document.getElementById(id);
    if (!currentElement) {
        (_a = document.querySelector('body')) === null || _a === void 0 ? void 0 : _a.append(wrapper);
    }
};
const currentOpenedToastCloseHandlers = [];
export const toast = utilify(({ props, render, unmount }) => {
    let currentProps = Object.assign(Object.assign({}, props), { open: true });
    const placement = props.placement || ToastPlacement.bottom;
    const isTop = placement === ToastPlacement.top;
    setTopAndBottomToastContainer(isTop ? topId : bottomId, placement);
    function renderToast(_props) {
        currentProps = _props;
        render(_jsx(Toast, Object.assign({}, _props, { isRelative: true, onClose: () => {
                var _a;
                unmount();
                currentOpenedToastCloseHandlers.splice(currentOpenedToastCloseHandlers.indexOf(close), 1);
                (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props);
            } })), { container: isTop ? '#' + topId : '#' + bottomId, unmountOnNavigation: true });
    }
    const update = (props) => renderToast(Object.assign(Object.assign(Object.assign({}, currentProps), { open: true }), props));
    const close = () => renderToast(Object.assign(Object.assign({}, currentProps), { open: false }));
    currentOpenedToastCloseHandlers.push(close);
    // render initially
    renderToast(currentProps);
    // return utilities to update and close it
    return { update, close };
});
toast.success = (props) => {
    return toast(Object.assign(Object.assign({}, props), { variant: Variant.success }));
};
toast.error = (props) => {
    return toast(Object.assign(Object.assign({}, props), { variant: Variant.error }));
};
toast.loading = (props) => {
    return toast(Object.assign(Object.assign({}, props), { variant: Variant.loading }));
};
toast.info = (props) => {
    return toast(Object.assign(Object.assign({}, props), { variant: Variant.info }));
};
toast.plain = (props) => {
    return toast(Object.assign(Object.assign({}, props), { variant: Variant.plain }));
};
toast.closeAll = () => {
    currentOpenedToastCloseHandlers.forEach((handler) => handler());
};
