import { useEffect } from 'react';
const DocumentTitle = ({ title = 'Prophecy.io' }) => {
    //To set the default title while page redirection is happening
    useEffect(() => {
        const prevTitle = document.title;
        document.title = title;
        return () => {
            document.title = prevTitle;
        };
    }, [title]);
    return null;
};
export default DocumentTitle;
