import { ProphecyError } from '@prophecy/utils/error';
import { clearStorage, navigateAndRefresh } from '@prophecy/utils/history';
import queryString from 'query-string';

import { setImpersonatingFlag } from '../../common/auth/utils';
import { CopilotTriggerType } from '../../common/Copilot/types';
import { AirflowConnection, AirflowEnvironment } from '../../common/Fabric/types/airflow';
import { SparkEnvironmentResponse } from '../../common/Fabric/types/spark';
import type {
  JobDeploymentMode,
  JobDeploymentRow,
  ProjectDeployment,
  ProjectDeploymentHistory,
  ProjectRelease,
  ProjectType
} from '../../common/project/types';
import { BranchInfoQueryType, CommitInfo, UncommittedEntityInfo } from '../../common/queries/git';
import { AirflowSpecLanguage, SessionResponse, SpecLanguage } from '../../common/types';
import { Public_Routes } from '../../common/url';
import { UserFabric } from '../../context/query';
import { UserFabricStatus } from '../../context/types';
import { BackEndCodeLanguageType, WorkflowModeType } from '../../graphqlTypes/enums';
import {
  BusinessRule,
  DependencyType,
  Fabric,
  JobSessionAttachmentSource,
  PrimitiveType,
  SColumn
} from '../../redux/types';
import { Schema } from '../../schema/sparkTypes';
import { SQLType } from '../../schema/sqlTypes';
import { Providers } from '../../schema/types';
import {
  APIUrls,
  getAirflowComponentListEndPoint,
  getExecutionServiceEndPoint,
  getFabricEndPoint,
  getFabricSecretUrl,
  getJobLogListUrl,
  getJobRunListUrl,
  getTranspilerEndpoint,
  getValidateDependencyEndPoint,
  getValidatePyPIDependencyEndPoint,
  getWorkflowComponentListEndPoint,
  hostUrl
} from '../../utils/getServerUrl';
import { GlobalWindow } from '../../utils/types';
import { ExecutionStatus } from '../execution.types';
import {
  errorHandler,
  Headers,
  makeDeleteCall,
  makeGetCall,
  makePostCall,
  makeRestCall,
  mockXHRResponse,
  uploadFiles,
  XHRResponseType,
  xhrWrapper,
  _fetch
} from '../util';
import {
  BasicFormType,
  ChangeEntityImportType,
  ComponentInfo,
  ConfigurationInfoType,
  DatasetInfoType,
  ImportResponse,
  InfoCardType,
  JobInfoType,
  PipelineInfoType,
  SourceInfoType,
  TranspilerEntityType,
  UDfInfoType
} from './transpileTypes';
import { AdminConfigType, AuthTypes, DatabrickUserPasswordPayload, ExecutionLog, ProviderType } from './types';

export async function fetchComponentList() {
  return await makeGetCall(getWorkflowComponentListEndPoint());
}

export async function fetchAirflowComponentList() {
  return await makeGetCall(getAirflowComponentListEndPoint());
}

export async function validateDependencyData(data: { artifact: string; repo?: string }) {
  return await makePostCall(getValidateDependencyEndPoint(), data);
}

export async function validateDependencyPypiData(data: { package: string; repo?: string }) {
  return await makePostCall(getValidatePyPIDependencyEndPoint(), data);
}

export function toFabricObject(fabric: UserFabric): Fabric {
  const id = fabric._id;
  let executionUrl = '';
  try {
    const clusterInfo = JSON.parse(fabric.cluster[0].AspectValue);
    executionUrl = clusterInfo.executionUrl || clusterInfo.urls.execution;
  } catch (e) {
    // ignore the error here, will show error only when that fabric is selected.
  }

  const aspects = fabric.aspects.reduce(
    (agg, { AspectName, AspectValue }) => {
      agg[AspectName] = JSON.parse(AspectValue);
      return agg;
    },
    {} as Record<string, object>
  );
  return {
    ...fabric,
    ...aspects,
    id,
    executionUrl
  };
}

export async function getSessionList(
  executionUrl: string,
  fabricId: string,
  data?: {
    tabId: string;
    dependencies?: { dependencies: DependencyType[] };
    jobFabricId?: string;
    jobSize?: string;
    attachmentSource?: JobSessionAttachmentSource;
  }
): XHRResponseType<SessionResponse> {
  return await makePostCall(`${getFabricEndPoint(executionUrl, fabricId)}/listSessions`, data);
}

export async function getJobSessionList(
  executionUrl: string,
  fabricId: string,
  data?: {
    tabId: string;
    dependencies?: { dependencies: DependencyType[] };
    jobFabricId?: string;
    jobSize?: string;
    attachmentSource?: JobSessionAttachmentSource;
  }
): XHRResponseType<SessionResponse> {
  return await makePostCall(`${getFabricEndPoint(executionUrl, fabricId)}/listSessionsForInteractiveJob`, data);
}

export async function handleDatabricksClusterAction(
  executionUrl: string,
  fabricId: string,
  data?: unknown
): XHRResponseType<SessionResponse> {
  return await makePostCall(`${getFabricEndPoint(executionUrl, fabricId)}/databricksOperation`, data);
}

export async function deleteLivySession(executionUrl: string, sessionName: string): XHRResponseType<SessionResponse> {
  return await makeDeleteCall(`${getExecutionServiceEndPoint(executionUrl)}/fabrics/sessions/${sessionName}`, {});
}

export async function getFabricSecrets(executionUrl: string, fabricId: string) {
  return await makeGetCall(getFabricSecretUrl(executionUrl, fabricId));
}

export async function saveFabricSecrets(
  executionUrl: string,
  fabricId: string,
  scope: string,
  username: string,
  password: string
) {
  return await makeRestCall(getFabricSecretUrl(executionUrl, fabricId), {
    method: 'PUT',

    body: JSON.stringify({
      scope,
      value1: { key: 'username', value: username },
      value2: { key: 'password', value: password }
    })
  });
}

export async function createSession({
  executionUrl,
  fabricId,
  poolId,
  clusterId,
  clusterName
}: {
  executionUrl: string;
  fabricId: string;
  poolId?: string;
  clusterId?: string;
  clusterName?: string;
}) {
  let payload: {
    fabricId: string;
    poolId?: string;
    clusterId?: string;
    clusterName?: string;
  } = {
    fabricId,
    poolId,
    clusterId
  };
  if (clusterName) {
    payload.clusterName = clusterName;
  }
  return await makePostCall(`${getFabricEndPoint(executionUrl, fabricId)}/sessions`, payload);
}

export const fetchJobRunList = xhrWrapper(async function fetchJobRunList(
  executionUrl: string,
  fabricId: string,
  payload: unknown
) {
  return makePostCall(getJobRunListUrl(executionUrl, fabricId), payload) as XHRResponseType<
    {
      status: string;
      prophecyJobId: string;
      prophecyJobName: string;
      startDate: number;
      endDate: number;
      databricksRunId?: string;
      databricksJobId?: string;
      airflowDagName?: string;
      airflowDagRunId?: string;
    }[]
  >;
});

export async function fetchJobLogList(executionUrl: string, fabricId: string, payload: unknown) {
  return makePostCall(getJobLogListUrl(executionUrl, fabricId), payload) as XHRResponseType<
    {
      status: ExecutionStatus;
      name: string;
      stepId: string;
      url: string;
      logs: ExecutionLog[];
      id?: string;
      type?: 'pipeline';
    }[]
  >;
}

export const getUpgradeVersions = () => {
  return makeGetCall(`${hostUrl}/athena/api/v1/cluster/availableVersions`);
};

export const upgradeVersion = async function upgradeVersion(body: unknown) {
  return makePostCall(`${hostUrl}/api/cluster/oneupgrade`, body);
};

export async function getAllIdpConfig() {
  return await makeGetCall(APIUrls.GET_ALL_IDP_CONFIG.url);
}

export async function getSSOConfig(identifier: string) {
  return await makePostCall(hostUrl + '/api/sso/getSSOInfoForUser', { identifier });
}

export async function getAllIdpTypes() {
  return await makeGetCall(`${hostUrl}/api/idp/getAllIDPsTypes`);
}

export async function setIdpConfig(payload: unknown) {
  return await makePostCall(APIUrls.SET_IDP_CONFIG.url, payload);
}

export async function setConfigureSSO(payload: unknown) {
  return await makePostCall(hostUrl + '/api/sso/setOrgForSSO', payload);
}

export async function setSSO(payload: unknown) {
  return await makePostCall(hostUrl + '/api/sso/setSSOInfo', payload);
}

export async function getAdminAllSandboxConfiguration() {
  return await makeGetCall(`${hostUrl}/api/feature/getConfig`);
}

export async function getSandboxUsageDetails() {
  return await makeGetCall(`${hostUrl}/athena/api/v1/sandbox/usage`);
}

export async function getSandboxDetail() {
  return await makeGetCall(`${hostUrl}/athena/api/v1/sandbox`);
}

export async function setAdminSandboxConfiguration({ configType, value }: AdminConfigType) {
  return await makePostCall(`${hostUrl}/api/feature/setConfig?configType=${configType}`, value);
}

export const formatExpression = async (payload: { code: string; language: BackEndCodeLanguageType }) => {
  return (await makePostCall(hostUrl + '/api/workflow/formatCode', payload)) as { status: boolean; code: string };
};

export async function authInternalGit(url: string) {
  return makePostCall(url, {});
}

export async function getGlobalConf(url: string) {
  return makeGetCall(hostUrl + '/api' + url);
}

export const getProphecyManagedFabricData = xhrWrapper(async () => {
  return makeGetCall(hostUrl + '/api/fabric/setup/default');
});

export const clonePipeline = async (payload: {
  sourceProjectUID: string;
  sourcePipelinePath: string;
  targetProjectUID: string;
  targetPipelineName: string;
  targetPackageName: string;
  targetBranch: string;
  targetConfigPackageName?: string;
  isConfigured?: boolean;
}): XHRResponseType<string> => {
  return makePostCall(hostUrl + `/api/editor/pipeline/clone`, payload);
};

export const rebuildCode = async (payload: {
  branch: string;
  projectId: string;
  pipelineIds?: string[];
}): XHRResponseType<{}> => {
  return makePostCall(hostUrl + `/api/editor/rebuildCode`, payload);
};

export async function getPlibsVersions() {
  return makeGetCall(hostUrl + '/api/editor/plibVersions') as XHRResponseType<{
    plibMavenVersions: string[];
    plibPypiVersions: string[];
    releaseVersions: { [key: string]: string };
  }>;
}
export const rebuildJobCode = async (payload: { branch: string; projectId: string }): XHRResponseType<{}> => {
  return makePostCall(hostUrl + `/api/editor/jobs/rebuildCode`, { ...payload, jobIds: [] });
};

export const deleteGem = xhrWrapper(
  (payload: {
    name: string;
    language: SpecLanguage | AirflowSpecLanguage;
    mode?: WorkflowModeType;
    category: string;
  }) => {
    return makePostCall(APIUrls.DELETE_GEM.url, payload);
  }
);

export const fetchFabricCatalogs = async (
  payload: unknown,
  executionServiceUrl: string
): XHRResponseType<{ version: string }> => {
  return makePostCall(`${getExecutionServiceEndPoint(executionServiceUrl)}/fabric/listCatalog`, payload);
};

export const fetchFabricCatalogSchema = async (
  payload: unknown,
  executionServiceUrl: string
): XHRResponseType<{ version: string }> => {
  return makePostCall(`${getExecutionServiceEndPoint(executionServiceUrl)}/fabric/listSchema`, payload);
};

export const fetchFabricCatalogSchemaTableData = async (
  payload: unknown,
  executionServiceUrl: string
): XHRResponseType<{ version: string }> => {
  return makePostCall(`${getExecutionServiceEndPoint(executionServiceUrl)}/fabric/getTable`, payload);
};

export const fetchFabricCatalogSchemaTable = async (
  payload: unknown,
  executionServiceUrl: string
): XHRResponseType<{ version: string }> => {
  return makePostCall(`${getExecutionServiceEndPoint(executionServiceUrl)}/fabric/listTable`, payload);
};

export async function testDatabricksConnection(url: string, token?: string, fabricId?: string) {
  let params;
  if (token) {
    params = {
      url: url,
      token: token as string
    };
  } else {
    params = {
      url: url,
      fabricId: fabricId as string
    };
  }
  return makePostCall(`${hostUrl}/api/fabric/databricks/type`, params);
}

export const testAirflowConnection = async (
  payload: unknown,
  executionServiceUrl: string
): XHRResponseType<{ version: string }> => {
  return makePostCall(`${getExecutionServiceEndPoint(executionServiceUrl)}/testConnection/airflow`, payload);
};

export const fetchAirflowEnvironments = async (
  payload: unknown,
  executionServiceUrl: string
): XHRResponseType<AirflowEnvironment[]> => {
  return makePostCall(`${getExecutionServiceEndPoint(executionServiceUrl)}/fetchEnvironments`, payload);
};

export const fetchSparkEnvironments = async (
  payload: unknown,
  executionServiceUrl: string
): XHRResponseType<SparkEnvironmentResponse> => {
  return makePostCall(`${getExecutionServiceEndPoint(executionServiceUrl)}/fetchEnvironments/spark`, payload);
};

export const fetchExternalAirflowConnections = async (
  payload: unknown,
  executionServiceUrl: string
): XHRResponseType<{ connections: AirflowConnection[] }> => {
  return makePostCall(`${getExecutionServiceEndPoint(executionServiceUrl)}/fetchConnections/airflow`, payload);
};

export const fetchProjectReleases = async (
  executionUrl: string,
  projectId: string
): XHRResponseType<ProjectRelease[]> => {
  return makeGetCall(`${getExecutionServiceEndPoint(executionUrl)}/release/project/${projectId}/listReleases`);
};

export const fetchProjectRelease = async (
  executionUrl: string,
  {
    projectId,
    releaseTag
  }: {
    projectId: string;
    releaseTag: string;
  }
): XHRResponseType<ProjectRelease> => {
  return makeGetCall(
    `${getExecutionServiceEndPoint(executionUrl)}/release/project/${projectId}/releaseDetail?releaseTag=${releaseTag}`
  );
};

export const fetchProjectDeployments = async (
  executionUrl: string,
  projectId: string,
  fabricId: string
): XHRResponseType<ProjectDeployment[]> => {
  return makeGetCall(
    `${getExecutionServiceEndPoint(executionUrl)}/release/project/${projectId}/fabric/${fabricId}/listDeployments`
  );
};

export const fetchProjectDeloymentHistory = async (
  executionUrl: string,
  projectId: string
): XHRResponseType<ProjectDeploymentHistory[]> => {
  return makeGetCall(`${getExecutionServiceEndPoint(executionUrl)}/release/project/${projectId}/listDeployments`);
};

export const fetchJobDeploymentInfo = async (
  executionUrl: string,
  projectId: string,
  releaseTag: string,
  fabricId?: string
): XHRResponseType<JobDeploymentRow[]> => {
  return makeGetCall(
    `${getExecutionServiceEndPoint(executionUrl)}/release/project/${projectId}${
      fabricId ? `/fabric/${fabricId}` : ''
    }/jobsToDeploy?releaseTag=${releaseTag}`
  );
};

export const fetchJobDeploymentId = async (
  executionUrl: string,
  payload: {
    project_id: string;
    release_tag: string;
    mode: JobDeploymentMode;
    jobs_and_fabric?: Array<{ job_id: string; fabric_id: string }>;
  }
): XHRResponseType<{ deployment_id: string }> => {
  return makePostCall(`${getExecutionServiceEndPoint(executionUrl)}/release/project/deploy`, payload);
};

export const migrateEntityFabric = async (url: string, fabricId?: string): XHRResponseType<never> => {
  const serviceUrl = getExecutionServiceEndPoint(url);
  return makeGetCall(`${serviceUrl}/fabrics${fabricId ? `/${fabricId}` : ''}/info`);
};

export async function fetchPackagesList() {
  return makeGetCall(getTranspilerEndpoint() + '/list') as XHRResponseType<ImportResponse>;
}

export async function getImportChanges(transpilerId: string) {
  const url = getTranspilerEndpoint() + '/' + transpilerId + '/getEntitiesToBeImported';
  return makeGetCall(url) as XHRResponseType<ChangeEntityImportType[]>;
}

export async function getPipelinePsets(transpilerId: string, path: string) {
  const url = `${getTranspilerEndpoint()}/${transpilerId}/dependentPsets`;
  return makePostCall(url, { path }) as XHRResponseType<{ data: string[] }>;
}

export async function getBasicFormDataChanges(transpilerId: string) {
  const url = getTranspilerEndpoint() + '/' + transpilerId + '/state';
  return makeGetCall(url) as XHRResponseType<{
    createTranspilerImportData: BasicFormType;
    entitiesData: TranspilerEntityType & { source?: string };
  }>;
}

export async function getInfoCardData(transpilerId: string, isBulk?: boolean) {
  const url = getTranspilerEndpoint() + '/' + transpilerId + '/info';
  return makeGetCall(isBulk ? url + '/v2' : url) as XHRResponseType<InfoCardType>;
}

export async function getAllTranspiledData(transpilerId: string, isBulk?: boolean) {
  // need isBulk flag here
  const url = getTranspilerEndpoint() + '/' + transpilerId + '/info/all';
  return makeGetCall(isBulk ? url : url + '/v1') as XHRResponseType<{
    entities: SourceInfoType[];
  }>;
}

export async function getTranspiledPipelinesData(transpilerId: string, isBulk?: boolean) {
  const url = getTranspilerEndpoint() + '/' + transpilerId + '/info/pipelines';
  return makeGetCall(isBulk ? url : url + '/v1') as XHRResponseType<{ pipelines: PipelineInfoType[] }>;
}

export async function getTranspiledModelsData(transpilerId: string, isBulk?: boolean) {
  const url = getTranspilerEndpoint() + '/' + transpilerId + '/info/models';
  return makeGetCall(isBulk ? url : url + '/v1') as XHRResponseType<{ models: PipelineInfoType[] }>;
}

export async function getTranspiledJobsData(transpilerId: string, isBulk?: boolean) {
  const url = getTranspilerEndpoint() + '/' + transpilerId + '/info/jobs';
  return makeGetCall(isBulk ? url : url + '/v1') as XHRResponseType<{ jobs: JobInfoType[] }>;
}

export async function getTranspiledConfigsData(transpilerId: string, isBulk?: boolean) {
  const url = getTranspilerEndpoint() + '/' + transpilerId + '/info/configuration';
  return makeGetCall(isBulk ? url : url + '/v1') as XHRResponseType<{ configurations: ConfigurationInfoType[] }>;
}

export async function getUdfsData(transpilerId: string) {
  const url = getTranspilerEndpoint() + '/' + transpilerId + '/info/udfs';
  return makeGetCall(url) as XHRResponseType<{ udfs: UDfInfoType[] }>;
}

export async function getTranspiledDatasetsData(transpilerId: string, isBulk?: boolean) {
  const url = getTranspilerEndpoint() + '/' + transpilerId + '/info/datasets';
  return makeGetCall(isBulk ? url : url + '/v1') as XHRResponseType<{ datasets: DatasetInfoType[] }>;
}

export async function fetchTranspilerPaths(transpilerId: string) {
  return makeGetCall(getTranspilerEndpoint() + '/' + transpilerId + '/uploadedFilePaths') as XHRResponseType<string[]>;
}

export async function fetchTranspilerComponentInfo(
  transpilerId: string,
  componentId: string,
  pipelineName: string,
  isBulk?: boolean
) {
  const url = isBulk
    ? `${getTranspilerEndpoint()}/${transpilerId}/pipeline/${pipelineName}/component/${componentId}/info/v2`
    : `${getTranspilerEndpoint()}/${transpilerId}/component/${componentId}/info`;
  return makeGetCall(url) as XHRResponseType<ComponentInfo>;
}

export async function deleteTranspilerFile(transpilerId: string, isBulk?: boolean) {
  const url = `${getTranspilerEndpoint()}/${transpilerId}/delete`;
  return makePostCall(isBulk ? url : `${url}/v1`, {}) as XHRResponseType<unknown>;
}

export async function deleteUploadedFile(transpilerId: string, filePath: string) {
  return makePostCall(`${getTranspilerEndpoint()}/${transpilerId}/file/delete`, {
    filePath
  }) as XHRResponseType<unknown>;
}

export async function moveToFolder(id: string, body: { currentFilePath: string; targetFilePath: string }) {
  return makePostCall(`${getTranspilerEndpoint()}/${id}/file/move`, body) as XHRResponseType<{
    transpilerId: string;
  }>;
}

export async function getSourceEntities(id: string, body: { filePath: string; transpilerType: string }) {
  return makePostCall(
    `${getTranspilerEndpoint()}/${id}/fetchUnderlyingEntities`,
    body
  ) as XHRResponseType<TranspilerEntityType>;
}

export async function setTranspilerBasicData(body: BasicFormType, transpilerId?: string) {
  return makePostCall(
    transpilerId
      ? `${getTranspilerEndpoint()}/${transpilerId}/updateBasicDetails`
      : `${getTranspilerEndpoint()}/create`,
    { ...body, transpilerId }
  ) as XHRResponseType<{ success: boolean; transpilerId?: string }>;
}

export async function importData(id: string, body: ChangeEntityImportType[]) {
  return makePostCall(`${getTranspilerEndpoint()}/${id}/importEntities`, body) as XHRResponseType<{
    success: boolean;
  }>;
}

export async function uploadFilesToTranspile(id: string, data: unknown, callback: (percentage: number) => void) {
  return uploadFiles(
    getTranspilerEndpoint() + '/' + id + '/file/upload',
    'POST',
    data as XMLHttpRequestBodyInit,
    callback
  ) as XHRResponseType<string[]>;
}

export async function uploadSqlFiles(
  executionUrl: string,
  id: string,
  data: unknown,
  callback: (percentage: number) => void
) {
  return uploadFiles(
    `${getExecutionServiceEndPoint(executionUrl)}/sql/upload/${id}`,
    'POST',
    data as XMLHttpRequestBodyInit,
    callback
  ) as XHRResponseType<string[]>;
}

export async function generateJob(id: string, data: unknown) {
  return makePostCall(
    `${getTranspilerEndpoint()}/${id}/getReferencedEntities`,
    data
  ) as XHRResponseType<TranspilerEntityType>;
}

export async function generatePsetContent(id: string, filePath: string) {
  return makePostCall(`${getTranspilerEndpoint()}/${id}/file/content`, { filePath }) as XHRResponseType<{
    content: string;
  }>;
}

export async function getAboutInfo() {
  return makeGetCall(hostUrl + '/athena/api/v1/service/status');
}

export async function getMonitoringInfo() {
  return makeGetCall(`${hostUrl}/api/service/monitoring`);
}

export async function getFeatureConfig(configType: string) {
  return makeGetCall(`${hostUrl}/api/feature/getConfig?configType=${configType}`);
}

export async function getMonitoringAlertInfo() {
  return makeGetCall(`${hostUrl}/api/alert/status`);
}

export async function inviteUser(body: unknown) {
  return await makePostCall(`${hostUrl}/api/auth/inviteUsertoTeam`, body);
}

export async function getUnSecureGlobalConfig() {
  return makeGetCall(hostUrl + '/api/unsecuredGlobalConf');
}

const logout = xhrWrapper(async function logout() {
  return makePostCall(hostUrl + '/api/logout', {});
});
export const login = xhrWrapper(async function login(body: {
  identifier: string;
  password: string;
  loginType: string;
}) {
  try {
    const data = (await makePostCall(hostUrl + '/api/authenticate', body)) as {
      token?: string;
    };
    return { data, message: 'Login successful', success: true };
  } catch (err) {
    const error = new ProphecyError('Unable to Authenticate. Check Email/Password again');
    error.stack = undefined;
    throw error;
  }
});

export const getUserType = xhrWrapper(async function getUserType(body: { identifier: string }) {
  try {
    const data = (await makePostCall(hostUrl + '/api/getUserManagedType', body)) as {
      success: boolean;
      type: AuthTypes;
      idp: ProviderType;
    };
    return { data, message: 'User type found.', success: true };
  } catch (err) {
    const error = new ProphecyError('Unable to Authenticate. Check Email again');
    error.stack = undefined;
    throw error;
  }
});

export const signUp = xhrWrapper(async function signUp(body: {
  identifier: string;
  firstName: string;
  lastName?: string;
  companyname: string;
}) {
  return makePostCall(hostUrl + '/api/auth/createAccount', body);
});
export const forgotPassword = xhrWrapper(async function forgotPassword(body: { identifier: string }) {
  return makePostCall(hostUrl + '/api/auth/forgotPassword', body);
});
export const resendCode = xhrWrapper(async function resendCode(body: { identifier: string }) {
  return makePostCall(hostUrl + '/api/auth/resendOTP', body);
});
export const verifyOTP = xhrWrapper(async function verifyOTP(body: {
  identifier: string;
  otp: string;
  invitation: boolean;
}) {
  return makePostCall(hostUrl + '/api/auth/verifyOTP', body);
});
export const setNewPassword = xhrWrapper(async function setNewPassword(body: {
  identifier: string;
  password: string;
  teamId?: string;
}) {
  return makePostCall(hostUrl + '/api/auth/setNewPassword', body);
});

export async function oAuthAzureADAuthenticate(body: unknown) {
  return makePostCall(hostUrl + '/api/oAuthAzureADAuthenticate', body);
}

export async function oAuthSamlAuthenticate(body: unknown) {
  return makePostCall(hostUrl + '/api/oAuthSamlAuthenticate', body);
}

export const oAuthGithubAuthenticate = xhrWrapper(async () => {
  return makePostCall(hostUrl + '/api/oAuthGithubAuthenticate', {});
});

export const getGithubOAuthInfo = xhrWrapper(async (params: queryString.StringifiableRecord) => {
  return makeGetCall(
    queryString.stringifyUrl({ url: hostUrl + '/api/githubUserInfo', query: params }, { encode: false })
  );
});

export const githubEnterpriseGenerateToken = xhrWrapper(async (host: string) => {
  return makePostCall(hostUrl + '/api/githubEnterprisePATAction?host=' + host, {});
});

export async function oAuthGoogleAuthenticate(body: unknown) {
  return makePostCall(hostUrl + '/api/oAuthGoogleAuthenticate', body);
}

export const setNewPasswordForDBUser = xhrWrapper(async function setNewPasswordForDBUser(
  body: DatabrickUserPasswordPayload,
  headers: Headers
) {
  return makePostCall(hostUrl + '/api/external/setNewPasswordForDBUser', body, headers);
});

export async function setNewEmail(body: unknown, isResend?: boolean) {
  const uri = isResend ? '/api/auth/changeEmailResendOTP' : '/api/auth/changeEmail';
  return makePostCall(hostUrl + uri, body);
}

export async function sendVerifiedOTP(body: unknown) {
  return makePostCall(hostUrl + '/api/auth/changeEmailVerifyOTP ', body);
}

export async function generateAuthToken(body: unknown) {
  return makePostCall(hostUrl + '/api/auth/generateSupportTokenForUser', body);
}

export async function changeUserPasswordAsAdmin(body: { userId: string; password: string }) {
  return makePostCall(hostUrl + '/api/auth/setPasswordAsAdmin', body);
}

export async function authenticateUsingToken(body: unknown) {
  return makePostCall(hostUrl + '/api/auth/loginUsingToken', body);
}

export async function autoGenerateProjectFiles(body: {
  projectId: string;
  branch: string;
  generateProjectBuildFiles: boolean;
  generateFunctionFiles: boolean;
}) {
  return makePostCall(hostUrl + '/api/editor/generateAutogeneratedFiles', body);
}

export async function upgradePlan() {
  return makePostCall(hostUrl + '/api/metering/startBilling', {});
}

export async function completeLogoutAndReloadPage(isAccountDelete = false) {
  try {
    if (!isAccountDelete) {
      await logout();
    }
    setImpersonatingFlag(false);
    navigateAndRefresh(Public_Routes.SignIn.getUrl(undefined, { redirect: '' }));
    clearStorage();
    const globalWindow = window as unknown as GlobalWindow;
    globalWindow.FreshworksWidget('logout');
  } catch (error) {
    errorHandler(error);
  }
}

export async function getAppSession<T>() {
  return (await makeGetCall(hostUrl + '/api/get-session')) as T;
}

export const acceptCookies = xhrWrapper(async function acceptCookies() {
  return makePostCall(`${hostUrl}/api/set-prophecy-cookies`, undefined);
});

export async function getUser<T>() {
  return (await makeGetCall(hostUrl + '/api/get-user')) as T;
}

export async function getUserFabricStatus(executionServiceUrl: string) {
  return (await makeGetCall(`${getExecutionServiceEndPoint(executionServiceUrl)}/user/fabric/status`)) as {
    fabric: UserFabricStatus;
  };
}

export async function getFreshDeskToken() {
  return (await makeGetCall(hostUrl + '/api/freskdesk/token')) as { token: string };
}

export async function getAthenaTrial<T>() {
  return (await makeGetCall(hostUrl + '/athena/api/v1/trial')) as T;
}

export async function getAthenaServiceStatus<T>() {
  return (await makeGetCall(hostUrl + '/athena/api/v1/prophecy/status')) as T;
}

export async function getKeepAliveSession<T>() {
  return (await makeRestCall(hostUrl + '/api/keep-alive')) as T;
}

export async function downloadMixpanelData() {
  return await makeGetCall(hostUrl + '/api/marketingDataDump');
}

export async function createUserToken(body: { name: string; expires_at: number }) {
  return await makePostCall(hostUrl + '/api/create-user-token', body);
}

export async function getUserTokens() {
  return await makeGetCall(hostUrl + '/api/get-user-tokens');
}

export async function deleteUserToken(body: { _id: string }) {
  return await makeDeleteCall(hostUrl + `/api/delete-user-token`, body);
}

export async function regenerateSQLEditor(body: { projectId: string; branch: string }) {
  return await makePostCall(hostUrl + '/api/editor/sqlEditor/regenerate', body);
}

export async function generateProjectDescription(body: {
  projectId: string;
  projectType: ProjectType;
  triggerType: CopilotTriggerType;
}): Promise<{ description: string }> {
  return await makePostCall(hostUrl + '/api/copilot/describe/project', body);
}
export async function getCodeInlineSuggestions(body: {
  files: Record<string, string>;
  currentFilePath: string;
  cursorPosition: {
    line_number: number;
    column_number: number;
  };
  userPrompt?: string;
  projectId: string;
  providerType: string;
}) {
  return await makePostCall(hostUrl + '/api/copilot/autocomplete/sql', body);
}

export async function generateCommitMessage(body: {
  gitDiff: string;
  gitLog: CommitInfo[];
  modifiedFiles: string[];
  modifiedEntities: UncommittedEntityInfo[];
  userPrompt?: string;
}): Promise<{ messages: { commitMessage: string; confidence: number }[] }> {
  return await makePostCall(hostUrl + '/api/copilot/git/commitMessage', body);
}
type DescriptionItem = { column: string; description: string };
type SqlTableSourceType = {
  tableName: string;
  datasourceSchema: {
    type: string;
    schema: SQLType;
    interimData?: {
      data: PrimitiveType[][];
    };
  };
  projectId: string;
  projectType: 'SQL';
  providerType: Providers;
  projectLanguage: BackEndCodeLanguageType;
};
type SqlColumnSourceType = SqlTableSourceType & {
  columnNames: string[];
};
type SparkTableSourceType = {
  tableName: string;
  datasourceSchema: {
    schema: Schema;
    interimData?: {
      data: PrimitiveType[][];
    };
  };
  projectId: string;
  projectType: 'Spark';
  projectLanguage: BackEndCodeLanguageType;
};
type SparkColumnSourceType = SparkTableSourceType & {
  columnNames: string[];
};
export async function getColumnDescription(body: { sources: (SparkColumnSourceType | SqlColumnSourceType)[] }) {
  const { data } = await (makePostCall(hostUrl + '/api/copilot/describe/column', body) as XHRResponseType<
    Record<string, { descriptions: DescriptionItem[] }>
  >);

  return Object.keys(data).reduce(
    (acc1, key) => {
      acc1[key] = data[key].descriptions.reduce(
        (acc, item) => {
          acc[item.column] = item;
          return acc;
        },
        {} as Record<string, DescriptionItem>
      );
      return acc1;
    },
    {} as Record<string, Record<string, DescriptionItem>>
  );
}

export async function getDataSourceDescription(body: { sources: (SparkTableSourceType | SqlTableSourceType)[] }) {
  const response = await (makePostCall(hostUrl + '/api/copilot/describe/datasource', body) as XHRResponseType<
    Record<string, { description: string }>
  >);
  const data = response.data;
  return data;
}
export async function parseFuncCall(body: { expressions: SColumn[] }) {
  return await makePostCall(hostUrl + '/api/expressions/parseFuncCall', body);
}

export async function getBusinessRuleExpression(body: { rule: BusinessRule; language: BackEndCodeLanguageType }) {
  return await makePostCall(hostUrl + '/api/expressions/getBusinessRuleExpression', body);
}

export async function generateReleaseMessage(body: {
  releaseVersion?: string;
  projectId: string;
  branch: string;
  userPrompt?: string;
  userId: string;
  commits: BranchInfoQueryType['BranchInfo']['localCommitInfo'];
}): Promise<{ messages: { releaseMessage: string; confidence: number }[] }> {
  return await makePostCall(hostUrl + '/api/copilot/git/releaseMessage', body);
}

export async function addGem(payload: {
  name: string;
  language: SpecLanguage;
  category: string;
  mode?: WorkflowModeType;
}) {
  return await makePostCall(hostUrl + '/api/editor/addComponent', payload);
}

export async function cloneJob(payload: {
  jobURI: string;
  targetProjectUID: string;
  targetJobName: string;
  targetBranch: string;
}): XHRResponseType<string> {
  return await makePostCall(`${hostUrl}/api/editor/job/clone`, payload);
}

export async function getServiceLogs() {
  const response = await makeGetCall(`${hostUrl}/api/logs`);

  return mockXHRResponse<{
    availableLogs: string[];
    beginTimestamp: string;
    endTimestamp: string;
  }>(response);
}

export async function downloadLogs(payload: { downloadStartTime: string; services: string[] }): Promise<Response> {
  return await _fetch(`${hostUrl}/api/logs/download`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json'
    }
  });
}
