import { useSelector } from 'react-redux';

import { getCurrentProcessGraphPath, getCurrentProcessPath, getGraphProperty } from '../Parser/bindings';
import {
  BaseProcess,
  BaseProcessMetadata,
  BaseState,
  Connection as GraphConnection,
  GenericGraph,
  Metadata
} from './types';

export const useOriginalGraph = <
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  S extends BaseState<G, Metadata>
>() => {
  const rootGraph = useSelector<S, G>((state) => state.$graph as G);
  const rootPath = useSelector<S, string>((state) => state.root);
  const graphPath = useSelector<S, string>((state) => state.graphPath);
  return getCurrentGraph(rootGraph, rootPath, graphPath);
};

export function useCurrentGraph<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  S extends BaseState<G, Metadata>
>() {
  const rootGraph = useSelector<S, G>((state) => state.currentGraph as G);
  const componentId = useSelector<S, string | undefined>((state) => state.currentComponentId);
  const rootPath = useSelector<S, string>((state) => state.root);
  const graphPath = useSelector<S, string>((state) => state.graphPath);
  return {
    ...getCurrentGraph(rootGraph, rootPath, graphPath),
    componentId
  };
}

export function useCurrentGraphPath<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  S extends BaseState<G, Metadata>
>() {
  const componentId = useSelector<S, string | undefined>((state) => state.currentComponentId);
  const rootPath = useSelector<S, string>((state) => state.root);
  const graphPath = useSelector<S, string>((state) => state.graphPath);
  return {
    rootPath,
    graphPath,
    componentId
  };
}

export function getCurrentGraph<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(rootGraph: G, rootPath: string, graphPath: string) {
  const graph = rootPath !== graphPath ? (getGraphProperty(rootGraph, graphPath, rootPath) as G) : rootGraph;

  return { rootGraph, rootPath, graph, graphPath };
}

export function useCurrentDialogGraph<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  S extends BaseState<G, Metadata>
>() {
  const rootGraph = useSelector<S, G>((state) => {
    return state.currentGraph as G;
  });
  const rootPath = useSelector<S, string>((state) => state.root);
  const graphPath = useSelector<S, string>((state) => state.graphPath);
  const processId = useSelector<S, string>((state) => state.currentComponentId as string);
  const currentProcessGraphPath = getCurrentProcessGraphPath(processId, graphPath);

  const { graph } = getCurrentGraph(rootGraph, rootPath, currentProcessGraphPath);

  return { rootGraph, rootPath, graph, graphPath: currentProcessGraphPath, processId };
}

export function useDialogProcessPath<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>,
  S extends BaseState<G, Metadata>
>() {
  const graphPath = useSelector<S, string>((state) => state.graphPath as string);
  const processId = useSelector<S, string>((state) => state.currentComponentId as string);
  const processPath = getCurrentProcessPath(processId, graphPath);
  return processPath;
}
