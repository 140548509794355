import { createContext, useContext } from 'react';

import { EditMode } from '../types';

const IDEModeContext = createContext<IDE_MODE_TYPE>(EditMode.disabled);

export type IDE_MODE_TYPE = EditMode.disabled | EditMode.enabled;

export function IDEModeProvider({ value, children }: { value: IDE_MODE_TYPE; children: React.ReactNode }) {
  return <IDEModeContext.Provider value={value}>{children}</IDEModeContext.Provider>;
}
export function useIDEMode() {
  return useContext(IDEModeContext);
}
