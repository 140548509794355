import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
export function useAutoScrollToBottom(container, dependencies) {
    const [scrollHeight, setScrollHeight] = useState();
    useEffect(() => {
        const element = container.current;
        if (!element)
            return;
        const { scrollHeight: newScrollHeight, clientHeight, scrollTop } = element;
        setScrollHeight(newScrollHeight);
        // on the fist time scrollHeight wouldn't be set so use previous
        const prevScrollHeight = scrollHeight;
        // when scrollHeight is not set, ie when component mounts, scroll to bottom
        if (prevScrollHeight === undefined) {
            element.scrollTop = newScrollHeight;
            return;
        }
        // check if not manually scrolled, scroll to bottom
        const manualScrollThreshold = 50;
        const isManuallyScrolled = scrollTop <= prevScrollHeight - clientHeight - manualScrollThreshold;
        if (scrollHeight !== newScrollHeight && !isManuallyScrolled) {
            element.scrollTo({
                top: element.scrollHeight,
                behavior: 'smooth'
            });
        }
        // Note: We intentionally don't want to execute effect on scrollHeight change, as its set from this effect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container.current, ...dependencies]);
}
export function AutoScrollToBottom(_a) {
    var { dependencies } = _a, props = __rest(_a, ["dependencies"]);
    const container = useRef(null);
    useAutoScrollToBottom(container, dependencies);
    return _jsx("div", Object.assign({ ref: container }, props));
}
const HandleContainer = styled.div `
  position: sticky;
  bottom: 0;
  transition: opacity ease 300ms;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'all' : 'none')};
`;
export function ScrollToBottom({ children, margin = 50, container }) {
    const [showHandle, toggleHandle] = useState(false);
    const observerElm = useRef(null);
    const getContainer = useCallback(() => { var _a; return container || ((_a = observerElm.current) === null || _a === void 0 ? void 0 : _a.parentNode); }, [container]);
    const scrollToBottom = () => {
        const containerElm = getContainer();
        if (!containerElm)
            return;
        containerElm.scrollTo({
            top: containerElm.scrollHeight,
            behavior: 'smooth'
        });
    };
    useEffect(() => {
        if (!observerElm.current)
            return;
        const root = getContainer();
        let options = {
            root,
            rootMargin: `${margin}px`,
            threshold: 1.0
        };
        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    toggleHandle(false);
                }
                else {
                    toggleHandle(true);
                }
            });
        }, options);
        observer.observe(observerElm.current);
        return () => {
            observer.disconnect();
        };
    }, [getContainer, margin]);
    return (_jsxs(_Fragment, { children: [_jsx(HandleContainer, { visible: showHandle, onClick: scrollToBottom, children: children }), _jsx("div", { ref: observerElm })] }));
}
