import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import { parseCronQuartz, parseStringQuartz, parseCronUnix, parseStringUnix } from '@vangware/cron';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Stack } from '../Layout';
import { TabsRoot, TabsList, TabsTrigger, TabsContent } from '../Tabs';
import { DayTab } from './DayTab';
import { HourTab } from './HourTab';
import { MinuteTab } from './MinuteTab';
import { MonthTab } from './MonthTab';
import { CronSelectDropdownStyles, StyledCronGenerator } from './styled';
import { tokens } from './tokens';
import { CronCategory, CronType } from './types';
import { getCronDefaults } from './utils';
import { WeekTab } from './WeekTab';
import { YearTab } from './YearTab';
const getCurrentCategory = (cronValue) => {
    if (cronValue.month !== '*') {
        return CronCategory.Yearly;
    }
    else if (cronValue.dayOfMonth !== '*' && cronValue.dayOfMonth !== '?') {
        return CronCategory.Monthly;
    }
    else if (cronValue.dayOfWeek !== '*' && cronValue.dayOfWeek !== '?') {
        return CronCategory.Weekly;
    }
    else if (cronValue.hours !== '*' && cronValue.hours.hasOwnProperty('every')) {
        return CronCategory.Hourly;
    }
    else if (cronValue.hours !== '*') {
        return CronCategory.Daily;
    }
    else if (cronValue.minutes !== '*') {
        return CronCategory.Minute;
    }
    return CronCategory.Minute;
};
const CategoryCronDefaults = {
    [CronCategory.Minute]: () => ({
        minutes: { every: 1, start: 0 }
    }),
    [CronCategory.Hourly]: () => ({
        hours: { every: 1, start: 0 },
        minutes: 0
    }),
    [CronCategory.Daily]: () => ({
        hours: 0,
        minutes: 0
    }),
    [CronCategory.Weekly]: (cronType) => ({
        hours: 0,
        minutes: 0,
        dayOfWeek: '*',
        dayOfMonth: cronType === CronType.quartz ? '?' : '*'
    }),
    [CronCategory.Monthly]: () => ({
        hours: 0,
        minutes: 0
    }),
    [CronCategory.Yearly]: () => ({
        hours: 0,
        minutes: 0
    })
};
const CategoryTabs = {
    [CronCategory.Minute]: MinuteTab,
    [CronCategory.Hourly]: HourTab,
    [CronCategory.Daily]: DayTab,
    [CronCategory.Weekly]: WeekTab,
    [CronCategory.Monthly]: MonthTab,
    [CronCategory.Yearly]: YearTab
};
const CategoryList = Object.values(CronCategory);
const StyledTabList = styled(TabsList) `
  padding: 0;
`;
const StyledTabTrigger = styled(TabsTrigger) `
  background-color: ${tokens.CronGenerator.Category.backgroundColor};
`;
export function CronGenerator({ value, cronType = CronType.quartz, onChange, min, max }) {
    var _a;
    const convertStringToCron = (cronType === CronType.quartz ? parseStringQuartz : parseStringUnix);
    const convertCronToString = (cronType === CronType.quartz ? parseCronQuartz : parseCronUnix);
    const categoryList = useMemo(() => {
        const minIndex = min ? CategoryList.indexOf(min) : 0;
        const maxIndex = max ? CategoryList.indexOf(max) + 1 : CategoryList.length;
        return CategoryList.slice(minIndex, maxIndex);
    }, [min, max]);
    const [cronValue, setCronValue] = useControlledState({
        value: convertStringToCron(value),
        onChange: (newCronValue) => {
            onChange(convertCronToString(newCronValue));
        }
    });
    const [category, setCategory] = useState(() => cronValue ? getCurrentCategory(cronValue) : categoryList[0]);
    const cronDefaults = getCronDefaults(cronType);
    // if cron generator is not able to parse the value, set it to minute tab and rerender
    if (!cronValue) {
        setCronValue(Object.assign(Object.assign({}, cronDefaults), (_a = CategoryCronDefaults[category]) === null || _a === void 0 ? void 0 : _a.call(CategoryCronDefaults, cronType)));
        return null;
    }
    const onCategoryChange = (tab) => {
        setCategory(tab);
        setCronValue(Object.assign(Object.assign({}, cronDefaults), CategoryCronDefaults[tab](cronType)));
    };
    const CategoryTab = CategoryTabs[category];
    return (_jsxs(StyledCronGenerator, { gap: tokens.CronGenerator.gap, children: [_jsxs(TabsRoot, { value: category, onValueChange: onCategoryChange, children: [_jsx(StyledTabList, { children: _jsx(Stack, { direction: 'horizontal', gap: tokens.CronGenerator.Category.gap, children: categoryList.map((currentCategory) => (_jsx(StyledTabTrigger, { value: currentCategory, children: currentCategory }, currentCategory))) }) }), _jsx(TabsContent, { value: category, children: _jsx(CategoryTab, { cronValue: cronValue, setCronValue: setCronValue, cronType: cronType }) })] }), _jsx(CronSelectDropdownStyles, {})] }));
}
