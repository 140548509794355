import { theme } from '../theme';
import { hexToRGBA } from '../theme/utils';
export const tokens = {
    Spinner: {
        Overlay: {
            backgroundColor: hexToRGBA(theme.colors.white, 0.2),
            zIndex: theme.zLayer.s
        },
        Tip: {
            fontSize: theme.fontSizes.x14
        },
        gap: theme.spaces.x8
    },
    GlobalSpinner: {
        zIndex: theme.zLayer.xxl
    }
};
