import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useOnMount, usePersistentCallback } from '@prophecy/utils/react/hooks';
import { parseStringDayOfMonth } from '@vangware/cron';
import styled from 'styled-components';
import { Stack } from '../Layout';
import { Text } from '../Typography/Text';
import { DatePicker } from './DatePicker';
import { TimePicker } from './TimePicker';
import { tokens } from './tokens';
import { DaysInMonth, getCronDefaults, getRangeFromValues, getValuesFromCronPart, useTimeValues } from './utils';
const StyledTimePicker = styled(TimePicker) `
  width: 180px;
`;
export function MonthTab({ cronType, cronValue, setCronValue }) {
    const dayOfMonth = getValuesFromCronPart(cronValue.dayOfMonth);
    const { hours, minutes, handleTimeChange } = useTimeValues(cronValue, setCronValue, ['dayOfMonth'], cronType);
    const cronDefaults = getCronDefaults(cronType);
    const handleDateSelection = usePersistentCallback((dates) => {
        var _a;
        const rangeString = getRangeFromValues(dates, DaysInMonth);
        const cronMonthDays = (_a = parseStringDayOfMonth(rangeString)) !== null && _a !== void 0 ? _a : cronDefaults.dayOfMonth;
        setCronValue(Object.assign(Object.assign({}, cronDefaults), { hours: hours, minutes: minutes, dayOfMonth: cronMonthDays }));
    });
    useOnMount(() => {
        if (!dayOfMonth.length) {
            handleDateSelection([1]);
        }
    });
    return (_jsxs(Stack, { direction: 'horizontal', height: '40px', alignY: 'center', gap: tokens.CronGenerator.Fields.gap, children: [_jsx(Text, { level: 'sm', children: "Repeat on the" }), _jsx(DatePicker, { mode: 'multiple', value: dayOfMonth, onChange: handleDateSelection }), _jsx(Text, { level: 'sm', children: "at" }), _jsx(StyledTimePicker, { value: [hours, minutes], onChange: handleTimeChange })] }));
}
