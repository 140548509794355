export enum DEPLOYMENT_TABS {
  INFRA_SETUP = 'INFRA_SETUP',
  DEPLOYMENT_PRE_CHECK = 'DEPLOYMENT_PRE_CHECK',
  DEPLOY = 'DEPLOY',
  POST_DEPLOY = 'POST_DEPLOY'
}

export type DeploymentStepBaseProp = {
  nextStep: () => void;
  prevStep: () => void;
};
