import { __rest } from "tslib";
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { isNil } from 'lodash-es';
import { useState } from 'react';
import { Stack } from '../Layout';
import { BaseColor, theme } from '../theme';
import { StyledButton, StyledClearIcon } from './styled';
import { BadgesComponentNames } from './tokens';
import { Size, BadgeShade } from './types';
export function Badges(_a) {
    var _b;
    var { closable = false, onClose, shadeType = BadgeShade.medium, children, size = Size.m, rounded = false, prefix, filled = true, suffix, tone = BaseColor.primary } = _a, restProps = __rest(_a, ["closable", "onClose", "shadeType", "children", "size", "rounded", "prefix", "filled", "suffix", "tone"]);
    const [visible, toggle] = useState(true);
    if (!visible) {
        return null;
    }
    const _onClose = () => {
        toggle(false);
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    return (_jsx(StyledButton, Object.assign({ size: size, filled: filled, shadeType: shadeType, tone: tone, rounded: rounded, icon: closable ? _jsx(StyledClearIcon, { type: 'mini', shadeType: shadeType, tone: tone, onClick: _onClose }) : null }, restProps, { iconPlacement: 'right', children: !isNil((_b = prefix !== null && prefix !== void 0 ? prefix : children) !== null && _b !== void 0 ? _b : suffix) ? (_jsxs(Stack, { "data-component": BadgesComponentNames.container, direction: 'horizontal', gap: theme.spaces.x8, alignY: 'center', children: [prefix, children, suffix] })) : null })));
}
export function IconBadge(props) {
    return _jsx(Badges, Object.assign({}, props, { rounded: true }));
}
Badges.IconBadge = IconBadge;
