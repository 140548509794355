import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { castArray, get } from 'lodash-es';
import { StyledHelpText } from '../Input/styled';
import { Stack } from '../Layout';
import { theme } from '../theme';
export const isFormDirty = (formContext, ignoreFields = []) => {
    const dirtyFieldSet = new Set(Object.keys(formContext.formState.dirtyFields));
    ignoreFields.forEach((field) => dirtyFieldSet.delete(field));
    return dirtyFieldSet.size > 0;
};
export const isFieldDirty = (formContext, checkFields) => {
    return castArray(checkFields).some((field) => Boolean(get(formContext.formState.dirtyFields, field)));
};
export const isFieldTouched = (formContext, checkFields) => {
    return castArray(checkFields).some((field) => Boolean(get(formContext.formState.touchedFields, field)));
};
export const isFieldDirtyOrTouched = (formContext, checkFields) => {
    return isFieldDirty(formContext, checkFields) || isFieldTouched(formContext, checkFields);
};
export const WithHelpText = ({ helpText, variant = 'primary', children }) => (_jsxs(Stack, { gap: theme.spaces.x4, width: '100%', children: [children, helpText && _jsx(StyledHelpText, { variant: variant, children: helpText })] }));
