import styled from 'styled-components';
import { hideScrollbarCss } from '../../../Misc/HideScrollbar';
import { LINE_HEIGHT } from '../../tokens';
export const StyledCodeBlockWrap = styled.div `
  ${(props) => (props.hideScrollbar ? hideScrollbarCss : '')}
  grid-area: 1 / 1 / auto / 2;
  display: grid;
  grid-template-columns: 1fr;
  overflow: auto;
`;
export const BaseBlock = styled.pre `
  grid-area: 1 / 1 / auto / 2;
  width: fit-content;
  min-width: 100%;
  white-space: pre;
  line-height: ${LINE_HEIGHT}px;
`;
export const BackgroundBlock = styled(BaseBlock) `
  z-index: 0;
`;
export const ColorizedBlock = styled(BaseBlock) `
  z-index: 1;
`;
