import { Entity } from '../types/Entity';
import { ide, metadata } from './common';
import { metadata as datasetMetadata, ide as datasetIde } from './dataset';
import { metadata as fabricMetadata } from './fabric';
import { ide as JobIDE, metadata as jobMetadata } from './job';
import { ide as LineageIDe } from './lineage';
import { ide as PipelineIDe, metadata as pipelineMetadata } from './pipeline';
import { metadata as projectMetadata } from './project';
import { home as SettingsHome } from './settings';
import { ide as SqlIDe, ide as GemIDe } from './sql';
import { ide as SubgraphIDE } from './subgraph';
import { metadata as teamMetadata } from './team';
import { home as TranspileHome } from './transpile';
import { metadata as userMetadata, home } from './user';

export const EntityEvents = {
  [Entity.Pipeline]: {
    ide: PipelineIDe,
    metadata: pipelineMetadata
  },
  [Entity.Configuration]: {
    ide: PipelineIDe
  },
  [Entity.Job]: {
    ide: JobIDE,
    metadata: jobMetadata
  },
  [Entity.SQL]: {
    ide: SqlIDe
  },
  [Entity.User]: {
    metadata: userMetadata,
    home
  },
  [Entity.Observation]: {
    ide: JobIDE
  },
  [Entity.Lineage]: {
    ide: LineageIDe
  },
  [Entity.Fabric]: {
    metadata: fabricMetadata
  },
  [Entity.Project]: {
    metadata: projectMetadata,
    ide: SqlIDe
  },
  [Entity.Dataset]: {
    metadata: datasetMetadata,
    ide: datasetIde
  },
  [Entity.Gem]: {
    ide: GemIDe
  },
  [Entity.JobSubgraph]: {
    ide: PipelineIDe
  },
  [Entity.Subgraph]: {
    ide: SubgraphIDE
  },
  [Entity.Team]: {
    metadata: teamMetadata
  },
  transpile: {
    home: TranspileHome
  },
  common: { ide, metadata },
  settings: { home: SettingsHome }
};

export * from './events';
export * from './signup';
export * from './signin';
