export function isDependencySame(dependency, lastDependency, equalityFunction = Object.is) {
    if (dependency.length !== lastDependency.length) {
        return false;
    }
    for (let i = 0; i < dependency.length; i++) {
        if (!equalityFunction(dependency[i], lastDependency[i])) {
            return false;
        }
    }
    return true;
}
export function memoize(equalityFunction) {
    let lastDependency = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let lastResult = null;
    function memoizedFunction(fn, dependency) {
        if (lastDependency && isDependencySame(dependency, lastDependency, equalityFunction)) {
            return lastResult;
        }
        lastDependency = dependency;
        lastResult = fn();
        return lastResult;
    }
    return memoizedFunction;
}
