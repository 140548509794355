import { Stack } from '@prophecy/ui/Layout';
import { theme } from '@prophecy/ui/theme';
import { Text } from '@prophecy/ui/Typography';
import { DeploymentAnalytics } from 'frontend/core/src/common/user-analytics/deployment';

import { Root, StyledList, StyledCard } from '../styles';
import { DeploymentStepBaseProp } from '../types';
import { DeploymentStepFooter } from './DeploymentStepFooter';
import DeploymentStepHeader from './DeploymentStepHeader';

export default function DeployProphecy({ nextStep, prevStep }: DeploymentStepBaseProp) {
  return (
    <StyledCard>
      <Root align='space-between'>
        <Stack gap={theme.spaces.x40}>
          <Stack gap={theme.spaces.x16}>
            <DeploymentStepHeader title='3. Deploy Prophecy' />
            <Stack gap={theme.spaces.x40}>
              <Text level='sm'>Follow the steps below to install Prophecy using the generated Helm charts:</Text>
            </Stack>
          </Stack>
          <Stack gap={theme.spaces.x12}>
            <StyledList>
              <li>Make sure Helm is installed</li>
              <li>Add Helm Chart Repository </li>
              <li>Update Helm Repositories</li>
              <li>Locate the Prophecy Helm Chart</li>
              <li>Install the application by running the `helm install` command</li>
              <li>Verify the installation is complete</li>
            </StyledList>
            <Text level='sm'>
              Remember to refer to the application's specific documentation and Helm chart documentation for any
              additional steps or considerations tailored to Prophecy.
            </Text>
          </Stack>
        </Stack>
        <DeploymentStepFooter
          eventCategory={DeploymentAnalytics.deployment_step_deploy_prophecy.event_category}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      </Root>
    </StyledCard>
  );
}
