import styled from 'styled-components';
import { Icon } from '../Icons';
import { StyledTabsList, StyledTabsTrigger, StyledTabsContentWrap } from './styled';
import { Tabs } from './Tabs';
import { tokens } from './tokens';
import { TabTone } from './types';
export const SwitchTabs = styled(Tabs) `
  ${StyledTabsList} {
    background: ${tokens.SwitchTabs.TabsList.background};
    border-radius: ${tokens.SwitchTabs.TabsList.borderRadius};
    padding: 0;
    display: flex;
    font-weight: ${tokens.SwitchTabs.TabsList.fontWeight};
    color: ${tokens.SwitchTabs.TabsTrigger.color};
  }

  ${StyledTabsContentWrap} {
    padding: 0;
  }

  ${StyledTabsTrigger} {
    background: transparent;
    flex: 1;
    border: 1px solid transparent;
    color: ${tokens.SwitchTabs.TabsTrigger.color};
    &[aria-selected='true'] {
      background: ${tokens.SwitchTabs.TabsTrigger.active.backgroundColor};
      border-radius: ${tokens.SwitchTabs.TabsTrigger.active.borderRadius};
      box-shadow: ${tokens.SwitchTabs.TabsTrigger.active.boxShadow};
      ${({ tone }) => tone === TabTone.primary
    ? `
      border: 1px solid ${tokens.SwitchTabs.TabsTrigger[tone].active.borderColor};
      color: ${tokens.SwitchTabs.TabsTrigger[tone].active.color};

      ${Icon} {
        color: ${tokens.SwitchTabs.TabsTrigger[tone].active.iconColor};
      }
    `
    : `
     border: 1px solid ${tokens.SwitchTabs.TabsTrigger.active.borderColor};
     color: ${tokens.SwitchTabs.TabsTrigger.active.color};
    `}
    }
    &[disabled] {
      &,
      &:hover,
      &:focus {
        color: ${tokens.TabsTrigger.disabled.color};
        box-shadow: none;
        background: transparent;
        border: none;
        cursor: not-allowed;
        &[aria-selected='true'] {
          background: ${tokens.TabsTrigger.disabled.backgroundColor};
        }
      }
    }
  }
`;
SwitchTabs.TabPane = Tabs.TabPane;
