import { ExtractArrayType } from '@prophecy/interfaces/generic';
import { graphql } from 'frontend/core/src/graphqlTypes/graphql';
import { ResultOf } from 'gql.tada';

export const TeamSettingsQuery = graphql(`
  query InitialGqlQuerySettingTeamPage($uid: String!) {
    User(uid: $uid) {
      _id
      teams {
        _id
        name
        teamKind
        createdByFullName
        InfoAspect: aspects(aspect: Info) {
          AspectName
          AspectValue
        }
        members {
          _id
          firstName
          lastName
          email
          invitationAccepted
        }
        admins {
          _id
          firstName
          lastName
          email
          invitationAccepted
        }
      }
    }
  }
`);
type TeamSettingsResponse = ResultOf<typeof TeamSettingsQuery>;
export type TeamType = ExtractArrayType<NonNullable<TeamSettingsResponse['User']>['teams']>;
export type MemberType = ExtractArrayType<TeamType['members']>;

export const CreateTeamQuery = graphql(`
  mutation createTeamEntity($adminID: String!, $name: String!) {
    addTeam(adminUID: $adminID, name: $name) {
      _id
      name
    }
  }
`);

export const AddTeamAdminQuery = graphql(`
  mutation addTeamAdmin($adminId: String!, $teamId: String!) {
    addTeamAdmin(userUID: $adminId, teamUID: $teamId)
  }
`);
export const RemoveTeamAdminQuery = graphql(`
  mutation removeTeamAdmin($adminId: String!, $teamId: String!) {
    removeTeamAdmin(userUID: $adminId, teamUID: $teamId)
  }
`);
