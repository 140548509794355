import { produceWithoutFreeze } from '@prophecy/utils/data';

import { NestedData } from './types';

export function uniqueID(prefix: string, excludes: string[]) {
  let id = `${prefix}0`;
  //Check for unique id
  for (let index = 0; index < excludes.length; index++) {
    const newId = `${prefix}${index + 1}`;
    if (!excludes.some((val) => val.toLowerCase() === newId.toLowerCase())) {
      //case insensitive check was failing for newly created item like 'Subgraph_x' if there is already 'subgraph_x'/ 'SubGraph_x' in the list
      id = newId;
      break;
    }
  }

  return id;
}

export function uniqueIDWithDelimiter(prefix: string, excludes: string[], delimiter: string = '_') {
  // if there is no existing id, return the prefix
  if (!excludes.includes(prefix)) return prefix;
  return uniqueID(`${prefix}${delimiter}`, excludes);
}

export function isUniqueID(value: string, list: { [key: string]: string }[], matchingKey: string) {
  let isUnique = true;
  //Check for unique id
  list.forEach((item) => {
    if (value === item[matchingKey]) {
      isUnique = false;
      return false;
    }
  });
  return isUnique;
}

export function spaceToUnderscore(word: string) {
  return word.replace(/\W+/g, ' ').split(' ').join('_').replace(/_$/, '');
}

export function dropWhileIndex(array: string[][], subIndex: number) {
  return array.map((v) => {
    v.splice(subIndex, 1);
    return v;
  });
}

export function insertDataForColumn(array: string[][], data: string[]) {
  return array.map((v, i) => {
    v.push(data?.[i]);
    return v;
  });
}

/**
 * Filter nested data list based on search string.
 * The nesting is done using children property
 * [{
 *   ...,
 *   children: [{...}, ...]
 * }, ...]
 */

export function searchAndFilterWithSelectedKey<T>(
  searchStr: string,
  data: T[]
): { data: T[]; selectedFields?: string[] } {
  const _searchStr = searchStr.toLowerCase();
  // if the search string is empty return all data
  if (!searchStr) return { data };

  const filterRecursively = (
    data: NestedData[],
    selectedFields: string[] = [],
    parentKey?: string
  ): [NestedData[], string[]] => {
    return [
      data.filter((item) => {
        const found = Object.keys(item).some((key) => {
          const value = item[key];
          return typeof value === 'string' && value.toLowerCase().includes(_searchStr);
        });
        // if we find match on any property return true
        if (found) {
          parentKey ? selectedFields.push(parentKey) : selectedFields.push(item.key);
          return true;
        }

        if (item.children) {
          const [_data, _selectedFields] = filterRecursively(item.children, selectedFields, parentKey || item.key);
          item.children = _data;
          selectedFields = _selectedFields;
        }

        // if it has any filtered children return true
        return !!item.children?.length;
      }),
      selectedFields
    ];
  };

  /**
   * We don't produce directly with data as we apply filter on it,
   * which returns new array. Immer doesn't support returning new data
   * if draft is being mutated in nested level
   */
  const selectedFields: string[] = [];
  return produceWithoutFreeze({ data, selectedFields }, (obj) => {
    const [_data, sFields] = filterRecursively(obj.data as NestedData[], []);
    obj.data = _data as T[];
    obj.selectedFields = sFields as string[];
  });
}
