import { CreatedIconType, NewIconVariant } from '@prophecy/ui/Icons';
import { getColorCode } from '@prophecy/ui/theme';

import { JobCategoryMap } from './constants';
import { JobCategoryTypes } from './types';

export function getJobNodeIcon(category: JobCategoryTypes) {
  const categoryInfo = JobCategoryMap[category];

  const Icon = categoryInfo.icon as CreatedIconType<'extended' | 'default' | 'solid' | 'mini'>;
  const iconType = categoryInfo.iconType;
  return <Icon type={iconType as NewIconVariant} color={getColorCode(categoryInfo.tone, 600)} />;
}
