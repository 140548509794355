import { addWatchableProperty, Watcher } from '@prophecy/utils/addPrivateProperty';
import { last } from 'lodash-es';
import { Noop } from 'react-hook-form';

import {
  GenericHistoryEntryType as H,
  BaseHistoryEntryType as I,
  GenericHistoryStackEntry as S,
  HistoryEntry,
  FindParams,
  FindOrder
} from './types';
import { frameId, mergeAtEnd } from './utils';

export class HistoryManager<T extends I = H> {
  private stackLength: number;

  private undoStack!: S<T>[];

  watchUndoStack!: Watcher<S<T>[]>;

  private triggerUndoStack!: Noop;

  private redoStack!: S<T>[];

  watchRedoStack!: Watcher<S<T>[]>;

  private triggerRedoStack!: Noop;

  resetStack!: string | undefined;

  watchResetStack!: Watcher<string | undefined>;

  private isUndoInProgress: boolean;

  public blocked: boolean = false;

  constructor(stackLength: number = 50) {
    this.isUndoInProgress = false;
    this.stackLength = stackLength;
    this.trackChange = this.trackChange.bind(this);
    this.mergeChange = this.mergeChange.bind(this);

    addWatchableProperty(this, {
      name: 'undoStack',
      defaultValue: []
    });
    addWatchableProperty(this, {
      name: 'redoStack',
      defaultValue: []
    });
    addWatchableProperty(this, {
      name: 'resetStack',
      defaultValue: undefined
    });
  }

  // if there is atleast one entry in redo stack, we are sure that last action was an undo
  isUndoActive() {
    return this.isUndoInProgress;
  }

  /* will iterate through undo stack in reverse order and update the undo stack until a falsy value is returned by callback */
  // its implicit that we want to iterate on undo changes only, we dont want to touch redo
  iterateAndMergeChanges(callback: (item: S<T>, index?: number) => S<T> | undefined | void) {
    for (let i = this.undoStack.length - 1; i > -1; i--) {
      const updatedItem = callback(this.undoStack[i], this.undoStack.length - 1 - i);
      if (!updatedItem) break;
      this.undoStack[i] = updatedItem;
      this.triggerUndoStack();
    }
  }

  mergeChange(callback: (item: S<T>) => S<T> | undefined | void) {
    const lastChange = last(this.undoStack);
    if (!lastChange) return;

    const updatedLastChange = callback(lastChange);
    if (updatedLastChange) {
      this.undoStack[this.undoStack.length - 1] = updatedLastChange;
      this.triggerUndoStack();
      return updatedLastChange;
    }
  }

  /**
   * Find a history entry in 2d undo stack in defined order (default to top/reverse order as we are dealing with stack so top and last should come first)
   */
  find<R = boolean>(callback: (params: FindParams<T>) => R, order: FindOrder = FindOrder.top) {
    const stackEntries = this.undoStack;
    const topOrder = order === FindOrder.top;
    const stackEntriesLn = stackEntries.length;
    // loop through stack entries on defined order
    for (
      let stackIndex = topOrder ? stackEntriesLn - 1 : 0;
      topOrder ? stackIndex > -1 : stackIndex < stackEntriesLn;
      stackIndex += topOrder ? -1 : 1
    ) {
      const stackEntry = stackEntries[stackIndex];
      const entries = stackEntry.entries;
      const entriesLn = entries.length;

      //loop through one historyEntries in one of the stack entry
      for (
        let entryIndex = topOrder ? entriesLn - 1 : 0;
        topOrder ? entryIndex > -1 : entryIndex < entriesLn;
        entryIndex += topOrder ? -1 : 1
      ) {
        const params: FindParams<T> = {
          stackEntry,
          historyEntry: entries[entryIndex],
          historyEntryIndex: entryIndex,
          stackEntryIndex: stackIndex
        };

        const value = callback(params);

        if (value) {
          return {
            params,
            value
          };
        }
      }
    }
  }

  /** find and merge new history entry to any position in the history stack  **/
  findAndMerge(callback: (params: FindParams<T>) => S<T> | undefined | void, order: FindOrder = FindOrder.top) {
    const foundItem = this.find(callback, order);
    if (!foundItem) return;

    const { params, value } = foundItem;

    this.undoStack[params.stackEntryIndex] = value as S<T>;
    this.triggerUndoStack();
  }

  trackChange<R extends H<T>>(item: HistoryEntry<R>, mergeId?: string): void {
    this.isUndoInProgress = false;
    this.resetStack = undefined;

    // reset redo stack on a new change
    this.redoStack = [];

    // store the change
    this.undoStack.push({ entries: [item], mergeId } as S<T>);

    // if stack length grows beyond remove the first entry
    if (this.undoStack.length > this.stackLength) {
      this.undoStack.shift();
    }

    this.triggerUndoStack();
  }

  /* check if change can merged, if cant merge track instead */
  mergeOrTrackChange<R extends H<T>>(
    item: HistoryEntry<R>,
    mergeId?: string,
    mergeCallback: (entries: S<T>, item: HistoryEntry<R>) => S<T> | undefined | void = mergeAtEnd
  ) {
    const merged = Boolean(
      this?.mergeChange((prevItem) => {
        // if mergeId is not empty, merge if last entry has matching mergeId. else always merge with last entry
        if (!mergeId || mergeId === prevItem.mergeId) {
          return mergeCallback(prevItem, item);
        }
      })
    );

    !merged && this?.trackChange(item, mergeId || frameId());
  }

  private lastChange() {
    this.isUndoInProgress = true;
    const currentItem = this.undoStack.pop();
    if (currentItem) {
      this.triggerUndoStack();
      this.redoStack.unshift(currentItem);
      this.triggerRedoStack();
    }
    return currentItem;
  }

  private nextChange() {
    const currentItem = this.redoStack.shift();
    if (currentItem) {
      this.triggerRedoStack();
      this.undoStack.push(currentItem);
      this.triggerUndoStack();
    }
    return currentItem;
  }

  reset(message?: string) {
    this.undoStack = [];
    this.redoStack = [];
    this.resetStack = message;
  }

  block(blocked: boolean) {
    this.blocked = blocked;
  }
}
