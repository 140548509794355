import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Breadcrumb } from '../Breadcrumb';
import { getURIParts } from './utils';
export default function EditorBreadcrumb(_a) {
    var { file } = _a, restProps = __rest(_a, ["file"]);
    const parts = getURIParts(file);
    let crumbs = [];
    parts.forEach((part, i) => {
        if (i < 6 || i === parts.length - 1) {
            crumbs.push({
                value: part,
                children: part
            });
        }
    });
    return _jsx(Breadcrumb, Object.assign({}, restProps, { active: parts[parts.length - 1], crumbs: crumbs }));
}
