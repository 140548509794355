import { Button, Stack, Stepper, Text, theme, useStepper } from '@prophecy/ui';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@prophecy/ui/Icons';
import { Step } from '@prophecy/ui/Stepper/types';
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { ReactNode } from 'react';

import { PageHeaderProps } from '../Header/PageHeader';
import { ScrollableFlexGrow } from '../ScrollableFlexGrow';
import { ErrorTooltip } from './ErrorTooltip';
import { TabError, useTabErrors } from './hooks';
import {
  NonInteractive,
  PageFooter,
  Root,
  StepContainer,
  StepContentContainer,
  StyledPageHeader,
  StyledProgressBar,
  StyledStack,
  StyledStackItem
} from './styles';

type StepWithKey = Step & { key: string };

const StepperPage = ({
  steps,
  onCancel,
  onComplete,
  getStepContent,
  getTabHeader,
  pageHeaderProps,
  isLoading,
  initialStep,
  okButton = 'Complete',
  onNext = () => true,
  onStepChange
}: {
  steps: StepWithKey[];
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onComplete: Function;
  getStepContent: (key: string, setErrors: (hasErrors: TabError) => void) => React.ReactNode;
  getTabHeader: (key: string) => React.ReactNode;
  pageHeaderProps: PageHeaderProps;
  isLoading: boolean;
  initialStep?: number;
  okButton?: ReactNode;
  onNext?: (key: string) => Promise<boolean> | boolean;
  onStepChange?: (step: StepWithKey) => void;
}) => {
  const { errors, makeSetTabError } = useTabErrors();

  const { stepIndex, currentStep, isFirstStep, isLastStep, prevStep, nextStep, stepProgress } = useStepper(
    steps,
    onStepChange,
    onComplete,
    initialStep
  );
  const goBack = () => {
    prevStep();
  };
  const goNext = usePersistentCallback(async () => {
    if (await onNext(currentStep.key)) {
      nextStep();
    }
  });
  return (
    <Root>
      <StyledPageHeader {...pageHeaderProps} />
      <StyledProgressBar variant='loading' value={stepProgress} width='100%' />

      <ScrollableFlexGrow>
        <StepContainer>
          <div>
            <Stack direction='horizontal' align='stretch' height='100%' gap={theme.spaces.x48}>
              <StepContentContainer>
                <StyledStack gap={theme.spaces.x40} padding={theme.spaces.x32}>
                  <Text level='lg' weight={theme.fontWeight.medium} tone={theme.colors.grey900}>
                    {getTabHeader(currentStep.key)}
                  </Text>
                  <StyledStackItem grow='1'>
                    {getStepContent(currentStep.key, makeSetTabError(currentStep.key))}
                  </StyledStackItem>
                </StyledStack>
              </StepContentContainer>
              <NonInteractive>
                <Stepper current={stepIndex} orientation='vertical' steps={steps} />
              </NonInteractive>
            </Stack>
          </div>
        </StepContainer>
      </ScrollableFlexGrow>

      <PageFooter direction='horizontal' align='space-between' alignY='center'>
        <Button variant='secondaryGrey' size='m' onClick={onCancel}>
          Cancel
        </Button>
        <Stack direction='horizontal' gap={theme.spaces.x16}>
          {!isFirstStep && (
            <Button
              onClick={goBack}
              variant='secondaryGrey'
              size='m'
              iconPlacement='left'
              icon={<ArrowNarrowLeftIcon type='default' />}>
              Back
            </Button>
          )}
          <ErrorTooltip
            errors={errors[currentStep.key]}
            render={(hasErrors) => (
              <Button
                onClick={goNext}
                loading={isLoading}
                disabled={hasErrors || isLoading}
                variant='primary'
                size='m'
                icon={isLastStep ? undefined : <ArrowNarrowRightIcon type='default' />}>
                {isLastStep ? okButton : 'Continue'}
              </Button>
            )}
          />
        </Stack>
      </PageFooter>
    </Root>
  );
};

export default StepperPage;
