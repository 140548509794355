import { lazy, Suspense } from 'react';

const DevToolEntry = lazy(() => import('./entry'));

export function DevTool() {
  if (process.env.NODE_ENV === 'development') {
    return <Suspense fallback={null}>{<DevToolEntry />}</Suspense>;
  } else {
    return null;
  }
}
