import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '../Layout';
import { Select } from '../Select';
import { Text } from '../Typography/Text';
import { tokens } from './tokens';
import { Minutes, getCronDefaults } from './utils';
export function MinuteTab({ cronType, cronValue, setCronValue }) {
    var _a, _b;
    const cronDefaults = getCronDefaults(cronType);
    const handleChange = (value) => {
        // reset all other fields and set minute
        setCronValue(Object.assign(Object.assign({}, cronDefaults), { minutes: value === undefined ? cronDefaults.minutes : { every: value, start: 0 } }));
    };
    const minutes = (_b = (_a = cronValue.minutes) === null || _a === void 0 ? void 0 : _a.every) !== null && _b !== void 0 ? _b : 1;
    return (_jsxs(Stack, { direction: 'horizontal', alignY: 'center', gap: tokens.CronGenerator.Fields.gap, children: [_jsx(Text, { level: 'sm', children: "Repeat every" }), _jsx(Select, { dropdownClassName: 'ui-cron-center-aligned-content', value: minutes, options: Minutes.slice(1).map((minute) => ({ label: minute, value: minute })), onChange: handleChange }), _jsx(Text, { level: 'sm', children: "minute(s)" })] }));
}
