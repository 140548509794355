import { ObjectLiteral, ValueOf } from '@prophecy/interfaces/generic';
import { ProphecyError } from '@prophecy/utils/error';
import { escapeRegExp, get, isEmpty, set, unset } from 'lodash-es';
import { Store } from 'redux';

import { PATH_ROOTS, SubgraphType } from '../common/constants';
import { ExpectationMetadata } from '../common/DataQuality/types';
import { getGemSpecByProcess } from '../common/getGemSpecByProcess';
import { ProcessKind } from '../common/graph/types';
import {
  BaseProcess,
  BaseProcessMetadata,
  BaseState,
  CommonState,
  Connection,
  GenericGraph,
  GenericGraphProcess,
  GenericGraphProcesses,
  GenericGraphProcessType,
  GenericSubGraph,
  Metadata
} from '../common/types';
import { BindingType, GemSpec, GemSpecType, Properties } from './types';

export const VARIABLE_START = '${';
export const VARIABLE_END = '}';
export function pathWithDot(path: string) {
  return `${path}.`;
}
const ProcessesPropertyName = `processes`;
export const MetadataPropertyName = `metadata`;
const ConfigurationPropertyName = `configuration`;
const ConnectionsPropertyName = `connections`;
const MetaInfoPropertyName = `metainfo`;
export const DotProcessesDotPropertyName = `.${ProcessesPropertyName}.`;

const METADATA_PREFIX = pathWithDot(PATH_ROOTS.METADATA);
const EXPECTATION_METADATA_PREFIX = pathWithDot(PATH_ROOTS.EXPECTATION + '.' + MetadataPropertyName);

export const COMPONENT_PREFIX = pathWithDot(PATH_ROOTS.COMPONENT);
export const metaInfoPath = getMetaInfoPath(PATH_ROOTS.WORKFLOW);
export const WORKFLOW_CONFIGURATION_PATH = `${metaInfoPath}.${ConfigurationPropertyName}`;

export function isComponentProperty(property: string) {
  return property.startsWith(PATH_ROOTS.COMPONENT);
}
export function isMetadataProperty(property: string) {
  return property.startsWith(PATH_ROOTS.METADATA);
}
export function isWorkflowConfiguration(property: string) {
  return property.startsWith(WORKFLOW_CONFIGURATION_PATH);
}
export function isExpectationMetadataProperty(property: string) {
  return property.startsWith(EXPECTATION_METADATA_PREFIX);
}
export function isGraphProperty(property: string, rootPath: string) {
  return property.startsWith(rootPath);
}
// TODO: remove this once we start using new isSubgraph util
function isSubGraphProcess(
  process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> | undefined
): process is GenericSubGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> {
  return process?.component?.toLowerCase() === ProcessKind.Subgraph.toLowerCase();
}

export function isNestedGraph(
  process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> | undefined
): process is GenericSubGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> {
  return Boolean(process && 'processes' in process && 'connections' in process);
}

export function isMetaSubgraphGem(
  process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> | undefined,
  specs: GemSpec[]
): process is GenericSubGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> {
  if (process) {
    const spec = getGemSpecByProcess(process, specs);
    return spec?.gemType === GemSpecType.MetaComponentGem;
  }
  return false;
}
export function isSubGraphOrMetaGem(
  process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> | undefined,
  specs: GemSpec[]
): process is GenericSubGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> {
  return isSubGraphProcess(process) || isMetaSubgraphGem(process, specs);
}
export function isMetaInputGemType(kind?: string) {
  return kind?.toLowerCase() === ProcessKind.ControlFlowInput.toLowerCase();
}
export function isMetaOutputGemType(kind?: string) {
  return kind?.toLowerCase() === ProcessKind.ControlFlowOutput.toLowerCase();
}
export function isMetaInputGem(
  process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> | undefined
): process is GenericSubGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> {
  return isMetaInputGemType(process?.component);
}
export function isMetaOutputGem(
  process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> | undefined
): process is GenericSubGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> {
  return isMetaOutputGemType(process?.component);
}

export function isMetaIOGem(
  process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> | undefined
): process is GenericSubGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> {
  return isMetaInputGem(process) || isMetaOutputGem(process);
}
function isMetaInputGemSpec(spec: GemSpec) {
  return isMetaInputGemType(spec.kind);
}
function isMetaOutputGemSpec(spec: GemSpec) {
  return isMetaOutputGemType(spec.kind);
}

export function isMetaIOGemSpec(spec: GemSpec) {
  return isMetaInputGemSpec(spec) || isMetaOutputGemSpec(spec);
}
export function hasChildProcess(
  process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> | undefined
): process is GenericSubGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection> {
  return !isEmpty(
    (process as GenericSubGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>)?.processes
  );
}

export function getCurrentProcessGraphPath(processId: string, graphPath: string) {
  // in case of current process is subgraph the current graph path will be pointing to that subgraph.
  // Ideally we want the parent path here, so we will return that
  return graphPath.endsWith(processId) ? graphPath.replace(`.${ProcessesPropertyName}.${processId}`, '') : graphPath;
}
export function getCurrentProcessPath(processId: string, graphPath: string) {
  // in case of current graph is subgraph, and processId is same subgraph, return same graph path
  return graphPath.endsWith(processId) ? graphPath : `${getProcessesPath(graphPath)}.${processId}`;
}

export function getProcessesPath(graphPath: string) {
  return `${graphPath}.${ProcessesPropertyName}`;
}
export function getConnectionPath(graphPath: string) {
  return `${graphPath}.${ConnectionsPropertyName}`;
}

export function getMetaInfoPath(graph: string) {
  return `${graph}.${MetaInfoPropertyName}`;
}

export function getGraphProcess<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(graph: G, graphPath: string, componentId: string, rootPath: string) {
  return getGraphProperty(
    graph,
    `${getProcessesPath(graphPath)}.${componentId}`,
    rootPath
  ) as GenericGraphProcessType<G>;
}

export function traverseNestedProcess<
  GP extends GenericGraphProcesses<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  specs: GemSpec[],
  processes: GP,
  graphPath: string,
  predicate: (
    process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>,
    processPath: string,
    graphParentNamePath?: string
  ) => boolean | void | undefined
) {
  // initialise a queue with initial set of processes
  const queue: {
    process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata, unknown>, Connection>;
    graphPath: string;
    graphParentNamePath?: string;
  }[] = Object.values(processes).map((process) => ({
    process,
    graphPath: `${getProcessesPath(graphPath)}.${process.id}`,
    processSlugPath: process.metadata.slug
  }));

  function isSubgraph(
    process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata, unknown>, Connection>,
    specs: GemSpec[]
  ): process is GenericSubGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata, unknown>, Connection> {
    const isJobIDE = graphPath.startsWith(PATH_ROOTS.JOB);

    if (isJobIDE) {
      return isJobSubgraph(process);
    } else {
      return isSubGraphOrMetaGem(process, specs);
    }
  }

  // keep iterating until we have traveresed all processes in the graph
  for (const { process, graphPath, graphParentNamePath } of queue) {
    // push subprocesses in the queue so we continue traversing the graph
    if (isSubgraph(process, specs)) {
      queue.push(
        ...Object.values(process.processes).map((subProcess) => ({
          process: subProcess,
          graphPath: `${getProcessesPath(graphPath)}.${subProcess.id}`,
          graphParentNamePath: graphParentNamePath
            ? `${graphParentNamePath}/${process.metadata.label}`
            : process.metadata.label
        }))
      );
    }
    const stopTraversal = predicate(process, graphPath, graphParentNamePath);

    if (stopTraversal) return;
  }
}

export function getAllProcessWithPath<
  GP extends GenericGraphProcesses<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(specs: GemSpec[], processes: GP, graphPath: string) {
  const allProcessAndPaths: Record<
    string,
    {
      process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>;
      path: string;
    }
  > = {};

  traverseNestedProcess(specs, processes, graphPath, (process, path) => {
    allProcessAndPaths[process.id] = {
      process,
      path
    };
  });

  return allProcessAndPaths;
}
export function getProcessWithPath<
  GP extends GenericGraphProcesses<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  specs: GemSpec[],
  processes: GP,
  graphPath: string,
  filter: (
    process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>,
    processPath: string,
    graphParentNamePath?: string
  ) => boolean
) {
  const allProcessAndPaths: Record<
    string,
    {
      process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>;
      path: string;
    }
  > = {};

  traverseNestedProcess(specs, processes, graphPath, (process, path) => {
    if (filter(process, path)) {
      allProcessAndPaths[process.id] = {
        process,
        path
      };
    }
  });

  return allProcessAndPaths;
}
export function getProcessById<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  specs: GemSpec[],
  rootGraph: G,
  rootPath: string,
  processId: string
): { process?: ValueOf<G['processes']>; path?: string } {
  let process: ValueOf<G['processes']> | undefined = undefined;
  let path: string | undefined = undefined;

  traverseNestedProcess(specs, rootGraph.processes, rootPath, (currentProcess, currentPath) => {
    if (currentProcess.id === processId) {
      process = currentProcess as ValueOf<G['processes']>;
      path = currentPath;
      return true;
    }
  });

  return { process, path };
}

export function getProcessesByIds<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  specs: GemSpec[],
  rootGraph: G,
  rootPath: string,
  processIds: string[]
): { process: ValueOf<G['processes']>; path: string }[] {
  const processes: { process: ValueOf<G['processes']>; path: string }[] = [];

  traverseNestedProcess(specs, rootGraph.processes, rootPath, (process, path) => {
    if (processIds.includes(process.id)) {
      processes.push({ process: process as ValueOf<G['processes']>, path });
    }
  });

  return processes;
}

export function findAllNodes<
  GP extends GenericGraphProcesses<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(specs: GemSpec[], processes: GP, graphPath: string): GP {
  const allProcess: GenericGraphProcesses<
    unknown,
    BaseProcessMetadata,
    BaseProcess<BaseProcessMetadata>,
    Connection
  > = {};
  traverseNestedProcess(specs, processes, graphPath, (process) => {
    allProcess[process.id] = process;
  });

  return allProcess as GP;
}

export function getGraphPathByProcess<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  specs: GemSpec[],
  graph: G,
  componentId: string,
  rootPath: string,
  graphPath?: string,
  traverseSubgraph: boolean = true
): string | undefined {
  const processes = Object.values(graph.processes);
  const path = graphPath ?? rootPath;
  for (let index = 0; index < processes.length; index++) {
    const process = processes[index];
    if (componentId === process.id) {
      return path;
    } else if (isSubGraphOrMetaGem(process, specs) && traverseSubgraph) {
      const result = getGraphPathByProcess(
        specs,
        process,
        componentId,
        rootPath,
        `${getProcessesPath(path)}.${process.id}`
      );
      if (result) {
        return result;
      }
    }
  }
}

export function getGraphPathAndProcessByMetadataLabel<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  specs: GemSpec[],
  graph: G,
  metadataLabel: string,
  rootPath: string,
  graphPath?: string
): [string, BaseProcess<BaseProcessMetadata>] | undefined {
  const processes = Object.values(graph.processes);
  const path = graphPath ?? rootPath;
  for (let index = 0; index < processes.length; index++) {
    const process = processes[index];
    if (metadataLabel === process.metadata?.label) {
      return [path, process];
    } else if (isSubGraphOrMetaGem(process, specs)) {
      const result = getGraphPathAndProcessByMetadataLabel(
        specs,
        process,
        metadataLabel,
        rootPath,
        `${getProcessesPath(path)}.${process.id}`
      );
      if (result) {
        return result;
      }
    }
  }
}
/**
 *  work same as lodash.set, only difference is it doesn't set value if parent object doesn't exist
 */
function strictSet<T extends object>(obj: T, path: string, value: unknown): T {
  const parts = path.split('.');
  parts.pop();
  const parentPath = parts.join('.');
  const parent = get(obj, parentPath);
  if (!parentPath) {
    // top level properties
    return set(obj, path, value);
  }
  if (parent) {
    return set(obj, path, value);
  } else {
    console.warn(`path ${path} doesn't exist on`, obj);
  }
  return obj;
}
export function updateComponentProperty<
  P extends GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(process: P, property: string, value: unknown) {
  strictSet(process, property.replace(COMPONENT_PREFIX, ''), value);
}

function getComponentProperty<
  P extends GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(process: P, property: string) {
  return get(process, property.replace(COMPONENT_PREFIX, ''));
}

export function updateBindingPathValue(rootObj: ObjectLiteral, property: string, value: unknown, rootPath: string) {
  // if its a root path replace the whole workflow with value
  if (rootPath === property) return value;

  const propertyPath = property.replace(pathWithDot(rootPath), '');

  // if we are setting a property as null, delete the property
  if (value === null) {
    unset(rootObj, propertyPath);
    return rootObj;
  }

  // when updating config instance value of subgraphs like configuration.instances.subgraph_1.another
  // subgraoh_1 may not even exist when a new configuration is created from workflow configuration hence we don't do
  // strict set of workflow configuration
  if (isWorkflowConfiguration(property)) {
    return set(rootObj, propertyPath, value);
  } else {
    return strictSet(rootObj, propertyPath, value);
  }
}

export function updateGraphProperty<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(graph: G, property: string, value: unknown, rootPath: string): G {
  return updateBindingPathValue(graph, property, value, rootPath) as G;
}

export function getGraphProperty<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(graph: G, property: string, rootPath: string) {
  return rootPath === property ? graph : get(graph, property.replace(pathWithDot(rootPath), ''));
}
function getMetadataProperty(metadata: Metadata, property: string) {
  return PATH_ROOTS.METADATA === property ? metadata : get(metadata, property.replace(METADATA_PREFIX, ''));
}

function getExpectationProperty(expectationMetadata: ExpectationMetadata, property: string) {
  return PATH_ROOTS.EXPECTATION === property
    ? expectationMetadata
    : get(expectationMetadata, property.replace(EXPECTATION_METADATA_PREFIX, ''));
}

export function updateMetadataProperty(metadata: Metadata, property: string, value: unknown) {
  return PATH_ROOTS.METADATA === property
    ? (value as Metadata)
    : strictSet(metadata, property.replace(METADATA_PREFIX, ''), value);
}

export function getSourceProcessLabel<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(graph: G, graphPath: string, componentId: string, portId: string, type: string, rootPath: string) {
  let connectedSourceId = '';
  const connections: Connection[] = getGraphProperty(graph, `${graphPath}.${ConnectionsPropertyName}`, rootPath);
  const isInputPort = type === 'in';
  const currentProcess = getGraphProcess(graph, graphPath, componentId, rootPath);
  // Handling cases for input type port
  if (isInputPort) {
    connections.forEach((connection) => {
      const currentPort = isInputPort ? connection.targetPort : connection.sourcePort;
      const currentId = isInputPort ? connection.target : connection.source;
      if (portId === currentPort && currentId === componentId) {
        connectedSourceId = connection.source;
      }
    });
    let connectedSourceProcess = getGraphProcess(graph, graphPath, connectedSourceId, rootPath);
    // in case of subgraph
    if (!connectedSourceProcess) {
      connectedSourceProcess = getGraphProperty(graph, graphPath, rootPath);
    }
    return connectedSourceProcess?.metadata?.label;
  }
  // Handling for portType output
  return currentProcess?.metadata?.label;
}

export function findBindings(properties: Properties) {
  const bindings: BindingType = {};
  const _static: BindingType = {};
  Object.entries(properties).forEach(([key, value]) => {
    if (typeof value === 'string' && value.startsWith(VARIABLE_START) && value.endsWith(VARIABLE_END)) {
      // can be better with index lookup
      bindings[key] = value.substring(2, value.length - 1);
      // } else if (isObject(value) && !isAtom(value)) {
      //   // parser objects other than components
      //   const [b, s] = findBindings(value);
      //   if (Object.keys(b).length) {
      //     bindings[key] = { ...b, ...s };
      //   } else {
      //     _static[key] = s;
      //   }
    } else {
      _static[key] = value;
    }
  });
  return [bindings, _static];
}

export function resolvePropertyValues(properties: Properties, store: Store) {
  const state = store.getState();
  const [bindings, _static] = findBindings(properties);

  const resolvedProperties = {
    ..._static
  };

  Object.entries(bindings).forEach(([key, property]) => {
    resolvedProperties[key] = resolveBindingValue(state, property, state.root);
  });

  return resolvedProperties;
}

export function resolveBindingValue<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(state: BaseState<G>, property: string, rootPath: string) {
  const { graphPath, currentComponentId, currentGraph, metadata } = state;
  const pattern = /\${([^{}]*)}/g;
  let match;
  // one level nested binding
  while ((match = pattern.exec(property)) !== null) {
    property = property.replace(bindingVariable(match[1]), String(resolveBindingValue(state, match[1], rootPath)));
  }

  if (isWorkflowConfiguration(property) && property.endsWith('.expression') && currentGraph) {
    const propertyParts = property.split('.');
    propertyParts.pop();
    const parentProperty = propertyParts.join('.');
    const parentPropertyValue = getGraphProperty(currentGraph, parentProperty, rootPath);
    if (parentPropertyValue && typeof parentPropertyValue === 'string') return parentPropertyValue;
  }
  if (isExpectationMetadataProperty(property)) {
    return state.expectation ? getExpectationProperty(state.expectation, property) : undefined;
  } else if (currentComponentId && isComponentProperty(property)) {
    const processGraphPath = getCurrentProcessGraphPath(currentComponentId, graphPath);
    const process = getGraphProcess(currentGraph as G, processGraphPath, currentComponentId, rootPath);
    return getComponentProperty(process, property);
  } else if (isMetadataProperty(property)) {
    return getMetadataProperty(metadata, property);
  } else {
    // TODO: handle root path
    return getGraphProperty(currentGraph as G, property, rootPath);
  }
}

export function getStatePath(state: CommonState, path: string) {
  if (path.startsWith(state.root)) {
    return `currentGraph.${path.replace(state.root, '')}`;
  } else if (isComponentProperty(path) && state.currentComponentId) {
    return `currentGraph.${getCurrentProcessGraphPath(state.currentComponentId, state.graphPath)}.${
      state.currentComponentId
    }.${path.replace(COMPONENT_PREFIX, '')}`;
  }

  return path;
}

export function resolveBindingPath(property: string, graphPath: string, componentId?: string) {
  if (!isComponentProperty(property)) {
    return property;
  } else {
    if (componentId) {
      // assuming path is relative to component
      const processGraphPath = getCurrentProcessGraphPath(componentId, graphPath);
      return `${getProcessesPath(processGraphPath as string)}.${componentId}.${property.replace(COMPONENT_PREFIX, '')}`;
    } else {
      throw new ProphecyError(`Can't use relative outside component: ${property}`);
    }
  }
}

// convert global($.workflow.processes.1.properties.a) to component local path (component.properties.a)
export function toComponentPropertyPath(property: string, graphPath: string, componentId: string) {
  const processPath = getCurrentProcessPath(componentId, graphPath);
  const componentPath = `${processPath}.`;
  return property.replace(componentPath, COMPONENT_PREFIX);
}

export function getGraphPathAndProcessId(property: string) {
  // GraphPath - $.workflow.processes.SubGraph_98060.processes.SubGraph_99489
  const processIds = property.split(DotProcessesDotPropertyName);
  const componentId = processIds.pop()?.split('.').shift() || '';
  const graphPath = processIds.join(DotProcessesDotPropertyName) || '';
  const isSubgraphPath = processIds.length > 1;
  return {
    componentId,
    graphPath,
    isSubgraphPath
  };
}

export const getMetaInfoOrMetadataPropertyKey = <
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  property: string,
  graph: G,
  rootPath: string
) => {
  const hasMetaInfoProperty = property.includes(`.${MetaInfoPropertyName}.`);
  const hasMetaDataProperty = property.includes(`.${MetadataPropertyName}.`);
  const arrayValues = property
    .replace(rootPath, '')
    .split('.')
    .filter((value) => value);
  let key = arrayValues?.[0];
  if (hasMetaDataProperty || hasMetaInfoProperty) {
    //Get the first key after metainfo or metadata in rootpath `metainfo.config` then key will be config
    key = arrayValues.length > 1 ? arrayValues[1] : key;
  }
  const value = get(graph, property.replace(pathWithDot(rootPath), ''));
  return { hasMetaInfoProperty, hasMetaDataProperty, key, value };
};

export function getGraphPathAndProcess<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(
  property: string,
  graph: G,
  rootPath: string
): {
  graphPath: string;
  process: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>;
  isSubgraphPath: boolean;
  componentId: string;
} {
  const { componentId, graphPath, isSubgraphPath } = getGraphPathAndProcessId(property);
  const process = getGraphProcess(graph, graphPath, componentId, rootPath);
  return { graphPath, process, isSubgraphPath, componentId };
}
export function getProcessIdFromPropertyPath(property: string) {
  const processIds = property.split(DotProcessesDotPropertyName);
  const last = processIds.pop()?.split('.').shift();
  return last;
}

export function getGraphProcessIds<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(specs: GemSpec[], graph: G, path: string, rootPath: string) {
  const processIds: string[] = [];

  const processes: GenericGraphProcesses<
    unknown,
    BaseProcessMetadata,
    BaseProcess<BaseProcessMetadata>,
    Connection
  > = get(graph, path.replace(pathWithDot(rootPath), '')) || {};

  traverseNestedProcess(specs, processes, path, (process) => {
    processIds.push(process.id);
  });

  return processIds;
}
export function isJobSubgraph<
  TMetadata = unknown,
  TProcess extends BaseProcess<BaseProcessMetadata, unknown> = BaseProcess<BaseProcessMetadata, unknown>
>(process: GenericGraphProcess<TMetadata, BaseProcessMetadata, TProcess, Connection>) {
  return Object.values(SubgraphType).indexOf(process.component as SubgraphType) > -1;
}
export function getProcessPropertyPath(
  state: BaseState<GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>, Metadata>
) {
  return state.currentComponentId
    ? `${getProcessesPath(getCurrentProcessGraphPath(state.currentComponentId, state.graphPath))}.${
        state.currentComponentId
      }`
    : state.graphPath;
}
export function getParentGraphPath(graphPath: string) {
  return graphPath.split('.processes.').slice(0, -1).join('.processes.');
}

export function getParentPathOfProcess(graphPath: string, processId: string) {
  return graphPath.replace(new RegExp(`${escapeRegExp(`${DotProcessesDotPropertyName}${processId}`)}($|\\..*,)`), '');
}

export function bindingVariable(path: string) {
  return `${VARIABLE_START}${path}${VARIABLE_END}`;
}

export function bindingVariablePath(variable: string) {
  return variable.replace(VARIABLE_START, '').replace(VARIABLE_END, '');
}
