import { tokens as dropdownTokens } from '../Dropdown/tokens';
import { tokens as inputTokens } from '../Input/tokens';
import { theme } from '../theme';
export const SELECT_ROOT_CLASS = 'ui-select';
export const SELECT_DISABLED_CLASS = 'ui-select-disabled';
export const SELECT_SELECTOR_CLASS = 'ui-select-selector';
export const MULTI_SELECT_CLASS = 'ui-select-multiple';
export const SELECT_SELECTION_SEARCH_CLASS = 'ui-select-selection-search';
export const SELECT_CLEAR_CLASS = 'ui-select-clear';
export const SELECT_SELECTION_OVERFLOW_CLASS = 'ui-select-selection-overflow';
export const SELECT_OPEN_CLASS = 'ui-select-open';
export const SELECT_SHOW_SEARCH_CLASS = 'ui-select-show-search';
export const SELECT_SELECTION_PLACEHOLDER_CLASS = 'ui-select-selection-placeholder';
export const SELECT_SEARCH_INPUT_CLASS = 'ui-select-selection-search-input';
export const SELECT_SEARCH_INPUT_MIRROR_CLASS = 'ui-select-selection-search-mirror';
export const SELECT_SELECTION_ITEM_CLASS = 'ui-select-selection-item';
export const SELECT_ARROW_CLASS = 'ui-select-arrow';
export const AUTOCOMPLETE_CLASS = 'ui-select-autocomplete';
export const SELECT_MENUS_CLASS = 'ui-select-menus';
export const SELECT_MENU_CLASS = 'ui-select-menu';
export const SELECT_ITEM_CLASS = 'ui-select-item';
export const SELECT_ITEM_GROUP_CLASS = 'ui-select-item-group';
export const SELECT_MENU_ITEM_CLASS = 'ui-select-menu-item';
export const SELECT_ITEM_OPTION_CLASS = 'ui-select-item-option';
export const SELECT_ITEM_OPTION_DISABLED_CLASS = 'ui-select-item-option-disabled';
export const SELECT_MENU_ITEM_ACTIVE_CLASS = 'ui-select-menu-item-active';
export const SELECT_ITEM_OPTION_ACTIVE_CLASS = 'ui-select-item-option-active';
export const SELECT_ITEM_OPTION_SELECTED_CLASS = 'ui-select-item-option-selected';
export const SELECT_ITEM_OPTION_CONTENT_CLASS = 'ui-select-item-option-content';
export const SELECT_DROPDOWN_CLASS = 'ui-select-dropdown';
export const SELECT_DROPDOWN_HIDDEN_CLASS = 'ui-select-dropdown-hidden';
export const SELECT_VIRTUAL_LIST_HOLDER = 'rc-virtual-list-holder';
export const SELECT_VIRTUAL_LIST_HOLDER_INNER = 'rc-virtual-list-holder-inner';
export const SELECT_VIRTUAL_LIST_SCROLLBAR = 'rc-virtual-list-scrollbar';
export const SELECT_VIRTUAL_LIST_SCROLLBAR_SHOW_CLASS = 'rc-virtual-list-scrollbar-show';
export const SELECT_MENU_ITEM_EXPAND_ICON_CLASS = 'ui-select-menu-item-expand-icon';
export const SELECT_SCROLL_THUMB = 'rc-virtual-list-scrollbar-thumb';
export const tokens = {
    Trigger: {
        boxShadow: theme.shadows.xs,
        primary: inputTokens.Input.primary,
        error: inputTokens.Input.error,
        disabled: inputTokens.Input.disabled,
        sizes: inputTokens.Input.size,
        minWidth: '80px'
    },
    IconWrap: {
        hover: {
            backgroundColor: theme.colors.grey100
        }
    },
    Icon: {
        color: theme.colors.grey500
    },
    Placeholder: {
        color: theme.colors.grey600
    },
    Item: Object.assign(Object.assign({}, dropdownTokens.Item), { gap: theme.spaces.x2 }),
    Content: {
        padding: theme.spaces.x8,
        zIndex: theme.zLayer.xxl
    },
    Separator: {
        margin: theme.spaces.x8
    },
    Label: {
        fontSize: theme.fontSizes.x12
    },
    Menu: {
        scrollThumbBackground: theme.colors.grey200,
        hoverScrollThumbBackground: theme.colors.grey300,
        borderColor: theme.colors.grey100,
        size: {
            s: {
                padding: dropdownTokens.size.md.padding,
                // top+ bottom padding and lineHeight = 32
                listItemHeight: 32
            },
            m: {
                padding: dropdownTokens.size.md.padding,
                // top+ bottom padding and lineHeight = 32
                listItemHeight: 32
            },
            l: {
                padding: dropdownTokens.size.lg.padding,
                // top+ bottom padding and lineHeight = 40
                listItemHeight: 40
            }
        },
        iconColor: theme.colors.grey700
    },
    Root: inputTokens.Root,
    Cascader: {
        Menu: {
            height: '210px'
        }
    },
    ToolbarButton: {
        active: {
            color: theme.colors.primary500
        },
        default: {
            color: theme.colors.grey500,
            marginLeft: theme.spaces.x10
        }
    },
    NoResults: {
        color: theme.colors.error300,
        fontFamily: theme.fontFamily.text
    },
    SearchBtn: {
        color: theme.colors.grey500
    }
};
