import styled from 'styled-components';
import { Button } from '../Button';
import { Icon, MiniXCloseIcon } from '../Icons';
import { getColorCode } from '../theme/utils';
import { tokens } from './tokens';
export const StyledButton = styled(Button) `
  border-radius: ${tokens.BadgesRoot.borderRadius};
  padding: ${({ size }) => tokens.BadgesRoot.size[size].padding};
  height: ${({ size }) => tokens.BadgesRoot.size[size].height};
  ${({ rounded, size }) => (rounded ? `width: ${tokens.BadgesRoot.size[size].height};` : '')}
  font-size: ${({ size }) => tokens.BadgesRoot.size[size].fontSize};
  font-weight: ${tokens.BadgesRoot.weight};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  ${({ disabled, shadeType, tone, filled }) => disabled
    ? 'cursor: not-allowed;'
    : `
  &:hover,
  &&:focus,
  &&& {
    border: none;
    color: ${tokens.BadgesRoot.shadeType[shadeType].color};
    background: ${filled ? getColorCode(tone, tokens.BadgesRoot.shadeType[shadeType].backgroundShade) : 'transparent'};
    outline: none;
    box-shadow: none;
  }
  `}

  /** Style icon base on size */
  ${Icon} {
    color: ${({ tone, shadeType }) => tokens.BadgesRoot.shadeType[shadeType].iconShade === 0
    ? tokens.BadgesRoot.white
    : getColorCode(tone, tokens.BadgesRoot.shadeType[shadeType].iconShade)};
    font-size: ${({ size }) => tokens.BadgesRoot.size[size].iconFontSize};
  }
`;
export const StyledClearIcon = styled(MiniXCloseIcon) `
  color: ${({ tone, shadeType }) => getColorCode(tone, tokens.BadgesRoot.shadeType[shadeType].closeIconShade)};
  cursor: pointer;
`;
