const impersonatingUserFlag = 'prophecy.ui.isImpersonating';

export function setImpersonatingFlag(enabled: boolean) {
  if (enabled) {
    const expirationTime = new Date().getTime() + 30 * 60 * 1000;
    localStorage.setItem(impersonatingUserFlag, expirationTime.toString());
  } else {
    localStorage.removeItem(impersonatingUserFlag);
  }
}

export function isImpersonatingUser() {
  const flagExpirationTime = localStorage.getItem(impersonatingUserFlag);

  if (flagExpirationTime) {
    const currentTime = new Date().getTime();
    const expirationTime = parseInt(flagExpirationTime);

    if (currentTime < expirationTime) {
      return true;
    } else {
      localStorage.removeItem(impersonatingUserFlag);
    }
  }

  return false;
}
