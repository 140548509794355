import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { noop } from 'lodash-es';
import { useState } from 'react';
export const useStepper = (steps, onStepChange = noop, onComplete, firstStepIndex = 0) => {
    const [stepIndex, setStepIndex] = useState(firstStepIndex);
    const currentStep = steps[stepIndex];
    const isFirstStep = stepIndex === firstStepIndex;
    const isLastStep = stepIndex >= steps.length - 1;
    const stepProgress = (100 * stepIndex + 1) / steps.length;
    const nextStep = usePersistentCallback(() => {
        if (!isLastStep) {
            setStepIndex(stepIndex + 1);
            onStepChange(steps[stepIndex + 1]);
        }
        else {
            onComplete();
        }
    });
    const prevStep = usePersistentCallback(() => {
        if (stepIndex) {
            setStepIndex(stepIndex - 1);
            onStepChange(steps[stepIndex - 1]);
        }
    });
    return { stepIndex, currentStep, isFirstStep, isLastStep, prevStep, nextStep, stepProgress };
};
