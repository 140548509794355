import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { WorkflowModeType } from '../graphqlTypes/enums';
import { BaseLSPClient } from '../LSP/base/BaseLSPClient';
import { useBaseLSPClient } from '../LSP/base/BaseLSPContext';
import { useLSPMethod } from '../LSP/base/hook';
import { LSP } from '../LSP/base/types';
import { getCurrentProcessGraphPath, getGraphProcess, getProcessById, metaInfoPath } from '../Parser/bindings';
import { ComponentInfo, GemSpec, GemType } from '../Parser/types';
import { Port, WorkflowGraph } from '../redux/types';
import { FROZEN_EMPTY_ARRAY } from './constants';
import { useUserFabrics } from './Fabric/hooks';
import { isSparkFabric } from './Fabric/utils';
import { getGemSpec } from './getGemSpec';
import {
  BaseProcess,
  BaseProcessMetadata,
  BaseState,
  CommonGraph,
  CommonState,
  Connection,
  GenericGraph,
  GenericGraphProcessType,
  Metadata
} from './types';

export function useGraphMetaInfo<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>() {
  return useSelector<BaseState<G, Metadata>, G['metainfo']>((state) => state.currentGraph?.metainfo);
}
export function useGraphMetaInfoFabricId() {
  const lspClient = useBaseLSPClient() as BaseLSPClient<unknown>;
  const isInitialized = lspClient.initialized;
  const { method: propertiesDidChange } = useLSPMethod(LSP.Method.propertiesDidChange);
  const allFabrics = useUserFabrics();
  const sparkFabrics = allFabrics.filter((fabric) => isSparkFabric(fabric));
  const fabricId = useSelector<BaseState<WorkflowGraph, Metadata>, WorkflowGraph['metainfo']['fabricId']>(
    (state) => state.currentGraph?.metainfo.fabricId || ''
  );
  const firstSparkFabricId = sparkFabrics[0]?.id;
  const matchingSparkFabricId = sparkFabrics.find((item) => item.id === fabricId)?.id;
  useEffect(() => {
    // if pipeline contains fabric id not matching any spark fabric, set first spark fabric as fabric for pipeline
    if (isInitialized && !matchingSparkFabricId && firstSparkFabricId) {
      propertiesDidChange({ property: `${metaInfoPath}.fabricId`, value: firstSparkFabricId });
    }
  }, [matchingSparkFabricId, firstSparkFabricId, propertiesDidChange, isInitialized]);
  return fabricId;
}

export function useWorkflowMode() {
  return useSelector<BaseState<WorkflowGraph, Metadata>, WorkflowModeType | undefined>(
    (state) => state.currentGraph?.metainfo.mode
  );
}

/**
 * Note: We might not get spec of a component if the component is deleted. so return of this can be undefined.
 */
export function useComponentSpec<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>(gemType: GemType) {
  return useSelector<BaseState<G>, GemSpec | undefined>((state) => {
    return getGemSpec(state.gemSpecs, gemType);
  });
}

export function useCurrentProcess<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>,
  T = GenericGraphProcessType<G>
>(processSelector: (process: GenericGraphProcessType<G>) => T = (process) => process as unknown as T) {
  const processInfo = useSelector<BaseState<G, Metadata>, T>((state) => {
    const { graphPath, currentComponentId: processId, currentGraph: rootGraph } = state;
    const currentProcessGraphPath = getCurrentProcessGraphPath(processId as string, graphPath);
    const rootPath = state.root;
    const process = getGraphProcess(rootGraph as G, currentProcessGraphPath as string, processId as string, rootPath);
    return processSelector(process);
  });

  return processInfo;
}

export const useInputPorts = () => {
  return useCurrentProcess((process) => process.ports?.inputs || (FROZEN_EMPTY_ARRAY as Port[]));
};

export function useProcessDialogOpenState() {
  const componentId = useSelector<CommonState, string | undefined>((state) => state.currentComponentId);
  const graphPath = useSelector<CommonState, string | undefined>((state) => state.graphPath);

  return { open: Boolean(componentId), componentId, graphPath };
}

export function gemTypeToString(gemType: GemType) {
  if (gemType.componentInfo) {
    return gemType.componentInfo?.gemId;
  }
  return gemType.component;
}
export function gemSpecsToGemTypeString(gemSpecs: CommonState['gemSpecs']) {
  const gemTypes = gemSpecs.map((spec) => gemTypeToString(gemSpecToGemType(spec)));
  return gemTypes;
}
export function gemSpecToGemType(gemSpec: GemSpec): GemType {
  return {
    componentInfo: gemSpec.componentInfo ? componentInfoToProcessComponentInfo(gemSpec.componentInfo) : undefined,
    component: gemSpec.kind,
    category: gemSpec.category
  };
}

export function getGemTypeByKind<K extends string>(gemSpecs: CommonState['gemSpecs'], kind: K) {
  const spec = gemSpecs.find((spec) => spec.kind === kind);
  if (spec) {
    return gemSpecToGemType(spec);
  }
}
export function getGemSpecByKind<K extends string>(gemSpecs: CommonState['gemSpecs'], kind: K) {
  const spec = gemSpecs.find((spec) => spec.kind === kind);
  return spec;
}
function componentInfoToProcessComponentInfo(componentInfo: ComponentInfo): ComponentInfo {
  return componentInfo;
}
export function useGraphMetaInfoOriginalState<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>() {
  return useSelector<BaseState<G, Metadata>, G['metainfo']>((state) => state.$graph?.metainfo);
}

export function usePortProcess<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
>() {
  const { currentGraph, rootPath, gemSpecs } = useSelector<
    CommonState,
    { currentGraph?: CommonGraph; rootPath: string; gemSpecs: GemSpec[] }
  >((state) => {
    const { currentGraph, root: rootPath, gemSpecs } = state;
    return {
      currentGraph: (currentGraph as unknown as { currentModelGraph: G })?.currentModelGraph || currentGraph,
      rootPath,
      gemSpecs
    };
  });

  return usePersistentCallback(
    <T,>(
      portId: string,
      processSelector: (process?: GenericGraphProcessType<G>) => T = (process) => process as unknown as T
    ) => {
      const processId = currentGraph?.connections.find(({ targetPort }) => targetPort === portId)?.source;
      return processSelector(
        processId ? getProcessById(gemSpecs, currentGraph as G, rootPath, processId)?.process : undefined
      );
    }
  );
}

export function useCompilationInProgress() {
  return useSelector<CommonState, boolean>((state) => !!state.logMessage);
}
