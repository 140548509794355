export enum LogCategory {
  diagnostics = 'diagnostics',
  runtime = 'runtime'
}

export enum LogType {
  error = 'error',
  warning = 'warning',
  info = 'info',
  hint = 'hint',
  debug = 'debug',
  success = 'success'
}
