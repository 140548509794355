import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
const langContext = {
    initializeSpec: (language, fabric) => { },
    spec: undefined,
    getSpec: (language, fabric) => Promise.resolve(undefined)
};
export const LanguageSpecContext = createContext(langContext);
export function LanguageSpecProvider({ initializeSpec, getSpec, spec, children }) {
    return (_jsx(LanguageSpecContext.Provider, { value: { initializeSpec, getSpec, spec }, children: children }));
}
export function useLanguageSpec() {
    return useContext(LanguageSpecContext);
}
