import { AIRFLOW_PROVIDER } from '../../constants/airflow';
import { SQL_PROVIDER } from '../../constants/sql';
import { DQ_PROVIDER } from './types/dataQuality';
import { SPARK_PROVIDER } from './types/spark';

export enum FabricProviderType {
  Spark = 'Spark',
  SQL = 'SQL',
  Airflow = 'Airflow',
  DataQuality = 'Data Quality'
}

export type FabricProviderMap = {
  [FabricProviderType.SQL]: SQL_PROVIDER;
  [FabricProviderType.Spark]: SPARK_PROVIDER;
  [FabricProviderType.Airflow]: AIRFLOW_PROVIDER;
  [FabricProviderType.DataQuality]: DQ_PROVIDER;
};

export type UserSQLCreds = { fabrics: Record<string, { authType?: string }> };
