import { __awaiter, __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFocusTrap } from '@prophecy/utils/react/focus';
import { useControlledState } from '@prophecy/utils/react/hooks';
import { FocusScope } from '@radix-ui/react-focus-scope';
import { Provider, Root, Portal, Trigger } from '@radix-ui/react-tooltip';
import React, { isValidElement } from 'react';
import styled from 'styled-components';
import { CopyAIcon } from '../Icons';
import { Stack } from '../Layout';
import { Markdown } from '../Markdown';
import { theme } from '../theme';
import { StyledContent, StyledArrow, tokens } from './styled';
import { TooltipVariant } from './types';
const StyledMarkDown = styled(Markdown) `
  color: inherit;
`;
const StyledCopyIcon = styled(CopyAIcon) `
  cursor: pointer;
  z-index: ${theme.zLayer.l};
  background: inherit;
`;
export function Tooltip(_a) {
    var { title, visible, onVisibleChange, children, overlayClassName, overlayStyle, arrow = true, placement: side = 'top', placementOffset: sideOffset = 8, align, hasCopy = false, alignOffset, delay = 100, variant = TooltipVariant.default, disabled, isMarkdown = false } = _a, contentProps = __rest(_a, ["title", "visible", "onVisibleChange", "children", "overlayClassName", "overlayStyle", "arrow", "placement", "placementOffset", "align", "hasCopy", "alignOffset", "delay", "variant", "disabled", "isMarkdown"]);
    const [_visible, toggleVisible] = useControlledState({
        value: !title || disabled ? false : visible,
        onChange: onVisibleChange
    });
    const titleRef = React.createRef();
    const container = useFocusTrap(Boolean(_visible));
    const onCopy = (e) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        e.stopPropagation();
        const text = ((_a = titleRef === null || titleRef === void 0 ? void 0 : titleRef.current) === null || _a === void 0 ? void 0 : _a.innerText) || '';
        yield navigator.clipboard.writeText(text);
    });
    const content = (_jsxs(StyledContent, Object.assign({ variant: variant, className: overlayClassName, style: overlayStyle, side: side, align: align, sideOffset: sideOffset, alignOffset: alignOffset, ref: container, tabIndex: -1 }, contentProps, { children: [arrow && _jsx(StyledArrow, { variant: variant, offset: parseInt(tokens.Arrow.offset, 10) }), _jsxs(Stack, { direction: 'horizontal', width: '100%', align: 'space-between', gap: theme.spaces.x4, children: [_jsx("span", { ref: titleRef, children: isMarkdown ? _jsx(StyledMarkDown, { children: title }) : title }), hasCopy && title && _jsx(StyledCopyIcon, { size: 'xs', type: 'default', onClick: onCopy })] })] })));
    return (_jsx(Provider, { children: _jsxs(Root, { open: _visible, delayDuration: delay, onOpenChange: toggleVisible, children: [_jsx(Trigger, { asChild: true, children: children }), _jsx(Portal, { children: _jsx(FocusScope, { asChild: true, trapped: false, onUnmountAutoFocus: (e) => e.preventDefault(), onMountAutoFocus: (e) => e.preventDefault(), children: content }) })] }) }));
}
export const renderWithTooltip = (content, tooltip) => {
    if (!tooltip)
        return content;
    const tooltipProps = isValidElement(tooltip)
        ? {
            title: tooltip,
            placement: 'top',
            isMarkdown: typeof tooltip === 'string',
            overlayStyle: {
                maxWidth: '500px',
                maxHeight: '300px',
                overflow: 'auto',
                padding: theme.spaces.x12
            }
        }
        : tooltip;
    return _jsx(Tooltip, Object.assign({}, tooltipProps, { children: content }));
};
