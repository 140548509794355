import { Callable, NestedData } from '@prophecy/interfaces/generic';
import { message, toast } from '@prophecy/ui';
import { isNodeEvnProduction } from '@prophecy/utils/env';
import { ProphecyError, isProphecyError } from '@prophecy/utils/error';
import { clearStorage, navigateAndRefresh } from '@prophecy/utils/history';
import { addBreadcrumb } from '@sentry/react';
import { noop, partialMatchKey } from '@tanstack/query-core/utils';
import {
  MutationFunction,
  QueryKey,
  useMutation as useReactMutation,
  UseMutationOptions,
  useQuery as useReactQuery,
  useInfiniteQuery as useReactInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  useMutation,
  useIsFetching,
  QueryFunction
} from '@tanstack/react-query';
import { TadaDocumentNode } from 'gql.tada';
import { GraphQLClient, RequestDocument, Variables } from 'graphql-request';
import { castArray, isString } from 'lodash-es';
import { useState, useEffect } from 'react';

import { delay } from '../common/onboarding/dom-util';
import { captureException } from '../common/sentry';
import { SentryBreadcrumb, SentryBreadcrumbType } from '../common/sentry/SentryBreadcrumb';
import { GqlErrorTags, SentryTags, ErrorTags, HTTPErrorTags } from '../common/sentry/SentryTags';
import { isAuthRoute, isPublicURL, Public_Routes } from '../common/url';
import { mdClient } from '../LSP/websocket/MdClient/MdWebSocketBase';
import { AsyncEventProgress } from '../LSP/websocket/MdClient/queries';
import { csrf } from '../utils/csrf';
import { getGraphQlEndPoint, getGraphQlEndPointNew } from '../utils/getServerUrl';
import { appLogger, AppLogLevel } from './apis/appLogger';

const endpoint = getGraphQlEndPoint();
const endpointNew = getGraphQlEndPointNew();

export type ExecutionServiceError = { msg: string; message: string; trace: string[] | string };

export type ReactQueryError = { errors: { message: string }[] };

export type Headers = {
  [key: string]: string;
};

function getQueryKeyWithVariables(key: QueryKey, variables?: unknown) {
  if (variables !== undefined) {
    return [...key, variables];
  }

  return key;
}

export const redirectToAuth = (redirect = window.location.href) => {
  navigateAndRefresh(Public_Routes.SignIn.getUrl(undefined, { redirect }));
};

const formatErrorTrace = (trace?: string | string[]) => {
  return Array.isArray(trace) ? trace.join('\n') : trace;
};

export function errorHandler(exception: unknown): void;
export function errorHandler(exception: unknown, sentryTag: string | undefined): void;
export function errorHandler(exception: unknown, sentryTag?: string) {
  let error = exception as ProphecyError;
  if (!sentryTag && !isNodeEvnProduction()) {
    console.warn('sentryTag is mandatory when capturing exception');
  }

  if (!isProphecyError(error)) {
    error = convertToProphecyError(exception);
  }

  captureException({
    exception: error,
    errorTags: { [SentryTags.ProphecyErrorHttpType]: sentryTag || HTTPErrorTags.QueryError }
  });

  // If the error is not thrown by us, mask stackTrace and message for user
  if (!error.cause) {
    console.error(error);
    error = new ProphecyError('Something went wrong');
  }

  message.error({ content: error.message, detail: error.stack });
}
function isExecutionServiceError(exception: unknown): exception is ExecutionServiceError {
  return Boolean((exception as ExecutionServiceError)?.message || (exception as ExecutionServiceError)?.msg);
}
function convertToProphecyError(exception: unknown) {
  const message = (exception as ExecutionServiceError)?.message || (exception as ExecutionServiceError)?.msg;
  if (isExecutionServiceError(exception)) {
    const err = exception;
    const error = new ProphecyError(message);
    error.stack = formatErrorTrace(err?.trace);
    return error;
  } else if (isString(exception)) {
    return new ProphecyError(exception);
  } else {
    return new ProphecyError('Something went wrong');
  }
}

const handleUnAuthorizedCall = (status: number, url: string) => {
  const path = window.location.pathname;
  let isAuthorized = true;
  if (status === 401 && !isAuthRoute(path) && !isPublicURL(path)) {
    appLogger({
      entity: 'User',
      operation: '401 Redirect',
      log: `Previous API call: ${url}
      Current Path: ${path}`,
      logLevel: AppLogLevel.error
    });
    csrf.delete();
    toast.error({ content: `Login Expired` });
    clearStorage();
    redirectToAuth();
    captureException({
      exception: new ProphecyError(`Login Expired`),
      errorTags: { [SentryTags.ProphecyErrorHttpType]: HTTPErrorTags.SessionExpire }
    });
    isAuthorized = false;
  }
  return isAuthorized;
};

export const uploadFiles = async (
  url: string,
  method: string,
  body: XMLHttpRequestBodyInit,
  callBack: (percentage: number, error?: boolean) => void
) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    let progress = 0;
    xhr.open(method, url, true);
    xhr.setRequestHeader(csrf.key, csrf.get());
    xhr.withCredentials = true;
    xhr.setRequestHeader('accept', 'application/json;');
    xhr.addEventListener('loadend', async () => {
      const isAuthorized = handleUnAuthorizedCall(xhr.status, url);
      if (!isAuthorized) {
        return reject(xhr.response);
      }
      const response = new Response(xhr.responseText, {
        status: xhr.status,
        statusText: xhr.statusText
      });

      if (!response.ok) {
        callBack(100, true);
        reject(new Error(xhr.response));
      } else {
        try {
          const resp = response.json();
          if (progress !== 100) {
            callBack(100);
          }
          resolve(resp);
        } catch (e) {
          callBack(100, true);
          captureException({
            exception: e,
            errorTags: { [SentryTags.ProphecyErrorType]: ErrorTags.JavascriptError }
          });
          throw new ProphecyError((e as { message: string })?.message || 'Something went wrong in an API');
        }
      }
    });
    xhr.upload.addEventListener('progress', (event) => {
      progress = (event.loaded / event.total) * 100;
      callBack(progress);
    });
    xhr.send(body as XMLHttpRequestBodyInit);
  });
};

export async function _fetch(url: URL | RequestInfo, requestConfig: RequestInit | undefined) {
  const body = requestConfig?.body;
  const isFormData = body instanceof FormData;
  const requestHeaders: NestedData = {
    accept: 'application/json;',
    'Content-Type': 'application/json;charset=utf-8',
    [csrf.key]: csrf.get(),
    ...requestConfig?.headers
  };
  if (isFormData && body) {
    delete requestHeaders['Content-Type'];
  }
  // add csrf token and credentials
  const modifiedOptions: RequestInit = {
    credentials: 'include',
    ...requestConfig,
    headers: { [csrf.key]: csrf.get(), ...requestHeaders }
  };

  let response: Response | undefined;

  try {
    response = await fetch(url, modifiedOptions);
  } catch (e) {
    captureException({
      exception: e,
      errorTags: { [SentryTags.ProphecyErrorType]: ErrorTags.JavascriptError }
    });
    throw new ProphecyError('Something went wrong in an API');
  }

  const isAuthorized = handleUnAuthorizedCall(response?.status, url.toString?.());

  if (!response?.ok && isAuthorized) {
    // anything outside 200 range
    let errorResponse;
    const clonedResponse = response?.clone();
    try {
      errorResponse = await response?.json();
    } catch (e) {
      const message = (await clonedResponse?.text()) || 'Something went wrong';
      // Putting a check if html response is returned the ui is breaking with toast/message
      throw new ProphecyError(message.length > 100 ? message.slice(0, 100) : message);
    }
    throw errorResponse;
  }
  // TODO: remove below logic once backend starts sending right content-type header
  const headers: [string, string][] = [];
  for (const [key, value] of response.headers.entries()) {
    if (key === 'content-type') {
      headers.push([key, 'application/json']);
    } else {
      headers.push([key, value]);
    }
  }
  return new Response(response.body, {
    status: response.status,
    statusText: response.statusText,
    headers
  });
}
const graphQLClient = new GraphQLClient(endpoint, {
  fetch: _fetch
});

const graphQLClientNew = new GraphQLClient(endpointNew, {
  fetch: _fetch
});

const graphQlBeaconClient = new GraphQLClient(endpoint, {
  fetch: (url: URL | RequestInfo, requestConfig: RequestInit | undefined) =>
    _fetch(url, { ...requestConfig, keepalive: true })
});

export function sendGraphQlBeacon(query: RequestDocument, variables?: Variables) {
  graphQlBeaconClient.request({ document: query, variables });
}

export async function makeRestCall(url: RequestInfo, requestConfig?: RequestInit) {
  const response = await _fetch(url, requestConfig);

  let data;
  const clone = response.clone();
  try {
    data = await response.json();
  } catch (error) {
    data = await clone.text();
  }
  return data;
}

export async function makePostCall(url: string, body: unknown, headers?: Headers) {
  return await makeRestCall(url, {
    method: 'POST',
    body: body instanceof FormData ? (body as BodyInit) : JSON.stringify(body),
    headers
  });
}

export async function makeBeaconCall(url: string, body: unknown, headers?: Headers) {
  return await makeRestCall(url, {
    method: 'POST',
    body: body instanceof FormData ? (body as BodyInit) : JSON.stringify(body),
    headers,
    keepalive: true
  });
}

export async function makeGetCall(url: string) {
  return await makeRestCall(url, {
    method: 'GET'
  });
}

export async function makeDeleteCall(url: string, body: unknown) {
  return await makeRestCall(url, {
    method: 'DELETE',
    body: body instanceof FormData ? (body as BodyInit) : JSON.stringify(body)
  });
}
function createCaller(graphQLClient: GraphQLClient) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async function makeGraphQlCall<T = any, V = Variables>(
    query: RequestDocument,
    variables?: V,
    queryName?: string
  ) {
    let name = queryName;
    if (!queryName) {
      name = guessQueryName(query as string);
    }
    try {
      addBreadcrumb({
        type: SentryBreadcrumbType.gql,
        category: SentryBreadcrumb.GraphQl,
        message: name,
        level: 'info'
      });
      const data = await graphQLClient.request({ document: query, variables: variables as Variables });
      return data as T;
    } catch (error) {
      console.error(JSON.stringify(error, undefined, 2));
      throw error;
    }
  };
}
export const makeGraphQlCall = createCaller(graphQLClient);
export const makeGraphQlCallSubscription = createCaller(graphQLClientNew);

export type ResponseFormat<T> = {
  success: boolean;
  message: string;
  code?: string;
  data: T;

  error?: string;
  trace?: string;
  stack?: string;
};

export type XHRResponseType<T> = Promise<ResponseFormat<T>>;

type XHRMethod<T, S> = (variables: S) => XHRResponseType<T>;

type ExtractXHRReturnType<C extends Callable> =
  ReturnType<C> extends Promise<{
    success: boolean;
    message: string;
    data: infer D;
  }>
    ? { message: string; data: D }
    : { message: string; data: never };

export type ExtractXHRResponseDataType<C extends Callable> =
  ReturnType<C> extends Promise<{
    message: string;
    data: infer D;
  }>
    ? D
    : never;

const handleXHRResponse = <T>(response: ResponseFormat<T>) => {
  if (response.success) {
    return { message: response.message, data: response.data };
  } else {
    const err = new ProphecyError(response.message || response.error, {
      cause: response.code
    });
    err.stack = formatErrorTrace(response.trace || response.stack);
    throw err;
  }
};

export const handleXHRError = <T>(
  err: ProphecyError | ResponseFormat<T>,
  onError: (err: ProphecyError) => void = errorHandler
) => {
  if (isProphecyError(err)) {
    onError(err);
  } else {
    try {
      handleXHRResponse(err);
    } catch (e) {
      onError(e as ProphecyError);
    }
  }
};

export function xhrWrapper<C extends Callable>(callback: C) {
  return async function (...args: Parameters<C>): Promise<ExtractXHRReturnType<C>> {
    const response = await callback(...args);
    return handleXHRResponse(response) as ExtractXHRReturnType<C>;
  };
}

export const mockXHRResponse = <T>(data: T, message: string = '', success: boolean = true) => {
  return Promise.resolve({ success, message, data }) as XHRResponseType<T>;
};

type CustomQueryOptions<T extends { onError?: Callable }> = Omit<T, 'onError'> & {
  onError?: (err: ProphecyError) => true | void;
  sentryTag?: string;
};

const getDefaultRestQueryOptions = <T extends { onError?: Callable }>(options?: CustomQueryOptions<T>) => ({
  ...((options || {}) as T),
  onError: (err: ProphecyError | ResponseFormat<unknown>) => {
    // in case of error response code (other than 200) xhrWrapper will get skipped
    //   so we check here again
    handleXHRError(err, (err: ProphecyError) => {
      let handleDefault = true;

      if (options?.onError) {
        handleDefault = Boolean(options.onError(err));
      }

      if (handleDefault) {
        errorHandler(err, options?.sentryTag);
      }
    });
  }
});

export const useRestQuery = <T = unknown>(
  key: QueryKey,
  queryFn: () => XHRResponseType<T>,
  queryOptions: CustomQueryOptions<UseQueryOptions<ExtractXHRReturnType<typeof queryFn>, ProphecyError>> = {}
) => {
  queryOptions.sentryTag ||= castArray(key).join('_');
  const _queryOptions = getDefaultRestQueryOptions(queryOptions);

  return useReactQuery<ExtractXHRReturnType<typeof queryFn>, ProphecyError>(
    key,
    () => xhrWrapper(queryFn)(),
    _queryOptions
  );
};

export const useRestMutation = <T = unknown, S = unknown>(
  queryFn: XHRMethod<T, S>,
  queryOptions: CustomQueryOptions<UseMutationOptions<ExtractXHRReturnType<typeof queryFn>, ProphecyError, S>> & {
    sentryTag: SentryTags | string;
  }
) => {
  const _queryOptions = getDefaultRestQueryOptions(queryOptions);
  return useReactMutation<ExtractXHRReturnType<typeof queryFn>, ProphecyError, S>(xhrWrapper(queryFn), _queryOptions);
};

export function guessQueryName(query: string) {
  let name = '';
  try {
    name = query.split('(').shift()?.trim().split(' ').pop() as string;
  } catch (error) {}
  return name;
}

export function getGqlDefaultOptions<S extends ReactQueryError, T extends { onError?: Callable }>(
  queryOptions: T | undefined,
  queryName: string
) {
  return {
    ...(queryOptions || ({} as T)),
    onError: (errorResponse: S) => {
      let handleDefault = true;
      if (queryOptions?.onError) {
        handleDefault = Boolean(queryOptions.onError(errorResponse, queryName));
      }
      if (!handleDefault) return;

      let errorMessage = readGraphQlErrorMessage(errorResponse);
      captureException({
        exception: new ProphecyError(errorMessage),
        errorTags: { [SentryTags.ProphecyErrorGqlType]: queryName || GqlErrorTags.QueryError }
      });
      message.error({ content: errorMessage });
    }
  };
}

export function useGraphQlQuery<
  TData = unknown,
  V extends Variables = Variables,
  TQueryFnData = unknown,
  TError = ReactQueryError
>(
  key: QueryKey,
  query: TadaDocumentNode<TData, V> | string,
  queryOptions: UseQueryOptions<TQueryFnData, TError, TData, QueryKey> = {},
  variables?: V
) {
  const queryName = guessQueryName(query as string);
  const _queryOptions = getGqlDefaultOptions(queryOptions, queryName);

  return useReactQuery<TQueryFnData, TError, TData, QueryKey>(
    getQueryKeyWithVariables(key, variables),
    () => {
      return makeGraphQlCall(query, variables, queryName);
    },
    _queryOptions
  );
}

// easier approach for graphQl hook
export function useGraphQlQueryFn<TData = unknown, V extends Variables = Variables>(
  key: QueryKey,
  queryFn: (input: V) => Promise<TData>,
  queryOptions: UseQueryOptions<TData, ReactQueryError, TData, QueryKey> & { queryName: string },
  variables: V
) {
  const queryKey = getQueryKeyWithVariables(key.concat(queryOptions.queryName), variables);
  const _queryOptions = getGqlDefaultOptions(queryOptions, queryOptions.queryName);
  return useReactQuery<TData, ReactQueryError, TData, QueryKey>(queryKey, () => queryFn(variables), _queryOptions);
}

export function useInfiniteGraphQlQuery<
  TData = unknown,
  V = unknown,
  TQueryFnData = Partial<V>,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey
>(
  key: TQueryKey,
  query: TadaDocumentNode<TData, V> | string,
  queryOptions: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey> = {},
  variables?: V
) {
  const queryName = guessQueryName(query as string);
  const _queryOptions = getGqlDefaultOptions(queryOptions, queryName);

  return useReactInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>(
    key,
    ({ pageParam }) => {
      return makeGraphQlCall(query, { ...variables, ...pageParam }, queryName);
    },
    _queryOptions
  );
}

export function readGraphQlErrorMessage(_errorResponse: unknown) {
  const __errorResponse = _errorResponse as NestedData;
  let errorMessage = '';
  if (__errorResponse.response) {
    const errorResponse = __errorResponse.response as ReactQueryError;
    errorMessage = errorResponse?.errors[0]?.message || '';
  } else if (__errorResponse.errors) {
    errorMessage = __errorResponse?.errors[0]?.message || '';
  } else if (__errorResponse.message) {
    errorMessage = __errorResponse.message;
  }
  return errorMessage;
}

export function useGraphQlMutation<
  TData = unknown,
  TVariables extends Variables = Variables,
  TError = ReactQueryError,
  TContext = unknown
>(query: TadaDocumentNode<TData, TVariables> | string, queryOptions?: UseQueryOptions<TData, TError, TData, QueryKey>) {
  const queryName = guessQueryName(query as unknown as string);
  const _queryOptions = getGqlDefaultOptions(queryOptions, queryName);

  const queryFunction: MutationFunction<TData, TVariables> = (args) => {
    return makeGraphQlCall(query, args, queryName);
  };
  return useReactMutation<TData, TError, TVariables, TContext>(
    queryFunction,
    _queryOptions as UseMutationOptions<TData, TError, TVariables, TContext>
  );
}
export function useGraphQlQuerySubscription<
  TData = unknown,
  V extends Variables = Variables,
  TQueryFnData = unknown,
  TError = ReactQueryError
>(
  key: QueryKey,
  query: TadaDocumentNode<TData, V> | string,
  queryOptions: UseQueryOptions<TQueryFnData, TError, TData, QueryKey> = {},
  variables?: V,
  onProgress?: AsyncEventProgress
) {
  const queryName = guessQueryName(query as string);
  const _queryOptions = getGqlDefaultOptions(queryOptions, queryName);
  const fn: QueryFunction<TQueryFnData, QueryKey> = async (args) => {
    const { operation_id } = (await makeGraphQlCallSubscription(query, args, queryName)) as { operation_id: string };
    const response = await mdClient.subscribe({
      id: operation_id,
      onProgress: onProgress || noop
    });
    // delay, so onProgress can settle first
    await delay(250);
    return response as TQueryFnData;
  };
  return useReactQuery<TQueryFnData, TError, TData, QueryKey>(
    getQueryKeyWithVariables(key, variables),
    fn,
    _queryOptions
  );
}
export function useGraphQlMutationSubscription<
  TData = unknown,
  TVariables extends Variables = Variables,
  TError = ReactQueryError,
  TContext = unknown
>(
  query: TadaDocumentNode<TData, TVariables> | string,
  queryOptions?: UseQueryOptions<TData, TError, TData, QueryKey>,
  onProgress?: AsyncEventProgress
) {
  const queryName = guessQueryName(query as unknown as string);
  const _queryOptions = getGqlDefaultOptions(queryOptions, queryName);

  const queryFunction: MutationFunction<TData, TVariables> = async (args) => {
    const { operation_id } = (await makeGraphQlCallSubscription(query, args, queryName)) as { operation_id: string };
    const response = await mdClient.subscribe({
      id: operation_id,
      onProgress: onProgress || noop
    });
    // delay, so onProgress can settle first
    await delay(250);
    return response as TData;
  };
  return useReactMutation<TData, TError, TVariables, TContext>(
    queryFunction,
    _queryOptions as UseMutationOptions<TData, TError, TVariables, TContext>
  );
}

export function useGraphQlMutationFn<TData = unknown, TVariables extends Variables = Variables>(
  queryFn: MutationFunction<TData, TVariables>,
  queryOptions: Parameters<typeof useReactMutation>[2] & { queryName: string }
) {
  const _queryOptions = getGqlDefaultOptions(queryOptions, queryOptions.queryName);
  return useReactMutation<TData, ReactQueryError, TVariables>(
    queryFn,
    _queryOptions as UseMutationOptions<TData, ReactQueryError, TVariables>
  );
}

// hook to check after mutation if its dependent queries are refetching
export function useDependentDataFetching(mutationInProgress: boolean, ...queryKeys: QueryKey[]) {
  const [listenForFetch, setListenForFetch] = useState(false);

  const _fetchInProgress = useIsFetching({
    predicate: (query) => {
      return queryKeys.some((key) => partialMatchKey(query.queryKey, key));
    }
  });

  const fetchInProgress = _fetchInProgress > 0;

  useEffect(() => {
    if (mutationInProgress) {
      setListenForFetch(true);
    }
  }, [mutationInProgress]);

  useEffect(() => {
    /**
     * If fetch in progress settles, reset the listenForFetch.
     * There can be a case where fetch is happen from some previous invalidation, in which case
     * it might resolve while mutation is in progress, we need to ignore such cases
     */
    if (!fetchInProgress && !mutationInProgress) {
      setListenForFetch(false);
    }
    // Note: We just want to watch for fetchInProgress and not mutationInProgress, we just want to use the value of mutationInProgress at the time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchInProgress]);

  // we only need to listen for fetches after the mutation is completed
  return listenForFetch && fetchInProgress && !mutationInProgress;
}

/**
 * couple of time we just want to get some data from the backend on demand basis, which is not required to be cached.
 * This hook lets calling any api call to retrive data without caching it.
 */
export function useFetch<TData, TVariables>(cb: MutationFunction<TData, TVariables>) {
  const { isLoading, data, error, mutateAsync: fetch } = useMutation(cb);

  return { data, isLoading, error, fetch };
}
