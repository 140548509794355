import { ProphecyError } from '@prophecy/utils/error';
import { getTabId } from '@prophecy/utils/session';
import { addBreadcrumb } from '@sentry/react';
// eslint-disable-next-line  no-restricted-syntax
import * as Comlink from 'comlink';
import { WebSocket } from 'partysocket';

import { captureException } from '../../../common/sentry';
import { SentryBreadcrumb, SentryBreadcrumbType } from '../../../common/sentry/SentryBreadcrumb';
import { ProphecyWSErrorTags, SentryTags } from '../../../common/sentry/SentryTags';
import { addCSRFQuery } from '../../../utils/csrf';
import { getMdWSEndPoint } from '../../../utils/getServerUrl';
import { isWebsocketCompressionEnabled } from '../../../utils/localstorage-keys';
import { KeepAliveClient } from '../../base/KeepAliveClient';
import { getRequestId } from '../../base/utils';
import {
  AsyncEventQuery,
  Message,
  AsyncEventRequest,
  NonAsyncEventRequest,
  State,
  NonAsyncEvent,
  projectUpdateEventQuery,
  ProjectUpdateEventType,
  Progress
} from './queries';

const WebSocketWorker = Comlink.wrap<WebSocket>(
  new Worker(new URL('../../../data/ReconnectingWebsocketWorker', import.meta.url))
);

function getVariables(id: string) {
  return { id: id, operation: 'Subscription' };
}
function getSubscriptionPayload(id: string) {
  return {
    query: AsyncEventQuery,
    variables: getVariables(id)
  };
}
function isAsyncEventResponse(obj: Message['event']) {
  return obj && 'data' in obj && 'asyncEvent' in obj.data;
}
export class MDClient {
  private _ws: WebSocket | undefined;

  private bus: {
    [key: string]: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
      onProgress: AsyncEventRequest['onProgress'];
    };
  };

  private nonAsyncEventBus: {
    [key: string]: {
      onProgress: NonAsyncEventRequest['onProgress'];
    };
  };

  private _connected: boolean = false;

  public get connected() {
    return this._ws && this._connected;
  }

  private url: string;

  private compressionEnabled: boolean;

  private aliveClient: KeepAliveClient;

  constructor(url: string, lazyInit?: boolean, compressionEnabled?: boolean) {
    this.bus = {};
    this.nonAsyncEventBus = {};
    this.url = url;

    this.compressionEnabled = !!compressionEnabled;
    if (!lazyInit) {
      this._wsInit(url);
    }
    this.aliveClient = new KeepAliveClient(() => {
      this.sendPong();
    });
  }

  private async _wsInit(url: string) {
    let { _onMessage, _onOpen, _onClose, _onError } = this;

    if (this._ws) {
      return;
    }

    let ws: WebSocket;
    if (this.compressionEnabled) {
      // @ts-ignore
      // for websocket worker we send onOpen callback to constructor
      ws = await new WebSocketWorker(getUrl(url), undefined, Comlink.proxy(_onOpen));

      _onMessage = Comlink.proxy(_onMessage);
      _onClose = Comlink.proxy(_onClose);
      _onError = Comlink.proxy(_onError);
    } else {
      // we handle open event for compression enabled case in constructor for websocket worker
      // for non compression case we handle it normally
      ws = new WebSocket(getUrl(url));
      ws.addEventListener('open', _onOpen);
    }

    ws.addEventListener('message', _onMessage);
    ws.addEventListener('close', _onClose);
    ws.addEventListener('error', _onError);
    this._ws = ws;
  }

  private _onOpen = () => {
    this._connected = true;
    // replay existing requests
    this.aliveClient.init(false);
    this.replayPendingRequests();
  };

  private _onClose = () => {
    this._connected = false;
    this.aliveClient.onClose();
  };

  private _onError = (error: Event) => {
    // ignore event in case of offline or tab not focused
    if (window.navigator.onLine || document.visibilityState === 'visible') {
      captureException({
        exception: new ProphecyError('error occurred in websocket, closing websocket ' + this.url),
        errorTags: { [SentryTags.ProphecyErrorWSType]: ProphecyWSErrorTags.LspDisconnect }
      });
    }
  };

  private _onMessage = (event: MessageEvent) => {
    let messageDataStr = event.data;

    if (this.compressionEnabled) {
      messageDataStr = new TextDecoder().decode(messageDataStr);
    }
    try {
      const messageData = JSON.parse(messageDataStr) as Message;
      const pingMessage = messageData.data?.response;
      const response = messageData.event;
      if (pingMessage === 'ping') {
        this.aliveClient.onPing();
      } else if (isAsyncEventResponse(response)) {
        const { graphQlResponse, state, id, progress } = response?.data.asyncEvent as NonNullable<
          Message['event']
        >['data']['asyncEvent'];
        const errors = graphQlResponse?.errors;
        if (id && this.bus[id]) {
          const { resolve, reject, onProgress } = this.bus[id];
          if (errors) {
            reject({ errors });

            addBreadcrumb({
              type: SentryBreadcrumbType.wss,
              category: SentryBreadcrumb.LSPError,
              message: JSON.stringify({ id, error: errors }),
              level: 'error'
            });
            captureException({
              exception: new ProphecyError(errors[0].message),
              errorTags: { [SentryTags.ProphecyErrorWSType]: 'MDWSClient' }
            });
            this.cancel(id);
          } else if (state === State.completed) {
            addBreadcrumb({
              type: SentryBreadcrumbType.wss,
              category: SentryBreadcrumb.LSPResponse,
              message: JSON.stringify({ id, state, graphQlResponse }),
              level: 'info'
            });
            resolve(graphQlResponse?.data);
            this.cancel(id);
          } else {
            progress && onProgress(progress);
            addBreadcrumb({
              type: SentryBreadcrumbType.wss,
              category: SentryBreadcrumb.LSPNotification,
              message: JSON.stringify(progress),
              level: 'info'
            });
          }
        }
      } else {
        const { subscriptionId, ...progress } = (Object.values(response?.data || {})[0] || {}) as NonAsyncEvent;
        if (subscriptionId && this.nonAsyncEventBus[subscriptionId]) {
          const { onProgress } = this.nonAsyncEventBus[subscriptionId];
          progress && onProgress(progress as Progress);
          addBreadcrumb({
            type: SentryBreadcrumbType.wss,
            category: SentryBreadcrumb.LSPNotification,
            message: JSON.stringify(progress),
            level: 'info'
          });
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  private send(payload: object) {
    // if any send is called before the ws is initialized, it may be be coming due to previous broken connection, that can be ignored.
    // also after new connection is established, the state is reset, so we don't need to queue this messages.
    if (!this.connected) return;

    if (this._ws) {
      let messageData: string | Uint8Array = JSON.stringify(payload);
      addBreadcrumb({
        type: SentryBreadcrumbType.wss,
        category: SentryBreadcrumb.LSPRequest,
        message: JSON.stringify(payload),
        level: 'info'
      });
      if (this.compressionEnabled) {
        messageData = new TextEncoder().encode(messageData);
        messageData = Comlink.transfer(messageData, [messageData.buffer]);
      }

      this._ws.send(messageData);
    } else {
      return Promise.reject(`Websocket is not connected`);
    }
  }

  private replayPendingRequests() {
    if (this._ws) {
      Object.keys(this.bus).forEach((id) => {
        this.send(getSubscriptionPayload(id));
      });
    } else {
      return Promise.reject(`Websocket is not connected`);
    }
  }

  private cancel(id: string) {
    const payload = {
      query: 'subscription AsyncOps($id: String!){ cancelSubscription(subscriptionId:$id ) {subscriptionId,message}}',
      variables: {
        id: id,
        operation: 'Subscription'
      }
    };
    if (this._ws) {
      this.send(payload);
      if (this.nonAsyncEventBus[id]) {
        delete this.nonAsyncEventBus[id];
      }
      if (this.bus[id]) {
        delete this.bus[id];
      }
      return;
    }
    return Promise.reject(`Websocket is not connected`);
  }

  private async sendPong() {
    await this.waitForWS();

    const payload = {
      query: 'pong'
    };
    this.send(payload);
  }

  private async waitForWS() {
    if (this._ws) {
      return Promise.resolve(1);
    } else {
      let id: NodeJS.Timer;
      return new Promise((resolve) => {
        id = setInterval(() => {
          if (this._ws) {
            resolve(1);
            clearInterval(id);
          }
        }, 5000);
      });
    }
  }

  public async subscribe({ id, onProgress }: AsyncEventRequest) {
    await this.waitForWS();

    this.send(getSubscriptionPayload(id));
    return new Promise((resolve, reject) => {
      this.bus[id] = { resolve, reject, onProgress };
    });
  }

  public async subscribeToProjectUpdates(
    projectId: string,
    onProgress: (payload: { project: ProjectUpdateEventType }) => void
  ) {
    await this.waitForWS();

    const id = getRequestId();

    this.nonAsyncEventBus[id] = {
      // @ts-ignore
      onProgress: onProgress
    };
    this.send({
      query: projectUpdateEventQuery,
      variables: {
        projectId: projectId,
        subscriptionId: id,
        operation: 'Subscription'
      }
    });

    return () => {
      this.cancel(id);
    };
  }

  public lazyInit() {
    this._wsInit(this.url);
  }
}
function getUrl(url: string) {
  return addCSRFQuery(url.replace('<id>', getTabId()));
}
export const mdClient = new MDClient(getMdWSEndPoint(), true, isWebsocketCompressionEnabled);
