import styled from 'styled-components';
import { tokens } from './tokens';
export const StyledIndicator = styled.div `
  width: ${({ size }) => tokens.IndicatorRoot.size[size].size};
  display: inline-block;
  height: ${({ size }) => tokens.IndicatorRoot.size[size].size};
  border-radius: 50%;
  background: ${(props) => tokens.IndicatorRoot[props.variant].backgroundColor};
  flex-shrink: 0;
`;
