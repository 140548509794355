import { DataNode } from '@prophecy/ui/Tree';

import { Port } from '../../redux/types';

export enum PortType {
  input = 'in',
  output = 'out'
}

export interface PortSchemaProps {
  type: PortType;
  ports: Port[];
  allowSelection: boolean;
  noFieldsMessage?: string;
  selectedFields: string[];
  minPorts?: number;
  showSelectAllColumns?: boolean;
  allowRename?: boolean;
  allowAddOrDelete?: boolean;
  onAdd?: (port: Port) => void;
  onDelete?: (portId: string) => void;
  onRename?: (port: Port) => void;
  onSelect?: (port: Port, column: string, variant: string) => void;
  onSelectAll?: (port: string) => void;
  allowSearch?: boolean;
  fieldSearchQuery?: string;
  allowCustomOutputSchema?: boolean;
  defaultCustomOutputSchema?: boolean;
}

export enum ItemType {
  'column' = 'column',
  'port' = 'port',
  'message' = 'message',
  'variantInfo' = 'variantInfo'
}

export interface TreeItem extends DataNode {
  title: string;
  type?: string;
  isSelected?: boolean;
  itemType: ItemType;
  port: Port;
  columnKey?: string;
  columnPath: string;
  canInferSchema?: boolean;
  haveInferredSchema?: boolean;
  lastFetchedTimeStamp?: number;
}

export type PortItemProps = TreeItem & {
  allowRename: boolean;
  showSelectAllColumns: boolean;
  renamePort?: (portId: string, portSlug: string) => Promise<boolean>;
  allowDelete: boolean;
  deletePort: (portId: string) => void;
  selectAllColumn?: (portId: string) => void;
  allowSelection: boolean;
  columnCount: number;
};
