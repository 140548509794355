import { message } from '@prophecy/ui';
import { useQueryClient } from '@tanstack/react-query';
import { Entity } from 'frontend/core/src/common/types/Entity';
import { useGraphQlMutation } from 'frontend/core/src/data/util';
import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { UpdateExecutionMetricsQuery } from '../queries';
import { ExecutionMetricsFormData } from '../types';

const useUpdateExecutionMetrics = (formContext: UseFormReturn<ExecutionMetricsFormData>) => {
  const client = useQueryClient();
  const aspectMutation = useGraphQlMutation(UpdateExecutionMetricsQuery);

  const updateExecutionMetrics = useCallback(
    async (uid: string) => {
      let values = formContext.getValues();

      if (!values.interimsTable) {
        values = {
          ...values,
          interimsTable: undefined,
          pipelineMetricsTable: undefined,
          componentMetricsTable: undefined
        };
      }

      await aspectMutation.mutateAsync(
        {
          teamUID: uid,
          ...values
        },
        {
          onSuccess: () => {
            message.success({ content: 'Execution metrics details updated successfully.' });
            client.invalidateQueries([Entity.Team]);
          }
        }
      );
    },
    [aspectMutation, formContext, client]
  );

  return { updateExecutionMetrics, isLoading: aspectMutation.isLoading };
};

export default useUpdateExecutionMetrics;
