import { ExtractUrlPathVariables } from '@prophecy/interfaces/generic';
import { encodeParams } from '@prophecy/utils/data';
import { BASE_PATH } from '@prophecy/utils/history';
import { appendQueryParams } from '@prophecy/utils/url';
import { generatePath } from 'react-router-dom';

import { SQL_PROVIDER } from '../constants/sql';
import { BackEndCodeLanguage, BackEndCodeLanguageType } from '../graphqlTypes/enums';
import { FabricProviderType } from './Fabric/types';
import { Entity } from './types/Entity';
import { ProjectSourceType } from './types/index';

const baseUrl = '/';
const AUTH_BASE = '/auth';

function toLowerCaseEntity<E extends Entity>(entity: E) {
  return entity.toLowerCase() as Lowercase<E>;
}

function entityBaseUrl<E extends Entity>(entity: E) {
  return `/entity/${toLowerCaseEntity(entity)}` as const;
}

function pluralize<N extends string>(noun: N) {
  return `${noun}s` as const;
}

const EntityURIMap = {
  [Entity.User]: entityBaseUrl(Entity.User),
  [Entity.Project]: pluralize(entityBaseUrl(Entity.Project)),
  [Entity.Pipeline]: pluralize(entityBaseUrl(Entity.Pipeline)),
  [Entity.Subgraph]: pluralize(entityBaseUrl(Entity.Subgraph)),
  [Entity.Job]: pluralize(entityBaseUrl(Entity.Job)),
  [Entity.Fabric]: pluralize(entityBaseUrl(Entity.Fabric)),
  [Entity.Dataset]: pluralize(entityBaseUrl(Entity.Dataset)),
  [Entity.Team]: pluralize(entityBaseUrl(Entity.Team)),
  [Entity.Package]: pluralize(entityBaseUrl(Entity.Package)),
  PackageHub: `/packagehub`,
  [Entity.Lineage]: `/${toLowerCaseEntity(Entity.Lineage)}` as const,
  PipelineIDE: `/${toLowerCaseEntity(Entity.Pipeline)}` as const,
  JobIDE: `/${toLowerCaseEntity(Entity.Job)}` as const,
  DatasetIDE: `/${toLowerCaseEntity(Entity.Dataset)}` as const,
  Create: '/create' as const,
  Settings: '/settings' as const,
  Home: baseUrl,
  Gems: '/components' as const,
  AirflowGems: '/airflow-components' as const,
  Transpiler: '/transpiler' as const,
  UPGRADE_PLAN: '/upgrade-plan' as const
};

type OptionalPropertyOf<T extends object> = Exclude<
  {
    [K in keyof T]: T extends Record<K, T[K]> ? never : K;
  }[keyof T],
  undefined
>;
type TupleMarkOptional<OBJ1, OBJ2 extends object> =
  OptionalPropertyOf<OBJ2> extends never ? [OBJ1, OBJ2] : [OBJ1, OBJ2?];

type GetUrlParameters<U extends string, QP> =
  ExtractUrlPathVariables<U> extends never
    ? QP extends Record<string, string>
      ? TupleMarkOptional<undefined, QP>
      : []
    : QP extends Record<string, string>
      ? TupleMarkOptional<ExtractUrlPathVariables<U>, QP>
      : [ExtractUrlPathVariables<U>];

type UrlObject<U extends string, QP> = {
  url: U;
  getUrl: (...args: GetUrlParameters<U, QP>) => string;
};

function createUrlObj<U extends string, QP>(url: U) {
  return {
    url: url,
    getUrl: function getUrl(...args: GetUrlParameters<U, QP>) {
      const [params, queryParams] = args;
      let _url = url;
      if (params) {
        _url = generatePath(_url, encodeParams(params)) as U;
      }
      if (queryParams) {
        return appendQueryParams(_url, queryParams);
      }
      return _url;
    }
  } as UrlObject<U, QP>;
}

const RouteMap = {
  Home: baseUrl,
  Transpiler: `${EntityURIMap.Transpiler}/listing`,
  UPGRADE_PLAN: `${EntityURIMap.UPGRADE_PLAN}`,
  TranspilerDetail: `${EntityURIMap.Transpiler}/:uid/:version/:type`,
  TranspilerImport: {
    home: `${EntityURIMap.Transpiler}/import`,
    tab: `${EntityURIMap.Transpiler}/import/:transpileId`
  },
  PackageHubList: `${EntityURIMap.PackageHub}/list`,
  Package: {
    home: `${EntityURIMap.Package}/:uid`,
    tab: `${EntityURIMap.Package}/:uid/:tab`
  },
  Gems: EntityURIMap.Gems,
  GemPlayground: `${EntityURIMap.Gems}/:category/:componentId`,
  AirflowGemPlayground: `${EntityURIMap.AirflowGems}/:category/:componentId`,
  Lineage_Search: EntityURIMap.Lineage,
  Lineage_IDE: {
    datasetOrPipeline: `${EntityURIMap.Lineage}/:projectId/:type/:datasetOrPipelineId`,
    column: `${EntityURIMap.Lineage}/:projectId/:type/:datasetOrPipelineId/:dataset/:column`
  },
  [Entity.User]: {
    home: EntityURIMap.User,
    tab: `${EntityURIMap.User}/:tab`
  },
  Settings: {
    home: EntityURIMap.Settings,
    tab: `${EntityURIMap.Settings}/:tab`
  },
  [Entity.Project]: {
    home: `${EntityURIMap.Project}/:uid`,
    tab: `${EntityURIMap.Project}/:uid/:tab`
  },
  [Entity.Pipeline]: {
    home: `${EntityURIMap.Pipeline}/:uid`,
    tab: `${EntityURIMap.Pipeline}/:uid/:tab`
  },
  [Entity.Subgraph]: {
    home: `${EntityURIMap.Subgraph}/:uid`,
    tab: `${EntityURIMap.Subgraph}/:uid/:tab`
  },
  [Entity.Job]: {
    home: `${EntityURIMap.Job}/:uid`,
    tab: `${EntityURIMap.Job}/:uid/:tab`
  },
  [Entity.Dataset]: {
    home: `${EntityURIMap.Dataset}/:uid`,
    tab: `${EntityURIMap.Dataset}/:uid/:tab`
  },
  [Entity.Fabric]: {
    home: `${EntityURIMap.Fabric}/:uid`,
    tab: `${EntityURIMap.Fabric}/:uid/:tab`
  },
  [Entity.Team]: {
    home: `${EntityURIMap.Team}/:uid`,
    tab: `${EntityURIMap.Team}/:uid/:tab`
  },

  Onboarding: '/onboarding',
  IDE: `/ide/:entity/:uid`,
  IDE_Home: `/ide/:entity`,
  IDE_LINEAGE: `/ide/${toLowerCaseEntity(Entity.Lineage)}/:projectId`,
  SQL_IDE: '/sql/:uid',
  Create_Entity: {
    project: `${EntityURIMap.Create}/project`,
    fabric: {
      new: `${EntityURIMap.Create}/fabric`,
      existing: `${EntityURIMap.Create}/fabric/:uid`
    },
    team: `${EntityURIMap.Create}/team`,
    home: EntityURIMap.Create,
    tab: `${EntityURIMap.Create}/:tab`
  },
  Maintenance: '/maintenance',
  ProphecyDown: '/prophecy-down',
  Support: 'https://join.slack.com/t/prophecy-io-support/shared_invite/zt-1dwuvkakg-JcavnQuoukyZ3q5jkSkKCg',
  ProphecyDoc: 'https://docs.prophecy.io/',
  Website: 'https://prophecy.io/',
  Help: 'https://www.prophecy.io/help',
  SignIn: `${AUTH_BASE}/signin`,
  SignUp: `${AUTH_BASE}/signup`,
  EmbeddedSignup: `${AUTH_BASE}/embedded-signup`,
  DatabricksSignUp: `${AUTH_BASE}/db-signup`,
  ResetPassword: `${AUTH_BASE}/reset-password`,
  OauthCallback: '/oauthCallback',
  OauthSamlCallback: '/oauthSamlCallback',
  LoginUsingToken: '/loginUsingToken',
  Deployment: '/deployment'
} as const;

export const Public_Routes = {
  Maintenance: createUrlObj(RouteMap.Maintenance),
  ProphecyDown: createUrlObj(RouteMap.ProphecyDown),
  Support: {
    url: RouteMap.Support,
    getUrl: () => RouteMap.Support
  },
  ProphecyDoc: {
    url: RouteMap.ProphecyDoc,
    getUrl: () => RouteMap.ProphecyDoc
  },
  Website: {
    url: RouteMap.Website,
    getUrl: () => RouteMap.Website
  },
  Help: {
    url: RouteMap.Help,
    getUrl: () => RouteMap.Help
  },
  LoginUsingToken: createUrlObj<typeof RouteMap.LoginUsingToken, { token: string }>(RouteMap.LoginUsingToken),
  OauthCallback: createUrlObj(RouteMap.OauthCallback),
  OauthSamlCallback: createUrlObj(RouteMap.OauthSamlCallback),
  SignIn: createUrlObj<typeof RouteMap.SignIn, { email?: string; redirect: string }>(RouteMap.SignIn),
  SignUp: createUrlObj<
    typeof RouteMap.SignUp,
    { email?: string; otp?: string; verifiedUser?: string; invitation?: string; teamid?: string; step?: string }
  >(RouteMap.SignUp),
  EmbeddedSignup: createUrlObj(RouteMap.EmbeddedSignup),
  ResetPassword: createUrlObj<typeof RouteMap.ResetPassword, { email?: string; step?: string }>(RouteMap.ResetPassword),
  DatabricksSignUp: createUrlObj(RouteMap.DatabricksSignUp)
};

function pathMatchesRoute(path: string, route: string) {
  return path.startsWith(BASE_PATH + route) || path.startsWith(route);
}

export const isAuthRoute = (path: string) => {
  return (
    pathMatchesRoute(path, RouteMap.SignIn) ||
    pathMatchesRoute(path, RouteMap.SignUp) ||
    pathMatchesRoute(path, RouteMap.EmbeddedSignup) ||
    pathMatchesRoute(path, RouteMap.ResetPassword) ||
    pathMatchesRoute(path, RouteMap.DatabricksSignUp)
  );
};

export const isEmbeddedSingUpUrl = (path: string) => {
  return pathMatchesRoute(path, RouteMap.EmbeddedSignup);
};

export const isPublicURL = (path: string): boolean => {
  return (
    pathMatchesRoute(path, RouteMap.LoginUsingToken) ||
    pathMatchesRoute(path, RouteMap.ProphecyDown) ||
    pathMatchesRoute(path, RouteMap.OauthCallback) ||
    pathMatchesRoute(path, RouteMap.Maintenance)
  );
};

type IDEQueryParams = {
  projectId?: string;
  branch?: string;
  'run-id'?: string;
  onboarding?: string;
  gemId?: string;
  functionName?: string;
  functionProject?: string;
  configInstance?: string;
  observedEntity?: Entity.Project | Entity.Connector;
  action?: string;
  transpilerGemId?: string;
  transpilerSource?: string;
  transpilerId?: string;
  transpilerVersion?: string;
};

export type CallBackEntityParams = {
  entityId?: string;
  entity?: Entity;
  language?: string;
  status?: string;
  step?: string;
  teamId?: string;
  convertLookupsToJoin?: string;
  source?: string;
  name?: string;
  projectId?: string;
  branch?: string;
  fabricId?: string;
  airflowFabricId?: string;
  isJobTranspile?: string;
  isLanguageEnabled?: string;
  clusterSize?: string;
};
type CreateProjectQueryParams =
  | CallBackEntityParams
  | {
      providerType?: SQL_PROVIDER;
      onboarding?: string;
      projectType?: ProjectSourceType;
      language?: BackEndCodeLanguageType;
      teamId?: string;
      dependencyId?: string;
    };

export const Private_Routes = {
  Home: createUrlObj(RouteMap.Home),
  Settings: {
    home: createUrlObj(RouteMap.Settings.home),
    tab: createUrlObj<typeof RouteMap.Settings.tab, { tab?: string }>(RouteMap.Settings.tab)
  },
  UPGRADE_PLAN: createUrlObj(RouteMap.UPGRADE_PLAN),
  Transpiler: createUrlObj(RouteMap.Transpiler),
  TranspilerDetail: createUrlObj<typeof RouteMap.TranspilerDetail, { tab?: string }>(RouteMap.TranspilerDetail),
  TranspilerImport: {
    home: createUrlObj<typeof RouteMap.TranspilerImport.home, CallBackEntityParams>(RouteMap.TranspilerImport.home),
    tab: createUrlObj<typeof RouteMap.TranspilerImport.tab, CallBackEntityParams>(RouteMap.TranspilerImport.tab)
  },
  PackageHubList: createUrlObj(RouteMap.PackageHubList),
  Package: {
    home: createUrlObj<typeof RouteMap.Package.home, { version?: string }>(RouteMap.Package.home),
    tab: createUrlObj<typeof RouteMap.Package.tab, { version?: string }>(RouteMap.Package.tab)
  },
  Gems: createUrlObj(RouteMap.Gems),

  GemPlayground: createUrlObj<typeof RouteMap.GemPlayground, { language: string; mode?: string }>(
    RouteMap.GemPlayground
  ),
  AirflowGemPlayground: createUrlObj<typeof RouteMap.AirflowGemPlayground, { language: string }>(
    RouteMap.AirflowGemPlayground
  ),
  Lineage_Search: createUrlObj<typeof RouteMap.Lineage_Search, { search?: string }>(RouteMap.Lineage_Search),
  Lineage_IDE: {
    datasetOrPipeline: createUrlObj(RouteMap.Lineage_IDE.datasetOrPipeline),
    column: createUrlObj(RouteMap.Lineage_IDE.column)
  },
  [Entity.User]: {
    home: createUrlObj(RouteMap.User.home),
    tab: createUrlObj(RouteMap.User.tab)
  },
  [Entity.Project]: {
    home: createUrlObj<typeof RouteMap.Project.home, { branch?: string; templateId?: string; mode?: string }>(
      RouteMap.Project.home
    ),
    tab: createUrlObj<typeof RouteMap.Project.tab, { branch?: string; templateId?: string; mode?: string }>(
      RouteMap.Project.tab
    )
  },
  [Entity.Pipeline]: {
    home: createUrlObj(RouteMap.Pipeline.home),
    tab: createUrlObj(RouteMap.Pipeline.tab)
  },
  [Entity.Subgraph]: {
    home: createUrlObj(RouteMap.Subgraph.home),
    tab: createUrlObj(RouteMap.Subgraph.tab)
  },
  [Entity.Job]: {
    home: createUrlObj(RouteMap.Job.home),
    tab: createUrlObj(RouteMap.Job.tab)
  },
  [Entity.Dataset]: {
    home: createUrlObj<typeof RouteMap.Dataset.home, { branch?: string }>(RouteMap.Dataset.home),
    tab: createUrlObj<typeof RouteMap.Dataset.tab, { branch?: string }>(RouteMap.Dataset.tab)
  },
  [Entity.Fabric]: {
    home: createUrlObj(RouteMap.Fabric.home),
    tab: createUrlObj<typeof RouteMap.Fabric.tab, { skipCredsWarning?: string }>(RouteMap.Fabric.tab)
  },
  [Entity.Team]: {
    home: createUrlObj(RouteMap.Team.home),
    tab: createUrlObj(RouteMap.Team.tab)
  },
  IDE: createUrlObj<typeof RouteMap.IDE, IDEQueryParams>(RouteMap.IDE),
  IDE_Home: createUrlObj<typeof RouteMap.IDE_Home, { observedEntity?: string }>(RouteMap.IDE_Home),
  IDE_Lineage: createUrlObj<typeof RouteMap.IDE_LINEAGE, { projectId?: string }>(RouteMap.IDE_LINEAGE),
  SQL_IDE: createUrlObj<
    typeof RouteMap.SQL_IDE,
    { entity?: string; name?: string; table?: string; source?: string; branch?: string; id?: string; action?: 'create' }
  >(RouteMap.SQL_IDE),
  Create_Entity: {
    project: createUrlObj<typeof RouteMap.Create_Entity.project, CreateProjectQueryParams>(
      RouteMap.Create_Entity.project
    ),
    fabric: {
      new: createUrlObj<
        typeof RouteMap.Create_Entity.fabric.new,
        CallBackEntityParams | { fabricProviderType?: FabricProviderType; onboarding?: string; provider?: string }
      >(RouteMap.Create_Entity.fabric.new),
      existing: createUrlObj<typeof RouteMap.Create_Entity.fabric.existing, CallBackEntityParams>(
        RouteMap.Create_Entity.fabric.existing
      )
    },
    team: createUrlObj<typeof RouteMap.Create_Entity.team, CallBackEntityParams>(RouteMap.Create_Entity.team),
    home: createUrlObj(RouteMap.Create_Entity.home),
    tab: createUrlObj<
      typeof RouteMap.Create_Entity.tab,
      CallBackEntityParams | { projectId?: string; onboarding?: string; projectType?: string }
    >(RouteMap.Create_Entity.tab)
  },
  Onboarding: createUrlObj(RouteMap.Onboarding),
  Deployment: createUrlObj(RouteMap.Deployment)
};
type IDE_URL_PARAMS = Parameters<typeof Private_Routes.IDE.getUrl>;

export function getIDEUrl({ uid, entity }: IDE_URL_PARAMS['0'] & { entity: Entity }, query?: IDE_URL_PARAMS['1']) {
  return Private_Routes.IDE.getUrl({ uid, entity: entity.toLowerCase() }, query);
}

export function getJobIdeUrl(
  jobId: string,
  projectId?: string,
  projectLanguage?: BackEndCodeLanguageType,
  branch?: string
) {
  if (!projectId) return '';

  return projectLanguage === BackEndCodeLanguage.sql
    ? Private_Routes.SQL_IDE.getUrl({ uid: projectId }, { entity: Entity.Job, id: jobId, branch })
    : getIDEUrl({ uid: jobId as string, entity: Entity.Job }, { branch });
}

export function getMetadataLineageUrl(projectId: string) {
  return Private_Routes.IDE_Lineage.getUrl({ projectId });
}

export const getGemIdeUrl = (
  currentProjectId: string,
  gemId: string,
  isGemFromOtherProject: boolean,
  branch?: string
) => {
  const queryParams = { branch, gemId, projectId: isGemFromOtherProject ? currentProjectId : undefined };
  return getIDEUrl({ uid: currentProjectId, entity: Entity.Gem }, queryParams);
};
