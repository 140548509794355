import styled from 'styled-components';
import { Button } from '../../Button';
import CodeBlock, { BackgroundBlock, ColorizedBlock } from '../../Editor/CodeBlock';
import { tagDecorationStyles } from '../../Editor/editorTags';
import { editorTooltipStyle } from '../../Editor/styled';
import { defaultSliderSize, LINE_HEIGHT } from '../../Editor/tokens';
import { PythonIcon, ScalaIcon, SqlIcon } from '../../Icons/FileIcons';
import { inputColorStyles } from '../../Input/styled';
import { Stack } from '../../Layout';
import { hideScrollbarCss } from '../../Misc/HideScrollbar';
import { tokens as tableTokens } from '../../Table/tokens';
import { theme } from '../../theme';
export const VERTICAL_PADDING_TOP = 7;
export const VERTICAL_PADDING_BOTTOM = 5;
export const VERTICAL_PADDING = VERTICAL_PADDING_TOP + VERTICAL_PADDING_BOTTOM;
export const MONACO_EXTRA_SPACING = 3;
export const TOTAL_SPACING = MONACO_EXTRA_SPACING + VERTICAL_PADDING;
export const MINIMUM_HEIGHT = LINE_HEIGHT + TOTAL_SPACING;
export const MAX_LINE = 20;
export const EXPANDABLE_MAX_LINE = 4;
export const options = {
    fontSize: parseInt(theme.fontSizes.x12),
    lineNumbers: 'off',
    glyphMargin: false,
    fontFamily: theme.fontFamily.code,
    lineDecorationsWidth: 0,
    lineNumbersMinChars: 0,
    wordWrap: 'off',
    wrappingIndent: 'none',
    contextmenu: true,
    scrollbar: {
        useShadows: false,
        arrowSize: 8,
        verticalHasArrows: false,
        horizontalHasArrows: false,
        vertical: 'hidden',
        horizontal: 'hidden',
        handleMouseWheel: true,
        alwaysConsumeMouseWheel: false,
        horizontalScrollbarSize: defaultSliderSize,
        verticalScrollbarSize: defaultSliderSize
    },
    scrollBeyondLastLine: false,
    lineHeight: LINE_HEIGHT,
    cursorStyle: 'line-thin',
    overviewRulerLanes: 0,
    overviewRulerBorder: false,
    hideCursorInOverviewRuler: true,
    folding: false,
    scrollBeyondLastColumn: 0,
    find: {
        addExtraSpaceOnTop: false,
        autoFindInSelection: 'never',
        seedSearchStringFromSelection: 'never'
    },
    minimap: { enabled: false },
    automaticLayout: true,
    renderLineHighlight: 'none',
    inlineSuggest: {
        enabled: true
    },
    padding: {
        top: VERTICAL_PADDING_TOP,
        bottom: VERTICAL_PADDING_BOTTOM
    }
};
export const StyledExpressionButton = styled(Button) `
  border: none;
  transition: none;
  &:hover {
    border: 1px solid ${theme.colors.grey200};
  }
`;
export const ExpressionActionsWrapper = styled(Stack) `
  position: absolute;
  top: ${theme.spaces.x4};
  right: ${theme.spaces.x6};
  z-index: ${theme.zLayer.s};
  display: none;

  /**
  * Instead of giving background to the whole container we are adding a
  * a layer with background which can cover up the text, but do not overlap
  * with the monaco suggestion dropdown.
  */
  &:after {
    position: absolute;
    content: '';
    width: calc(100% - ${theme.spaces.x4});
    height: ${theme.sizes.x16};
    z-index: -${theme.zLayer.xs};
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: ${theme.colors.white};
  }
`;
export const CodeWrapper = styled.div `
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  position: relative;
  .expression-language-icon {
    height: ${theme.sizes.x20};
    background: white;
  }
  &:hover,
  &:focus-within {
    ${ExpressionActionsWrapper} {
      display: flex;
    }
  }
`;
export const EditorWrapper = styled.div `
  grid-area: 1 / 1 / auto / 2;
  ${tagDecorationStyles}
  ${editorTooltipStyle}
`;
export const LANGUAGE_ICON_MAP = {
    sql: SqlIcon,
    python: PythonIcon,
    scala: ScalaIcon
};
export const StyledCodeBlock = styled(CodeBlock) `
  ${inputColorStyles}
  outline: none;
  min-height: ${MINIMUM_HEIGHT}px;
  height: ${(props) => props.height}px;
  ${({ disabled }) => (disabled ? `background: ${theme.colors.grey50}` : '')};
  .has-error && {
    border-bottom: 1px solid ${tableTokens.ExcelTable.Input.error.borderColor};
  }

  position: relative;
  ${({ hideScrollbar }) => hideScrollbar
    ? `  
    padding: ${VERTICAL_PADDING_TOP}px ${theme.spaces.x8} ${VERTICAL_PADDING_BOTTOM + MONACO_EXTRA_SPACING}px;
    `
    : ''}
  ${BackgroundBlock},
  ${ColorizedBlock} {
    min-height: calc(${MINIMUM_HEIGHT}px - 2px); // leave 2px for the border
    font-size: ${options.fontSize}px;
    line-height: ${LINE_HEIGHT}px;
    color: inherit;
    font-family: ${options.fontFamily};
    grid-area: 1 / 1 / auto / 2;
    max-width: 100%;
    overflow: auto;
    ${({ hideScrollbar }) => hideScrollbar
    ? hideScrollbarCss
    : `  
    padding: ${VERTICAL_PADDING_TOP}px ${theme.spaces.x8} ${VERTICAL_PADDING_BOTTOM + MONACO_EXTRA_SPACING}px;
    `}
    ${({ actionMargin }) => actionMargin
    ? `
      > span:first-child{
        margin-right: ${actionMargin}px;
      }
    `
    : ''}
  }
  ${BackgroundBlock} {
    opacity: ${(props) => (props.isPlaceholder ? '.5' : '1')};
  }
`;
