import { ObjectLiteral } from '@prophecy/interfaces/generic';
import { Button, message, Separator, Spinner, Stack, Text, theme, Tooltip } from '@prophecy/ui';
import { ReactComponent as AzureLogo } from '@prophecy/ui/assets/images/azure_active_directory.svg';
import { ReactComponent as GoogleLogo } from '@prophecy/ui/assets/images/google.svg';
import { ReactComponent as OktaLogo } from '@prophecy/ui/assets/images/okta.svg';
import { ReactComponent as SamlLogo } from '@prophecy/ui/assets/images/saml.svg';
import { BASE_PATH } from '@prophecy/utils/history';
import { appendQueryParams } from '@prophecy/utils/url';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { openOauthWindow } from '../../common/oAuth/utils';
import { useUnsecuredGlobalVariables } from '../../context/globalConfig/unsecuredConfigHook';
import {
  getAllIdpTypes,
  oAuthAzureADAuthenticate,
  oAuthGoogleAuthenticate,
  oAuthSamlAuthenticate
} from '../../data/apis/api';
import { AuthTypes, ProviderType } from '../../data/apis/types';
import { GlobalWindow } from '../../utils/types';
import { IdpProviderLabelMap } from '../UserSettings/config/admin';
import { initializeFactorsAi } from './signup/factorsai';
import { initializeZoomInfo } from './signup/zoominfo';

export const StyledSeparator = styled(Separator)`
  position: relative;
`;

export const Header = styled(Stack).attrs({
  direction: 'horizontal',
  gap: theme.spaces.x16,
  alignY: 'center',
  align: 'space-between'
})`
  padding: ${theme.spaces.x22} ${theme.spaces.x32};
  position: absolute;
  width: 100%;
  z-index: ${theme.zLayer.s};
  @media (max-width: ${theme.breakpoints.s}) {
    padding: ${theme.spaces.x16};
    position: unset;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 0;
`;

const StretchedButton = styled(Button)`
  flex-grow: 1;
  width: 100%;
`;

export const StyledLinkButton = styled(Button)`
  padding: 0;
  height: auto;
` as typeof Button;

export const StyledFormContainer = styled.div`
  width: 450px;
  max-width: 100%;
  @media (max-width: ${theme.breakpoints.s}) {
    height: 100%;
  }
`;

export function PageLink({ label, buttonText, url }: { label: string; buttonText: string; url: string }) {
  return (
    <Stack direction='horizontal' gap={theme.spaces.x4} alignY='center'>
      <Text level='sm' tone={theme.colors.grey500}>
        {label}
      </Text>
      <StyledLinkButton elementType='link' variant='link' to={url}>
        <Text level='sm'>{buttonText}</Text>
      </StyledLinkButton>
    </Stack>
  );
}

export const useQualifiedChatBox = () => {
  const unsecureGlobalConfigManager = useUnsecuredGlobalVariables();
  const isCrispChatEnabled = unsecureGlobalConfigManager.isCrispChatEnabled;
  useEffect(() => {
    if (!isCrispChatEnabled) {
      return;
    }

    (function (w, q) {
      w['QualifiedObject'] = q;
      w[q] =
        w[q] ||
        function () {
          (w[q].q = w[q].q || []).push(arguments);
        };
    })(window as ObjectLiteral, 'qualified');

    let script = document.createElement('script');
    script.src = 'https://js.qualified.com/qualified.js?token=K15YXsxGRU2PqcHL';
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);
    return () => {
      script?.remove();
    };
  }, [isCrispChatEnabled]);
};

export const ExternalAuthButtons = ({ embedded, isLogin }: { embedded?: boolean; isLogin?: boolean }) => {
  const { idpTypesSupported, isLoading, signInWithAzureAD, signInWithSaml, signInWithGoogle } =
    useExternalLogin(embedded);
  if (isLoading) {
    return <Spinner />;
  }

  const getButtonTextAndLogo = (idpType: IDPSupportedType) => {
    const providerType =
      idpType.idp === ProviderType.others ? AuthTypes.saml.toUpperCase() : idpType.idp || ProviderType.okta;
    let tooltipText = `Sign in with ${providerType}`;
    let icon = <OktaLogo />;
    if (idpType.idp === ProviderType.google) {
      icon = <GoogleLogo />;
    } else if (idpType.idp === ProviderType.others) {
      icon = <SamlLogo />;
    } else if (idpType.idp === ProviderType.azuread) {
      tooltipText = `Sign in with ${IdpProviderLabelMap[idpType.idp]}`;
      icon = <AzureLogo />;
    }
    return { tooltipText, icon };
  };
  return idpTypesSupported.length > 0 ? (
    <Stack
      gap={theme.spaces.x16}
      padding={isLogin ? '0' : `${theme.spaces.x24} 0 0`}
      direction='horizontal'
      align='center'>
      {idpTypesSupported.map((idpType) => {
        if (idpType.type === AuthTypes.azuread) {
          return (
            <Tooltip title='Sign in with Azure'>
              <Stack width='100%'>
                <StretchedButton
                  iconPlacement='left'
                  icon={<AzureLogo />}
                  variant='secondaryGrey'
                  onClick={() => signInWithAzureAD()}
                />
              </Stack>
            </Tooltip>
          );
        } else if (idpType.type === AuthTypes.google) {
          return (
            <Tooltip title='Sign in with Google'>
              <Stack width='100%'>
                <StretchedButton
                  iconPlacement='left'
                  icon={<GoogleLogo />}
                  variant='secondaryGrey'
                  onClick={() => signInWithGoogle()}
                />
              </Stack>
            </Tooltip>
          );
        } else if (idpType.type === AuthTypes.saml) {
          const { tooltipText, icon } = getButtonTextAndLogo(idpType);
          return (
            <Tooltip title={tooltipText}>
              <Stack width='100%'>
                <StretchedButton
                  iconPlacement='left'
                  icon={icon}
                  variant='secondaryGrey'
                  onClick={() => signInWithSaml()}
                />
              </Stack>
            </Tooltip>
          );
        } else {
          return null;
        }
      })}
    </Stack>
  ) : null;
};

type IDPSupportedType = { idp?: ProviderType; type: AuthTypes };

export function useExternalLogin(embedded?: boolean) {
  const unsecureGlobalConfigManager = useUnsecuredGlobalVariables();
  const { data, isLoading } = useQuery(['idpconfigtypes'], getAllIdpTypes, {
    enabled: unsecureGlobalConfigManager.isConfigurableAuthEnabled
  });
  let idpTypesSupported: IDPSupportedType[] = data?.data?.idpTypes || [];

  const redirectToOauth = (url: string) => {
    openOauthWindow(url, () => {
      if (embedded) {
        redirectParentToUrl('/');
      } else {
        window.location.reload();
      }
    });
  };

  async function signInWithAzureAD(params: { identifier?: string } = {}) {
    //linkedin track
    (window as unknown as GlobalWindow).lintrk?.('track', { conversion_id: 8934260 });
    try {
      const oAuthResp = await oAuthAzureADAuthenticate(params);
      redirectToOauth(oAuthResp.action);
    } catch (e) {
      message.error({ content: 'Some error occurred.' });
    }
  }

  async function signInWithGoogle(params: { identifier?: string } = {}) {
    //linkedin track
    (window as unknown as GlobalWindow).lintrk?.('track', { conversion_id: 8934260 });
    try {
      const oAuthResp = await oAuthGoogleAuthenticate(params);
      redirectToOauth(oAuthResp.action);
    } catch (e) {
      message.error({ content: 'Some error occurred.' });
    }
  }

  async function signInWithSaml(params: { identifier?: string } = {}) {
    try {
      const oAuthResp = await oAuthSamlAuthenticate(params);

      const url = appendQueryParams(oAuthResp.action, {
        SAMLRequest: oAuthResp.SAMLRequest,
        RelayState: oAuthResp.RelayState
      });

      redirectToOauth(url);
    } catch (e) {
      message.error({ content: 'Some error occurred.' });
    }
  }

  return { idpTypesSupported, isLoading, signInWithAzureAD, signInWithSaml, signInWithGoogle };
}

const allowedDomain = ['app.prophecy.io'];

export function useHubSpot() {
  useEffect(() => {
    if (!allowedDomain.includes(window.location.host)) return;
    const script = document.createElement('script');
    script.src = 'https://js.hs-scripts.com/22397615.js';
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);
    return () => {
      script.remove();
    };
  }, []);
}

export function useLinkedInTrack() {
  useEffect(() => {
    if (!allowedDomain.includes(window.location.host)) return;
    const globalWindow = window as unknown as GlobalWindow;
    const _linkedin_partner_id = '2212284';
    globalWindow._linkedin_data_partner_ids = globalWindow._linkedin_data_partner_ids || [];
    globalWindow._linkedin_data_partner_ids.push(_linkedin_partner_id);

    if (!globalWindow.lintrk) {
      globalWindow.lintrk = function (a, b) {
        globalWindow.lintrk?.q?.push([a, b]);
      };
      globalWindow.lintrk.q = [];
    }

    const script = document.createElement('script');
    script.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);
    return () => {
      script.remove();
    };
  }, []);
}

export function useFactorsAi() {
  useEffect(() => {
    if (!allowedDomain.includes(window.location.host)) return;

    initializeFactorsAi();

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://app.factors.ai/assets/v1/factors.js';
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);

    return () => {
      script.remove();
    };
  }, []);
}

export function useZoomInfo() {
  useEffect(() => {
    if (!allowedDomain.includes(window.location.host)) return;

    const script = initializeZoomInfo();
    return () => {
      script.remove();
    };
  }, []);
}

export const useSignupParamsFromURL = () => {
  const [searchParams] = useSearchParams();
  const strTeamNameUpdationAllowed = searchParams.get('teamNameUpdationAllowed') || '';

  const teamName = searchParams.get('teamName') || '';
  const company = searchParams.get('company') || '';
  const nameInputNeeded = searchParams.get('nameInputNeeded') === 'true';
  const firstName = searchParams.get('firstName') || '';
  const lastName = searchParams.get('lastName') || '';
  const email = searchParams.get('email') || '';
  const token = searchParams.get('token') || '';
  const teamUID = searchParams.get('teamUID') || '';
  const otp = searchParams.get('otp') || '';
  const verifiedUser = searchParams.get('verifiedUser') || '';
  const invitation = searchParams.get('invitation') || '';
  const teamid = searchParams.get('teamid') || '';
  const uid = searchParams.get('uid') || '';
  const stepParam = searchParams.get('step');
  const teamNameUpdationAllowed = strTeamNameUpdationAllowed === 'true';
  return {
    otp,
    uid,
    verifiedUser,
    teamid,
    stepParam,
    firstName,
    lastName,
    teamName,
    invitation,
    company,
    nameInputNeeded,
    email,
    token,
    teamUID,
    teamNameUpdationAllowed,
    strTeamNameUpdationAllowed
  };
};

export function redirectParentToUrl(url: string) {
  const baseUrl = `${window.location.origin}${BASE_PATH}`;

  if (!url.startsWith(baseUrl)) url = `${baseUrl}${url}`;

  window.top?.postMessage({ type: 'ProphecyAppSignup', url }, '*');
}
