import { graphql } from '../../graphqlTypes/graphql';

export const UpdateExecutionMetricsQuery = graphql(`
  mutation adminOperations(
    $teamUID: String!
    $pipelineMetricsTable: String
    $componentMetricsTable: String
    $interimsTable: String
    $enabled: Boolean!
  ) {
    adminOperations(
      teamUID: $teamUID
      executionMetrics: {
        pipelineMetricsTable: $pipelineMetricsTable
        componentMetricsTable: $componentMetricsTable
        interimsTable: $interimsTable
        enabled: $enabled
      }
    )
  }
`);

export const UpdateCodeGenerationQuery = graphql(`
  mutation adminOperations($teamUID: String!, $enabled: Boolean!) {
    adminOperations(teamUID: $teamUID, codegenSettings: { multiFileCodegen: $enabled })
  }
`);

export const GetTeamTranspilersQuery = graphql(`
  query ($teamUID: String!) {
    Team(uid: $teamUID) {
      enabledTranspilers {
        abinitio
        hive
        alteryx
        datastage
        informatica
        snaplogic
      }
    }
  }
`);

export const UpdateTeamTranspilersQuery = graphql(`
  mutation ($teamUID: String!, $transpilerName: String!, $transpilerEnabled: Boolean!) {
    updateEnabledTranspilers(
      teamUID: $teamUID
      transpilerName: $transpilerName
      transpilerEnabled: $transpilerEnabled
    ) {
      abinitio
      hive
      alteryx
      datastage
      informatica
      snaplogic
    }
  }
`);

export const GetUserTeamsEnabledTranspilersQuery = graphql(`
  query getUserTeams($uid: String!) {
    User(uid: $uid) {
      teams {
        enabledTranspilers {
          abinitio
          hive
          alteryx
          datastage
          informatica
          snaplogic
        }
      }
    }
  }
`);

export const GetTeamCopilotSettingsQuery = graphql(`
  query ($teamUID: String!) {
    Team(uid: $teamUID) {
      copilotDataSamplingEnabled
    }
  }
`);

export const UpdateTeamCopilotSettingsQuery = graphql(`
  mutation ($teamUID: String!, $enabled: Boolean!) {
    updateCopilotDataSampling(teamUID: $teamUID, copilotDataSamplingEnabled: $enabled)
  }
`);
