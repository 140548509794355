import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyEffect } from '@prophecy/utils/react/hooks';
import { useState } from 'react';
import { StyledRange, StyledRoot, StyledThumb, StyledThumbInput, StyledTrack, TrackContent } from './styled';
const getLocalValue = (value) => {
    if (typeof value === 'number') {
        return [value];
    }
    return value;
};
export const Slider = (_a) => {
    var { track = {}, range = {}, thumb = {}, value, onChange } = _a, props = __rest(_a, ["track", "range", "thumb", "value", "onChange"]);
    const isSingleSlider = typeof value === 'number';
    const [localState, setLocalState] = useState(() => getLocalValue(value));
    useLazyEffect(() => {
        setLocalState(getLocalValue(value));
    }, [value]);
    const onChangeHandler = (value) => {
        if (isSingleSlider) {
            return onChange(value === null || value === void 0 ? void 0 : value[0]);
        }
        onChange(value);
    };
    const onInputChange = (index) => (e) => {
        const value = [...localState];
        value[index] = Math.max(props.min, Math.min(Number(e.target.value) || (index ? props.max : props.min), props.max));
        setLocalState(value);
    };
    return (_jsxs(StyledRoot, Object.assign({ value: localState, onValueChange: onChangeHandler }, props, { children: [_jsx(StyledTrack, Object.assign({}, track, { children: _jsx(TrackContent, { children: _jsx(StyledRange, Object.assign({}, range)) }) })), _jsx(StyledThumb, Object.assign({}, thumb, { children: _jsx(StyledThumbInput, { value: localState[0], onBlur: onInputChange(0) }) })), !isSingleSlider && (_jsx(StyledThumb, Object.assign({}, thumb, { children: _jsx(StyledThumbInput, { value: localState[1], onBlur: onInputChange(1) }) })))] })));
};
