import { usePersistentCallback } from '@prophecy/utils/react/hooks';

import { useLSPMethod } from '../../LSP/base/hook';
import { LSP } from '../../LSP/base/types';
import { getCurrentProcessGraphPath, getProcessesPath } from '../../Parser/bindings';
import { spaceToUnderscore, uniqueIDWithDelimiter } from '../../utils';
import { useIsPipelineOnboardingEnabled } from '../onboarding/hooks';
import { CommonGraph, Connection, DidChangeEntry } from '../types';

export function useForwardOutputSlugs(graph: CommonGraph, graphPath: string) {
  const { method: propertiesDidChange } = useLSPMethod(LSP.Method.propertiesDidChange);
  const isPipelineOnboardingEnabled = useIsPipelineOnboardingEnabled();

  return usePersistentCallback((updatedConnections: Connection[], newOrUpdatedProcesses?: CommonGraph['processes']) => {
    if (isPipelineOnboardingEnabled) return;
    const { processes } = graph;

    const changes = updatedConnections.map((connection) => {
      const { source, sourcePort, target, targetPort } = connection;
      const isSourceParentGraph = source === graph.id;
      const isTargetParentGraph = target === graph.id;
      const sourcePortType = isSourceParentGraph ? 'inputs' : 'outputs';
      const targetPortType = isTargetParentGraph ? 'outputs' : 'inputs';
      const allProcesses = { ...processes, ...newOrUpdatedProcesses };
      const sourceProcess = isSourceParentGraph ? graph : allProcesses[source];
      const targetProcess = isTargetParentGraph ? graph : allProcesses[target];
      const targetPorts = targetProcess.ports?.[targetPortType] || [];
      const targetPortIndex = targetPorts.findIndex((port) => port.id === targetPort);
      const sourcePorts = sourceProcess.ports?.[sourcePortType] || [];
      let portSlug: string = sourcePorts.find((port) => port.id === sourcePort)!.slug;

      // if we have single output port we are connecting which has predefined slug name like out, ou1, then use process slug instead
      if (sourcePorts.length === 1 && !isSourceParentGraph && portSlug.match(/^(out|in)(\d*$|$)/)) {
        portSlug = spaceToUnderscore(sourceProcess.metadata.label);
      }

      // if same output port is connected to two different input port, create unique slug
      portSlug = uniqueIDWithDelimiter(
        portSlug,
        targetPorts.filter((_, index) => index !== targetPortIndex).map(({ slug }) => slug)
      );

      const processGraphPath = getCurrentProcessGraphPath(targetProcess.id, graphPath);

      const slugChange: DidChangeEntry = {
        property: `${getProcessesPath(processGraphPath)}.${
          targetProcess.id
        }.ports.${targetPortType}[${targetPortIndex}].slug`,
        value: portSlug
      };

      return slugChange;
    });

    return changes.length ? propertiesDidChange(changes) : Promise.resolve();
  });
}
