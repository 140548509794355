import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect, useState } from 'react';

import { PublicLayout } from './App';
import { Public_Routes } from './common/url';
import { useAppMetadata } from './context/appMetadata';
import { useMixPanel } from './context/mixpanel/context';
import { MixPanelContextKeys } from './context/mixpanel/types';
import { UserProvider } from './context/user';
import { PrivateRoutes } from './router/PrivateRoutes';
import { PublicRoutes } from './router/PublicRoutes';
import { isReactQueryDevtoolsEnabled } from './utils/localstorage-keys';

export const MainApp = ({ isLoggedIn, userId }: { isLoggedIn: boolean; userId: string }) => {
  const { addContext, removeContext, loadFullStory, publicInitMixpanel, privateMixpanelInit } = useMixPanel();
  const { user } = useAppMetadata();

  const [isMixpanelLoaded, setMixpanelLoaded] = useState(false);
  const showLoggedInState = isLoggedIn && !window.location.pathname.endsWith(Public_Routes.SignUp.getUrl(undefined));

  useEffect(() => {
    if (isLoggedIn && user?.id)
      addContext(MixPanelContextKeys.User, {
        email: user.email,
        id: user.id,
        type: user.type
      });
    else {
      removeContext(MixPanelContextKeys.User);
    }
  }, [isLoggedIn, user, addContext, removeContext]);

  useEffect(() => {
    if (!isMixpanelLoaded) {
      setTimeout(async () => {
        await loadFullStory();
        if (showLoggedInState) {
          await privateMixpanelInit();
        } else {
          await publicInitMixpanel();
        }
        setMixpanelLoaded(true);
      }, 0);
    }
  }, [showLoggedInState, isMixpanelLoaded, loadFullStory, privateMixpanelInit, publicInitMixpanel]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        networkMode: 'offlineFirst'
      },
      mutations: {
        networkMode: 'offlineFirst'
      }
    }
  });

  if (!isMixpanelLoaded) {
    return <PublicLayout children={null} />;
  }
  return isLoggedIn ? (
    <QueryClientProvider client={queryClient}>
      <UserProvider userUID={userId as string}>
        <PrivateRoutes />
      </UserProvider>
      {isReactQueryDevtoolsEnabled && <ReactQueryDevtools></ReactQueryDevtools>}
    </QueryClientProvider>
  ) : (
    <PublicRoutes />
  );
};
