import styled from 'styled-components';
import { Columns } from '../Layout';
import { TABLE_CONTAINER } from "../Table/tokens";
import { theme } from '../theme';
export const StyledColumns = styled(Columns) `
  border: 1px solid ${theme.colors.grey300};
  border-radius: ${theme.radius.m};
  grid-auto-flow: column;
  overflow-x: auto;
`;
export const StyledColumn = styled(Columns.Column) `
  border-right: 1px solid ${theme.colors.grey200};
  overflow-y: scroll;
  .${TABLE_CONTAINER}{
    padding-right: 0;
    border-radius:  0;
  }
  min-width: ${({ directoryColumnWidth }) => directoryColumnWidth};

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
  }
  &&& {
    td,
    th {
      padding: 0;
    }
  }
`;
export const FileCell = styled.div `
  background: ${(props) => (props.selected ? theme.colors.grey200 : theme.colors.white)};
  border-bottom: 1px solid ${theme.colors.grey200};
  color: inherit;
  padding: ${theme.spaces.x4} ${theme.spaces.x8};
  position: ${(props) => (props.selected ? 'sticky' : 'static')};
  top: 0;
  bottom: 0;
  cursor: pointer;
`;
