import { theme } from './theme';
import { getGradientBorder } from './utils';
export const copilotStyles = {
    background40: `linear-gradient(71.36deg, rgba(231, 244, 255, 0.4) -21.61%, rgba(235, 232, 254, 0.4) 117.1%)`,
    background50: 'linear-gradient(76.56deg, rgba(231, 244, 255, 0.5) -15.46%, rgba(235, 233, 254, 0.5) 60.56%)',
    background80: `linear-gradient(76.56deg, rgba(231, 244, 255, 0.8) -15.46%, rgba(235, 233, 254, 0.8) 60.56%)`,
    backgroundHover: 'linear-gradient(90deg, rgba(155, 140, 252, 0.2) 0%, rgba(133, 203, 255, 0.2) 100%)'
};
export function getCopilotGradientBorder(borderSize = '1px') {
    return getGradientBorder(`linear-gradient(to right, ${theme.colors.purple400},${theme.colors.blue300})`, 'white', borderSize);
}
