import { Dialog } from '@prophecy/ui/Dialog';
import { isNodeEvnProduction } from '@prophecy/utils/env';
import { history } from '@prophecy/utils/history';

const interval = 5 * 60 * 1000; //5 minutes
const currentVersion = process.env.RELEASE_VERSION;
const isProd = isNodeEvnProduction();
let versionCheckTimer: number | undefined;
export async function checkForNewVersion() {
  if (!isProd) {
    return;
  }

  try {
    const response = await fetch(`/release?time=${Date.now()}`, { cache: 'no-store' });
    const result = (await response.json()) as { version: string };
    if (result.version && result.version !== currentVersion) {
      Dialog.info({
        size: 'l',
        closable: false,
        className: 'topLayer',
        title: 'New Version Available',
        subTitle: `A new version (${result.version}) is available, please reload page to upgrade. Current version: ${currentVersion}`,
        okButton: { children: 'Reload' },
        closeButton: null,
        onOk: () => {
          versionCheckTimer && clearInterval(versionCheckTimer);
          versionCheckTimer = undefined;
          window.location.reload();
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function triggerVersionCheck() {
  if (!versionCheckTimer) {
    // Check for new version immediately
    checkForNewVersion();
    versionCheckTimer = setInterval(checkForNewVersion, interval) as unknown as number;
  }
}
function init() {
  if (currentVersion) {
    if (!isProd) {
      return;
    }
    // only check it in production where we already have a version
    window.addEventListener('load', function () {
      history.listen(() => {
        checkForNewVersion();
      });
      window.addEventListener('online', triggerVersionCheck);
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          triggerVersionCheck();
        } else {
          versionCheckTimer && clearInterval(versionCheckTimer);
          versionCheckTimer = undefined;
        }
      });
      triggerVersionCheck();
    });
  }
}
init();
