import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useEffect, useState, useContext, useRef, createContext, useLayoutEffect } from 'react';
const BoxContext = createContext(undefined);
export function useBoxDimension(getBox = () => undefined) {
    const currentBox = useRef();
    const [boxDim, setBoxDim] = useState();
    const resizeObserver = useRef();
    useLayoutEffect(() => {
        const box = getBox();
        // if we are getting same box we don't need to handle dim again, it will be handled by observer
        if (currentBox.current === box)
            return;
        /**
         * if there is existing observer disconnect it,
         * we no longer want to observe previous element as its changed
         */
        if (resizeObserver.current) {
            resizeObserver.current.disconnect();
        }
        // no need to assign new observer if there is no box
        if (!box)
            return;
        const _setBoxDim = () => {
            const boundingRect = box.getBoundingClientRect();
            setBoxDim({
                width: boundingRect.width,
                height: boundingRect.height
            });
        };
        // initialize the box height;
        _setBoxDim();
        currentBox.current = box;
        resizeObserver.current = new ResizeObserver(_setBoxDim);
        resizeObserver.current.observe(box);
    });
    useEffect(() => {
        // before unmount cleanup the observer
        return () => {
            var _a;
            (_a = resizeObserver.current) === null || _a === void 0 ? void 0 : _a.disconnect();
        };
    }, []);
    return boxDim;
}
export const Box = forwardRef(function Box(_a, ref) {
    var { style = {}, width = style.width, height = style.height, children } = _a, divProps = __rest(_a, ["style", "width", "height", "children"]);
    const internalRef = useRef(null);
    const boxRef = (ref || internalRef);
    const boxDim = useBoxDimension(() => boxRef.current);
    return (_jsx(BoxContext.Provider, { value: boxDim, children: _jsx("div", Object.assign({ ref: boxRef, style: Object.assign(Object.assign({}, style), { width, height }) }, divProps, { children: children })) }));
});
function parseDimension(expression, dim) {
    // if the expression is not set early return
    if (!expression)
        return expression;
    const hasBoxUnits = !!expression.match(/bw|bh/g);
    // if dim is not set and had box units return auto
    if (hasBoxUnits && !dim)
        return 'auto';
    let value = expression;
    if (hasBoxUnits) {
        const { width, height } = dim; //undefined check is already done
        value = expression.replace(/bw/g, `*${width / 100}px`);
        value = value.replace(/bh/g, `*${height / 100}px`);
    }
    if (!value.includes('calc') && value !== 'auto') {
        value = `calc(${value})`;
    }
    return value;
}
export function useBoxUnit(expression) {
    const dim = useContext(BoxContext);
    return parseDimension(expression, dim);
}
export function useBoxUnitWithBox(expression, getBox) {
    const dim = useBoxDimension(getBox);
    return parseDimension(expression, dim);
}
/**
 * Convert calc based expression to numeric pixel value, this is sometime needed
 * by the external libraries we use.
 * Note: this will support expression only px values, any percentage or vh or any other
 * unit will not work
 */
export function evaluateViewBoxCalcExp(calcExpr, defaultValue) {
    if (calcExpr === 'auto')
        return 0;
    try {
        // eslint-disable-next-line no-eval
        const val = eval(calcExpr.replace(/calc|px/g, ''));
        return val;
    }
    catch (e) {
        if (defaultValue !== undefined)
            return defaultValue;
        throw new Error(`only pixel based calc can be evaluated, but received ${calcExpr}`);
    }
}
