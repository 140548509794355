export function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function formatNumber(number, decimalScale = 2) {
    return new Intl.NumberFormat('en-us', {
        maximumFractionDigits: decimalScale
    }).format(number);
}
export function padNumber(num, size) {
    return num.toString().padStart(size, '0');
}
export function compactNumber(number) {
    return Intl.NumberFormat('en', { notation: 'compact' }).format(number);
}
export function getNumberWithOrdinalSuffix(number) {
    let suffix = 'th';
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;
    if (lastTwoDigits < 11 || lastTwoDigits > 13) {
        switch (lastDigit) {
            case 1:
                suffix = 'st';
                break;
            case 2:
                suffix = 'nd';
                break;
            case 3:
                suffix = 'rd';
        }
    }
    return `${number}${suffix}`;
}
