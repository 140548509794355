import { castArray } from 'lodash-es';
import { nanoid } from 'nanoid';

import { BaseHistoryEntryType, FindParams, HistoryEntry, HistoryStackEntry } from './types';

export function mergeAtEnd<T extends BaseHistoryEntryType>(
  stackEntry: HistoryStackEntry<T>,
  newHistoryEntry: HistoryEntry<T>
): HistoryStackEntry<T> {
  return {
    ...stackEntry,
    entries: [...stackEntry.entries, newHistoryEntry]
  };
}

export function findAndMergeAfterEntry<T extends BaseHistoryEntryType>(
  find: (findParams: FindParams<T>) => boolean,
  newHistoryEntries: HistoryEntry<T> | HistoryEntry<T>[]
) {
  const _newHistoryEntries = castArray(newHistoryEntries);
  return (params: FindParams<T>) => {
    const found = find(params);
    if (found) {
      const { stackEntry, historyEntryIndex } = params;
      const historyEntries = stackEntry.entries;
      return {
        ...stackEntry,
        entries: [
          ...historyEntries.slice(0, historyEntryIndex + 1),
          ..._newHistoryEntries,
          ...historyEntries.slice(historyEntryIndex + 1, historyEntries.length)
        ]
      };
    }
  };
}

export const frameId = (() => {
  let frameId = nanoid();
  let isUpdateInProgress = false;

  const updateFrameId = () => {
    if (isUpdateInProgress) return;
    isUpdateInProgress = true;
    requestAnimationFrame(() => {
      frameId = nanoid();
      isUpdateInProgress = false;
    });
  };

  return () => {
    updateFrameId();
    return frameId;
  };
})();
