import { theme } from '../theme';
export const tokens = {
    Alert: {
        gap: theme.spaces.x8,
        fontSize: theme.fontSizes.x14,
        borderRadius: theme.radius.m,
        fontFamily: theme.fontFamily.text,
        padding: theme.spaces.x12,
        error: {
            backgroundColor: theme.colors.error50,
            borderColor: theme.colors.error300,
            color: theme.colors.error700,
            iconColor: theme.colors.error700
        },
        warning: {
            backgroundColor: theme.colors.warning50,
            borderColor: theme.colors.warning300,
            color: theme.colors.grey900,
            iconColor: theme.colors.warning700
        },
        success: {
            backgroundColor: theme.colors.success50,
            borderColor: theme.colors.success300,
            color: theme.colors.grey900,
            iconColor: theme.colors.success700
        },
        info: {
            backgroundColor: theme.colors.grey50,
            borderColor: theme.colors.grey300,
            color: theme.colors.grey900,
            iconColor: theme.colors.grey900
        }
    }
};
