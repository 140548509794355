import { Stack, theme, TypographyGlobalStyle } from '@prophecy/ui';
import { BASE_PATH, history, useLocationPathName } from '@prophecy/utils/history';
import DocumentTitle from '@prophecy/utils/react/DocumentTitle';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { matchPath, Navigate, Outlet, Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import {
  ComponentListPage,
  CreateEntityPage,
  CreateFabricPage,
  CreateProject,
  DatasetDetailPage,
  GemBuilderPage,
  HomePage,
  IdeHome,
  IDEPage,
  JobDetailPage,
  LineagePage,
  LineageSearchPage,
  Maintenance,
  OAuthCallback,
  OAuthSamlCallback,
  PackageDetail,
  PackageHubListPage,
  PipelineDetailPage,
  PrivateMainSection,
  ProjectDetailPage,
  ServiceDown,
  SqlPage,
  SubgraphDetailPage,
  TeamDetailPage,
  TranspilerDetailPage,
  TranspilerImportPage,
  TranspilerListingPage,
  UpdateFabricPage,
  UpgradePlanPage,
  UserDetailPage,
  UserSettings
} from '../App';
import { getSparkOnboardingFlow, getSqlOnboardingFlow } from '../common/onboarding/ide-utils';
import { OnboardingStatus } from '../common/onboarding/types';
import { Private_Routes, Public_Routes } from '../common/url';
import { isDeploymentUXEnabled } from '../common/utils';
import { AcceptCookieBanner } from '../components/AcceptCookie';
import { MonitoringNotification } from '../components/Monitoring/MonitoringNotification';
import { useAppMetadata, useTabFocusEvents } from '../context/appMetadata';
import { useGlobalVariables } from '../context/globalConfig/securedConfigHook';
import { useUnsecuredGlobalVariables } from '../context/globalConfig/unsecuredConfigHook';
import { UserFabricStatusKnownStatus } from '../context/types';
import { useUser } from '../context/user';
import { SideNavBar } from '../layout/SideNavBar';
import DeploymentPage from '../pages/Deployment/Deployment';
import { MaintenanceUpgradeStatusList } from '../pages/Maintenance/constants';
import { MaintenanceNotification } from '../pages/Maintenance/MaintenanceNotification';
import { ServiceDownNotification } from '../pages/Maintenance/ServiceDownNotification';
import { useDownServiceStatus } from '../pages/Maintenance/util';
import { OnboardingHome } from '../pages/onboarding';
import { CreateTeam } from '../pages/Team/CreateTeam';
import { useDelightSurvey } from '../trackers/customerDelight';
import { NoMatch, StyledRoot } from './common';

const RouteTemplate = () => {
  const { user } = useUser();

  const sqlFabricStatus = user.sqlFabricStatus;
  const preLoginData = useAppMetadata();
  const globalConfigManager = useGlobalVariables();
  const unsecureGlobalConfigManager = useUnsecuredGlobalVariables();
  const updateStatus = preLoginData?.maintenanceUpdateInfo?.upgrade_status?.status;
  const userInfo = preLoginData?.user;
  const isUnderMaintenance = updateStatus && MaintenanceUpgradeStatusList.indexOf(updateStatus) !== -1;
  const sparkFabricStatus = userInfo?.fabric;
  const isFabricSet = sparkFabricStatus === UserFabricStatusKnownStatus.AlreadyHaveFabric;
  const trialInfo = userInfo?.trialInfo;
  const isCopilotEnabled = globalConfigManager.isCopilotEnabled;
  let isOnboardingRequired = false;
  let onboardingUrl = Private_Routes.Onboarding.getUrl();
  const sparkOnboardingStatus = globalConfigManager.getSparkOnboardingStatus;
  const sqlOnboardingStatus = globalConfigManager.getSqlOnboardingStatus;
  const sparkOnboardingPipelineId = userInfo?.onboardingDetail?.project?.pipelines[0]?._id;
  const sqlOnboardingProjectId = userInfo?.onboardingDetail?.sqlProject?._id;

  const sparkOnboarding = sparkOnboardingPipelineId
    ? getSparkOnboardingFlow(sparkFabricStatus as number, sparkOnboardingStatus, sparkOnboardingPipelineId)
    : null;
  const sqlOnboarding = sqlOnboardingProjectId
    ? getSqlOnboardingFlow(sqlFabricStatus, sqlOnboardingStatus, sqlOnboardingProjectId)
    : null;
  const isSqlOnboardingPending = sqlOnboarding && sqlOnboarding.flow === OnboardingStatus.ONBOARDING_PIPELINE;
  const isSparkOnboardingPending = sparkOnboarding && sparkOnboarding.flow === OnboardingStatus.ONBOARDING_PIPELINE;
  if (isCopilotEnabled) {
    if (isSparkOnboardingPending && isSqlOnboardingPending) {
      isOnboardingRequired = true;
    }
  } else {
    if (isSparkOnboardingPending && sparkOnboarding) {
      isOnboardingRequired = true;
      onboardingUrl = sparkOnboarding.url;
    }
  }
  const enterpriseTrial = Boolean(trialInfo && !trialInfo?.billingStarted);
  const expiryTime: number = trialInfo?.daysLeft || 2;
  const isPlanExpired = Boolean(unsecureGlobalConfigManager.isMeteringEnabled && enterpriseTrial && expiryTime < 1);

  globalConfigManager.setFabricStatus(isFabricSet);
  globalConfigManager.setUnsetFabricStatusId(sparkFabricStatus as number);

  const patterns = [
    '/create/*',
    '/entity/*',
    '/settings/*',
    '/lineage/*',
    '/deployment/*',
    '/pipeline/*',
    '/job/*',
    '/components/*'
  ];

  const pathname = useLocationPathName();
  const routeMatched = patterns.some((pattern) => {
    const match = matchPath(pattern, pathname);
    if (match) {
      return true;
    }
    return false;
  });

  const isProphecyDown = useDownServiceStatus()?.isProphecyDown;
  useTabFocusEvents();

  let OutletComponent = Outlet;
  //Maintenance page should get the priority than prophecydown
  if (isUnderMaintenance && (pathname !== Public_Routes.Maintenance.getUrl() || routeMatched)) {
    OutletComponent = () => <Navigate replace to={Public_Routes.Maintenance.getUrl()} />;
  } else if (
    isProphecyDown &&
    !isUnderMaintenance &&
    (pathname !== Public_Routes.ProphecyDown.getUrl() || routeMatched)
  ) {
    OutletComponent = () => <Navigate replace to={Public_Routes.ProphecyDown.getUrl()} />;
  } else if (isPlanExpired && (pathname === Private_Routes.Home.getUrl() || routeMatched)) {
    OutletComponent = () => <Navigate replace to={Private_Routes.UPGRADE_PLAN.getUrl()} />;
  } else if (!isUnderMaintenance && pathname === Public_Routes.Maintenance.getUrl()) {
    OutletComponent = () => <Navigate replace to={Private_Routes.Home.getUrl()} />;
  } else if (
    isOnboardingRequired &&
    ![
      onboardingUrl,
      sparkOnboarding?.url || '',
      sqlOnboarding?.url.split('?')[0],
      Private_Routes.Settings.home.getUrl()
    ].some((item) => pathname.startsWith(item as string))
  ) {
    OutletComponent = () => <Navigate replace to={onboardingUrl} />;
  }

  return (
    <>
      <DocumentTitle title='Prophecy.io' />
      {!isUnderMaintenance && (
        <>
          <ServiceDownNotification />
          <MaintenanceNotification />
          <MonitoringNotification />
        </>
      )}
      <StyledRoot>
        <Stack width='100%' direction='horizontal'>
          {!isProphecyDown && !isUnderMaintenance && (
            <SideNavBar isOnboardingRequired={isOnboardingRequired} isPlanExpired={isPlanExpired} />
          )}
          <OutletComponent />
        </Stack>
      </StyledRoot>
    </>
  );
};

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export const PrivateRoutes = function () {
  const globalConfigManager = useGlobalVariables();
  const user = useUser();

  useDelightSurvey();

  return (
    <>
      <TypographyGlobalStyle />
      <AcceptCookieBanner />
      <HistoryRouter basename={BASE_PATH} history={history}>
        <SentryRoutes>
          <Route element={<RouteTemplate />}>
            <Route
              key={Private_Routes.Home.url}
              path={Private_Routes.Home.url}
              element={
                <PrivateMainSection>
                  <HomePage />
                </PrivateMainSection>
              }
            />
            <Route
              key={Public_Routes.Maintenance.url}
              path={Public_Routes.Maintenance.url}
              element={
                <PrivateMainSection wrapInLayout={true}>
                  <Maintenance />
                </PrivateMainSection>
              }
            />
            <Route
              key={Public_Routes.ProphecyDown.url}
              path={Public_Routes.ProphecyDown.url}
              element={
                <PrivateMainSection wrapInLayout={true}>
                  <ServiceDown />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Create_Entity.home.url}
              key={`CreateEntityHome`}
              element={
                <PrivateMainSection wrapInLayout={true}>
                  <CreateEntityPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Create_Entity.project.url}
              key={`CreateProject`}
              element={
                <PrivateMainSection>
                  <CreateProject />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Create_Entity.fabric.new.url}
              key={`CreateFabric`}
              element={
                <PrivateMainSection>
                  <CreateFabricPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Create_Entity.fabric.existing.url}
              key={`CreateFabric`}
              element={
                <PrivateMainSection>
                  <CreateFabricPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Create_Entity.tab.url}
              key={`CreateEntityTab`}
              element={
                <PrivateMainSection wrapInLayout={true}>
                  <CreateEntityPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Create_Entity.team.url}
              key={`CreateTeam`}
              element={
                <PrivateMainSection>
                  <CreateTeam />
                </PrivateMainSection>
              }
            />
            {globalConfigManager.isPlaygroundEnabled && (
              <>
                <Route
                  path={Private_Routes.Gems.url}
                  key={Private_Routes.Gems.url}
                  element={
                    <PrivateMainSection wrapInLayout={true}>
                      <ComponentListPage />
                    </PrivateMainSection>
                  }
                />
                <Route
                  path={Private_Routes.GemPlayground.url}
                  key={Private_Routes.GemPlayground.url}
                  element={
                    <PrivateMainSection>
                      <GemBuilderPage isAirflow={false} />
                    </PrivateMainSection>
                  }
                />
                <Route
                  path={Private_Routes.AirflowGemPlayground.url}
                  key={Private_Routes.AirflowGemPlayground.url}
                  element={
                    <PrivateMainSection>
                      <GemBuilderPage isAirflow />
                    </PrivateMainSection>
                  }
                />
              </>
            )}
            <Route
              path={Private_Routes.UPGRADE_PLAN.url}
              key={Private_Routes.UPGRADE_PLAN.url}
              element={
                <PrivateMainSection wrapInLayout={true}>
                  <UpgradePlanPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Package.home.url}
              key={Private_Routes.Package.home.url}
              element={
                <PrivateMainSection>
                  <PackageDetail />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Package.tab.url}
              key={Private_Routes.Package.tab.url}
              element={
                <PrivateMainSection>
                  <PackageDetail />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.PackageHubList.url}
              key={Private_Routes.PackageHubList.url}
              element={
                <PrivateMainSection>
                  <PackageHubListPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.User.home.url}
              key={Private_Routes.User.home.url}
              element={
                <PrivateMainSection wrapInLayout={true}>
                  <UserDetailPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.User.tab.url}
              key={Private_Routes.User.tab.url}
              element={
                <PrivateMainSection wrapInLayout={true}>
                  <UserDetailPage />
                </PrivateMainSection>
              }
            />

            <Route
              path={Private_Routes.Lineage_IDE.datasetOrPipeline.url}
              key={`datasetOrPipeline_lineage`}
              element={
                <PrivateMainSection minBreakpoint={theme.breakpoints.l}>
                  <LineagePage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Lineage_IDE.column.url}
              key={`column_lineage`}
              element={
                <PrivateMainSection minBreakpoint={theme.breakpoints.l}>
                  <LineagePage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Lineage_Search.url}
              key={Private_Routes.Lineage_Search.url}
              element={
                <PrivateMainSection>
                  <LineageSearchPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Team.home.url}
              key={Private_Routes.Team.home.url}
              element={
                <PrivateMainSection>
                  <TeamDetailPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Team.tab.url}
              key={Private_Routes.Team.tab.url}
              element={
                <PrivateMainSection>
                  <TeamDetailPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Subgraph.home.url}
              key={Private_Routes.Subgraph.home.url}
              element={
                <PrivateMainSection>
                  <SubgraphDetailPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Subgraph.tab.url}
              key={Private_Routes.Subgraph.tab.url}
              element={
                <PrivateMainSection>
                  <SubgraphDetailPage />
                </PrivateMainSection>
              }
            />

            <Route
              path={Private_Routes.Dataset.home.url}
              key={Private_Routes.Dataset.home.url}
              element={
                <PrivateMainSection>
                  <DatasetDetailPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Dataset.tab.url}
              key={Private_Routes.Dataset.tab.url}
              element={
                <PrivateMainSection>
                  <DatasetDetailPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Job.home.url}
              key={Private_Routes.Job.home.url}
              element={
                <PrivateMainSection>
                  <JobDetailPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Job.tab.url}
              key={Private_Routes.Job.tab.url}
              element={
                <PrivateMainSection>
                  <JobDetailPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Pipeline.home.url}
              key={Private_Routes.Pipeline.home.url}
              element={
                <PrivateMainSection>
                  <PipelineDetailPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Pipeline.tab.url}
              key={Private_Routes.Pipeline.tab.url}
              element={
                <PrivateMainSection>
                  <PipelineDetailPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Settings.home.url}
              key={Private_Routes.Settings.home.url}
              element={
                <PrivateMainSection wrapInLayout={true}>
                  <UserSettings />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Settings.tab.url}
              key={Private_Routes.Settings.tab.url}
              element={
                <PrivateMainSection wrapInLayout={true}>
                  <UserSettings />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Project.home.url}
              key={Private_Routes.Project.home.url}
              element={
                <PrivateMainSection>
                  <ProjectDetailPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Project.tab.url}
              key={Private_Routes.Project.tab.url}
              element={
                <PrivateMainSection>
                  <ProjectDetailPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Fabric.home.url}
              key={Private_Routes.Fabric.home.url}
              element={
                <PrivateMainSection>
                  <UpdateFabricPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Fabric.tab.url}
              key={Private_Routes.Fabric.tab.url}
              element={
                <PrivateMainSection>
                  <UpdateFabricPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.Transpiler.url}
              key={Private_Routes.Transpiler.url}
              element={
                <PrivateMainSection>
                  <TranspilerListingPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.TranspilerDetail.url}
              key={Private_Routes.TranspilerDetail.url}
              element={
                <PrivateMainSection>
                  <TranspilerDetailPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.TranspilerImport.home.url}
              key={Private_Routes.TranspilerImport.home.url}
              element={
                <PrivateMainSection>
                  <TranspilerImportPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.TranspilerImport.tab.url}
              key={Private_Routes.TranspilerImport.tab.url}
              element={
                <PrivateMainSection>
                  <TranspilerImportPage />
                </PrivateMainSection>
              }
            />
            <Route
              path={Private_Routes.IDE_Home.url}
              key={Private_Routes.IDE_Home.url}
              element={
                <PrivateMainSection minBreakpoint={theme.breakpoints.l}>
                  <IdeHome />
                </PrivateMainSection>
              }
            />
            <Route
              key={Private_Routes.IDE.url}
              path={Private_Routes.IDE.url}
              element={
                <PrivateMainSection minBreakpoint={theme.breakpoints.l}>
                  <IDEPage />
                </PrivateMainSection>
              }
            />

            <Route
              key={Public_Routes.OauthCallback.url}
              path={Public_Routes.OauthCallback.url}
              element={
                <PrivateMainSection>
                  <OAuthCallback />
                </PrivateMainSection>
              }
            />

            <Route
              key={Public_Routes.OauthSamlCallback.url}
              path={Public_Routes.OauthSamlCallback.url}
              element={
                <PrivateMainSection>
                  <OAuthSamlCallback />
                </PrivateMainSection>
              }
            />

            {globalConfigManager.isSQLProjectEnabled && (
              <Route
                key={Private_Routes.SQL_IDE.url}
                path={Private_Routes.SQL_IDE.url}
                element={
                  <PrivateMainSection minBreakpoint={theme.breakpoints.l}>
                    <SqlPage />
                  </PrivateMainSection>
                }
              />
            )}
            <Route
              key={Private_Routes.Onboarding.url}
              path={Private_Routes.Onboarding.url}
              element={
                <PrivateMainSection wrapInLayout={true}>
                  <OnboardingHome />
                </PrivateMainSection>
              }
            />
            {isDeploymentUXEnabled(user.user) && (
              <Route
                key={Private_Routes.Deployment.url}
                path={Private_Routes.Deployment.url}
                element={
                  <PrivateMainSection>
                    <DeploymentPage />
                  </PrivateMainSection>
                }
              />
            )}
            <Route path='*' element={<NoMatch />} />
          </Route>
          <Route path='*' element={<NoMatch />} />
        </SentryRoutes>
      </HistoryRouter>
    </>
  );
};
