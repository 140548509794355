import styled, { css } from 'styled-components';
import { Icon, LoadingDIcon } from '../Icons';
import { theme } from '../theme';
import { LinkComponent } from './LinkComponent';
import { tokens } from './tokens';
import { ButtonShape, ButtonState, ButtonVariants, IconPlacement } from './types';
const getVariantProperty = ({ category, variant }, property, state) => {
    var _a, _b, _c;
    const variantProperties = (_b = (_a = tokens.Button[category]) === null || _a === void 0 ? void 0 : _a[variant]) !== null && _b !== void 0 ? _b : {};
    if (state) {
        return (_c = variantProperties[state][property]) !== null && _c !== void 0 ? _c : variantProperties[property];
    }
    return variantProperties[property];
};
const PlainButtonStyle = css `
  all: unset;
  && {
    -webkit-appearance: none;
  }
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  cursor: pointer;
`;
const ButtonStyle = css `
  &&:focus-visible {
    outline: none;
  }
  //TODO: remove this once we have removed tailwind
  && {
    -webkit-appearance: none;
  }

  cursor: pointer;
  box-sizing: border-box;
  font-weight: ${tokens.Button.fontWeight};
  transition: ${tokens.Button.transition};
  font-family: ${theme.fontFamily.text};
  border-radius: ${(props) => props.shape === ButtonShape.circle ? '50%' : tokens.Button.size[props.size].borderRadius};
  font-size: ${(props) => tokens.Button.size[props.size].fontSize};
  height: ${(props) => tokens.Button.size[props.size].height};
  word-break: normal;
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : 'auto')};
  ${(props) => props.hasChildren
    ? `padding: ${tokens.Button.size[props.size].padding};`
    : `width: ${tokens.Button.size[props.size].height};`};

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${({ size }) => tokens.Button.size[size].gap};

  background-color: ${(props) => { var _a; return (_a = getVariantProperty(props, 'backgroundColor')) !== null && _a !== void 0 ? _a : 'transparent'; }};
  color: ${(props) => getVariantProperty(props, 'color')};
  border: ${(props) => getVariantProperty(props, 'borderColor') ? `1px solid ${getVariantProperty(props, 'borderColor')}` : 'none'};
  box-shadow: ${(props) => { var _a; return (_a = getVariantProperty(props, 'boxShadow')) !== null && _a !== void 0 ? _a : 'none'; }};

  &:focus,
  &:active,
  &[data-active='true'] {
    background-color: ${(props) => { var _a; return (_a = getVariantProperty(props, 'backgroundColor', ButtonState.focused)) !== null && _a !== void 0 ? _a : 'transparent'; }};
    color: ${(props) => getVariantProperty(props, 'color', ButtonState.focused)};
    border: ${(props) => {
    var _a;
    return getVariantProperty(props, 'borderColor', ButtonState.focused)
        ? `${(_a = getVariantProperty(props, 'borderWidth', ButtonState.focused)) !== null && _a !== void 0 ? _a : '1px'} solid ${getVariantProperty(props, 'borderColor', ButtonState.focused)}`
        : 'none';
}};
    box-shadow: ${(props) => { var _a; return (_a = getVariantProperty(props, 'boxShadow', ButtonState.focused)) !== null && _a !== void 0 ? _a : 'none'; }};
  }

  &:hover {
    background-color: ${(props) => { var _a; return (_a = getVariantProperty(props, 'backgroundColor', ButtonState.hover)) !== null && _a !== void 0 ? _a : 'transparent'; }};
    color: ${(props) => getVariantProperty(props, 'color', ButtonState.hover)};
    border: ${(props) => getVariantProperty(props, 'borderColor', ButtonState.hover)
    ? `1px solid ${getVariantProperty(props, 'borderColor', ButtonState.hover)}`
    : 'none'};
    box-shadow: ${(props) => { var _a; return (_a = getVariantProperty(props, 'boxShadow', ButtonState.hover)) !== null && _a !== void 0 ? _a : 'none'; }};

    &:focus,
    &:active,
    &[data-active='true'] {
      border: ${(props) => {
    var _a;
    return getVariantProperty(props, 'borderColor', ButtonState.focused)
        ? `${(_a = getVariantProperty(props, 'borderWidth', ButtonState.focused)) !== null && _a !== void 0 ? _a : '1px'} solid ${getVariantProperty(props, 'borderColor', ButtonState.focused)}`
        : 'none';
}};

      box-shadow: ${(props) => { var _a; return (_a = getVariantProperty(props, 'boxShadow', ButtonState.focused)) !== null && _a !== void 0 ? _a : 'none'; }};
    }
  }

  &:disabled {
    background-color: ${(props) => { var _a; return (_a = getVariantProperty(props, 'backgroundColor', ButtonState.disabled)) !== null && _a !== void 0 ? _a : 'transparent'; }};
    color: ${(props) => getVariantProperty(props, 'color', ButtonState.disabled)};
    border: ${(props) => getVariantProperty(props, 'borderColor', ButtonState.disabled)
    ? `1px solid ${getVariantProperty(props, 'borderColor', ButtonState.disabled)}`
    : 'none'};
    box-shadow: ${(props) => { var _a; return (_a = getVariantProperty(props, 'boxShadow', ButtonState.disabled)) !== null && _a !== void 0 ? _a : 'none'; }};
    cursor: not-allowed;
  }

  /** Override for block button */
  ${(props) => props.block &&
    `
    border-radius: 0;
    height: 100%;
    width: 100%;
    padding-top: ${theme.spaces.x6};
    padding-bottom: ${theme.spaces.x6};
  `}

  /** Override for plain button */
  ${(props) => props.variant === ButtonVariants.plain &&
    `
    border-radius: 0;
    height: auto;
    width: auto;
    padding: 0;
    font-size: inherit;
  `}

  /** Style icon base on size */
  ${Icon} {
    font-size: ${(props) => tokens.Button.size[props.size].iconSize};
  }
`;
export const StyledButton = styled.button `
  ${(props) => (props.variant === ButtonVariants.plain ? PlainButtonStyle : ButtonStyle)}
`;
export const StyledButtonLink = styled(LinkComponent) `
  ${(props) => (props.variant === ButtonVariants.plain ? PlainButtonStyle : ButtonStyle)}
`;
export const StyledButtonAnchor = styled.a `
  ${(props) => (props.variant === ButtonVariants.plain ? PlainButtonStyle : ButtonStyle)}
`;
export const StyledIconWrapper = styled.span `
  display: inline-block;
  line-height: 1;
  order: ${(props) => (props.iconPlacement === IconPlacement.left ? '1' : '3')};
`;
export const StyledButtonContent = styled.span `
  order: 2;
`;
export const StyledLoadingIcon = styled(LoadingDIcon) `
  order: ${(props) => (props.iconPlacement === IconPlacement.left ? '3' : '1')};
  display: inline-block;
  vertical-align: middle;
`;
export const ButtonWrapperForTooltip = styled.span `
  display: inline-block;
`;
