import { graphql } from '../../graphqlTypes/graphql';

export const SetUserPreferenceQuery = graphql(`
  mutation setUserPreference($userPreference: String!) {
    setUserPreference(userPreference: $userPreference)
  }
`);

export const GetUserPreferenceQuery = graphql(`
  query getUserPreference($key: String) {
    getUserPreference(key: $key)
  }
`);
