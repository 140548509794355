import { GlobalContextProvider, setGlobalContext } from '@prophecy/utils/react/utilify';
import { noop } from 'lodash-es';
import React, { createContext, useContext, useEffect, useMemo } from 'react';

import { fetchOnboardingDetails } from '../common/onboarding/query';
import { OnboardingStatus } from '../common/onboarding/types';
import { captureException } from '../common/sentry';
import { Entity } from '../common/types/Entity';
import { EntityEvents } from '../common/user-analytics';
import {
  getAppSession,
  getAthenaTrial,
  getFreshDeskToken,
  getGlobalConf,
  getKeepAliveSession,
  getUnSecureGlobalConfig,
  getUser,
  getUserFabricStatus
} from '../data/apis/api';
import { fetchUpdateInfo } from '../data/apis/athena';
import { isAnyWebsocketAlive } from '../data/websocket-counter';
import { mdClient } from '../LSP/websocket/MdClient/MdWebSocketBase';
import { csrf, CSRF_TOKEN_KEY } from '../utils/csrf';
import { WORKFLOW_DEBUG_MODE_KEY } from '../utils/localstorage-keys';
import { GlobalWindow } from '../utils/types';
import { useMixPanel } from './mixpanel/context';
import { AppMetadataProviderType, UserDetails, UserFabricStatusKnownStatus, TrialInfo } from './types';

const AppMetadataContext = createContext<Omit<AppMetadataProviderType, 'globalConfig' | 'unsecuredConfig'> | undefined>(
  undefined
);

export const getRecentUpdateInfo = async () => {
  try {
    const maintenanceUpdateInfoData = await fetchUpdateInfo();
    if (maintenanceUpdateInfoData && maintenanceUpdateInfoData.success) {
      return maintenanceUpdateInfoData && maintenanceUpdateInfoData.data;
    }
  } catch (error: unknown) {
    console.error(error);
    // To avoid the error case when we load userinfo
    return {};
  }
};

function keepTokenAlive() {
  const schedule = () => {
    setTimeout(
      () => {
        if (!isAnyWebsocketAlive() && document.hidden) {
          // document.hidden will be true if tab is not visible
          return;
        }

        getKeepAliveSession()
          .then(schedule) // schedule next one only when the API succeeds. It will fail in case of error.
          .catch(noop);
      },
      3 * 60 * 1000
    ); // 3 minutes
  };

  document.addEventListener('visibilitychange', function () {
    // we need to refresh token when ever there visibility change visible -> hidden or hidden to active.
    // visible -> hidden is required so that we make sure, token is not expired just after the tab change
    // hidden -> visible is required so that we can check if token is valid
    getKeepAliveSession().catch(noop);
  });

  schedule();
}

let global = window as unknown as GlobalWindow;
global.__current_user_info = {
  id: ''
};

async function fetchUserDetails(execURl: string): Promise<UserDetails> {
  // merge fabric status from execution API
  const [userDetailsResult, userFabricStatusResult] = await Promise.allSettled([
    getUser<Omit<UserDetails, 'fabric'>>(),
    getUserFabricStatus(execURl)
  ]);
  let userDetails: UserDetails;

  if (userDetailsResult.status === 'fulfilled') userDetails = userDetailsResult.value;
  else throw userDetailsResult.reason;
  // if fabric status api fails don't throw and set the fabric status to -1
  userDetails.fabric =
    userFabricStatusResult.status === 'fulfilled'
      ? userFabricStatusResult.value.fabric
      : UserFabricStatusKnownStatus.AlreadyHaveFabric;

  return userDetails;
}

type ConfigType = { [key: string]: boolean | undefined | string };
const fetchUnsecuredGlobalConfig = async () => {
  try {
    const resp = (await getUnSecureGlobalConfig()) as unknown as ConfigType[];
    let settings: ConfigType = {};
    (resp || []).forEach((config) => {
      settings = { ...settings, ...config };
    });
    return settings;
  } catch (error) {
    console.error(error);
    return {};
  }
};

const fetchSecuredGlobalConfig = async () => {
  try {
    const resp = (await getGlobalConf('/globalConf')) as unknown as ConfigType[];
    let settings: ConfigType = {};
    (resp || []).forEach((config) => {
      settings = { ...settings, ...config };
    });
    return settings;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export async function getAppMetadata() {
  const appMetadata: AppMetadataProviderType = {
    user: {
      id: '',
      email: ''
    },
    globalConfig: {
      onboardingStatus: OnboardingStatus.NOT_SET,
      sqlOnboardingStatus: OnboardingStatus.NOT_SET,
      unsetFabricStatusId: -1,
      isFabricSet: false
    },
    unsecuredConfig: {},
    cookieAccepted: false,
    maintenanceUpdateInfo: {},
    setMaintenanceUpdateInfo: () => null
  };

  appMetadata.unsecuredConfig = await fetchUnsecuredGlobalConfig();

  appMetadata.setMaintenanceUpdateInfo = async () => {
    appMetadata.maintenanceUpdateInfo = await getRecentUpdateInfo();
  };
  try {
    const maintenanceUpdateInfo = await getRecentUpdateInfo();
    appMetadata.maintenanceUpdateInfo = maintenanceUpdateInfo;
    // fetch csrf
    const session = await getAppSession<{ [CSRF_TOKEN_KEY]: string; 'accept-prophecy-cookies': boolean }>();
    const token = session[CSRF_TOKEN_KEY];
    appMetadata.cookieAccepted = session['accept-prophecy-cookies'];
    if (token) {
      csrf.set(token);
    } else {
      return appMetadata;
    }

    //Check if user trying to login using token
    if (window.location.href.indexOf('loginUsingToken') >= 0) {
      return appMetadata;
    }
    const globalConfig = await fetchSecuredGlobalConfig();
    appMetadata.globalConfig = { ...appMetadata.globalConfig, ...globalConfig };
    //check if session is active
    const data = await fetchUserDetails(appMetadata.globalConfig?.['prophecy.ui.execution.url'] as string);

    if (!data?.id) {
      return appMetadata;
    }
    let onboardingDetail: UserDetails['onboardingDetail'];
    try {
      onboardingDetail = await fetchOnboardingDetails(
        { uid: data.id },
        Boolean(appMetadata.globalConfig['prophecy.ui.backend.sql.enabled']),
        Boolean(appMetadata.globalConfig['prophecy.ui.airflow.enabled'])
      );
      if (onboardingDetail) {
        appMetadata.globalConfig.onboardingStatus = onboardingDetail.lastFlowCompleted as OnboardingStatus;
        appMetadata.globalConfig.sqlOnboardingStatus = onboardingDetail.lastFlowCompletedSql as OnboardingStatus;
      }
    } catch (error) {
      // ignore error as it is already handled in fetchOnboardingDetails
    }
    let freshDeskToken = '';
    try {
      const freshDeskResponse = await getFreshDeskToken();
      freshDeskToken = freshDeskResponse.token;
    } catch (error) {
      captureException({ exception: error });
    }
    // turn on debug mode by default for user with prophecy email
    if (data.email.endsWith('@prophecy.io')) {
      // set only if user haven't set before
      const wasSet = localStorage.getItem(WORKFLOW_DEBUG_MODE_KEY);
      if (!wasSet) {
        localStorage.setItem(WORKFLOW_DEBUG_MODE_KEY, 'true');
      }
    }

    let trialInfo;
    if (appMetadata.unsecuredConfig?.['prophecy.ui.unsecured.metering.enabled'] && data.email) {
      try {
        trialInfo = await getAthenaTrial<TrialInfo>();
      } catch (error) {
        captureException({ exception: error });
      }
    }

    // store email and id on global container
    global.__current_user_info.id = data.id;

    mdClient.lazyInit();
    appMetadata.user = {
      ...data,
      freshDeskToken,
      trialInfo,
      fabric: Number(data.fabric),
      onboardingDetail: onboardingDetail
    };
    keepTokenAlive();
    return appMetadata;
  } catch (error) {
    return appMetadata;
  }
}

export function useAppMetadata() {
  return useContext(AppMetadataContext) as Omit<AppMetadataProviderType, 'globalConfig' | 'unsecuredConfig'>;
}

export function AppMetadataProvider({
  children,
  value
}: {
  children: React.ReactNode;
  value: AppMetadataProviderType;
}) {
  const { globalConfig, unsecuredConfig, appMetadata } = useMemo(() => {
    const { globalConfig, unsecuredConfig, ...appMetadata } = value;
    return { globalConfig, unsecuredConfig, appMetadata };
  }, [value]);

  useMemo(() => {
    setGlobalContext('globalConfig', globalConfig);
    setGlobalContext('unsecuredConfig', unsecuredConfig);
  }, [globalConfig, unsecuredConfig]);

  return (
    <AppMetadataContext.Provider value={appMetadata}>
      <GlobalContextProvider>{children}</GlobalContextProvider>
    </AppMetadataContext.Provider>
  );
}

const EVENT_NAME = 'visibilitychange';

export const useTabFocusEvents = () => {
  const { track } = useMixPanel();
  useEffect(() => {
    const onVisibilityChange = () => {
      const isVisible = document.visibilityState === 'visible';
      track(isVisible ? EntityEvents[Entity.User].home.tabFocusIn : EntityEvents[Entity.User].home.tabFocusOut);
    };

    document.addEventListener(EVENT_NAME, onVisibilityChange);

    return () => {
      document.removeEventListener(EVENT_NAME, onVisibilityChange);
    };
  }, [track]);
};
