import { useCallback, useEffect, useRef } from 'react';
import { getFirstFocusableElement } from '../dom';
export function useAutoFocus(enabled, container) {
    useEffect(() => {
        if (!enabled)
            return;
        if (container.current) {
            container.current.focus();
        }
    }, [container, enabled]);
}
export const useFocusTrapWithContainer = (enabled, container) => {
    useEffect(() => {
        if (!enabled)
            return;
        const cb = (event) => {
            if (event.relatedTarget === null && (container === null || container === void 0 ? void 0 : container.current)) {
                container === null || container === void 0 ? void 0 : container.current.focus();
            }
        };
        document.addEventListener('focusout', cb);
        return () => {
            document.removeEventListener('focusout', cb);
        };
    }, [enabled, container]);
};
export function useFocusTrap(enabled) {
    const container = useRef(null);
    useFocusTrapWithContainer(enabled, container);
    return container;
}
export function useFocusHandleOnClose(open) {
    const focusedElm = useRef(null);
    useEffect(() => {
        if (open) {
            focusedElm.current = document.activeElement;
        }
    }, [open]);
    const preventOnCloseFocus = useCallback((event) => {
        var _a;
        if (focusedElm.current && document.body.contains(focusedElm.current)) {
            focusedElm.current.focus();
        }
        else {
            (_a = getFirstFocusableElement()) === null || _a === void 0 ? void 0 : _a.focus();
            event.preventDefault();
        }
    }, []);
    return preventOnCloseFocus;
}
