import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Tooltip } from '../Tooltip';
import { LabelWithHint } from './LabelWithHint';
import { BaseCode, StyledCodeBlock } from './styled';
export const Code = forwardRef((_a, ref) => {
    var { level = 'sm13', hint, tooltip, tone, boxed, weight, elementType, children } = _a, rest = __rest(_a, ["level", "hint", "tooltip", "tone", "boxed", "weight", "elementType", "children"]);
    let childUI = boxed ? _jsx(StyledCodeBlock, { children: children }) : children;
    if (tooltip) {
        const tooltipProps = typeof tooltip === 'string'
            ? { title: tooltip, placement: 'top' }
            : tooltip;
        childUI = (_jsx(Tooltip, Object.assign({}, tooltipProps, { children: _jsx("span", { children: childUI }) })));
    }
    else if (hint) {
        childUI = _jsx(LabelWithHint, { hint: hint, children: childUI });
    }
    return (_jsx(BaseCode, Object.assign({}, rest, { tone: tone, weight: weight, as: elementType, level: level, ref: ref, children: childUI })));
});
