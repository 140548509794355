export var CronCategory;
(function (CronCategory) {
    CronCategory["Minute"] = "Minute";
    CronCategory["Hourly"] = "Hourly";
    CronCategory["Daily"] = "Daily";
    CronCategory["Weekly"] = "Weekly";
    CronCategory["Monthly"] = "Monthly";
    CronCategory["Yearly"] = "Yearly";
})(CronCategory || (CronCategory = {}));
/**
 * Note CronQuartz is super set of CronUnix, so we can use CronUnix to define all
 * internal implementation of CronInput and CronGenerator
 */
export var CronType;
(function (CronType) {
    CronType["quartz"] = "quartz";
    CronType["unix"] = "unix";
})(CronType || (CronType = {}));
