import { Stack } from '@prophecy/ui/Layout';
import { theme } from '@prophecy/ui/theme';
import { Text } from '@prophecy/ui/Typography';
import { DeploymentAnalytics } from 'frontend/core/src/common/user-analytics/deployment';

import { Root, StyledCard, StyledList } from '../styles';
import { DeploymentStepBaseProp } from '../types';
import { DeploymentStepFooter } from './DeploymentStepFooter';
import DeploymentStepHeader from './DeploymentStepHeader';

export default function PostDeploymentCheck({ nextStep, prevStep }: DeploymentStepBaseProp) {
  return (
    <StyledCard>
      <Root align='space-between'>
        <Stack gap={theme.spaces.x40}>
          <Stack gap={theme.spaces.x16}>
            <DeploymentStepHeader title='4. Post-deployment check' />
            <Stack gap={theme.spaces.x40}>
              <Text level='sm'>Follow the steps below to run a test on your installation.</Text>
            </Stack>
          </Stack>
          <StyledList>
            <li>Log-in your Prophecy deployment</li>
            <li>
              Create a Fabric that connects to your data provider. Example: Databricks, Spark, Snowflake, or BigQuery
            </li>
            <li>Create a new project</li>
            <li>Create a new pipeline and navigate to the pipeline designer screen</li>
            <li>Attach to an existing cluster or create a new cluster</li>
            <li>Make sure the cluster attaches successfully</li>
          </StyledList>
        </Stack>
        <DeploymentStepFooter
          eventCategory={DeploymentAnalytics.deployment_step_post_check.event_category}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      </Root>
    </StyledCard>
  );
}
