import { ObjectLiteral } from '@prophecy/interfaces/generic';

const WEBSOCKET_COUNTER_KEY = '__WEBSOCKET_COUNTER_KEY';
const windowStore = window as ObjectLiteral;
function initWebSocketCounter() {
  if (!windowStore[WEBSOCKET_COUNTER_KEY]) {
    windowStore[WEBSOCKET_COUNTER_KEY] = 0;
  }
}
initWebSocketCounter();
export function increaseWebsocketCounter() {
  windowStore[WEBSOCKET_COUNTER_KEY] = windowStore[WEBSOCKET_COUNTER_KEY] + 1;
}
export function decreaseWebsocketCounter() {
  windowStore[WEBSOCKET_COUNTER_KEY] = windowStore[WEBSOCKET_COUNTER_KEY] - 1;
}
export function isAnyWebsocketAlive() {
  return windowStore[WEBSOCKET_COUNTER_KEY] > 0;
}
