import { __awaiter, __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { waitForElement } from '@prophecy/utils/dom';
import { useAfterNextRender, useLazyEffect, usePersistentCallback, useWatch } from '@prophecy/utils/react/hooks';
import { isEqual } from 'lodash-es';
import { useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { Button } from '../Button';
import { PlusIcon } from '../Icons';
import { theme } from '../theme';
const StyledObserverElem = styled.div `
  height: 1px;
  width: 100%;
  background: transparent;
  position: relative;
  bottom: ${({ bottom }) => bottom};
`;
const StyledButtonWrap = styled.div `
  position: sticky;
  bottom: 0;
  left: 0;
`;
const StyleButtonBackDrop = styled.div `
  background: white;
  padding: ${theme.spaces.x4};
  border-radius: ${theme.radius.m};
  position: absolute;
  bottom: 0;
  left: 0;
`;
function shouldAutoScrollOnAddition(prevData, newData) {
    const changedIndex = prevData.findIndex((item, index) => !isEqual(item, newData[index]));
    // if changedIndex is -1, it means that the new data is added at the end
    return changedIndex === -1;
}
function BottomObserverElement({ placeholderRows = 0, rowHeight, totalRows, setObserverElementRef, observerElmRef, shouldAutoScroll }) {
    const afterNextRender = useAfterNextRender();
    const lastTotalRows = useRef(totalRows);
    useLazyEffect(() => {
        const lastRowsCount = lastTotalRows.current;
        lastTotalRows.current = totalRows;
        // no need to auto scroll to bottom if something is deleted
        if (lastRowsCount >= totalRows)
            return;
        if (!shouldAutoScroll())
            return;
        afterNextRender(() => {
            var _a;
            if (observerElmRef.current) {
                (_a = observerElmRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'nearest' });
            }
        });
    }, [totalRows]);
    return (_jsx(_Fragment, { children: _jsx(StyledObserverElem, { ref: setObserverElementRef, bottom: `${placeholderRows * rowHeight || 0}px` }) }));
}
function AddButton({ newRowLabel, observerElmRef, boxRef }) {
    const onClick = () => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        (_a = observerElmRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'nearest' });
        try {
            const newRow = (yield waitForElement('tr[data-new-row="true"]', boxRef.current, 2000));
            (_b = newRow.querySelector('input,textArea')) === null || _b === void 0 ? void 0 : _b.focus();
        }
        catch (e) {
            // ignore error
        }
    });
    return (_jsx(StyledButtonWrap, { children: _jsx(StyleButtonBackDrop, { children: _jsxs(Button, { onClick: onClick, variant: 'secondaryGrey', size: 's', icon: _jsx(PlusIcon, { type: 'default' }), children: ["Add ", newRowLabel] }) }) }));
}
export function useObservableTable(_a) {
    var { showAddNewRowButton, rowHeight, newRowLabel, boxRef, dataSource, autoScrollOnAddition } = _a, props = __rest(_a, ["showAddNewRowButton", "rowHeight", "newRowLabel", "boxRef", "dataSource", "autoScrollOnAddition"]);
    const { ref: intersectionRef, inView, entry } = useInView({
        rootMargin: `0px 0px ${rowHeight}px 0px`
    });
    const observerElmRef = useRef(null);
    const newData = useRef(dataSource);
    const prevData = useRef(dataSource);
    useWatch(() => {
        prevData.current = newData.current;
        newData.current = dataSource;
    }, [dataSource]);
    const shouldAutoScroll = usePersistentCallback(() => {
        if (Boolean(autoScrollOnAddition) === false)
            return false;
        if (typeof autoScrollOnAddition === 'function') {
            return autoScrollOnAddition(prevData.current, newData.current);
        }
        return shouldAutoScrollOnAddition(prevData.current, newData.current);
    });
    const setObserverElementRef = useCallback((node) => {
        observerElmRef.current = node;
        intersectionRef(node);
    }, [intersectionRef]);
    const table = usePersistentCallback((tableProps) => {
        const { tableComponent } = props, rest = __rest(props, ["tableComponent"]);
        const Component = tableComponent || 'table';
        return (_jsxs(_Fragment, { children: [_jsx(Component, Object.assign({}, tableProps)), _jsx(BottomObserverElement, Object.assign({}, rest, { rowHeight: rowHeight, totalRows: dataSource.length, observerElmRef: observerElmRef, shouldAutoScroll: shouldAutoScroll, setObserverElementRef: setObserverElementRef }))] }));
    });
    const addButton = inView || !entry || !showAddNewRowButton ? null : (_jsx(AddButton, { newRowLabel: newRowLabel, observerElmRef: observerElmRef, boxRef: boxRef }));
    return { table, addButton };
}
