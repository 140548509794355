import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//TODO: Add stories for collapsible, currently just reexporting from here
import { useControlledState } from '@prophecy/utils/react/hooks';
import { Root, Trigger, Content } from '@radix-ui/react-collapsible';
import styled from 'styled-components';
import { ChevronDownIcon, ChevronRightIcon } from '../Icons';
import { Stack, StackItem } from '../Layout';
import { theme } from '../theme';
import { renderWithTooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { tokens } from './tokens';
export const CollapseHeader = styled(Stack) `
  cursor: pointer;
`;
export const CollapseHeaderContainer = styled(StackItem) `
  order: ${(props) => props.order};
`;
export const CollapseIconContainer = styled.div `
  width: ${theme.sizes.x16};
  order: ${(props) => props.order};
`;
export function Collapse(_a) {
    var { header, hint, tooltip, children, open, onOpenChange, iconPlacement = 'right' } = _a, props = __rest(_a, ["header", "hint", "tooltip", "children", "open", "onOpenChange", "iconPlacement"]);
    const [_open, _onOpenChange] = useControlledState({
        value: open,
        defaultValue: open,
        onChange: onOpenChange
    });
    let headerUI = header;
    if (tooltip) {
        headerUI = renderWithTooltip(header, tooltip);
    }
    if (hint) {
        headerUI = _jsx(LabelWithHint, { hint: hint, children: header });
    }
    return (_jsxs(Root, Object.assign({ open: _open, onOpenChange: _onOpenChange }, props, { children: [_jsx(Trigger, { asChild: true, children: _jsxs(CollapseHeader, { direction: 'horizontal', alignY: 'center', gap: tokens.Trigger.gap, children: [_jsx(CollapseHeaderContainer, { order: iconPlacement === 'right' ? 1 : 2, grow: '1', children: headerUI }), _jsx(CollapseIconContainer, { order: iconPlacement === 'right' ? 2 : 1, children: _open ? _jsx(ChevronDownIcon, { size: 'xs', type: 'default' }) : _jsx(ChevronRightIcon, { size: 'xs', type: 'default' }) })] }) }), _jsx(Content, { asChild: true, children: children })] })));
}
