export const metadata = {
  prConflicts: 'metadata.project.pr.conflicts',
  prCommitStep: 'metadata.project.pr.step.commit',
  prCommitSuccess: 'metadata.project.pr.step.commit.success',
  pullOrigin: 'metadata.project.pr.step.commit.pullOrigin',
  pullOriginSuccess: 'metadata.project.pr.pullOrigin.success',
  pullStep: 'metadata.project.pr.step.pull',
  mergeStep: 'metadata.project.pr.step.merge',
  pullStepSuccess: 'metadata.project.pr.step.pull.success',
  mergeStepSuccess: 'metadata.project.pr.step.merge.success',
  releaseStep: 'metadata.project.pr.step.release',
  releaseStepSuccess: 'metadata.project.pr.step.release.success',
  prLandingStep: 'metadata.project.pr.step.landing',
  projectCreationSuccess: 'project.entity.creation.successful',
  projectCreationFailed: 'project.entity.creation.failed',
  createProjectLanding: 'metadata.create.project',
  cloneProject: 'metadata.clone.project',
  cloneProjectSuccess: 'metadata.clone.project.success',
  cloneProjectFailed: 'metadata.clone.project.failed',
  externalGit: 'metadata.externalGit',
  multiForkOpen: 'metadata.externalGit.multiFork.open',
  unlinkStarted: 'metadata.project.git-setup.unlink_started',
  unlinkFailed: 'metadata.project.git-setup.unlink_failed',
  unlinkSuccess: 'metadata.project.git-setup.unlink_success',
  linkStarted: 'metadata.project.git-setup.link_started',
  linkFailed: 'metadata.project.git-setup.link_failed',
  linkSuccess: 'metadata.project.git-setup.link_success',
  codeTabOpen: 'metadata.entity.code.open',
  codeInternal: 'metadata.entity.code.internal',
  codeExternal: 'metadata.entity.code.external',
  revokeOpen: 'metadata.revoke.open',
  revokeSuccess: 'metadata.project.revoke',
  publishOpen: 'metadata.publish.open',
  publishSuccess: 'metadata.project.publish'
};
