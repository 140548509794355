import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Root } from '@radix-ui/react-separator';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { theme } from '../theme';
const tokens = {
    Separator: {
        borderColor: theme.colors.grey200
    },
    Content: {
        backgorund: theme.colors.white,
        padding: theme.spaces.x12
    }
};
const StyledSeparator = styled(Root) `
  position: relative;
  ${(props) => props.orientation === 'vertical'
    ? `
        border-left: 1px solid ${tokens.Separator.borderColor};
        height: 100%;
      `
    : `border-top: 1px solid ${tokens.Separator.borderColor};`}
`;
const StyledSeparatorContent = styled.div `
  background: ${tokens.Content.backgorund};
  position: absolute;
  padding: 0 ${tokens.Content.padding};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const Separator = forwardRef((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsx(StyledSeparator, Object.assign({}, props, { ref: ref, children: children ? _jsx(StyledSeparatorContent, { children: children }) : null })));
});
