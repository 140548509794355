import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { noop } from 'lodash-es';
import { nanoid } from 'nanoid';
import { createContext, useRef, useState, useContext } from 'react';
import { createContext as createSelectableContext, useContextSelector } from 'use-context-selector';

import { useGlobalVariables } from '../context/globalConfig/securedConfigHook';
import { BaseHistoryEntryType, HistoryEntryTypes } from './types';

import { HistoryManager } from '.';

export const DUMMY_STORE_VALUE = nanoid();

type FrameStoreContext = {
  frameStore: Record<string, unknown>;
  setFrameStore: (key: string, value: unknown) => void;
};

const FrameStoreCtx = createSelectableContext<FrameStoreContext>({ frameStore: {}, setFrameStore: noop });

export const useFrameStore = (k: string) => {
  const key = useRef(k);

  const frameStore = useContextSelector(FrameStoreCtx, ({ frameStore }) => {
    return Object.prototype.hasOwnProperty.call(frameStore, key.current)
      ? frameStore?.[key.current]
      : DUMMY_STORE_VALUE;
  });

  const _setFrameStore = useContextSelector(FrameStoreCtx, ({ setFrameStore }) => setFrameStore);

  const setFrameStore = usePersistentCallback((value: unknown) => _setFrameStore(key.current, value));

  return [frameStore, setFrameStore] as const;
};

export interface HistoryContext<T extends BaseHistoryEntryType = HistoryEntryTypes> extends HistoryManager<T> {}

const HistoryCtx = createContext<HistoryContext | undefined>(undefined);
export const useHistory = <T extends BaseHistoryEntryType = HistoryEntryTypes>() => {
  const history = useContext(HistoryCtx);
  return history as HistoryContext<T> | undefined;
};

export const withHistoryProvider = <T extends {}>(
  WrappedComponent: React.FC<T>,
  ...args: ConstructorParameters<typeof HistoryManager>
) => {
  const history = new HistoryManager(...args);

  return (props: T & { isHistoryEnabled?: boolean }) => {
    const globalConfig = useGlobalVariables();
    const skipHistory = !globalConfig.isHistoryEnabled || !props.isHistoryEnabled;

    const updatedHistory = skipHistory ? undefined : history;

    const [frameStore, _setFrameStore] = useState<Record<string, unknown>>({});

    const setFrameStore = usePersistentCallback((key: string, value: unknown) => {
      // this is used to call setState method across re-mounted re-renders
      // setStore -> listen to change -> setLocalState
      _setFrameStore((prev) => {
        const next = { ...prev };
        if (value === DUMMY_STORE_VALUE) {
          delete next[key];
        } else {
          next[key] = value;
        }
        return next;
      });
    });

    return (
      <FrameStoreCtx.Provider value={{ frameStore, setFrameStore }}>
        <HistoryCtx.Provider value={updatedHistory as HistoryContext}>
          <WrappedComponent {...props} />
        </HistoryCtx.Provider>
      </FrameStoreCtx.Provider>
    );
  };
};
