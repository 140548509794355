import styled from 'styled-components';
import { Button } from '../Button';
import { StyledCheckboxRoot } from '../Checkbox/styled';
import { Icon, MiniXCloseIcon } from '../Icons';
import { focusedShadow, getColorCode } from '../theme/utils';
import { tokens } from './tokens';
export const StyledButton = styled(Button) `
  font-weight: ${tokens.TagsRoot.fontWeight};
  border-radius: ${tokens.TagsRoot.borderRadius};
  padding: ${({ size }) => tokens.TagsRoot.size[size].padding};
  height: ${({ size }) => tokens.TagsRoot.size[size].height};
  font-size: ${({ size }) => tokens.TagsRoot.size[size].fontSize};

  /** Style icon base on size */
  ${Icon} {
    color: ${({ tone, disabled }) => getColorCode(tone, disabled ? 300 : 400)};
    font-size: ${({ size }) => tokens.TagsRoot.size[size].iconFontSize};
  }
  &&&,
  &&&:hover,
  &&&:focus {
    color: ${({ tone, disabled }) => getColorCode(disabled ? 'grey' : tone, disabled ? 400 : 700)};
    background: ${({ tone, filled, disabled }) => filled || disabled ? getColorCode(disabled ? 'grey' : tone, disabled ? 25 : 50) : 'transparent'};
    border: 1px solid ${({ tone, disabled }) => getColorCode(disabled ? 'grey' : tone, disabled ? 200 : 300)};
    outline: none;
    box-shadow: none;
  }
  &&&:hover {
    background: ${({ disabled, filled, tone }) => disabled || filled ? getColorCode(disabled ? 'grey' : tone, disabled ? 25 : 100) : 'transparent'};
  }
  &&&:active,
  &&&:focus {
    background: ${({ tone, disabled }) => (disabled ? getColorCode(disabled ? 'grey' : tone, 25) : 'transparent')};
    box-shadow: ${({ tone, disabled }) => (disabled ? 'none' : focusedShadow(getColorCode(tone, 100)))};
  }
  ${({ disabled, tone }) => disabled
    ? 'cursor: not-allowed;'
    : `
    ${StyledCheckboxRoot}{  
      border: 1px solid ${getColorCode(tone, 300)};
    }
  `}
`;
export const StyledClearIcon = styled(MiniXCloseIcon) `
  color: ${({ tone }) => getColorCode(tone, 400)};
  cursor: pointer;
`;
export const StyledNumberContainer = styled.div `
  background: ${({ tone, disabled }) => getColorCode(tone, disabled ? 50 : 200)};
  color: ${({ tone, disabled }) => getColorCode(tone, disabled ? 400 : 700)};
  border-radius: ${({ countCircle }) => (countCircle ? '50%' : tokens.TagsRoot.countBorderRadius)};
  ${({ countCircle, size }) => countCircle
    ? `
    height: ${tokens.TagsRoot.circleSize};
    min-width: ${tokens.TagsRoot.circleSize};
    line-height: ${tokens.TagsRoot.circleSize};
    font-size: ${tokens.TagsRoot.circleFontSize};
  `
    : ` padding: 1px ${tokens.TagsRoot.size[size].countPadding}`};
`;
