import { theme } from '@prophecy/ui';
import { MarkerSeverity } from '@prophecy/ui/Editor/types';

import { LogType } from '../generic-components/Logger/LogCategory';

export enum SEVERITY_TYPES {
  error = 1,
  warning = 2,
  info = 3,
  hint = 4
}
export const SEVERITY_TYPE_COLOR = {
  [SEVERITY_TYPES.error]: theme.colors.error600,
  [SEVERITY_TYPES.warning]: theme.colors.warning600,
  [SEVERITY_TYPES.info]: theme.colors.blue600,
  [SEVERITY_TYPES.hint]: theme.colors.primary600
};

export const LSP_Severity_To_LogType = {
  1: LogType.error,
  2: LogType.warning,
  3: LogType.info,
  4: LogType.hint
};
export const MonacoSeverityToLogType = {
  [MarkerSeverity.Error]: LogType.error,
  [MarkerSeverity.Warning]: LogType.warning,
  [MarkerSeverity.Info]: LogType.info,
  [MarkerSeverity.Hint]: LogType.hint
};
// TODO: Try to standardize severity from the lsp backend
export const LSP_TO_MONACO_SEVERITY = {
  1: MarkerSeverity.Error,
  2: MarkerSeverity.Warning,
  3: MarkerSeverity.Info,
  4: MarkerSeverity.Hint
};
export type LSP_SEVERITY_KEYS = keyof typeof LSP_TO_MONACO_SEVERITY;
export const WEBSOCKET_ACTOR_DEAD_CODE = -33003;
