export const home = {
  tabChange: 'settings.tab.change',
  adminChangePassword: 'admin.user.changePassword.start',
  impersonateTokenStart: 'admin.user.tokenGenerate.start',
  deleteAccountStart: 'metadata.user_management.delete_account.started',
  teamInvitationSuccess: 'settings.team.invitation.success',
  teamInvitationfailed: 'settings.team.invitation.failed',
  adminAddRemoveSuccess: (admin: string) => `settings.team.admin.${admin}.success`,
  adminAddRemoveFailed: (admin: string) => `settings.team.admin.${admin}.failed`,
  upgradePlanStart: 'upgrade.plan.user.start'
};
