import { theme } from '../theme';
import { focusedShadow } from '../theme/utils';
export const tokens = {
    Button: {
        transition: theme.transition.s,
        fontWeight: theme.fontWeight.medium,
        plain: {
            backgroundColor: 'transparent',
            color: 'inherit',
            border: 'none',
            hover: {
                backgroundColor: 'transparent'
            }
        },
        default: {
            primary: {
                backgroundColor: theme.colors.primary500,
                color: theme.colors.white,
                borderColor: theme.colors.primary500,
                boxShadow: theme.shadows.xs,
                hover: {
                    backgroundColor: theme.colors.primary600,
                    borderColor: theme.colors.primary600
                },
                focused: {
                    backgroundColor: theme.colors.primary600,
                    borderColor: theme.colors.primary600,
                    boxShadow: focusedShadow(theme.colors.primary100)
                },
                disabled: {
                    borderColor: theme.colors.primary200,
                    backgroundColor: theme.colors.primary200
                }
            },
            secondary: {
                borderColor: theme.colors.primary50,
                backgroundColor: theme.colors.primary50,
                color: theme.colors.primary700,
                hover: {
                    backgroundColor: theme.colors.primary100,
                    borderColor: theme.colors.primary100
                },
                focused: {
                    backgroundColor: theme.colors.primary50,
                    borderColor: theme.colors.primary50,
                    boxShadow: focusedShadow(theme.colors.primary100)
                },
                disabled: {
                    backgroundColor: theme.colors.primary25,
                    borderColor: theme.colors.primary25,
                    color: theme.colors.primary300
                }
            },
            secondaryGrey: {
                color: theme.colors.grey700,
                borderColor: theme.colors.grey300,
                backgroundColor: theme.colors.white,
                hover: {
                    backgroundColor: theme.colors.grey100,
                    color: theme.colors.grey800,
                    borderColor: theme.colors.grey300
                },
                focused: {
                    boxShadow: focusedShadow(theme.colors.grey100),
                    borderColor: theme.colors.grey300
                },
                disabled: {
                    color: theme.colors.grey300,
                    borderColor: theme.colors.grey200
                }
            },
            tertiary: {
                backgroundColor: 'transparent',
                color: theme.colors.primary700,
                boxShadow: 'none',
                hover: {
                    backgroundColor: theme.colors.primary50
                },
                focused: {
                    borderColor: theme.colors.primary100,
                    borderWidth: '4px'
                },
                disabled: {
                    color: theme.colors.grey300
                }
            },
            tertiaryGrey: {
                backgroundColor: 'transparent',
                color: theme.colors.grey500,
                boxShadow: 'none',
                hover: {
                    backgroundColor: theme.colors.grey100,
                    color: theme.colors.grey600
                },
                focused: {
                    borderColor: theme.colors.grey100,
                    borderWidth: '4px'
                },
                disabled: {
                    color: theme.colors.grey300
                }
            },
            link: {
                color: theme.colors.primary700,
                padding: 0,
                boxShadow: 'none',
                hover: {
                    color: theme.colors.primary800
                },
                focused: {
                    borderColor: theme.colors.primary100,
                    borderWidth: '4px'
                },
                disabled: {
                    color: theme.colors.grey300
                }
            },
            linkGrey: {
                color: theme.colors.grey500,
                padding: 0,
                boxShadow: 'none',
                hover: {
                    color: theme.colors.grey600
                },
                focused: {
                    borderColor: theme.colors.grey100,
                    borderWidth: '4px'
                },
                disabled: {
                    color: theme.colors.grey300
                }
            }
        },
        danger: {
            primary: {
                borderColor: theme.colors.error600,
                backgroundColor: theme.colors.error600,
                color: theme.colors.white,
                hover: {
                    backgroundColor: theme.colors.error700,
                    borderColor: theme.colors.error700
                },
                focused: {
                    backgroundColor: theme.colors.error600,
                    borderColor: theme.colors.error600,
                    boxShadow: focusedShadow(theme.colors.error100)
                },
                disabled: {
                    backgroundColor: theme.colors.error200
                }
            },
            secondary: {
                backgroundColor: theme.colors.error50,
                color: theme.colors.error700,
                hover: {
                    backgroundColor: theme.colors.error100
                },
                focused: {
                    backgroundColor: theme.colors.error50,
                    boxShadow: focusedShadow(theme.colors.error100)
                },
                disabled: {
                    backgroundColor: theme.colors.error25,
                    color: theme.colors.error300
                }
            },
            secondaryGrey: {
                color: theme.colors.error700,
                borderColor: theme.colors.error300,
                backgroundColor: 'transparent',
                hover: {
                    backgroundColor: theme.colors.error50
                },
                focused: {
                    boxShadow: focusedShadow(theme.colors.error100)
                },
                disabled: {
                    color: theme.colors.error300,
                    borderColor: theme.colors.error200
                }
            },
            tertiary: {
                backgroundColor: 'transparent',
                color: theme.colors.error700,
                boxShadow: 'none',
                hover: {
                    backgroundColor: theme.colors.error50
                },
                focused: {
                    borderColor: theme.colors.error100,
                    borderWidth: '4px'
                },
                disabled: {
                    color: theme.colors.error300
                }
            },
            link: {
                color: theme.colors.error700,
                padding: 0,
                boxShadow: 'none',
                hover: {
                    color: theme.colors.error800,
                    backgroundColor: theme.colors.error50
                },
                focused: {
                    borderColor: theme.colors.error100,
                    borderWidth: '4px'
                },
                disabled: {
                    color: theme.colors.error300
                }
            }
        },
        size: {
            xs: {
                fontSize: theme.fontSizes.x12,
                height: '20px',
                padding: `0 ${theme.spaces.x8}`,
                borderRadius: theme.radius.xs,
                iconSize: theme.sizes.x12,
                gap: theme.spaces.x6
            },
            s: {
                fontSize: theme.fontSizes.x13,
                height: theme.sizes.x28,
                padding: `0 ${theme.spaces.x14}`,
                borderRadius: theme.radius.s,
                iconSize: theme.sizes.x16,
                gap: theme.spaces.x8
            },
            m: {
                fontSize: theme.fontSizes.x14,
                height: theme.sizes.x40,
                padding: `0 ${theme.spaces.x16}`,
                borderRadius: theme.radius.m,
                iconSize: theme.sizes.x20,
                gap: theme.spaces.x8
            },
            l: {
                fontSize: theme.fontSizes.x16,
                height: theme.sizes.x48,
                padding: `0 ${theme.spaces.x20}`,
                borderRadius: theme.radius.m,
                iconSize: theme.sizes.x24,
                gap: theme.spaces.x8
            },
            xl: {
                fontSize: theme.fontSizes.x18,
                height: theme.sizes.x56,
                padding: `0 ${theme.spaces.x28}`,
                borderRadius: theme.radius.m,
                iconSize: theme.sizes.x28,
                gap: theme.spaces.x12
            }
        }
    },
    ButtonGroupItem: {
        color: theme.colors.grey700,
        secondaryGrey: {
            focused: {
                backgroundColor: theme.colors.grey50
            }
        }
    }
};
