export var Size;
(function (Size) {
    Size["s"] = "s";
    Size["m"] = "m";
    Size["l"] = "l";
})(Size || (Size = {}));
export var Placement;
(function (Placement) {
    Placement["left"] = "left";
    Placement["right"] = "right";
})(Placement || (Placement = {}));
