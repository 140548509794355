import { ObjectLiteral } from '@prophecy/interfaces/generic';

export type ControlCenterResponse = {
  data: {
    key: string;
    url?: string;
  };
};

export type UsgaeMetricsResponse = {
  data: {
    aggregateMetrics: ObjectLiteral;
    requestID: string;
  };
};
export type AdminConfigType = { configType: string; value: object };
export enum ExecutionStatus {
  INITIALIZED = 'INITIALIZED', // this is an FE status doesnt exist in BE
  NOT_RUNNING = 'NOT_RUNNING',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  FAILED = 'FAILED',
  TIMEDOUT = 'TIMEDOUT',
  CANCELLED = 'CANCELLED',
  SKIPPED = 'SKIPPED',
  PENDING = 'PENDING',
  TERMINATING = 'TERMINATING',
  TERMINATED = 'TERMINATED',
  BLOCKED = 'BLOCKED',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  UNKNOWN = 'UNKNOWN',
  UPSTREAM_SKIPPED = 'UPSTREAM_SKIPPED'
}

export type ExecutionLog = {
  message: string;
  level: string;
  trace?: string;
  errorCode?: string;
};

export enum AuthTypes {
  prophecymanaged = 'ProphecyManaged',
  ldap = 'ldap',
  saml = 'saml',
  azuread = 'azuread',
  google = 'google'
}

export enum ProviderType {
  google = 'google',
  okta = 'okta',
  azuread = 'azuread',
  others = 'others'
}

export type DatabrickUserPasswordPayload = {
  identifier: string;
  password: string;
  sendpromotionalemail: boolean;
  teamName?: string;
  firstName: string;
  lastName?: string;
  teamUID?: string;
  token?: string;
  email?: string;
  company?: string;
  teamNameUpdationAllowed?: string;
};
