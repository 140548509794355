import { ExtractArrayType } from '@prophecy/interfaces/generic';
import { ResultOf } from 'gql.tada';

import { userGitCredsQuery } from '../common/git/GitIntegration/queries';
import { getUserFabricFields } from '../data/apis/getUserFabricFields';
import { graphql } from '../graphqlTypes/graphql';

export function getUserQuery(isSqlProject: boolean, hasAirflow: boolean) {
  return graphql(`
    query UserFabricQuery($id: String!) {
      User(uid: $id) {
        _id
        attributes
        firstName
        lastName
        company
        created
        email
        gitSavedCreds {
          ${userGitCredsQuery}
        }
        aspects(aspect: [Info]) {
          AspectName
          AspectValue
        }
        fabrics {
          ${getUserFabricFields(isSqlProject, hasAirflow)}
        }
        SqlFabricCredsInfoAspect: aspects(aspect: SqlFabricCredsInfo) {
          AspectValue
          AspectName
        }
      }
    }
  `);
}
export type UserQueryResponse = ResultOf<ReturnType<typeof getUserQuery>>;
export type User = NonNullable<NonNullable<UserQueryResponse>['User']>;
export type UserFabric = ExtractArrayType<User['fabrics']>;
