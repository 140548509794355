import styled from 'styled-components';
import { Separator } from '../Separator';
import { TabsRoot, TabsList } from '../Tabs';
import { theme } from '../theme';
import { StackItem } from './../Layout/Stack';
import { tokens } from './tokens';
import { StepState } from './types';
export const StyledTabsRoot = styled(TabsRoot) `
  height: auto;
  && button {
    ${({ filled }) => (filled ? '' : `background: transparent; padding: 0; height: auto;`)}
  }
`;
export const StyledSeparatorBar = styled(Separator) `
  margin: -${theme.spaces.x20} 18px;
  height: ${theme.spaces.x32};
  border-color: ${({ state }) => (state === StepState.completed ? theme.colors.success400 : theme.colors.grey300)};
`;
export const StepIconContainer = styled.div `
  height: ${tokens.StepIcon.height};
  width: ${tokens.StepIcon.width};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StepIconBadge = styled(StepIconContainer) `
  border-radius: ${tokens.StepIcon.radius};

  ${({ filled, state, disabled }) => filled
    ? `
    background: ${disabled ? tokens.StepIcon.disabled.backgroundColor : tokens.StepIcon[state].backgroundColor};
  `
    : `
    border: 1px solid ${disabled ? tokens.StepIcon.disabled.borderColor : tokens.StepIcon[state].borderColor};
    border-radius: 50%;
    ${state === StepState.pending || disabled ? 'opacity: 0.5;' : ''}
    ${state === StepState.completed ? 'border-width: 0;' : ''}
    height: 28px;
    width: 28px;
    text-align: center;
  `}

  color: ${(props) => tokens.StepIcon[props.state].color};
`;
export const StyledTabsList = styled(TabsList) `
  flex-direction: ${(props) => (props.orientation === 'horizontal' ? 'row' : 'column')};
`;
export const NoWrapStackItem = styled(StackItem) `
  white-space: nowrap;
  ${({ state }) => (state === StepState.pending ? 'opacity: 0.5;' : '')}
`;
