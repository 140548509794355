import { OnboardingEntitiesQueryResponse } from '../common/onboarding/query';
import { UserTypes } from '../common/UserTypes';

type MaintainanceType = {
  estimatedTimeRemainingForUpgrade?: number;
  timeLeftMinutes?: number;
  timeLeftHours?: number;
  updateAvailable?: boolean;
  updateTime?: string;
  upgrade_status?: {
    status?: string;
  };
} | null;
export type TrialInfo = {
  billingStarted?: boolean;
  daysLeft?: number;
  billingDetails?: {
    price?: number;
    dimension?: number;
  };
};
// -1 => already have working fabric
// 0 => dont have any fabric
// 1 => fabric id, fabric(unconfigured)

export enum UserFabricStatusKnownStatus {
  AlreadyHaveFabric = -1,
  NoFabric = 0
}
export type UserFabricStatus = UserFabricStatusKnownStatus | number;

export type UserDetails = {
  id: string;
  email: string;
  type?: UserTypes;
  fabric?: UserFabricStatus;
  created?: number;
  trialInfo?: TrialInfo;
  freshDeskToken?: string;
  onboardingDetail?: NonNullable<OnboardingEntitiesQueryResponse['User']>['onboardingEntities'];
};

export type AppMetadataProviderType = {
  user?: UserDetails;
  cookieAccepted: boolean;
  globalConfig: { [key: string]: unknown };
  unsecuredConfig: { [key: string]: unknown };
  maintenanceUpdateInfo?: MaintainanceType;
  setMaintenanceUpdateInfo: (maintenanceUpdateInfo?: MaintainanceType) => void;
};
