import { Stack, Text, theme } from '@prophecy/ui';

const ExecutionMetricsDescription = () => {
  return (
    <Text level='sm13' weight={theme.fontWeight.normal} tone={theme.colors.grey500}>
      <Stack gap={theme.spaces.x6} padding={`${theme.spaces.x6} 0 0 0`}>
        <p>Mention fully qualified table names to store execution metrics in delta lake.</p>
        <p>
          Leaving all fields blank will trigger table and database creation by Prophecy using databricks token of the
          user. Make sure users have access to do so.
        </p>
        <p>
          If these fields are filled, make sure a workspace admin creates these tables and give proper accesses to all
          databricks groups/users in this team.
        </p>
        <p>
          <a
            target='_blank'
            href='https://docs.prophecy.io/low-code-spark/execution/execution-metrics'
            rel='noreferrer'>
            Please refer here for more information on table creation and access control management
          </a>
        </p>
      </Stack>
    </Text>
  );
};

export default ExecutionMetricsDescription;
