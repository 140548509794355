import { Stack, theme, Text } from '@prophecy/ui';
import { ProphecyError } from '@prophecy/utils/error';
import { isArray } from 'lodash-es';
import styled from 'styled-components';

const PrimaryMessageContainer = styled(Stack)`
  flex-wrap: wrap;
`;
// 20 minutes
const TIMEOUT_FOR_ELEMENT_WAIT = 20 * 60 * 1000;

export const EVENT_OCCURRED = 'EVENT_OCCURRED' as const;
export const ELEMENT_REMOVED = 'ELEMENT_REMOVED' as const;
const DELAY = 'DELAY' as const;

export function waitForElement(selector: Selector, timeout?: number | false): Promise<HTMLElement> {
  const timeoutAllowed = timeout !== false;
  const _timeout = (timeout ?? TIMEOUT_FOR_ELEMENT_WAIT) as number;
  return new Promise((resolve, reject) => {
    let start = performance.now();

    function checkForElement() {
      const element = querySelector(selector) as HTMLElement;
      if (element) {
        resolve(element);
      } else if (timeoutAllowed && performance.now() - start > _timeout) {
        reject(new ProphecyError(`Element with selector ${selector} was not found in the DOM after ${_timeout}ms`));
      } else {
        requestAnimationFrame(checkForElement);
      }
    }
    checkForElement();
  });
}

export function followElement(selector: Selector, elementToUpdate: HTMLElement, alwaysShow: boolean) {
  let animationId: number;

  function update() {
    const elementToFollow = querySelector(selector);
    if (elementToFollow) {
      const rect = elementToFollow.getBoundingClientRect();
      const { top, left } = elementToUpdate.getBoundingClientRect();
      if (!alwaysShow) {
        elementToUpdate.style.display = 'block';
      }
      if (!(top === rect.top && left === rect.left)) {
        elementToUpdate.style.top = `${rect.top}px`;
        elementToUpdate.style.left = `${rect.left}px`;
      }
    } else if (!alwaysShow) {
      elementToUpdate.style.display = 'none';
    }

    animationId = requestAnimationFrame(update);
  }

  animationId = requestAnimationFrame(update);

  return function cancelUpdate() {
    cancelAnimationFrame(animationId);
  };
}

export function renderMessage(title: React.ReactNode, subtitle?: React.ReactNode) {
  return title && subtitle ? (
    <Stack gap={theme.spaces.x8}>
      <Text level='sm' weight={theme.fontWeight.medium} color={theme.colors.grey700}>
        <PrimaryMessageContainer direction='horizontal' gap={theme.spaces.x4} alignY='center'>
          {title}
        </PrimaryMessageContainer>
      </Text>
      <Text level='sm' color={theme.colors.grey700}>
        <PrimaryMessageContainer direction='horizontal' gap={theme.spaces.x4} alignY='center'>
          {subtitle}
        </PrimaryMessageContainer>
      </Text>
    </Stack>
  ) : (
    <Text level='sm' weight={theme.fontWeight.medium} color={theme.colors.grey700}>
      <PrimaryMessageContainer direction='horizontal' gap={theme.spaces.x4} alignY='center'>
        {title}
      </PrimaryMessageContainer>
    </Text>
  );
}

export type Selector = string | [string, string];

export function querySelector(selector: Selector) {
  const [cssSelector, text] = isArray(selector) ? selector : [selector];
  if (text) {
    const elements = document.querySelectorAll(cssSelector);
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      if (element.textContent === text) {
        return element;
      }
    }
    return null;
  } else {
    return document.querySelector(cssSelector);
  }
}

export function syncElement(
  selector: Selector,
  callBack: (element: Element) => void,
  cleanUp?: (element: Element | null) => void
) {
  let animationId: number;

  function update() {
    const elementToFollow = querySelector(selector);
    if (elementToFollow) {
      callBack(elementToFollow);
    }

    animationId = requestAnimationFrame(update);
  }

  animationId = requestAnimationFrame(update);

  return function cancelUpdate() {
    cancelAnimationFrame(animationId);
    const elementToFollow = querySelector(selector);
    cleanUp?.(elementToFollow);
  };
}

type ELEMENT_REMOVED_TYPE = typeof ELEMENT_REMOVED;

export async function waitForElementToRemoved(element: Element): Promise<ELEMENT_REMOVED_TYPE> {
  return new Promise((resolve, reject) => {
    function listener(mutations: MutationRecord[], observer: MutationObserver) {
      try {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList') {
            mutation.removedNodes.forEach((removedNode) => {
              if (removedNode === element) {
                resolve(ELEMENT_REMOVED);
                observer.disconnect();
              }
            });
          }
        });
      } catch (error) {
        reject(error);
        observer.disconnect();
      }
    }
    const observer = new MutationObserver(listener);
    observer.observe(element.parentNode as Element, { childList: true });
    return observer;
  });
}
export async function waitForAttributeChange(element: Element, attributeName: string, callback: () => boolean) {
  return new Promise((resolve, reject) => {
    function listener(mutations: MutationRecord[], observer: MutationObserver) {
      try {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === attributeName) {
            const result = callback();
            if (result) {
              resolve(true);
              observer.disconnect();
            }
          }
        });
      } catch (error) {
        reject(error);
        observer.disconnect();
      }
    }
    const observer = new MutationObserver(listener);
    observer.observe(element, { attributes: true });
    return observer;
  });
}
type EVENT_OCCURRED_TYPE = typeof EVENT_OCCURRED;

export async function waitForEvent(
  selectorOrElements: string | Element[],
  event: string,
  delayTime: number = 500
): Promise<EVENT_OCCURRED_TYPE> {
  let elements: Element[];
  if (typeof selectorOrElements === 'string') {
    elements = Array.from(document.querySelectorAll(selectorOrElements));
  } else {
    elements = selectorOrElements;
  }

  return new Promise((resolve, reject) => {
    async function listener() {
      // wait for redux to reflect changes
      if (delayTime) {
        await delay(delayTime);
      }
      resolve(EVENT_OCCURRED);
    }
    elements.forEach((element) => {
      element.addEventListener(event, listener, { once: true });
    });
  });
}

type DELAY_TYPE = typeof DELAY;

export async function delay(delay: number = 250): Promise<DELAY_TYPE> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(DELAY);
    }, delay);
  });
}
