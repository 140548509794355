import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Stack } from '../Layout';
import { getColorCode } from '../theme/utils';
import { tokens } from './tokens';
const StyledBar = styled(Stack) `
  background-color: ${({ tone }) => getColorCode(tone, 50)};
  border: 1px solid ${({ tone }) => getColorCode(tone, 200)};
  color: ${({ tone }) => getColorCode(tone, 700)};
  border-radius: ${tokens.Bar.borderRadius};
  height: ${({ orientation }) => (orientation === 'horizontal' ? tokens.Bar.height : '100%')};
  width: ${({ orientation }) => (orientation === 'vertical' ? tokens.Bar.height : '100%')};
  padding: ${({ orientation }) => `${orientation === 'vertical' ? tokens.Bar.padding : '0'} ${orientation === 'horizontal' ? tokens.Bar.padding : '0'}`};

  ${({ orientation }) => orientation === 'vertical' &&
    `
      writing-mode: tb;
      text-orientation: mixed;
    `}
`;
export function Bar(_a) {
    var { orientation = 'horizontal' } = _a, restProps = __rest(_a, ["orientation"]);
    return _jsx(StyledBar, Object.assign({ alignY: 'center', align: 'start', orientation: orientation }, restProps, { direction: 'horizontal' }));
}
