import { ResultOf, VariablesOf } from 'gql.tada';

import { graphql } from '../../../graphqlTypes/graphql';

export enum State {
  started = 'started',
  inprogress = 'inprogress',
  completed = 'completed'
}
type StateType = `${State}`;
export type AsyncEventProgress = (payload: Progress) => void;
export type NonAsyncEventProgress<T = unknown> = (payload: Omit<NonAsyncEvent<T>, 'subscriptionId'>) => void;

export type AsyncEventRequest = {
  id: string;
  onProgress: AsyncEventProgress;
};
export type NonAsyncEventRequest<T = unknown> = {
  id: string;
  onProgress: NonAsyncEventProgress<T>;
};
export type Progress = {
  message: string;
  completedPercentage: number;
};
type AsyncEvent = {
  id: string;
  state: StateType;
  graphQlResponse?: { data: unknown; errors?: { message: string }[] };
  progress?: Progress;
};
export type NonAsyncEvent<T = unknown> = { subscriptionId: string } & T;
export type Message = {
  data?: {
    response: 'ping';
  };
  event?: {
    data: {
      asyncEvent: AsyncEvent;
      [key: string]: AsyncEvent | NonAsyncEvent | string;
    };
  };
};
export const AsyncEventQuery = `
subscription NewEvents($id: String!) {
  asyncEvent(uid: $id) {
    id
    state
    graphQlResponse
    progress {
      message
      completedPercentage
    }
  }
}`;
const CodeGenFields = `
pipelines {
  _id
  name
  codeGenStatus
  diagnostics {
    severity
    message
  }
}
jobs {
  _id
  name
  codeGenStatus
  diagnostics {
    severity
    message
  }
}
`;
const projectCodeGenStatusQuery = graphql(`
  query ProjectCodeGenStatusQuery($projectId: String!) {
    Project(uid: $projectId) {
      ${CodeGenFields}
    }
  }
`);
export type ProjectPipelinesStatus = ResultOf<typeof projectCodeGenStatusQuery>;
export type ProjectUpdateEventType = NonNullable<ProjectPipelinesStatus['Project']>;
export type ProjectPipelinesStatusVariables = VariablesOf<typeof projectCodeGenStatusQuery>;
export const projectUpdateEventQuery = `
subscription NewEvents($projectId: String!, $subscriptionId:String! ) {
  projectUpdateEvent(uid: $projectId ,subscriptionId:$subscriptionId) {
    subscriptionId
    project {
        ${CodeGenFields}
    }
  }
}
`;
