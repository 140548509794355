import { Button } from '@prophecy/ui/Button';
import { CheckIcon } from '@prophecy/ui/Icons';
import { Stack } from '@prophecy/ui/Layout';
import { theme } from '@prophecy/ui/theme';
import { Text } from '@prophecy/ui/Typography';
import { DeploymentAnalytics } from 'frontend/core/src/common/user-analytics/deployment';
import { useEffect, useState } from 'react';

import { useMixPanel } from '../../../context/mixpanel/context';
import { Root, StyledAnchor, StyledList, StyledCard } from '../styles';
import { DeploymentStepBaseProp } from '../types';
import { DeploymentStepFooter } from './DeploymentStepFooter';
import DeploymentStepHeader from './DeploymentStepHeader';

type onNeedHelpProp = {
  onNeedHelp: (needsHelp: boolean) => void;
};

export default function InfraSetup({ onNeedHelp, ...rest }: DeploymentStepBaseProp & onNeedHelpProp) {
  const [isSubstep, setIsSubStep] = useState(false);

  useEffect(() => {
    onNeedHelp(isSubstep);
  }, [isSubstep, onNeedHelp]);

  return isSubstep ? (
    <InfraSetup2 {...rest} prevStep={() => setIsSubStep(false)} />
  ) : (
    <InfraSetup1 {...rest} onNeedHelp={() => setIsSubStep(true)} />
  );
}

function InfraSetup1({ nextStep, onNeedHelp }: DeploymentStepBaseProp & onNeedHelpProp) {
  const { track } = useMixPanel();

  return (
    <StyledCard>
      <Root align='space-between'>
        <Stack gap={theme.spaces.x40}>
          <DeploymentStepHeader title='1. Infrastructure Setup' />
          <Stack gap={theme.spaces.x12}>
            <Text level='md' weight={theme.fontWeight.medium}>
              Do you have an existing Kubernetes cluster?
            </Text>
            <Text level='sm'>
              Before proceeding with the installation of Prophecy on your infrastructure, it is crucial to have a
              deployed Kubernetes cluster in place. If you do not already have a Kubernetes cluster set up, simply click
              on the option "No. Help me to set up," and we will guide you through the process of setting up the
              required infrastructure.
            </Text>
          </Stack>
        </Stack>
        <Stack direction='horizontal' align='end' gap={theme.spaces.x22}>
          <Button
            onClick={() => {
              track(
                DeploymentAnalytics.deployment_step_infra_setup1.events['deployment.step.helpMeSetupInfra'],
                {},
                DeploymentAnalytics.deployment_step_infra_setup1.event_category
              );
              onNeedHelp(true);
            }}
            variant='secondaryGrey'>
            No. Help me to setup
          </Button>
          <Button
            onClick={() => {
              track(
                DeploymentAnalytics.events['deployment.nextStep'],
                {},
                DeploymentAnalytics.deployment_step_infra_setup1.event_category
              );
              nextStep();
            }}
            iconPlacement='left'
            icon={<CheckIcon type='default' />}>
            Yes. My environment is ready
          </Button>
        </Stack>
      </Root>
    </StyledCard>
  );
}

function InfraSetup2({ nextStep, prevStep }: DeploymentStepBaseProp) {
  return (
    <StyledCard>
      <Root align='space-between'>
        <Stack gap={theme.spaces.x16}>
          <Text level='xl' weight={theme.fontWeight.medium}>
            1. Infrastructure Setup
          </Text>
          <Stack gap={theme.spaces.x40}>
            <Text level='sm'>
              It appears that you currently don't have a Kubernetes cluster in place. To enable the deployment of
              Prophecy, please follow the steps below to set up your infrastructure and create a Kubernetes cluster. It
              is important to note that the individual deploying the provided templates should possess full
              administrator access to your environment for a successful setup and configuration.
            </Text>
            <Stack gap={theme.spaces.x20}>
              <Text level='sm'>
                Visit Prophecy{' '}
                <StyledAnchor href='https://github.com/SimpleDataLabsInc/prophecy-on-public-cloud/tree/main'>
                  public repository
                </StyledAnchor>{' '}
                to start setting up your infrastructure.
              </Text>
              <StyledList>
                <li>
                  <StyledAnchor href='https://github.com/SimpleDataLabsInc/prophecy-on-public-cloud/tree/main/aws'>
                    AWS
                  </StyledAnchor>
                </li>
                <li>
                  <StyledAnchor href='https://github.com/SimpleDataLabsInc/prophecy-on-public-cloud/tree/main/azure'>
                    Azure
                  </StyledAnchor>
                </li>
                <li>
                  <StyledAnchor href='https://github.com/SimpleDataLabsInc/prophecy-on-public-cloud/tree/main/crds'>
                    Custom Resource Definitions
                  </StyledAnchor>
                </li>
                <li>
                  <StyledAnchor href='https://github.com/SimpleDataLabsInc/prophecy-on-public-cloud/tree/main/identity/Single%20Sign%20On'>
                    Identity and SSO
                  </StyledAnchor>
                </li>
              </StyledList>
            </Stack>
          </Stack>
        </Stack>
        <DeploymentStepFooter
          nextStep={nextStep}
          prevStep={prevStep}
          eventCategory={DeploymentAnalytics.deployment_step_infra_setup2.event_category}
        />
      </Root>
    </StyledCard>
  );
}
