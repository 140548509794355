import { __rest } from "tslib";
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Button } from '../Button';
import { InfoCircleIcon } from '../Icons';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { Tooltip } from '../Tooltip';
import { Text } from '../Typography/Text';
const StyledButton = styled(Button) `
  line-height: 1;
`;
const StyledInfoCircleIcon = styled(InfoCircleIcon) `
  color: ${theme.colors.grey500} !important;
  font-size: ${theme.fontSizes.x14} !important;
`;
export const Label = (_a) => {
    var { children, required, helpText } = _a, props = __rest(_a, ["children", "required", "helpText"]);
    return (_jsxs(Stack, { direction: 'horizontal', gap: theme.spaces.x6, alignY: 'center', children: [_jsxs(Text, Object.assign({ level: 'xs' }, props, { children: [required ? '* ' : '', children] })), helpText && (_jsx(Tooltip, { title: helpText, placement: 'right', children: _jsx(StyledButton, { variant: 'plain', children: _jsx(StyledInfoCircleIcon, { type: 'default', fontSize: theme.fontSizes.x14 }) }) }))] }));
};
