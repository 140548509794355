export const EDITOR_TAG_CLASS = 'ui-editor-tags';
export const OPENING_DELIMITER_CLASS = 'ui-editor-opening-tag-delimiter';
export const CLOSING_DELIMITER_CLASS = 'ui-editor-closing-tag-delimiter';
export const TAG_CONTENT_CLASS = 'ui-editor-tag-content';
export const TAG_TONE_CLASS_PREFIX = 'ui-editor-tag-tone';
export const IMG_URL_CLASS_PREFIX = 'ui-editor-img-url';
export const TAG_DROPDOWN_WIDGET = 'tag-dropdown-widget';
export const MONACO_EDITOR_CLASS = 'monaco-editor';
export const MONACO_TOOLTIP_CLASS = 'monaco-hover';
export const MONACO_VIEW_LINES_CLASS = 'view-lines';
export const MONACO_LINE_OVERLAY_CLASS = 'margin-view-overlays';
export const MONACO_DECORATION_TOOLTIP_WIDGET = 'editor.contrib.resizableContentHoverWidget';
export const MONACO_DECORATION_TOOLTIP_WIDGET_SELECTOR = `[widgetid="${MONACO_DECORATION_TOOLTIP_WIDGET}"]`;
export const LINE_HEIGHT = 20;
export const defaultSliderSize = 8;
