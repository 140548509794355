import { tokens as iconTokens } from '../Icons/tokens';
import { theme } from '../theme';
export const TREE_CLASS = 'ui-tree';
export const TREE_DRAGGABLE_ICON_CLASS = 'ui-tree-draggable-icon';
export const TREE_LIST_HOLDER_CLASS = 'ui-tree-list-holder';
export const TREE_NODE_CLASS = 'ui-tree-treenode';
export const TREE_NODE_DISABLED_CLASS = 'ui-tree-treenode-disabled';
export const TREE_INDENT_UNIT_CLASS = 'ui-tree-indent-unit';
export const TREE_NODE_SELECTED_CLASS = 'ui-tree-treenode-selected';
export const TREE_NODE_CONTENT_WRAPPER_CLASS = 'ui-tree-node-content-wrapper';
export const TREE_NODE_CONTENT_WRAPPER_OPEN_CLASS = 'ui-tree-node-content-wrapper-open';
export const TREE_TITLE_CLASS = 'ui-tree-title';
export const TREE_CHECKBOX_CLASS = 'ui-tree-checkbox';
export const TREE_CHECKBOX_CHECKED_CLASS = 'ui-tree-checkbox-checked';
export const TREE_CHECKBOX_DISABLED_CLASS = 'ui-tree-checkbox-disabled';
export const TREE_CHECKBOX_INDETERMINATE_CLASS = 'ui-tree-checkbox-indeterminate';
export const TREE_SWITCHER_CLASS = 'ui-tree-switcher';
export const TREE_SWITCHER_CLOSE_CLASS = 'ui-tree-switcher_close';
export const TREE_SWITCHER_NOOP_CLASS = 'ui-tree-switcher-noop';
export const TREE_ICON_ELEMENT_CLASS = 'ui-tree-iconEle';
export const TREE_ICON_CUSTOMIZE_CLASS = 'ui-tree-icon__customize';
export const TREE_LIST_SCROLLBAR_SHOW_CLASS = 'ui-tree-list-scrollbar-show';
export const TREE_SCROLLBAR_CLASS = 'ui-tree-list-scrollbar';
export const TREE_LIST_SCROLLBAR_THUMB_CLASS = 'ui-tree-list-scrollbar-thumb';
export const tokens = {
    Tree: {
        color: theme.colors.grey900,
        fontSize: theme.fontSizes.x14,
        iconSize: iconTokens.Icon.xs.size,
        scrollBarWidth: theme.sizes.x8,
        scrollBarRadius: theme.radius.m,
        scrollBarBackgroundColor: theme.colors.grey200,
        scrollBarBackgroundHoverColor: theme.colors.grey300
    },
    ExpandIcon: {
        color: theme.colors.grey500
    },
    Node: {
        gap: theme.spaces.x8,
        height: theme.sizes.x28,
        borderRadius: theme.radius.xs,
        backgroundColor: theme.colors.primary50,
        Info: {
            color: theme.colors.grey500
        },
        hover: {
            backgroundColor: theme.colors.grey100
        }
    }
};
