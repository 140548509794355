export const Onboarding = {
  onboarding_home: {
    event_category: 'onboarding.home',
    events: {
      onboarding_home_flow_type: 'onboarding.home.flowType',
      onboarding_home_skip: 'onboarding.home.skip'
    }
  },
  onboarding_pipeline: {
    event_category: 'onboarding.pipeline',
    events: {
      onboarding_pipeline_env_info: 'onboarding.pipeline.envInfo',
      onboarding_pipeline_run: 'onboarding.pipeline.run'
    }
  },
  onboarding_create_project: {
    event_category: 'onboarding.create.project',
    events: {
      onboarding_project_create: 'onboarding.project.create'
    }
  },
  onboarding_create_fabric: {
    event_category: 'onboarding.create.fabric',
    events: {
      onboarding_fabric_create: 'onboarding.fabric.create'
    }
  },
  onboarding_update_fabric: {
    event_category: 'onboarding.update.fabric',
    events: {
      onboarding_fabric_update: 'onboarding.fabric.update'
    }
  },
  onboarding_initial_pipeline: {
    event_category: 'onboarding.initial.pipeline',
    events: {
      onboarding_ds_create: 'onboarding.ds.create',
      onboarding_pipeline_env_info: 'onboarding.pipeline.envInfo'
    }
  },
  onboarding_copilot: {
    event_category: 'onboarding.copilot',
    events: {
      onboarding_copilot_try: 'onboarding.copilot.try'
    }
  }
};
export type EventType = [string, Record<string, string | boolean>, string];
