import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { CaretDownIcon } from '../Icons';
import { StyledIconWrap, StyledDownIconWrap, StyledHandle, StyledHandleContent } from './styled';
import { tokens } from './tokens';
import { SelectSize, SelectVariants } from './types';
export const SelectHandle = forwardRef((_a, ref) => {
    var { size = SelectSize.m, icon, children, showDownIcon = true, disabled, variant = SelectVariants.primary } = _a, restProps = __rest(_a, ["size", "icon", "children", "showDownIcon", "disabled", "variant"]);
    return (_jsxs(StyledHandle, Object.assign({}, restProps, { ref: ref, direction: 'horizontal', alignY: 'center', gap: tokens.Trigger.sizes[size].gap, size: size, disabled: disabled, variant: variant, children: [icon && _jsx(StyledIconWrap, { size: size, children: icon }), _jsx(StyledHandleContent, { grow: '1', children: children }), showDownIcon && (_jsx(StyledDownIconWrap, { size: size, variant: variant, children: _jsx(CaretDownIcon, { type: 'extended' }) }))] })));
});
