import { theme } from '../theme';
import { tokens as TypographyTokens } from '../Typography/tokens';
export const SELECTED_STATE_ATTRIBUTE = 'data-selected';
export const DISABLED_STATE_ATTRIBUTE = 'data-disabled';
export const tokens = {
    Content: {
        background: theme.colors.white,
        shadow: theme.shadows.lg,
        borderRadius: theme.radius.l,
        zIndex: theme.zLayer.xxl,
        borderColor: theme.colors.grey100
    },
    Label: {
        fontFamily: TypographyTokens.Text.fontFamily,
        fontSize: TypographyTokens.Text.level.xs.fontSize,
        fontWeight: TypographyTokens.Text.fontWeight,
        lineHeight: TypographyTokens.Text.level.xs.lineHeight,
        letterSpacing: TypographyTokens.Text.letterSpacing,
        padding: `${theme.spaces.x6} ${theme.spaces.x8}`,
        color: theme.colors.grey600
    },
    size: {
        lg: {
            padding: `${theme.spaces.x10} ${theme.spaces.x8}`
        },
        md: {
            padding: `${theme.spaces.x6} ${theme.spaces.x8}`
        }
    },
    Separator: {
        border: `${theme.radius.xxxs} solid ${theme.colors.grey100}`,
        borderRadius: theme.radius.xs,
        margin: theme.spaces.x8
    },
    Item: {
        fontFamily: TypographyTokens.Text.fontFamily,
        fontSize: TypographyTokens.Text.level.sm.fontSize,
        fontWeight: TypographyTokens.Text.fontWeight,
        lineHeight: TypographyTokens.Text.level.sm.lineHeight,
        letterSpacing: TypographyTokens.Text.letterSpacing,
        borderRadius: theme.radius.xs,
        color: theme.colors.grey900,
        iconColor: theme.colors.grey700,
        borderStyle: 'solid',
        state: {
            hovered: {
                background: theme.colors.grey50
            },
            selected: {
                background: theme.colors.grey100
            },
            disabled: {
                color: theme.colors.grey300,
                iconColor: theme.colors.grey200
            },
            dragging: {
                background: theme.colors.grey100,
                borderColor: theme.colors.grey300,
                boxShadow: theme.shadows.md
            },
            draggingStarted: {
                borderColor: theme.colors.grey300,
                color: theme.colors.grey500
            }
        }
    },
    SubMenu: {
        Arrow: {
            gap: theme.spaces.x6
        }
    }
};
