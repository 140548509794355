import { theme } from '../theme/index';
export const tokens = {
    Root: {},
    Track: {
        backgroundColor: theme.colors.primary100
    },
    Range: {
        backgroundColor: theme.colors.primary500
    },
    Thumb: {
        backgroundColor: theme.colors.primary500
    }
};
