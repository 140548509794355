export const ide = {
  inActivityDisconnect: 'ide.pipeline.inactivity.disconnect',
  errorDisconnect: 'ide.pipeline.error.disconnect',
  netWorkChangeDisconnect: 'ide.pipeline.networkChange.disconnect',
  inferFromCluster: 'infer.from.cluster',
  genericUnitTestSave: 'generate.unit.test',
  inferFromSchema: 'infer.the.schema',
  showInterimPreview: 'show.the.preview',
  changeConfiguration: 'change.configurations',
  modifyComponent: 'modified.component',
  openPipeline: 'open.workflow',
  settingsSave: 'options.save',
  switchFabric: 'switch.fabric',
  clusterConnectionStart: 'start.spinning.up.cluster',
  longLivingClusterConnection: 'connect.long.living.cluster',
  empheralClusterConnection: 'connect.existing.cluster',
  editUDF: 'edit.udf.or.udafs',
  changeSubgraphConfiguration: 'change.subgraph.configurations',
  dependencyListOpen: 'dependencies.list.open',
  dependencySave: 'dependency.save',
  pipelineCounterKey: 'pipeline.run.counter',
  processRun: 'interactive.componentLevel.run',
  fullRun: 'interactive.full.run',
  jobScheduleSuccess: 'ide.job.create.success',
  pastedComponent: 'ide.pipeline.paste.success',
  copiedComponentsWithCut: 'ide.pipeline.cut.copied.success',
  pipelineSettingsMount: 'ide.pipeline.settings.open',
  addNewComponent: 'ide.pipeline.add.new.node',
  copiedComponent: 'ide.pipeline.components.copy',
  addReusableSubgraph: 'ide.reusable.subgraph.add',
  createSchedule: 'Workflow editor create schedule'
};

export const metadata = {
  createPipelineLanding: 'metadata.create.pipeline',
  pipelineCreationFailed: 'pipeline.entity.creation.failed',
  pipelineCreationSuccess: 'pipeline.entity.creation.successful',
  pipelineDescriptionUpdate: 'aspects.updated.for.pipeline',
  pipelineDescriptionUpdateFailed: 'aspects.update.failed.for.pipeline'
};
