import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { get } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { getParentGraphPath } from '../../Parser/bindings';
import { actionTypes } from '../../redux/action-types';
import { WorkflowProcessesPath } from '../../redux/constants';
import { WORKFLOW_ACTION_TYPES, WorkflowState } from '../../redux/types';
import { InterimKeyType } from './types';

const RunIdDelimiter = '____runId____';
const PortIdDelimiter = '____portId____';

export function toInterimKey({ processId, portId, runId }: InterimKeyType) {
  if (runId) {
    return `${processId}${PortIdDelimiter}${portId}${RunIdDelimiter}${runId}`;
  }
  return `${processId}${PortIdDelimiter}${portId}`;
}
export function toInterimKeyObject(interimKey: string): InterimKeyType {
  const [processIdAndPortId, runId] = interimKey.split(RunIdDelimiter);
  const [processId, portId] = processIdAndPortId.split(PortIdDelimiter);

  return { processId, portId, runId };
}
export function useInterimRunId() {
  const dispatch: Dispatch<WORKFLOW_ACTION_TYPES> = useDispatch();
  const graphPath = useSelector<WorkflowState, string>((state) => state.graphPath);
  const interimRunIds = useSelector<WorkflowState, WorkflowState['interimRunIds']>((state) => state.interimRunIds);
  const _path = graphPath.replace(`${WorkflowProcessesPath}.`, '');
  const parentInterimRunId = closetInterimRunId(interimRunIds, _path);
  const interimRunId = get(interimRunIds, _path)?.interimRunId;
  const changeInterimRunIds = usePersistentCallback((interimRunId: string) => {
    dispatch({
      type: actionTypes.updateInterimRunId,
      payload: { path: _path, interimRunId }
    });
  });
  const setInterimRunIds = usePersistentCallback((interimRunIds: WorkflowState['interimRunIds']) => {
    dispatch({
      type: actionTypes.initialInterimRunIds,
      payload: interimRunIds
    });
  });
  return {
    interimRunId: interimRunId || '',
    parentInterimRunId: parentInterimRunId || '',
    interimRunIds,
    changeInterimRunIds,
    setInterimRunIds
  };
}
function closetInterimRunId(interimRunIds: WorkflowState['interimRunIds'], path: string) {
  const parentPath = getParentGraphPath(path);
  if (!parentPath) {
    return;
  }
  const obj = get(interimRunIds, parentPath);
  if (obj && obj.interimRunId) {
    return obj.interimRunId;
  } else {
    return closetInterimRunId(interimRunIds, getParentGraphPath(path));
  }
}
