import { graphql } from 'frontend/core/src/graphqlTypes/graphql';
import { ResultOf } from 'gql.tada';

export const addUserGitCredsMutationQuery = graphql(`
  mutation addUserGitCreds(
    $externalGitHost: String
    $externalGitProvider: ExternalGitProviderType
    $externalRepoEmail: String!
    $externalRepoUsername: String!
    $externalRepoAccessToken: String!
    $externalGitAlias: String!
  ) {
    addUserGitCreds(
      externalGitHost: $externalGitHost
      externalGitProvider: $externalGitProvider
      externalRepoEmail: $externalRepoEmail
      externalRepoUsername: $externalRepoUsername
      externalRepoAccessToken: $externalRepoAccessToken
      externalGitAlias: $externalGitAlias
    ) {
      _id
    }
  }
`);

export const updateUserGitCredsMutationQuery = graphql(`
  mutation updateUserGitCreds(
    $uid: String!
    $externalGitHost: String
    $externalGitProvider: ExternalGitProviderType
    $externalRepoEmail: String
    $externalRepoUsername: String
    $externalRepoAccessToken: String
    $externalGitAlias: String
  ) {
    updateUserGitCreds(
      uid: $uid
      externalGitHost: $externalGitHost
      externalGitProvider: $externalGitProvider
      externalRepoEmail: $externalRepoEmail
      externalRepoUsername: $externalRepoUsername
      externalRepoAccessToken: $externalRepoAccessToken
      externalGitAlias: $externalGitAlias
    ) {
      _id
    }
  }
`);

export const deleteUserGitCredsMutationQuery = graphql(`
  mutation deleteUserGitCreds($uid: String!) {
    deleteUserGitCreds(uid: $uid)
  }
`);

export const checkGitConnectionMutationQuery = graphql(`
  query checkGitConnection(
    $externalUriArg: String
    $externalRepoEmail: String
    $externalRepoUsername: String
    $externalRepoAccessToken: String
    $optionalProjectUID: String
    $pushAccessCheck: Boolean
    $userGitCredsUID: Long
  ) {
    checkGitConnection(
      externalUriArg: $externalUriArg
      externalRepoEmail: $externalRepoEmail
      externalRepoUsername: $externalRepoUsername
      externalRepoAccessToken: $externalRepoAccessToken
      optionalProjectUID: $optionalProjectUID
      pushAccessCheck: $pushAccessCheck
      userGitCredsUID: $userGitCredsUID
    )
  }
`);

export const linkedProjectsQuery = graphql(`
  query linkedProjects($uid: String!) {
    linkedProjects(uid: $uid) {
      _id
      name
    }
  }
`);
export type LinkedProjectsResponse = ResultOf<typeof linkedProjectsQuery>;

export const userGitCredsQuery = `
_id
externalGitHost
externalRepoUsername
externalRepoEmail
externalGitAlias
externalGitProvider
saved
lastModified
`;
