export var SelectVariants;
(function (SelectVariants) {
    SelectVariants["primary"] = "primary";
    SelectVariants["error"] = "error";
})(SelectVariants || (SelectVariants = {}));
export var SelectSize;
(function (SelectSize) {
    SelectSize["s"] = "s";
    SelectSize["m"] = "m";
    SelectSize["l"] = "l";
})(SelectSize || (SelectSize = {}));
