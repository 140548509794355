import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { isNil } from 'lodash-es';
export function isRenderableNode(node) {
    return !(isNil(node) || node === false);
}
export function newLineToBr(content) {
    if (typeof content !== 'string')
        return content;
    const lines = content.split('\n');
    return (_jsx(_Fragment, { children: lines.map((line, i) => (_jsxs(_Fragment, { children: [i !== 0 && _jsx("br", {}), line] }))) }));
}
