import styled from 'styled-components';
import { Stack } from '../Layout';
import { tokens } from './tokens';
export const StyledAlert = styled(Stack) `
  font-family: ${tokens.Alert.fontFamily};
  gap: ${tokens.Alert.gap};
  padding: ${tokens.Alert.padding};
  border-radius: ${tokens.Alert.borderRadius};
  background-color: ${(props) => tokens.Alert[props.variant].backgroundColor};
  border: 1px solid ${(props) => tokens.Alert[props.variant].borderColor};
  color: ${(props) => tokens.Alert[props.variant].color};
  pointer-events: all;

  ${(props) => props.banner &&
    `
      border-radius: 0;
      border-left: none;
      border-right: none;
      width: 100%;
    `}
`;
