import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { history } from '../history';
const globalContext = {};
export const setGlobalContext = (key, value) => {
    globalContext[key] = value;
};
export const GlobalContext = createContext(globalContext);
export const useGlobalContext = () => useContext(GlobalContext);
export const GlobalContextProvider = ({ children }) => (_jsx(GlobalContext.Provider, { value: globalContext, children: children }));
const defaultOptions = {
    container: 'body',
    unmountOnNavigation: false
};
export default function utilify(renderComponent) {
    return (props) => {
        const mountHolderElement = document.createElement('div');
        let mountHolderRoot;
        let isAppended = false;
        let unListener;
        function unMountComponent() {
            if (!isAppended)
                return;
            mountHolderRoot === null || mountHolderRoot === void 0 ? void 0 : mountHolderRoot.unmount();
            mountHolderElement.remove();
            isAppended = false;
            unListener === null || unListener === void 0 ? void 0 : unListener();
        }
        function render(element, options = defaultOptions) {
            const { container, unmountOnNavigation } = Object.assign(Object.assign({}, defaultOptions), options);
            const $container = document.querySelector(container);
            if (!isAppended) {
                isAppended = true;
                $container === null || $container === void 0 ? void 0 : $container.appendChild(mountHolderElement);
                mountHolderRoot = ReactDOM.createRoot(mountHolderElement);
                // if unmountOnNavigation is set, attach a listener on history object to observe location
                if (unmountOnNavigation) {
                    const { pathname } = document.location;
                    unListener = history.listen(({ location }) => {
                        if (pathname !== location.pathname) {
                            unMountComponent();
                        }
                    });
                }
            }
            mountHolderRoot.render(_jsx(GlobalContextProvider, { children: element }));
        }
        // render component can return promises or anything the component wants to return
        return renderComponent({ props, render, unmount: unMountComponent });
    };
}
