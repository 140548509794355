import { GemType } from '../Parser/types';
import {
  BaseProcess,
  BaseProcessMetadata,
  Connection as GraphConnection,
  GenericGraph,
  GenericGraphProcessType
} from './types';

export function getGemTypeByProcess<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, GraphConnection>
>(process: GenericGraphProcessType<G>): GemType {
  return { component: process.component, componentInfo: process.componentInfo };
}
