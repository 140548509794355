export var TabTone;
(function (TabTone) {
    TabTone["primary"] = "primary";
    TabTone["grey"] = "grey";
    TabTone["white"] = "white";
})(TabTone || (TabTone = {}));
export var TabSize;
(function (TabSize) {
    TabSize["s"] = "s";
    TabSize["m"] = "m";
})(TabSize || (TabSize = {}));
export var TriggerType;
(function (TriggerType) {
    TriggerType["trigger"] = "trigger";
    TriggerType["group"] = "group";
})(TriggerType || (TriggerType = {}));
