import { jsx as _jsx } from "react/jsx-runtime";
import { initialActiveKeys } from '@prophecy/utils/keyCodes';
import React, { useMemo } from 'react';
import { ReactFlowProvider } from 'reactflow';
export const GraphNodeContext = React.createContext({
    icons: {},
    activeControlKeys: initialActiveKeys
});
export const GraphContext = React.createContext({
    viewPortCache: new Map()
});
export function GraphProvider({ children }) {
    const viewPortCache = useMemo(() => new Map(), []);
    return (_jsx(ReactFlowProvider, { children: _jsx(GraphContext.Provider, { value: { viewPortCache: viewPortCache }, children: children }) }));
}
export function useGraphContext() {
    return React.useContext(GraphContext);
}
