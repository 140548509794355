import { keyframes } from 'styled-components';
export const slideRight = (size) => keyframes `
  0% { 
    transform: translateX(0)
  }
  100% {
    transform: translateX(${size});
  };
`;
export const slideLeft = (size) => keyframes `
  0% { 
    transform: translateX(${size})
  }
  100% {
    transform: translateX(0);
  };
`;
export const fadeOut = (opacity = 1) => keyframes `
  0% { 
    opacity: ${opacity};
  }
  100% {
    opacity: 0;
  };
`;
export const fadeIn = (opacity = 1) => keyframes `
  0% { 
    opacity: 0;
  }
  100% {
    opacity: ${opacity};
  };
`;
export const rotate = keyframes `
  from {
    transform: rotate(0deg);
  }
  to { 
    transform: rotate(360deg);
  }
`;
