export var Size;
(function (Size) {
    Size["xs"] = "xs";
    Size["s"] = "s";
    Size["m"] = "m";
    Size["l"] = "l";
})(Size || (Size = {}));
export var BadgeShade;
(function (BadgeShade) {
    BadgeShade["light"] = "light";
    BadgeShade["medium"] = "medium";
    BadgeShade["dark"] = "dark";
})(BadgeShade || (BadgeShade = {}));
