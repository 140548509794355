import { nanoid } from 'nanoid';
const TAB_SESSION_KEY = 'TAB_SESSION_ID';
export const getTabId = (() => {
    let tabId = sessionStorage.getItem(TAB_SESSION_KEY);
    sessionStorage.removeItem(TAB_SESSION_KEY);
    if (!tabId)
        tabId = nanoid();
    if (window) {
        window.addEventListener('beforeunload', () => {
            sessionStorage.setItem(TAB_SESSION_KEY, tabId);
            return null;
        });
    }
    return () => {
        return tabId;
    };
})();
