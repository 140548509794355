import { Stack, StackItem } from '@prophecy/ui/Layout';
import { ProgressBar } from '@prophecy/ui/ProgressBar';
import { theme } from '@prophecy/ui/theme';
import styled from 'styled-components';

import { PageHeader } from '../Header/PageHeader';

export const NonInteractive = styled.div`
  pointer-events: none;
`;

export const Root = styled(Stack).attrs({
  direction: 'vertical'
})`
  background: ${theme.colors.grey25};
  height: 100%;
`;

export const StyledProgressBar = styled(ProgressBar)`
  height: 1px;
  flex-shrink: 0;
  z-index: initial;
`;

export const StepContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 80px 0 30px;
  text-align: center;

  > div {
    width: auto;
    height: 100%;
    text-align: left;
    display: inline-block;
  }
`;

export const StepContentContainer = styled(StackItem)`
  background: ${theme.colors.white};
  border-radius: ${theme.radius.l};
  overflow-y: auto;
  width: 900px;
`;

export const PageFooter = styled(Stack)`
  background: ${theme.colors.white};
  border-top: 1px solid ${theme.colors.grey200};
  padding: ${theme.spaces.x20};
`;

export const StyledPageHeader = styled(PageHeader)`
  background: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.grey200};
`;

export const StyledStack = styled(Stack)`
  min-height: 100%;
`;

export const StyledStackItem = styled(StackItem)`
  position: relative;
`;

export const FixedHeightStep = styled.div`
  position: absolute;
  inset: 0;
`;
