import { prophecyErrorToError } from '@prophecy/utils/error';
import { captureException as sentryCaptureException, Scope, withScope, addBreadcrumb } from '@sentry/react';

import { NetworkConnection } from '../../utils/types';
import { SentryBreadcrumb } from './SentryBreadcrumb';
import { ErrorTagsType, SentryTags } from './SentryTags';

type Params = Parameters<typeof sentryCaptureException>;

export function captureException({
  exception,
  captureContext,
  errorTags
}: {
  exception: Params[0];
  captureContext?: Params[1];
  errorTags?: ErrorTagsType;
}) {
  const message: string = exception.message || '';
  if (message && !blacklistKeywords.some((word) => message.includes(word)))
    withScope(function (scope) {
      if (errorTags) {
        Object.entries(errorTags).forEach(([key, value]) => {
          scope.setTag(key, value);
        });
      }
      setNetworkConnectionTags(scope);
      sentryCaptureException(prophecyErrorToError(exception), captureContext);
    });
}

function setNetworkConnectionTags(scope: Scope) {
  /** window.navigator.connection is not supported by all browsers, adding @ts-ignore to skip typing */

  const connection = (window.navigator as unknown as { connection: NetworkConnection }).connection;
  if (connection) {
    // Note: properties of window.navigator.connection is non-enumerable
    scope.setTag(SentryTags.NetworkConnectionDownlink, connection.downlink);
    scope.setTag(SentryTags.NetworkConnectionEffectiveType, connection.effectiveType);
    scope.setTag(SentryTags.NetworkConnectionRtt, connection.rtt);
    scope.setTag(SentryTags.NetworkConnectionSaveData, connection.saveData);
    scope.setTag(SentryTags.NetworkConnectionType, connection.type);
  }
}
export function listenForNetwork() {
  const connection = (window.navigator as unknown as { connection: NetworkConnection }).connection;
  if (connection) {
    connection.addEventListener('change', (event) => {
      const networkObject = event.currentTarget as unknown as NetworkConnection;
      let message = 'Network Connection changed';
      if (networkObject) {
        // Note: properties of window.navigator.connection is non-enumerable
        message = `${message} 
        ${JSON.stringify({
          downlink: networkObject.downlink,
          effectiveType: networkObject.effectiveType,
          rtt: networkObject.rtt,
          saveData: networkObject.saveData,
          type: networkObject.type
        })}`;
      }
      addBreadcrumb({
        category: SentryBreadcrumb.NetworkConnection,
        message: message,
        level: 'info',
        data: event.currentTarget as object
      });
    });
  }
  window.addEventListener('offline', (e) => {
    addBreadcrumb({
      category: SentryBreadcrumb.NetworkConnection,
      message: 'Network is offline',
      level: 'info'
    });
  });

  window.addEventListener('online', (e) => {
    addBreadcrumb({
      category: SentryBreadcrumb.NetworkConnection,
      message: 'Network is online',
      level: 'info'
    });
  });
}
const packages = [`java.io`, `java.nio`, `com.databricks.sql`, `org.apache`, 'java.lang.ClassNotFoundException'];
const words = [
  `NameError`,
  `AnalysisException`,
  `FileNotFoundError`,
  `Job aborted due to stage failure`,
  `ParseException`,
  `RecordFormatException`,
  `IndentationError`,
  `AttributeError`,
  'IllegalArgumentException',
  'FileAlreadyExistsException'
];
const blacklistKeywords = [...packages, ...words];
