import { Root } from '@radix-ui/react-tabs';
import styled from 'styled-components';
import { Button } from '../Button';
import { ICON_CLASS } from '../Icons/tokens';
import { Stack, StackItem } from '../Layout';
import { Resizable } from '../Resizable';
import { StyledResizeIcon } from '../Resizable/styled';
import { RESIZE_HANDLE_CLASS } from '../Resizable/tokens';
import { Separator } from '../Separator';
import { tokens } from './tokens';
export const StyledDrawer = styled(Stack) `
  height: 100%;
`;
export const StyledDrawerHeader = styled(Stack) `
  height: ${tokens.Drawer.Header.height};
  background: ${tokens.Drawer.Header.background};
  border-top: 1px solid ${tokens.Drawer.Header.borderColor};
  border-bottom: 1px solid ${tokens.Drawer.Header.borderColor};
  padding: 0 ${tokens.Drawer.padding};
`;
export const StyledDrawerContent = styled(StackItem) `
  background: ${tokens.Drawer.Content.background};
  overflow: auto;
`;
export const StyledResizable = styled(Resizable) `
  position: absolute !important; // required to override inline style
  bottom: ${(props) => `${props.bottomOffset}px`};
  left: 0;
  width: 100% !important; // required to override inline style
  z-index: ${tokens.Drawer.zIndex};

  .${RESIZE_HANDLE_CLASS} {
    &:hover {
      background-color: transparent;
    }

    .${ICON_CLASS} {
      display: none;
    }
  }

  ${StyledResizeIcon} {
    color: ${tokens.Drawer.Icon.color};
  }
`;
export const DrawerTabRoot = styled(Root) `
  width: calc(100% + 2 * ${tokens.Drawer.padding});
  margin: 0 -${tokens.Drawer.padding};
`;
export const DrawerTab = styled(Button) `
  cursor: pointer;
  min-width: 95px;
  text-align: center;
  height: ${tokens.Drawer.Header.height};
  border-radius: 0;

  &&:hover,
  &&[data-state='active'] {
    background: ${tokens.DrawerTabHeader.Tab.active.backgroundColor};
  }
`;
export const DrawerTabSeparator = styled(Separator) `
  height: ${tokens.DrawerTabHeader.Separator.height};
`;
