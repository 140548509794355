import { theme } from '@prophecy/ui/theme';
import { Tooltip } from '@prophecy/ui/Tooltip';
import { castArray } from 'lodash-es';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { TabError } from './hooks';

const StyledList = styled.ol`
  margin: 0;
  padding-inline-start: ${theme.spaces.x24};
`;

const getMessage = (error: string | boolean) => {
  return error === true ? 'Please fix form errors to continue' : error || null;
};

export const ErrorTooltip = ({ errors, render }: { errors?: TabError; render: (hasErrors?: boolean) => ReactNode }) => {
  const errorList = (errors ? castArray(errors) : []).filter(Boolean);

  if (errorList.length) {
    const tooltip =
      errorList.length > 1 ? (
        <>
          <StyledList>
            {errorList.map((error, index) => (
              <li key={index}>{getMessage(error)}</li>
            ))}
          </StyledList>
        </>
      ) : (
        getMessage(errorList[0])
      );

    return <Tooltip title={tooltip}>{render(true)}</Tooltip>;
  }

  return <>{render()}</>;
};
