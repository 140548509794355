import { useGlobalContext } from '@prophecy/utils/react/utilify';
import { useMemo } from 'react';

export const useUnsecuredGlobalVariables = () => {
  const globalContext = useGlobalContext();
  const settings = useMemo(
    () => (globalContext.unsecuredConfig || {}) as Record<string, unknown>,
    [globalContext.unsecuredConfig]
  );
  return useMemo(
    () => ({
      isConfigurableAuthEnabled:
        (settings['prophecy.ui.unsecured.configurableAuthOptions.enabled'] as boolean) ?? false,
      isSCIMEnabled: (settings['prophecy.ui.unsecured.scim.enabled'] as boolean) ?? false,
      isSaasSetup: (settings['prophecy.ui.unsecured.is_saas_setup.enabled'] as boolean) ?? false,
      isConfigurableUpdateEnabled: settings['prophecy.ui.unsecured.configurableUpdate.enabled'] ?? false,
      isMeteringEnabled: settings['prophecy.ui.unsecured.metering.enabled'] ?? false,
      isSignupEnabled: settings['prophecy.ui.unsecured.signup.enabled'] ?? true,
      isSMTPEnabled: settings['prophecy.ui.unsecured.smtp.enabled'] ?? true,
      isCrispChatEnabled: settings['prophecy.ui.unsecured.crispchat.enabled'] ?? false
    }),
    [settings]
  );
};
