import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import styled from 'styled-components';
const BaseElement = forwardRef((_a, ref) => {
    var { height, width, layout, gap, children } = _a, props = __rest(_a, ["height", "width", "layout", "gap", "children"]);
    return (_jsx("div", Object.assign({ ref: ref }, props, { children: children })));
});
const BaseCell = forwardRef((_a, ref) => {
    var { height, width, children } = _a, props = __rest(_a, ["height", "width", "children"]);
    return (_jsx("div", Object.assign({ ref: ref }, props, { children: children })));
});
const Grid = styled(BaseElement) `
  display: grid;
  column-gap: ${({ gap }) => gap || '0px'};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  grid-template-columns: ${({ layout }) => layout};
  justify-content: ${({ align }) => align || 'start'};
  align-items: ${({ alignY }) => alignY || 'start'};
`;
const Cell = styled(BaseCell) `
  height: 100%;
  min-width: 0;
  min-height: 0;
  padding: ${({ padding }) => padding !== null && padding !== void 0 ? padding : '0px'};
  overflow: ${(props) => (props.overflow ? `${props.overflow}` : 'initial')};
`;
const _Columns = forwardRef((_a, ref) => {
    var { children, gap = '0px', align, alignY, layout, height = 'auto', width = 'auto' } = _a, props = __rest(_a, ["children", "gap", "align", "alignY", "layout", "height", "width"]);
    const widths = [];
    const Columns = React.Children.toArray(children)
        .filter(Boolean)
        .map((child) => {
        if (React.isValidElement(child)) {
            const { width } = child.props;
            widths.push((width === 'content' ? 'max-content' : width) || '1fr');
        }
        return child;
    });
    // if layout is not defined autogenerate layout based on children widths
    const gridLayout = layout || widths.join(' ');
    return (_jsx(Grid, Object.assign({ ref: ref, gap: gap, height: height, width: width, layout: gridLayout, align: align, alignY: alignY }, props, { children: Columns })));
});
const Column = forwardRef((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsx(Cell, Object.assign({ ref: ref }, props, { children: children })));
});
// Ts cry on compound pattern with forward refs
export const Columns = Object.assign(_Columns, { Column });
