import { InlineInput } from '@prophecy/ui/Input';
import { InputWrap, StyledInput, StyledInputContainer } from '@prophecy/ui/Input/styled';
import styled from 'styled-components';

export const InlineEditableInput = styled(InlineInput)`
  ${InputWrap} {
    width: auto;
    &:focus-within {
      width: 100%;
    }
  }

  ${StyledInput}, ${StyledInputContainer} {
    &[disabled] {
      background-color: transparent;
    }
  }
`;
