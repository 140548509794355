import StepperPage from '../../common/StepperPage';
import BasicForm from './components/BasicForm';
import ExecutionMetricsDescription from './components/ExecutionMetricsDescription';
import ExecutionMetricsForm from './components/ExecutionMetricsForm';
import { TEAM_STEPS, TEAM_TAB_LABELS } from './constants';
import useCreateTeam from './hooks/useCreateTeam';
import useFormContext from './hooks/useFormContext';
import { TEAM_TABS } from './types';

export const CreateTeam = () => {
  const formContexts = useFormContext();
  const teamName = formContexts[TEAM_TABS.basic].watch('name');

  const { backToTeams, createTeam, isLoading } = useCreateTeam(formContexts);

  const getTabHeader = (key: string) => {
    if (key === TEAM_TABS.executionMetrics) {
      return (
        <>
          {TEAM_TAB_LABELS[key as TEAM_TABS]}
          <ExecutionMetricsDescription />
        </>
      );
    }

    return TEAM_TAB_LABELS[key as TEAM_TABS];
  };

  const getStepContent = (key: string, setErrors: (hasErrors: boolean) => void) => {
    switch (key) {
      case TEAM_TABS.basic:
        return <BasicForm formContext={formContexts[key]} updateTeamErrors={setErrors} />;
      case TEAM_TABS.executionMetrics:
        return <ExecutionMetricsForm formContext={formContexts[key]} updateTeamErrors={setErrors} />;
    }
  };

  return (
    <StepperPage
      steps={TEAM_STEPS}
      getStepContent={getStepContent}
      getTabHeader={getTabHeader}
      onCancel={backToTeams}
      onComplete={createTeam}
      isLoading={isLoading}
      pageHeaderProps={{
        crumbs: [
          {
            category: 'Team',
            label: teamName || `Untitled Team`
          }
        ]
      }}
    />
  );
};
