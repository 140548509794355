import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { uniqueId } from 'lodash-es';
import { createContext, useContext, forwardRef, useLayoutEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { Button } from './Button';
import { tokens } from './tokens';
import { ButtonVariants, ButtonSize } from './types';
export const ButtonGroupContext = createContext({ size: ButtonSize.m, register: () => () => undefined, groupItems: [] });
const StyledButtonGroup = styled.div `
  display: inline-flex;
  overflow: hidden;

  &[disabled] {
    box-shadow: none;
    border-color: ${tokens.Button.default.secondaryGrey.disabled.borderColor};
  }
`;
const StyledButtonGroupItem = styled(Button) `
  border-radius: 0;

  ${(props) => props.alternate &&
    `
    background: ${tokens.Button.default.secondaryGrey.backgroundColor};
    color: ${tokens.ButtonGroupItem.color};
  `}

  &,
  &:disabled,
  &:hover,
  &:focus,
  &:active,
  &:hover:focus {
    box-shadow: none;
    border: none;
    border: ${(props) => props.alternate || props.variant === ButtonVariants.secondaryGrey
    ? `1px solid ${tokens.Button.default.secondaryGrey.borderColor}`
    : 'none'};

    ${(props) => !props.isFirstItem && `border-left: ${props.alternate ? 'none' : '1px solid rgba(255, 255, 255, .2)'};`}

    ${(props) => props.isFirstItem &&
    `
        border-top-left-radius: ${tokens.Button.size[props.size].borderRadius};
        border-bottom-left-radius: ${tokens.Button.size[props.size].borderRadius};
      `}

  ${(props) => props.isLastItem &&
    `
        border-top-right-radius: ${tokens.Button.size[props.size].borderRadius};
        border-bottom-right-radius: ${tokens.Button.size[props.size].borderRadius};
      `}
  }

  &:disabled {
    * {
      opacity: 0.7;
    }
    box-shadow: none;
  }

  ${(props) => props.variant === ButtonVariants.secondaryGrey &&
    `
      &:active {
        background: ${tokens.ButtonGroupItem.secondaryGrey.focused.backgroundColor};
      }
    `}
`;
const _ButtonGroup = forwardRef(function ButtonGroup(_a, forwardedRef) {
    var { children, disabled, size = ButtonSize.m } = _a, restProps = __rest(_a, ["children", "disabled", "size"]);
    const [groupItems, setGroupItems] = useImmer([]);
    const register = usePersistentCallback((buttonKey) => {
        setGroupItems((items) => {
            // if an item key is getting registered again, it means a new item is introduced,
            // in such case reset current items
            if (items.includes(buttonKey))
                return [buttonKey];
            return [...items, buttonKey];
        });
        return () => {
            setGroupItems((items) => {
                items.splice(items.indexOf(buttonKey), 1);
            });
        };
    });
    return (_jsx(ButtonGroupContext.Provider, { value: { size, disabled, register, groupItems }, children: _jsx(StyledButtonGroup, Object.assign({ size: size, disabled: !!disabled }, restProps, { ref: forwardedRef, children: children })) }));
});
export const ButtonGroupItem = forwardRef((_a, forwardedRef) => {
    var { variant = ButtonVariants.secondaryGrey, alternate } = _a, restProps = __rest(_a, ["variant", "alternate"]);
    const { size, disabled, register, groupItems } = useContext(ButtonGroupContext);
    const itemKey = useMemo(() => uniqueId('buttonItem'), []);
    useLayoutEffect(() => {
        return register(itemKey);
    }, [itemKey, groupItems.length, register]);
    const isFirstItem = groupItems[0] === itemKey;
    const isLastItem = groupItems[groupItems.length - 1] === itemKey;
    return (_jsx(StyledButtonGroupItem, Object.assign({ size: size, variant: variant, alternate: alternate, disabled: disabled, isFirstItem: isFirstItem, isLastItem: isLastItem }, restProps, { ref: forwardedRef })));
});
// Ts cry on compound pattern with forward refs
export const ButtonGroup = Object.assign(_ButtonGroup, { Item: ButtonGroupItem });
