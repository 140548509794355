import { jsx as _jsx } from "react/jsx-runtime";
// an HOC to add default props to a component
export function withProps(Component, defaultProps) {
    const HOC = (props) => {
        const _props = Object.assign(Object.assign({}, defaultProps), props);
        return _jsx(Component, Object.assign({}, _props));
    };
    // Display name for better debugging
    HOC.displayName = `withProps(${Component.displayName || Component.name || 'Component'})`;
    return HOC;
}
