import styled, { css } from 'styled-components';
// Source: https://inthedigital.co.uk/cross-browser-support-for-hiding-scrollbars-including-firefox/
export const hideScrollbarCss = css `
  /*FireFox*/
  scrollbar-width: none;
  /*IE10+*/
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    /*Chrome, Safari, Edge*/
    display: none;
  }
`;
const HideScrollbar = styled.div `
  ${hideScrollbarCss}
`;
export default HideScrollbar;
