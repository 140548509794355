import { theme } from '../theme';
export const tokens = {
    ArrowIcon: {
        color: theme.colors.grey300,
        fontSize: theme.fontSizes.x14,
        margin: theme.spaces.x8
    },
    Input: {
        background: theme.colors.grey50,
        radius: theme.radius.s
    },
    Crumb: {
        fontWeight: theme.fontWeight.medium,
        gap: theme.spaces.x8,
        color: theme.colors.grey500,
        iconColor: theme.colors.grey400,
        fontFamily: theme.fontFamily.text,
        background: theme.colors.white,
        hover: {
            color: theme.colors.grey700
        },
        active: {
            color: theme.colors.primary600,
            iconColor: theme.colors.primary600
        }
    },
    primary: {
        background: theme.colors.grey50,
        active: {
            background: theme.colors.primary50
        }
    },
    plain: {
        background: theme.colors.white
    }
};
