import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { theme } from '../theme';
import { Tooltip } from '../Tooltip';
import { Text } from './Text';
export const TextWithDottedUnderline = styled(Text) `
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  text-decoration-style: dashed;
  text-underline-offset: ${theme.spaces.x4};
  text-decoration-color: ${theme.colors.grey400};
`;
export const UnderlineHint = (_a) => {
    var { hint, level = 'sm', tone = theme.colors.grey500, children } = _a, restProps = __rest(_a, ["hint", "level", "tone", "children"]);
    let _hint;
    if (typeof hint === 'string') {
        _hint = { title: hint, overlayStyle: { maxWidth: '350px', maxHeight: '300px', overflow: 'auto' } };
    }
    else {
        _hint = hint;
    }
    return (_jsx(Tooltip, Object.assign({ title: (_hint === null || _hint === void 0 ? void 0 : _hint.title) || '' }, _hint, { children: _jsx(TextWithDottedUnderline, Object.assign({ underline: Boolean(_hint), level: level }, restProps, { children: children })) })));
};
