import { APIUrls, getUsageMetricsOnPremUrl, hostUrl } from '../../utils/getServerUrl';
import { XHRResponseType, makeGetCall, makePostCall, Headers } from '../util';

export async function fetchUpdateInfo() {
  return await makeGetCall(APIUrls.GET_UPDATE_INFO.url);
}

export const fetchUsageMetrics = async () => {
  return await makeGetCall(APIUrls.GET_USAGE_METRICS.url);
};

export const fetchControlCentreAuth = async () => {
  return await makeGetCall(APIUrls.GET_ATHENA_AUTH.url);
};

export const updateTimeStamp = async (payload: unknown) => {
  return await makePostCall(APIUrls.UPDATE_METRICS_TIME_STAMPE.url, payload);
};

export const postUsageMetrics = async (controlCentreUrl: string = hostUrl, payload: unknown, headers: Headers) => {
  return (await makePostCall(getUsageMetricsOnPremUrl(controlCentreUrl), payload, headers)) as XHRResponseType<{}>;
};
