import { AuthTypes, ProviderType } from 'frontend/core/src/data/apis/types';

import type { AzureData, LDAPData, ProphecyManagedData, SAMLData } from '../Admin/AuthProvider/types';

export type ProfileData = {
  currentPassword: string;
  firstName: string;
  newPassword: string;
  rtNewPassword: string;
  lastName?: string;
};

export type IdpConfig = SAMLData | LDAPData | AzureData | ProphecyManagedData;

type AuthProviderDataType = {
  [AuthTypes.azuread]: AzureData;
  [AuthTypes.google]: AzureData;
  [AuthTypes.saml]: SAMLData;
  [AuthTypes.ldap]: LDAPData;
  [AuthTypes.prophecymanaged]: ProphecyManagedData;
};

export type AdminData = ProphecyManagedData &
  AuthProviderDataType & {
    authProvider: string;
    idp?: ProviderType;
  };

export type AuthConfig = {
  id: string;
  idpConfig: IdpConfig;
  idp?: ProviderType;
  name: string;
  resourceVersion: string;
  type: AuthTypes;
};

export const AuthProviderOptions = [
  {
    label: 'Prophecy Managed',
    value: AuthTypes.prophecymanaged
  },
  {
    label: 'LDAP',
    value: AuthTypes.ldap
  },
  {
    label: 'SAML',
    value: AuthTypes.saml
  },
  {
    label: 'Azure Active Directory',
    value: AuthTypes.azuread
  },
  {
    label: 'Google',
    value: AuthTypes.google
  }
];

export const IdpProviderLabelMap: { [key: string]: string } = {
  [ProviderType.azuread]: 'Azure Active Directory',
  [ProviderType.others]: 'Others',
  [ProviderType.google]: 'Google',
  [ProviderType.okta]: 'Okta',
  saml: 'SAML'
};
