import { ReactComponent as BigQueryIcon } from '@prophecy/ui/assets/images/bigquery.svg';
import { ReactComponent as DatabricksIcon } from '@prophecy/ui/assets/images/databricks_outline.svg';
import { ReactComponent as RedshiftIcon } from '@prophecy/ui/assets/images/redshift.svg';
import { ReactComponent as SnowflakeIcon } from '@prophecy/ui/assets/images/snowflake.svg';
import { ReactComponent as TrinoIcon } from '@prophecy/ui/assets/images/trino.svg';

export enum SQL_PROVIDER {
  BigQuery = 'bigquery',
  DataBricks = 'databricks',
  Snowflake = 'snowflake',
  Trino = 'trino',
  Redshift = 'redshift'
}

export const SQL_PROVIDER_OPTIONS = [
  {
    icon: <DatabricksIcon />,
    iconVariant: DatabricksIcon,
    value: SQL_PROVIDER.DataBricks,
    label: 'Databricks',
    beta: false,
    disabled: false
  },
  {
    icon: <SnowflakeIcon />,
    iconVariant: SnowflakeIcon,
    value: SQL_PROVIDER.Snowflake,
    label: 'Snowflake',
    beta: false,
    disabled: false
  },
  {
    icon: <BigQueryIcon />,
    iconVariant: BigQueryIcon,
    value: SQL_PROVIDER.BigQuery,
    label: 'BigQuery (Beta)',
    beta: true,
    disabled: false
  },
  {
    icon: <TrinoIcon />,
    iconVariant: TrinoIcon,
    value: SQL_PROVIDER.Trino,
    label: 'Trino (Beta)',
    beta: true,
    disabled: false
  },
  {
    icon: <RedshiftIcon />,
    iconVariant: RedshiftIcon,
    value: SQL_PROVIDER.Redshift,
    label: 'Redshift (Beta)',
    beta: true,
    disabled: false
  }
];
