const ProcessLabels = {
  Nation: 'nation',
  Customer: 'customer',
  ByNationId: 'ByNationId',
  CleanUp: 'CleanUp',
  NumberOfCustomers: 'NumberOfCustomers',
  CustomerNation: 'CustomerNation'
};
export const Processes = {
  Nation: {
    label: ProcessLabels.Nation,
    position: {
      x: 100,
      y: 120
    }
  },
  Customer: {
    label: ProcessLabels.Customer,
    position: {
      x: 100,
      y: 300
    }
  },
  ByNationId: {
    label: ProcessLabels.ByNationId,
    position: {
      x: 300,
      y: 220
    }
  },
  CleanUp: {
    label: ProcessLabels.CleanUp,
    position: {
      x: 500,
      y: 220
    }
  },
  NumberOfCustomers: {
    label: ProcessLabels.NumberOfCustomers,
    position: {
      x: 700,
      y: 220
    }
  },
  CustomerNation: {
    label: ProcessLabels.CustomerNation,
    position: {
      x: 900,
      y: 220
    }
  }
};
