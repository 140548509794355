export enum AIRFLOW_PROVIDER {
  CLOUD_COMPOSER = 'composer',
  PROPHECY_MANAGED_TRIAL = 'prophecy',
  MWAA = 'mwaa',
  OPEN_SOURCE = 'opensource'
}

export const AirflowProviderToPayloadKey: { [key in AIRFLOW_PROVIDER]: string | undefined } = {
  [AIRFLOW_PROVIDER.CLOUD_COMPOSER]: 'composerInfo',
  [AIRFLOW_PROVIDER.PROPHECY_MANAGED_TRIAL]: undefined,
  [AIRFLOW_PROVIDER.MWAA]: 'mwaaInfo',
  [AIRFLOW_PROVIDER.OPEN_SOURCE]: 'openSourceAttributes'
};

export const ProviderToScheduler: { [key in AIRFLOW_PROVIDER]: string } = {
  [AIRFLOW_PROVIDER.CLOUD_COMPOSER]: 'Composer',
  [AIRFLOW_PROVIDER.PROPHECY_MANAGED_TRIAL]: 'Prophecy',
  [AIRFLOW_PROVIDER.MWAA]: 'MWAA',
  [AIRFLOW_PROVIDER.OPEN_SOURCE]: 'OpenSource'
};

export const AirflowProviderLabels: { [key in AIRFLOW_PROVIDER]: string } = {
  [AIRFLOW_PROVIDER.CLOUD_COMPOSER]: 'Cloud Composer',
  [AIRFLOW_PROVIDER.PROPHECY_MANAGED_TRIAL]: 'Prophecy Managed',
  [AIRFLOW_PROVIDER.MWAA]: 'MWAA',
  [AIRFLOW_PROVIDER.OPEN_SOURCE]: 'Open Source'
};
