import { theme } from '../theme';
export const tokens = {
    StepList: {
        horizontal: {
            gap: theme.spaces.x6
        },
        vertical: {
            gap: theme.spaces.x16
        }
    },
    Step: {
        gap: theme.spaces.x8,
        nonFilledGap: theme.spaces.x16
    },
    StepIcon: {
        radius: theme.radius.m,
        height: theme.sizes.x24,
        width: theme.sizes.x30,
        disabled: {
            backgroundColor: theme.colors.grey100,
            color: theme.colors.grey500,
            borderColor: theme.colors.grey300
        },
        active: {
            backgroundColor: theme.colors.primary100,
            borderColor: theme.colors.primary500,
            color: theme.colors.primary700
        },
        completed: {
            backgroundColor: theme.colors.success50,
            color: theme.colors.grey700,
            borderColor: theme.colors.success500,
            iconColor: theme.colors.success500
        },
        pending: {
            backgroundColor: theme.colors.grey100,
            color: theme.colors.grey500,
            borderColor: theme.colors.grey300
        }
    },
    Separator: {
        color: theme.colors.grey300
    }
};
