import { Stack } from '@prophecy/ui/Layout';
import { theme } from '@prophecy/ui/theme';
import { Text } from '@prophecy/ui/Typography';
import { DeploymentAnalytics } from 'frontend/core/src/common/user-analytics/deployment';
import styled from 'styled-components';

import { Root, StyledAnchor, StyledCard } from '../styles';
import { DeploymentStepBaseProp } from '../types';
import { DeploymentStepFooter } from './DeploymentStepFooter';
import DeploymentStepHeader from './DeploymentStepHeader';

const BoldAnchor = styled(StyledAnchor)`
  font-weight: ${theme.fontWeight.bold};
`;

export default function PreDeploymentCheck({ nextStep, prevStep }: DeploymentStepBaseProp) {
  return (
    <StyledCard>
      <Root align='space-between'>
        <Stack gap={theme.spaces.x40}>
          <Stack gap={theme.spaces.x16}>
            <DeploymentStepHeader title='2. Deployment pre-check' />
            <Stack gap={theme.spaces.x40}>
              <Text level='sm'>
                With your infrastructure now fully prepared, we can proceed to the next step: providing you with Helm
                Charts that encompass all the essential resources required to seamlessly deploy Prophecy on your
                Kubernetes cluster. These comprehensive Helm Charts include YAML configuration files for deployments,
                services, secrets, and config maps, ensuring a smooth and efficient deployment process.
              </Text>
            </Stack>
          </Stack>
          <Stack gap={theme.spaces.x20}>
            <Text weight={theme.fontWeight.bold} level='sm'>
              Option 1
            </Text>
            <div>
              <BoldAnchor href='https://github.com/SimpleDataLabsInc/pre-install-script/archive/refs/heads/main.zip'>
                Download
              </BoldAnchor>{' '}
              Python script to generate Helm charts for your Prophecy installation.
            </div>
            <Text weight={theme.fontWeight.bold} level='sm'>
              Option 2
            </Text>
            <div>
              <BoldAnchor href='https://github.com/SimpleDataLabsInc/pre-install-script'>Clone</BoldAnchor> from the
              public repository.
            </div>
          </Stack>
        </Stack>
        <DeploymentStepFooter
          eventCategory={DeploymentAnalytics.deployment_step_pre_check.event_category}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      </Root>
    </StyledCard>
  );
}
