/* eslint-disable*/
//@ts-nocheck
import { useEffect } from 'react';
import { useUser } from '../context/user';

const filteredEmails = [
  'tylerg@prophecy.io',
  'angela@prophecy.io',
  'sujay@prophecy.io'
  // 'vishal@simpledatalabs.com',
  // 'sudhanshu@prophecy.io'
];

const filteredCompanyDomains = [
  'aetion.com', // Aetion
  'bonafidehealth.com', // Bonafide Health
  'cobalths.com', // Cobalt Health Solutions
  'driftwoodcapital.com', // Driftwood Hospitality Management
  'sap.com', // SAP SE
  'texasrangers.com', // Texas Rangers
  'waterfallam.com', // Waterfall Asset Management
  'workday.com', // Workday
  'zionsbancorp.com', // Zions
  'flexera.com', // Flexera
  'onbe.com', // Onbe
  'amgen.com', // Amgen
  'cegid.com', // Cegid
  'clearwateranalytics.com', // CWAN
  'telekom.de', // Deutsche Telekom
  't-systems.com', // Deutsche Telekom
  'goldchp.org', // Gold Coast
  'hsbc.com', // HSBC
  'hsbc.co.*', // HSBC (wildcard for domains like .uk, .in, etc.)
  'jnj.com', // Johnson & Johnson
  'jpmchase.com', // JPMC
  'chase.com', // JPMC
  'jpmorgan.com', // JPMC
  'jpmorganchase.com', // JPMC
  'jpmc.com', // JPMC
  'marks-and-spencer.com', // Marks & Spencer
  'marksandspencer.com', // Marks & Spencer
  'microsoft.com', // Microsoft
  'cz.nl', // CZ
  'optum.com', // Optum
  'ralphlauren.com', // Ralph Lauren
  'visa.com', // Visa
  'wellsfargo.com' // Wells Fargo
];

// this is marketing manage script so can be typescript and eslint ignored, instead of fixing them, so updating code becomes easier
export function useDelightSurvey() {
  const user = useUser();
  const { firstName, lastName, email, company } = user.user;

  useEffect(() => {
    const isProphecyTestUser = filteredEmails.includes(email);
    const isPaidCustomer = filteredCompanyDomains.some((domain) => email.match(new RegExp(`@(?:.*\\.)?${domain}$`)));

    if (!email || !(isProphecyTestUser || isPaidCustomer)) {
      return;
    }

    !(function (e, t, r, n) {
      if (!e[n]) {
        for (
          var a = (e[n] = []),
            i = [
              'survey',
              'reset',
              'config',
              'init',
              'set',
              'get',
              'event',
              'identify',
              'track',
              'page',
              'screen',
              'group',
              'alias'
            ],
            s = 0;
          s < i.length;
          s++
        ) {
          var c = i[s];
          a[c] =
            a[c] ||
            (function (e) {
              return function () {
                var t = Array.prototype.slice.call(arguments);
                a.push([e, t]);
              };
            })(c);
        }
        a.SNIPPET_VERSION = '1.0.1';
        var o = t.createElement('script');
        (o.type = 'text/javascript'),
          (o.async = !0),
          (o.src = 'https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/' + r + '/' + n + '.js');
        var p = t.getElementsByTagName('script')[0];
        p.parentNode.insertBefore(o, p);
      }
    })(window, document, 'oqe8NHnqsB4iekdZ', 'delighted');

    delighted.survey({
      email,
      name: `${firstName} ${lastName}`,
      properties: {
        company
      }
    });
  }, []);
}
