import { graphql } from '../../graphqlTypes/graphql';

export const UserDetailDropdownOptionsQuery = graphql(`
  query UserDetailDropdownOptionsQuery($entityId: String!) {
    User(uid: $entityId) {
      _id
      projects {
        _id
        name
        mainBranch
        language
        mainBranchModificationAllowed
      }
      fabrics {
        _id
        name
        JobSizeAspect: aspects(aspect: JobSize) {
          AspectValue
          AspectName
        }
        SparkProviderInfoAspect: aspects(aspect: SparkProviderInfo) {
          AspectValue
          AspectName
        }
        AirflowProviderInfoAspect: aspects(aspect: AirflowProviderInfo) {
          AspectValue
          AspectName
        }
        SqlProviderInfoAspect: aspects(aspect: SqlProviderInfo) {
          AspectValue
          AspectName
        }
      }
    }
  }
`);

export const UserAllFabricInfoQuery = graphql(`
  query UserAllFabricInfoAspect($uid: String!) {
    User(uid: $uid) {
      _id
      UserAllFabricInfoAspect: aspects(aspect: UserAllFabricInfo) {
        AspectValue
        AspectName
      }
      SqlFabricCredsInfoAspect: aspects(aspect: SqlFabricCredsInfo) {
        AspectValue
        AspectName
      }
    }
  }
`);

export const UserTeamsInfoAspectQuery = graphql(`
  query getUserTeamsInfoAspect($uid: String!) {
    User(uid: $uid) {
      _id
      teams {
        InfoAspect: aspects(aspect: Info) {
          AspectName
          AspectValue
        }
      }
    }
  }
`);
