import { ReactComponent as DatabricksIcon } from '@prophecy/ui/assets/images/databricks_outline.svg';

export enum DQ_PROVIDER {
  DATABRICKS = 'databricks'
}

export const DQ_PROVIDER_OPTIONS = [
  {
    icon: <DatabricksIcon />,
    iconVariant: DatabricksIcon,
    value: DQ_PROVIDER.DATABRICKS,
    label: 'Databricks',
    disabled: false
  }
];

export type DQDataBricksProvider = {
  providerType: DQ_PROVIDER.DATABRICKS;
  sqlServerHostName: string;
  databricksUrl: string;
  databricksToken: string;
  sqlHttpPath: string;
  runTableName: string;
  expectationTableName: string;
  databaseName: string;
};

export type DQProviderInfo = DQDataBricksProvider;

export type DQProvider<T = DQProviderInfo> = {
  providerInfo: T;
};
