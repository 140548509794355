export var DialogSize;
(function (DialogSize) {
    DialogSize["m"] = "m";
    DialogSize["l"] = "l";
    DialogSize["xl"] = "xl";
    DialogSize["xxl"] = "xxl";
})(DialogSize || (DialogSize = {}));
export var Type;
(function (Type) {
    Type["alert"] = "alert";
    Type["success"] = "success";
    Type["warning"] = "warning";
    Type["info"] = "info";
})(Type || (Type = {}));
export var IconPlacement;
(function (IconPlacement) {
    IconPlacement["center"] = "center";
    IconPlacement["left"] = "left";
    IconPlacement["right"] = "right";
})(IconPlacement || (IconPlacement = {}));
