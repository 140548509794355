import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { padNumber } from '@prophecy/utils/number';
import { useState } from 'react';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { Button } from '../Button';
import { SELECTED_STATE_ATTRIBUTE } from '../Dropdown/tokens';
import { ClockIcon } from '../Icons';
import { Input } from '../Input';
import { Stack, StackItem } from '../Layout';
import { Popover } from '../Popover';
import { Option } from './styled';
import { tokens } from './tokens';
import { Hours12, Minutes, DayTime, to12HourFormat, to24HourFormat } from './utils';
const TimePopup = styled(Stack) `
  padding: 0 0 ${tokens.TimePicker.verticalGap};
`;
const TimeColumn = styled(StackItem) `
  padding: 0 ${tokens.TimePicker.horizontalGap};
  overflow: auto;
`;
const TimePopupFooterButton = styled(Button) `
  flex: 1;
`;
const TimePickerOption = styled(Option) `
  width: 50px;
  justify-content: center;

  &[${SELECTED_STATE_ATTRIBUTE}='true'] {
    position: sticky;
    top: 0;
    bottom: 0;
  }
`;
const TimePickerInputWrap = styled.div `
  display: inline-flex;
`;
const StyledInput = styled(Input) `
  width: 100%;
`;
export function TimePicker({ value: [hour, minute], onChange, className }) {
    const [isPopoverOpen, togglePopover] = useState(false);
    const [hour12, dayTime] = to12HourFormat(hour);
    const _hour12 = hour12.toString();
    const _minute = padNumber(minute, 2);
    const [time, setTime] = useImmer({
        hour: _hour12,
        minute: _minute,
        dayTime
    });
    const clockTime = `${_hour12}:${_minute} ${dayTime}`;
    const renderOptions = (options, key) => {
        return (_jsx(_Fragment, { children: options.map((option) => (_jsx(TimePickerOption, { onClick: () => {
                    setTime((time) => {
                        if (key === 'dayTime') {
                            time.dayTime = option;
                        }
                        else {
                            time[key] = option;
                        }
                    });
                }, [SELECTED_STATE_ATTRIBUTE]: option === time[key], children: option }, option))) }));
    };
    const saveTime = () => {
        let hour12 = Number(time.hour);
        const hour24 = to24HourFormat(hour12, time.dayTime);
        const minute = Number(time.minute);
        onChange([hour24, minute]);
        togglePopover(false);
    };
    const overlay = (_jsxs(Stack, { height: '250px', children: [_jsx(StackItem, { grow: '1', children: _jsxs(TimePopup, { direction: 'horizontal', alignY: 'stretch', height: '100%', children: [_jsx(TimeColumn, { children: renderOptions(Hours12.map(String), 'hour') }), _jsx(TimeColumn, { children: renderOptions(Minutes.map((min) => padNumber(min, 2)), 'minute') }), _jsx(TimeColumn, { children: renderOptions(Object.values(DayTime), 'dayTime') })] }) }), _jsxs(Stack, { direction: 'horizontal', align: 'stretch', gap: tokens.TimePicker.horizontalGap, children: [_jsx(TimePopupFooterButton, { variant: 'secondaryGrey', onClick: () => togglePopover(false), children: "Cancel" }), _jsx(TimePopupFooterButton, { variant: 'primary', onClick: saveTime, children: "Set" })] })] }));
    return (_jsx(Popover, { visible: isPopoverOpen, onVisibleChange: togglePopover, overlay: overlay, onOpenAutoFocus: (e) => e.preventDefault(), onCloseAutoFocus: (e) => e.preventDefault(), overlayStyle: {
            padding: `${tokens.TimePicker.verticalGap} ${tokens.TimePicker.horizontalGap}`
        }, align: 'end', children: _jsx(TimePickerInputWrap, { className: className, children: _jsx(StyledInput, { value: clockTime, readOnly: true, onClick: (e) => e.stopPropagation(), onFocus: (e) => togglePopover(true), icon: _jsx(ClockIcon, { type: 'default' }) }) }) }));
}
