import { clamp } from 'lodash-es';
import { randomNumber } from './number';
export function getViewportGrids({ points, height, width, leftCornerX, leftCornerY, gap = 60, cellHeight = 80, cellWidth = 80 }) {
    const cellFullHeight = cellHeight + gap;
    const cellFullWidth = cellWidth + gap;
    const noOfRows = Math.round(height / cellFullHeight);
    const noOfColumns = Math.round(width / cellFullWidth);
    const grid = Array(noOfRows);
    for (let index = 0; index < grid.length; index++) {
        grid[index] = Array(noOfColumns).fill(false);
    }
    // mark cell as filled if a node is present in cell
    points.forEach((p) => {
        const { x, y } = p;
        // no need to consider if the node is not on visible area.
        if (x + cellFullWidth < leftCornerX || // if horizontally its before visible canvas
            y + cellFullHeight < leftCornerY || // if vertically its before visible canvas
            x > leftCornerX + width || // if horizontally its after visible canvas
            y > leftCornerY + height // if vertically its after visible canvas
        ) {
            return;
        }
        // get the position of a node in the cell,
        // distribute the gap on both side of node
        const cellX = clamp(Math.round((y - gap / 2 - leftCornerY) / cellFullHeight), 0, noOfRows - 1);
        const cellY = clamp(Math.round((x - gap / 2 - leftCornerX) / cellFullWidth), 0, noOfColumns - 1);
        // mark the cell as occupied
        grid[cellX][cellY] = true;
    });
    return {
        noOfColumns,
        noOfRows,
        grid,
        cellFullWidth,
        cellFullHeight
    };
}
export function findNextEmptyCell(params) {
    const { width, height, leftCornerX, leftCornerY } = params;
    const { grid, noOfRows, noOfColumns, cellFullWidth, cellFullHeight } = getViewportGrids(params);
    // add new element on empty cell
    // avoid adding on top corner, so start with 2nd row
    for (let i = 1; i < noOfRows; i++) {
        const row = grid[i];
        // avoid adding on left corner, so start with 2nd column
        for (let j = 1; j < noOfColumns; j++) {
            const cell = row[j];
            if (!cell) {
                // empty cell
                // keep 20 px offset from top so the label is visible.
                return [leftCornerX + j * cellFullWidth, leftCornerY + 20 + i * cellFullHeight];
            }
        }
    }
    // if we are here that means we couldn't find a empty cell, falling back to random x,y
    return [leftCornerX + randomNumber(0, width / 2), leftCornerY + randomNumber(0, height / 2)];
}
export function getBoundaryFromPoints(points) {
    const xs = points.map((p) => p.x);
    const ys = points.map((p) => p.y);
    return {
        x: Math.min(...xs),
        y: Math.min(...ys),
        width: Math.max(...xs) - Math.min(...xs),
        height: Math.max(...ys) - Math.min(...ys)
    };
}
