import { Entity } from 'frontend/core/src/common/types/Entity';
import { useGraphQlMutation } from 'frontend/core/src/data/util';
import { AspectKind } from 'frontend/core/src/graphqlTypes/enums';
import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { updateEntityAspectQuery } from '../../../common/queries/common';
import { BasicFormData } from '../types';

const useUpdateInfoAspect = (formContext: UseFormReturn<BasicFormData>) => {
  const aspectMutation = useGraphQlMutation(updateEntityAspectQuery);

  const updateDescription = useCallback(
    async (uid: string) => {
      const aspectValue = JSON.stringify({
        description: formContext.getValues('description'),
        transpilerEnabled: formContext.getValues('transpilerFlag'),
        entraIdEnabled: formContext.getValues('entraIdFlag'),
        assumedRoleEnabled: formContext.getValues('assumedRoleEnabled')
      });
      await aspectMutation.mutateAsync(
        {
          uid,
          entityKind: Entity.Team,
          aspectKind: AspectKind.Info,
          aspectValue
        },
        {}
      );
    },
    [aspectMutation, formContext]
  );

  return { updateDescription, isLoading: aspectMutation.isLoading };
};

export default useUpdateInfoAspect;
