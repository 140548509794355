export var Variant;
(function (Variant) {
    Variant["error"] = "error";
    Variant["success"] = "success";
    Variant["loading"] = "loading";
    Variant["info"] = "info";
    Variant["plain"] = "plain";
    Variant["warning"] = "warning";
})(Variant || (Variant = {}));
export var ToastPlacement;
(function (ToastPlacement) {
    ToastPlacement["top"] = "top";
    ToastPlacement["bottom"] = "bottom";
})(ToastPlacement || (ToastPlacement = {}));
