import { useDebounce, usePersistentCallback, useWindowEvent } from '@prophecy/utils/react/hooks';
import { inRange, noop } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useLanguageSpec } from './LanguageSpecProvider';
import { registerCompletionItemProvider, setupModelForSuggestion } from './utils';
export function useSuggestions(suggestions, getInlineSuggestion, editor, monaco, prepareSuggestions) {
    const { getSpec: getLanguageSpec } = useLanguageSpec();
    useEffect(() => {
        if (monaco && getLanguageSpec) {
            registerCompletionItemProvider(monaco, getLanguageSpec, prepareSuggestions);
        }
    }, [monaco, getLanguageSpec, prepareSuggestions]);
    // register suggestions
    useEffect(() => {
        if (editor) {
            const currentModel = editor.getModel();
            currentModel && setupModelForSuggestion(currentModel, suggestions || [], getInlineSuggestion);
        }
    }, [suggestions, editor, getInlineSuggestion]);
}
// hook for handle editor decorations
export function useDecorations(decorations = [], editor, monaco) {
    const appliedDecorations = useRef([]);
    useEffect(() => {
        // if there is no decorations or editor is not initialized return
        if (!editor || !monaco)
            return;
        const editorDecorations = decorations.map((decoration) => {
            const { type } = decoration;
            // get range
            let monacoRange;
            if (decoration.type === 'lineIcon') {
                monacoRange = new monaco.Range(decoration.line, 1, decoration.line, 1);
            }
            else {
                const { range } = decoration;
                const { from, to } = range;
                monacoRange = new monaco.Range(from.line, from.column, to.line, to.column);
            }
            // get options
            let options = {};
            if (decoration.type === 'custom') {
                options = decoration.options;
            }
            else if (decoration.type === 'lineIcon') {
                options = {
                    isWholeLine: true,
                    glyphMarginClassName: `decoration-line-icon ${decoration.iconClass}`
                };
            }
            else if (type === 'highlight') {
                options = {
                    className: `decoration-highlight`,
                    hoverMessage: { value: decoration.message || '' }
                };
            }
            return {
                range: monacoRange,
                options
            };
        });
        // get decoration related to git conflict
        const value = editor.getValue();
        const blocks = generateConflictBlocks(value);
        const gitDecorations = conflictBlocksToDecorations(monaco, blocks);
        appliedDecorations.current = editor.deltaDecorations(appliedDecorations.current, [
            ...editorDecorations,
            ...gitDecorations
        ]);
    }, [decorations, editor, monaco]);
    // handle clicks on line icons
    useEffect(() => {
        if (!editor || !decorations)
            return;
        const eventHandler = editor.onMouseDown(function (e) {
            const { position, element } = e.target;
            // for margins (line numbers) column is 1
            if (element === null || element === void 0 ? void 0 : element.classList.contains('decoration-line-icon')) {
                decorations.forEach((decoration) => {
                    var _a;
                    if (decoration.type === 'lineIcon' && decoration.line === (position === null || position === void 0 ? void 0 : position.lineNumber)) {
                        (_a = decoration.onClick) === null || _a === void 0 ? void 0 : _a.call(decoration);
                    }
                });
            }
        });
        return eventHandler.dispose;
    }, [editor, decorations]);
}
// hook for handling language definitions
export function useDefinitions(language, definitions, editor, monaco) {
    useEffect(() => {
        if (!(editor && monaco && definitions))
            return;
        const { languages } = monaco;
        // const { range, referencePath, action } = definitions;
        const DefinitionProvider = {
            provideDefinition: function (model, position) {
                const currentModel = editor.getModel();
                const { lineNumber, column } = position;
                // if the model is from different editor ignore.
                if (model !== currentModel)
                    return null;
                const def = definitions.find((def) => {
                    const { range } = def;
                    const { from, to } = range;
                    // if the position is between the range return that the def
                    // TODO Check if the definition can range between multiple line
                    return from.line === lineNumber && from.column <= column && column <= to.column;
                });
                // if there is no definition return
                if (!def)
                    return null;
                const { referencePath, range, action } = def;
                const { from, to } = range;
                action === null || action === void 0 ? void 0 : action(referencePath, editor, model, position);
                return {
                    uri: monaco.Uri.parse(referencePath),
                    range: new monaco.Range(from.line, from.column, to.line, to.column)
                };
            }
        };
        const providerDisposable = languages.registerDefinitionProvider(language, DefinitionProvider);
        return () => {
            providerDisposable.dispose();
        };
    }, [language, definitions, editor, monaco]);
}
export function useDiagnostics(diagnostics = [], editor, monaco) {
    useEffect(() => {
        if (!editor || !monaco)
            return;
        const model = editor.getModel();
        if (!model)
            return;
        const modelDiagnostics = diagnostics.map(({ message, severity, range }) => {
            const { from, to } = range;
            return {
                startLineNumber: from.line,
                startColumn: from.column,
                endLineNumber: to.line,
                endColumn: to.column,
                message,
                severity
            };
        });
        monaco.editor.setModelMarkers(model, 'code-diagnostics', modelDiagnostics);
    }, [diagnostics, editor, monaco]);
}
export function useValue(getValue, value, onChange, onBlur, delay) {
    const [localValue, setLocalValue] = useState(value);
    const slowOnChange = useDebounce(onChange || noop, delay);
    useEffect(() => {
        setLocalValue(value);
    }, [value]);
    const handleChange = (newValue) => {
        setLocalValue(newValue || '');
        slowOnChange(newValue || '');
    };
    const handleBlur = usePersistentCallback(() => {
        const editorValue = getValue();
        // cancel debouce
        slowOnChange.cancel();
        // trigger change only if value is not up to date
        if (editorValue !== value) {
            onChange === null || onChange === void 0 ? void 0 : onChange(editorValue);
        }
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(editorValue);
    });
    // in case if component unmounts without blur
    useEffect(() => () => {
        slowOnChange.flush();
    }, [slowOnChange]);
    return { value: localValue, handleChange, handleBlur };
}
const CONFLICT_START = '<<<<<<<';
const CONFLICT_BREAKPOINT = '=======';
const CONFLICT_END = '>>>>>>>';
export function useGitCodeLens(language, monaco, editor) {
    useEffect(() => {
        let dispose;
        if (monaco && editor) {
            dispose = monaco.languages.registerCodeLensProvider(language, {
                onDidChange: function () {
                    return dispose;
                },
                provideCodeLenses: gitConflictCodeLenProvide(editor)
            });
        }
        return () => {
            dispose === null || dispose === void 0 ? void 0 : dispose.dispose();
        };
    }, [monaco, editor, language]);
}
function resolveConflict(text, block, type) {
    const lines = text.split('\n');
    return lines
        .filter((line, index) => {
        const lineNumber = index + 1;
        if (type === 'current') {
            if (lineNumber === block.current_start) {
                return false;
            }
            if (inRange(lineNumber, block.incoming_start - 1, block.incoming_end + 1)) {
                return false;
            }
        }
        else if (type === 'incoming') {
            if (lineNumber === block.incoming_end) {
                return false;
            }
            if (inRange(lineNumber, block.current_start, block.current_end + 2)) {
                return false;
            }
        }
        return true;
    })
        .join('\n');
}
function conflictBlocksToDecorations(monaco, blocks) {
    const decorations = [];
    blocks.forEach((block) => {
        decorations.push({
            range: new monaco.Range(block.current_start, 0, block.current_start, 0),
            options: {
                isWholeLine: true,
                className: `start_conflict_current_decoration`,
                afterContentClassName: 'start_conflict_current_after_decoration'
            }
        });
        decorations.push({
            range: new monaco.Range(block.current_start + 1, 0, block.current_end, 0),
            options: {
                isWholeLine: true,
                className: `conflict_current_decoration`
            }
        });
        decorations.push({
            range: new monaco.Range(block.incoming_start, 0, block.incoming_end - 1, 0),
            options: {
                isWholeLine: true,
                className: `conflict_incoming_decoration`
            }
        });
        decorations.push({
            range: new monaco.Range(block.incoming_end, 0, block.incoming_end, 0),
            options: {
                isWholeLine: true,
                className: `end_conflict_incoming_decoration`,
                afterContentClassName: 'end_conflict_incoming_after_decoration'
            }
        });
    });
    return decorations;
}
export function generateConflictBlocks(text) {
    const lines = text.split('\n');
    const blocks = [];
    lines.forEach((line, index) => {
        const lastBlock = blocks[blocks.length - 1];
        if (line.startsWith(CONFLICT_START)) {
            blocks.push({
                current_start: index + 1,
                current_end: -1,
                incoming_start: -1,
                incoming_end: -1
            });
        }
        else if (lastBlock && line.startsWith(CONFLICT_BREAKPOINT)) {
            lastBlock.current_end = index;
            lastBlock.incoming_start = index + 2;
        }
        else if (lastBlock && line.startsWith(CONFLICT_END)) {
            lastBlock.incoming_end = index + 1;
        }
    });
    return blocks;
}
function gitConflictCodeLenProvide(editor) {
    return function provider(model, tokens) {
        const lenses = [];
        const value = model.getValue();
        const blocks = generateConflictBlocks(value);
        const fullRange = model.getFullModelRange();
        blocks.forEach((block) => {
            lenses.push({
                range: {
                    startLineNumber: block.current_start,
                    startColumn: 0,
                    endLineNumber: block.current_end,
                    endColumn: 0
                },
                id: 'current',
                command: {
                    id: editor.addCommand(0, () => {
                        editor.executeEdits(null, [
                            {
                                text: resolveConflict(value, block, 'current'),
                                range: fullRange
                            }
                        ]);
                    }),
                    title: 'Accept Current Change'
                }
            });
            lenses.push({
                range: {
                    startLineNumber: block.current_start,
                    startColumn: 0,
                    endLineNumber: block.current_end,
                    endColumn: 0
                },
                id: 'incoming',
                command: {
                    id: editor.addCommand(0, () => {
                        editor.executeEdits(null, [
                            {
                                text: resolveConflict(value, block, 'incoming'),
                                range: fullRange
                            }
                        ]);
                    }),
                    title: 'Accept Incoming Change'
                }
            });
        });
        return {
            lenses: lenses,
            dispose: () => { }
        };
    };
}
export function useEditorSave({ value, onChange, onSave, editor, editorInFocus, monaco, autoSaveAfterDelay }) {
    const valueBeforeSave = useRef(value);
    const autoSaveTimeout = useRef();
    const clearAutSaveTimeout = () => {
        if (autoSaveTimeout.current) {
            clearTimeout(autoSaveTimeout.current);
            autoSaveTimeout.current = undefined;
        }
    };
    // save on ctrl + s
    useWindowEvent('keydown', (e) => {
        var _a;
        if (((_a = e.key) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 's' && (e.ctrlKey || e.metaKey) && onSave && editorInFocus) {
            persistentOnSave();
            e.preventDefault();
        }
    });
    const persistentOnSave = usePersistentCallback(() => {
        // make sure any debounced changes are saved
        const editorValue = (editor === null || editor === void 0 ? void 0 : editor.getValue()) || '';
        // trigger onChange if value is changed
        if (editorValue !== value) {
            onChange(editorValue);
        }
        // only trigger save when value is different from last save
        if (valueBeforeSave.current !== editorValue) {
            valueBeforeSave.current = editorValue;
            onSave === null || onSave === void 0 ? void 0 : onSave(editorValue);
        }
        clearAutSaveTimeout();
    });
    useEffect(() => {
        if (editor && monaco) {
            // save on editor blur
            editor.onDidBlurEditorWidget(() => {
                persistentOnSave();
            });
        }
    }, [editor, monaco, persistentOnSave]);
    // on value change schedule auto save after defined timeout
    useEffect(() => {
        if (!autoSaveAfterDelay)
            return;
        // if there is existing scheduled onSave happening no need to reschedule
        if (autoSaveTimeout.current)
            return;
        autoSaveTimeout.current = setTimeout(persistentOnSave, autoSaveAfterDelay);
        return () => {
            clearAutSaveTimeout();
        };
    }, [value, autoSaveAfterDelay, persistentOnSave]);
}
export function usePlaceholderSuggestion({ placeholderSuggestion, setPlaceholderSuggestion, focused, onChange, editor }) {
    useEffect(() => {
        if (focused && placeholderSuggestion && editor && !editor.getValue()) {
            onChange(placeholderSuggestion);
            editor.setValue(placeholderSuggestion);
            const range = editor.getModel().getFullModelRange();
            editor.setSelection(range);
            setPlaceholderSuggestion === null || setPlaceholderSuggestion === void 0 ? void 0 : setPlaceholderSuggestion(undefined);
        }
        // no need to listen for onChange update
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused, editor, placeholderSuggestion]);
}
