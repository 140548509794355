import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Icon, EditBIcon } from '../Icons';
import { theme } from '../theme';
import { Input } from './Input';
import { InputWrap, StyledInput, StyledInputContainer } from './styled';
import { tokens } from './tokens';
import { InputSize } from './types';
export const growInputCss = css `
  // auto grow element based on input content
  ${InputWrap} {
    display: inline-grid;
    overflow: hidden;

    &[data-value='']:after,
    &:not([data-value]):after {
      content: attr(data-placeholder) ' ';
    }
    &:after {
      content: attr(data-value) ' ';
      visibility: hidden;
      white-space: nowrap;
    }

    &::after,
    ${StyledInput} {
      width: auto;
      min-width: 50px;
      grid-area: 1 / 2;
    }
  }
  ${Icon} {
    font-size: ${tokens.InlineInput.iconSize};
  }
`;
const StyledInlineInput = styled(Input) `
  ${StyledInputContainer} {
    height: auto;
    padding: 0;
    padding-bottom: ${tokens.InlineInput.padding};
    box-shadow: none;
    border-color: transparent;
    &:focus-within {
      border-color: ${tokens.InlineInput.focus.borderColor};
      padding: 0 ${(props) => tokens.Input.size[props.inputSize].padding};
      box-shadow: ${tokens.Input.primary.focus.boxShadow};
    }

    ${Icon} {
      visibility: hidden;
    }

    &:hover {
      ${Icon} {
        visibility: visible;
      }
    }
  }
  ${growInputCss}
  ${InputWrap} {
    &:after {
      padding-left: ${(props) => tokens.Input.size[props.inputSize].padding};
    }
  }
`;
export const InlineInput = forwardRef((_a, ref) => {
    var { inputSize = InputSize.m, onFocus, onPressEnter, onBlur, disabled } = _a, restProps = __rest(_a, ["inputSize", "onFocus", "onPressEnter", "onBlur", "disabled"]);
    const [focused, toggleFocused] = useState(false);
    return (_jsx(StyledInlineInput, Object.assign({ size: 1, inputSize: inputSize, icon: focused || disabled ? undefined : _jsx(EditBIcon, { type: 'default', color: theme.colors.grey400 }), iconPlacement: 'right', disabled: disabled, onPressEnter: (e) => {
            toggleFocused(false);
            onPressEnter === null || onPressEnter === void 0 ? void 0 : onPressEnter(e);
        }, onFocus: (e) => {
            toggleFocused(true);
            onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
        }, onBlur: (e) => {
            toggleFocused(false);
            onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
        }, ref: ref }, restProps)));
});
