function arrayToObject<T extends string>(array: T[]): Record<T, T> {
  return array.reduce((obj, item) => {
    obj[item] = item;
    return obj;
  }, {} as Record<T, T>);
}

export const DeploymentAnalytics = {
  deployment_settings: {
    event_category: 'deployment.settings',
    events: arrayToObject([
      'deployment.settings.getStarted',
      'deployment.settings.documentation',
      'deployment.settings.contactSupport'
    ])
  },
  deployment_step_infra_setup1: {
    event_category: 'deployment.step.infraSetup1',
    events: arrayToObject(['deployment.step.helpMeSetupInfra'])
  },
  deployment_step_infra_setup2: {
    event_category: 'deployment.step.infraSetup2'
  },
  deployment_step_pre_check: {
    event_category: 'deployment.step.preCheck'
  },
  deployment_step_post_check: {
    event_category: 'deployment.step.postCheck'
  },
  deployment_step_deploy_prophecy: {
    event_category: 'deployment.step.deployProphecy'
  },
  deployment_step_success: {
    event_category: 'deployment.step.success',
    events: arrayToObject(['deployment.step.successDone'])
  },
  events: arrayToObject(['deployment.step.needHelp', 'deployment.previousStep', 'deployment.nextStep'])
};

export const ReleaseRevampAnalytics = {
  event_category: 'feature.release_revamp',
  events: arrayToObject(['deployment.settingChanged', 'release.clicked', 'deploy.clicked', 'release.and.deployClicked'])
};
