import { Root, Indicator } from '@radix-ui/react-checkbox';
import styled from 'styled-components';
import { Icon } from '../Icons';
import { Stack } from '../Layout';
import { BaseColor } from '../theme';
import { getBoxShadow } from '../theme/utils';
import { Text } from '../Typography/Text';
import { tokens } from './tokens';
export const StyledCheckboxRoot = styled(Root) `
  all: unset;
  box-sizing: border-box;
  background: ${({ disabled }) => (disabled ? tokens.disabled.backgroundColor : tokens.normal.backgroundColor)};
  border: ${tokens.CheckboxRoot.borderThickness} solid ${tokens.normal.borderColor};
  width: ${({ size }) => tokens.CheckboxRoot[size].size};
  height: ${({ size }) => tokens.CheckboxRoot[size].size};
  border-radius: ${({ size }) => tokens.CheckboxRoot[size].borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  ${({ disabled }) => disabled
    ? 'cursor: not-allowed;'
    : `
    &:hover {
      background: ${tokens.normal.hover.backgroundColor};
      border-color: ${tokens.normal.hover.borderColor};
    }
    &:focus,
    &:active {
      box-shadow: ${getBoxShadow(BaseColor.primary, 100)};
      border-color: ${tokens.normal.focused.borderColor};
    }
  `}

  &[data-state='checked'],
  &[data-state='indeterminate'] {
    &&& ${Icon} {
      color: ${({ disabled }) => (disabled ? tokens.disabled.color : tokens.selected.iconColor)};
    }
    background-color: ${({ disabled }) => disabled ? tokens.disabled.checked.backgroundColor : tokens.selected.backgroundColor};
    border-color: ${({ disabled }) => (disabled ? tokens.normal.borderColor : tokens.selected.borderColor)};
    &:hover,
    &:focus {
      box-shadow: ${({ disabled }) => (disabled ? 'none' : getBoxShadow(BaseColor.primary, 100))};
    }
  }

  ${Icon} {
    font-size: ${({ size }) => tokens.CheckboxRoot[size].iconSize};
  }
`;
export const CheckboxLabel = styled(Text) `
  color: ${({ disabled }) => (disabled ? tokens.disabled.color : tokens.normal.color)};
  min-width: 0;
  font-size: ${({ size }) => tokens.CheckboxRoot[size].fontSize};
`;
export const StyledCheckboxIndicator = styled(Indicator) `
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${tokens.selected.iconColor};
`;
export const StyledHelpText = styled(Stack) `
  font-size: ${tokens.CheckboxRoot.HelpText.fontSize};
  color: ${tokens.CheckboxRoot.HelpText.color};
`;
