import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Ellipsis } from '../Ellipsis';
import { LoadingDIcon } from '../Icons';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { Text } from '../Typography/Text';
import { StyledUploadContainer, UploadRootContainer } from './styled';
const defaultMaxSize = 4000000000000;
const defaultMaxFiles = 10000000;
const getFileDetails = (files, title, isRejected) => {
    if (files.length < 1) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Text, { level: 'sm', children: title }), files === null || files === void 0 ? void 0 : files.map((file, index) => {
                file = isRejected ? file.file : file;
                return (_jsxs(Stack, { direction: 'horizontal', gap: theme.spaces.x8, align: 'space-between', children: [_jsx(Text, { level: 'sm13', children: _jsx(Ellipsis, { tooltip: true, children: file === null || file === void 0 ? void 0 : file.name }) }), _jsx(Text, { level: 'sm13', children: _jsx(Ellipsis, { tooltip: true, children: file === null || file === void 0 ? void 0 : file.type }) }), _jsx(Text, { level: 'sm13', children: _jsx(Ellipsis, { tooltip: true, children: file === null || file === void 0 ? void 0 : file.size }) })] }, index));
            })] }));
};
export const Uploader = forwardRef(function Uploader(_a, ref) {
    var { loading, disabled, rootRef = 'dropRoot', maxSize = defaultMaxSize, maxFiles = defaultMaxFiles, showUploadList = false, children, style, className } = _a, restProps = __rest(_a, ["loading", "disabled", "rootRef", "maxSize", "maxFiles", "showUploadList", "children", "style", "className"]);
    const dropzoneState = useDropzone(Object.assign({ disabled: loading || disabled, maxFiles,
        maxSize }, restProps));
    const { getRootProps, getInputProps, acceptedFiles, fileRejections } = dropzoneState;
    return (_jsxs(UploadRootContainer, Object.assign({}, getRootProps({ refKey: rootRef }), { style: style, className: className, children: [_jsx("input", Object.assign({ ref: ref }, getInputProps())), showUploadList && (_jsxs(Stack, { gap: theme.spaces.x8, children: [getFileDetails(acceptedFiles, 'Accepted Files'), getFileDetails(fileRejections, 'Rejected Files', true)] })), _jsxs(StyledUploadContainer, { alignY: 'center', disabled: disabled || loading, gap: theme.spaces.x12, align: 'center', children: [typeof children === 'function' ? children(dropzoneState) : children, loading && _jsx(LoadingDIcon, { type: 'extended', spin: true })] })] })));
});
