import { Text, StackItem, Button, Stack, theme } from '@prophecy/ui';
import { StyledAlert } from '@prophecy/ui/Alert/styled';
import { tokens } from '@prophecy/ui/Alert/tokens';
import { InfoCircleIcon, XCloseIcon } from '@prophecy/ui/Icons';
import { useState } from 'react';
import styled from 'styled-components';

import { useAppMetadata } from '../context/appMetadata';
import { acceptCookies } from '../data/apis/api';

const Link = styled(Button)`
  padding: 0 ${theme.spaces.x4};
` as typeof Button;

export function AcceptCookieBanner() {
  const metadata = useAppMetadata();
  const [visible, setVisible] = useState(!metadata.cookieAccepted);
  async function accept() {
    setVisible(false);
    acceptCookies();
  }
  return visible ? (
    <StyledAlert banner={true} variant='info' direction='horizontal' alignY='start' role='alert'>
      <StackItem grow='1'>
        <Stack direction='horizontal' alignY='center' align='center' gap={theme.spaces.x10}>
          <InfoCircleIcon type='default' color={tokens.Alert.info.iconColor} />
          <Text level='sm'>
            By continuing to browse this website, you are agreeing to our use of cookies. For more information about
            cookies and how we use them, please see our
            <Link size='s' elementType='anchor' variant='link' href='https://legal.prophecy.io/privacy' target='blank'>
              cookie policy.
            </Link>
          </Text>
        </Stack>
      </StackItem>
      <Button
        variant='plain'
        icon={<XCloseIcon type='default' color={theme.colors.grey500} size='l' />}
        onClick={accept}
      />
    </StyledAlert>
  ) : null;
}
