export var SwitchSize;
(function (SwitchSize) {
    SwitchSize["md"] = "md";
    SwitchSize["lg"] = "lg";
})(SwitchSize || (SwitchSize = {}));
export var Variant;
(function (Variant) {
    Variant["default"] = "default";
    Variant["grey"] = "grey";
})(Variant || (Variant = {}));
