import { jsx as _jsx } from "react/jsx-runtime";
import { PATH_KEY } from '@prophecy/utils/nestedData';
import { difference } from 'lodash-es';
import { Checkbox } from '../Checkbox';
import { isCreatableRow } from './utils';
function getNestedCheckedState({ data, rowKey, ancestors = [], selectedRowsMap, getCheckboxProps }) {
    let checkedStateMap = {};
    data.forEach((row) => {
        var _a;
        const checkBoxProps = (getCheckboxProps === null || getCheckboxProps === void 0 ? void 0 : getCheckboxProps(row)) || { disabled: false };
        const disabled = Boolean(checkBoxProps.disabled);
        const rowId = (_a = row[rowKey]) === null || _a === void 0 ? void 0 : _a.toString();
        const checkedState = {
            disabled,
            checked: Boolean(selectedRowsMap[rowId]),
            ancestors,
            indeterminate: false,
            path: row[PATH_KEY],
            nestedChildren: []
        };
        checkedStateMap[rowId] = checkedState;
        if (row === null || row === void 0 ? void 0 : row.children) {
            const childrenCheckedStateMap = getNestedCheckedState({
                data: row.children,
                rowKey,
                ancestors: [...ancestors, rowId],
                selectedRowsMap,
                getCheckboxProps
            });
            const enabledChildren = Object.values(childrenCheckedStateMap).filter((child) => !child.disabled);
            const enabledCheckedRows = enabledChildren.filter((child) => child.checked);
            const indeterminate = enabledCheckedRows.length > 0 && enabledCheckedRows.length < enabledChildren.length;
            const nestedChildren = Object.keys(childrenCheckedStateMap);
            // update indeterminate and nestedChildren information
            checkedState.indeterminate = indeterminate;
            checkedState.nestedChildren = nestedChildren;
            checkedStateMap = Object.assign(Object.assign({}, checkedStateMap), childrenCheckedStateMap);
        }
    });
    return checkedStateMap;
}
export function getTopLevelRowSelection(selectionState) {
    const topLevelRows = Object.values(selectionState).filter((row) => row.ancestors.length === 0);
    const topLevelCheckedRows = topLevelRows.filter((row) => row.checked);
    const topLevelIndeterminateRows = topLevelRows.filter((row) => row.indeterminate);
    return {
        checked: topLevelCheckedRows.length === topLevelRows.length,
        indeterminate: topLevelCheckedRows.length > 0 || topLevelIndeterminateRows.length > 0
    };
}
export function useTableRowSelection(rowSelection, columns, dataSource, rowKey) {
    if (!rowSelection)
        return { columns, selectionState: {} };
    const { selectedRowKeys = [], onChange, getCheckboxProps, disableAll, hideSelectAll } = rowSelection !== null && rowSelection !== void 0 ? rowSelection : {};
    const selectedRowsMap = Object.fromEntries(selectedRowKeys.map((key) => [key, true]));
    const nestedCheckedStateMap = getNestedCheckedState({
        data: dataSource,
        rowKey,
        selectedRowsMap,
        getCheckboxProps,
        ancestors: []
    });
    const allRowKeys = Object.keys(nestedCheckedStateMap);
    const selectAllDisabled = disableAll || allRowKeys.every((key) => nestedCheckedStateMap[key].disabled);
    const enabledRows = allRowKeys.filter((key) => !nestedCheckedStateMap[key].disabled);
    const disabledCheckedRows = allRowKeys.filter((key) => nestedCheckedStateMap[key].disabled && nestedCheckedStateMap[key].checked);
    const enabledCheckedRows = enabledRows.filter((key) => nestedCheckedStateMap[key].checked);
    const onSelectAll = (checked) => {
        const selectedChildren = checked ? [...enabledRows, ...disabledCheckedRows] : [...disabledCheckedRows];
        onChange === null || onChange === void 0 ? void 0 : onChange(selectedChildren, nestedCheckedStateMap);
    };
    const selectAllElm = hideSelectAll ? null : (_jsx(Checkbox, { disabled: selectAllDisabled, indeterminate: enabledCheckedRows.length > 0 && enabledCheckedRows.length < enabledRows.length, checked: enabledCheckedRows.length > 0, onChange: onSelectAll }));
    return {
        selectionState: nestedCheckedStateMap,
        columns: [
            {
                title: selectAllElm,
                width: '50px',
                hideEllipsis: true,
                className: 'selection-cell',
                resizable: false,
                render(col, row, rowIndex) {
                    var _a;
                    // for creatable row row don't show checkbox
                    if (isCreatableRow(row, rowIndex, dataSource))
                        return null;
                    const id = (_a = row[rowKey]) === null || _a === void 0 ? void 0 : _a.toString();
                    const checkboxState = nestedCheckedStateMap[id];
                    const { indeterminate, checked, disabled, nestedChildren, ancestors } = checkboxState;
                    const _onChange = (checked) => {
                        // add/remove its self from selected list
                        let newSelectedRowKeys = checked ? [...selectedRowKeys, id] : selectedRowKeys.filter((key) => key !== id);
                        // add/remove all children from selected list
                        const enabledRows = nestedChildren.filter((key) => !nestedCheckedStateMap[key].disabled);
                        const selectedKeysWithoutChildren = difference(newSelectedRowKeys, enabledRows);
                        newSelectedRowKeys = checked
                            ? [...selectedKeysWithoutChildren, ...enabledRows]
                            : selectedKeysWithoutChildren;
                        // add/remove ancestors to/from selected list
                        const selectedKeysWithoutAncestors = difference(newSelectedRowKeys, ancestors);
                        const selectedAncestors = ancestors.filter((key) => {
                            const ancestorState = nestedCheckedStateMap[key];
                            let ancestorEnabledAndCheckedChildren = ancestorState.nestedChildren.filter((key) => {
                                const childState = nestedCheckedStateMap[key];
                                return !childState.disabled && childState.checked;
                            });
                            // exclude any children which is in ancestor path or children path of current row, as they already considered
                            ancestorEnabledAndCheckedChildren = difference(ancestorEnabledAndCheckedChildren, ancestors.concat(nestedChildren));
                            /**
                             * If any ancestor's children are enabled and checked, then the ancestor should be checked.
                             * if the ancestor has single child selected and the current box went from checked to unchecked,
                             * we need to uncheck the ancestor as well.
                             */
                            return checked || ancestorEnabledAndCheckedChildren.length > 1;
                        });
                        newSelectedRowKeys = [...selectedKeysWithoutAncestors, ...selectedAncestors];
                        onChange === null || onChange === void 0 ? void 0 : onChange(newSelectedRowKeys, nestedCheckedStateMap, row);
                    };
                    return (_jsx(Checkbox, Object.assign({}, getCheckboxProps === null || getCheckboxProps === void 0 ? void 0 : getCheckboxProps(row), { checked: checked, disabled: disabled || disableAll, indeterminate: indeterminate, onChange: _onChange })));
                }
            },
            ...columns
        ]
    };
}
