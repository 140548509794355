import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { StyledResize, StyledResizeIcon } from './styled';
import { tokens, RESIZE_HANDLE_CLASS } from './tokens';
export function Resizable(_a) {
    var { direction, children, handleStyles, offset = parseInt(tokens.ResizeHandle.offset) } = _a, props = __rest(_a, ["direction", "children", "handleStyles", "offset"]);
    const style = {
        [direction]: Object.assign({ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: tokens.ResizeHandle.zIndex, left: direction === 'left' && `-${offset}px`, top: direction === 'top' ? `-${offset}px` : (direction === 'left' || direction === 'right') && '0', right: direction === 'right' && `-${offset}px`, bottom: direction === 'bottom' && `-${offset}px`, fontSize: tokens.ResizeHandle.fontSize }, handleStyles)
    };
    if (direction === 'top' || direction === 'bottom') {
        style[direction].height = '5px';
    }
    else {
        style[direction].width = '5px';
    }
    return (_jsx(StyledResize, Object.assign({ handleComponent: {
            [direction]: _jsx(StyledResizeIcon, { type: 'extended', direction: direction, style: Object.assign({}, handleStyles) })
        }, handleStyles: style, handleClasses: { [direction]: RESIZE_HANDLE_CLASS }, enable: { [direction]: true }, boundsByDirection: true }, props, { children: children })));
}
