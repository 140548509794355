import { Button } from '@prophecy/ui/Button';
import { CheckIcon, HelpCircleIcon, ArrowNarrowLeftIcon } from '@prophecy/ui/Icons';
import { Stack } from '@prophecy/ui/Layout';
import { theme } from '@prophecy/ui/theme';
import { CONTACT_SUPPORT_URL } from 'frontend/core/src/common/constants';
import { DeploymentAnalytics } from 'frontend/core/src/common/user-analytics/deployment';

import { useMixPanel } from '../../../context/mixpanel/context';
import { DeploymentStepBaseProp } from '../types';

type DeploymentStepFooterProps = DeploymentStepBaseProp & {
  eventCategory?: string;
};

export function DeploymentStepFooter({ nextStep, prevStep, eventCategory }: DeploymentStepFooterProps) {
  const { track } = useMixPanel();

  return (
    <Stack direction='horizontal' align='space-between'>
      <Button
        onClick={() => {
          if (eventCategory) {
            track(DeploymentAnalytics.events['deployment.previousStep'], {}, eventCategory);
          }
          prevStep();
        }}
        variant='secondaryGrey'
        icon={<ArrowNarrowLeftIcon type='default' />}
      />
      <Stack direction='horizontal' gap={theme.spaces.x22}>
        <Button
          elementType='anchor'
          target='_blank'
          href={CONTACT_SUPPORT_URL}
          iconPlacement='left'
          icon={<HelpCircleIcon type='default' />}
          variant='secondaryGrey'>
          I need help
        </Button>
        <Button
          onClick={() => {
            if (eventCategory) {
              track(DeploymentAnalytics.events['deployment.nextStep'], {}, eventCategory);
            }
            nextStep();
          }}
          iconPlacement='left'
          icon={<CheckIcon type='default' />}>
          I have completed these steps
        </Button>
      </Stack>
    </Stack>
  );
}
