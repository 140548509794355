import { __rest } from "tslib";
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Stack } from '../Layout';
import { theme } from '../theme';
import { useHint } from './Hint';
import { Text } from './Text';
export const levelToIconSizeMap = {
    xs: 'xxxs',
    sm13: 'xxs',
    sm: 'xs',
    xl: 'xs'
};
export const LabelWithHint = (_a) => {
    var { children, hint, level = 'sm', tone = theme.colors.grey500 } = _a, restProps = __rest(_a, ["children", "hint", "level", "tone"]);
    const _hint = useHint({ hint, level, tone });
    return (_jsx(Text, Object.assign({ level: level }, restProps, { children: _jsxs(Stack, { direction: 'horizontal', gap: theme.spaces.x8, alignY: 'center', children: [children, _hint] }) })));
};
