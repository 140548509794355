import styled from 'styled-components';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { tokens } from './tokens';
export const StyledLoaderWrap = styled(Stack) `
  font-family: ${theme.fontFamily.text};
  gap: ${tokens.Spinner.gap};
`;
export const StyledTip = styled.div `
  font-size: ${tokens.Spinner.Tip.fontSize};
`;
export const StyledSpinnerWrap = styled.div `
  position: relative;
`;
export const StyledOverlay = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${tokens.Spinner.Overlay.backgroundColor};
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${tokens.Spinner.Overlay.zIndex};
`;
