export const signup = {
  normal: {
    resetPasswordSubmit: 'page.resetPassword.ResetPasswordButtonClicked',
    otp: 'page.signup.step.otp',
    signup: 'page.signup.step.signup',
    password: 'page.signup.step.password',
    otpLanding: 'page.signup.OtpLandingPage',
    otpSubmit: 'page.signup.VerifyOTPButtonClicked',
    passwordLanding: 'page.signin.passwordLanding',
    passwordSubmit: 'page.signup.CreatePasswordButtonClicked',
    signupsubmit: 'page.signup.CreateTeamButtonClicked'
  },
  databricks: {
    pageLanding: 'page.signup.databricksLandingPage',
    createPasswordSuccess: 'page.dbsignup.createPasswordSuccess'
  }
};
