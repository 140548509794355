import styled from 'styled-components';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { tokens } from './tokens';
export const TextAreaRoot = styled(Stack) `
  font-family: ${theme.fontFamily.text};
`;
export const StyledTextAreaContainer = styled.div `
  display: flex;
  overflow: hidden;
  background-color: ${(props) => tokens.Input[props.variant].backgroundColor};
  border-radius: ${(props) => tokens.Input.size.m.borderRadius};
  font-size: ${(props) => tokens.Input.size.m.fontSize};
  box-shadow: ${(props) => tokens.Input.boxShadow};
  border: 1px solid ${({ variant }) => tokens.Input[variant].borderColor};

  &:hover {
    color: ${(props) => tokens.Input[props.variant].hover.color};
  }

  &:focus-within {
    border-color: ${(props) => tokens.Input[props.variant].focus.borderColor};
    box-shadow: ${(props) => tokens.Input[props.variant].focus.boxShadow};
  }
  &[disabled] {
    color: ${tokens.Input.disabled.color};
    background-color: ${tokens.Input.disabled.backgroundColor};
    border-color: ${tokens.Input.disabled.borderColor};
    cursor: not-allowed;
  }
`;
export const StyledTextArea = styled.textarea `
  font-family: ${theme.fontFamily.text};
  width: 100%;
  height: 100%;
  min-height: ${(props) => tokens.Input.size.m.height};
  border: none;
  background: transparent;
  font-size: ${theme.fontSizes.x14};
  padding: ${(props) => tokens.Input.size.m.padding};
  outline: none;
  resize: ${(props) => props.resize};
`;
