let lastRequestId: string | null = null;

export function getLastRequestId() {
  return lastRequestId;
}

export function getRequestId() {
  lastRequestId = `${Math.random()}`;
  return lastRequestId;
}
