import queryString from 'query-string';
export var LinkTarget;
(function (LinkTarget) {
    LinkTarget["_blank"] = "_blank";
    LinkTarget["_self"] = "_self";
    LinkTarget["_parent"] = "_parent";
    LinkTarget["_top"] = "_top";
})(LinkTarget || (LinkTarget = {}));
export function appendQueryParams(url, params) {
    const parsedUrlConfig = queryString.parseUrl(url);
    return queryString.stringifyUrl(Object.assign(Object.assign({}, parsedUrlConfig), { query: Object.assign(Object.assign({}, parsedUrlConfig.query), params) }));
}
export function isAbsoluteURL(url) {
    return /^(?:[a-z+]+:)?\/\//.test(url);
}
