import styled, { css } from 'styled-components';
import { tokens } from './tokens';
export const baseDisplayTypographyCss = css `
  all: unset;
  font-family: ${tokens.Display.fontFamily};
  font-size: ${(props) => tokens.Display.level[props.level].fontSize};
  line-height: ${(props) => tokens.Display.level[props.level].lineHeight};
  font-weight: ${({ weight }) => (weight ? weight : tokens.Display.fontWeight)};
  color: ${({ tone }) => (tone ? tone : 'inherit')};
  letter-spacing: ${tokens.Display.letterSpacing};
`;
export const BaseDisplay = styled.span `
  ${baseDisplayTypographyCss}
`;
export const baseTextTypographyCss = css `
  all: unset;
  font-family: ${tokens.Text.fontFamily};
  font-size: ${(props) => tokens.Text.level[props.level].fontSize};
  line-height: ${(props) => tokens.Text.level[props.level].lineHeight};
  font-weight: ${({ weight }) => (weight ? weight : tokens.Text.fontWeight)};
  color: ${({ tone }) => (tone ? tone : 'inherit')};
  letter-spacing: ${tokens.Text.letterSpacing};

  ${({ weight = tokens.Text.fontWeight }) => typeof weight === 'number' && weight > tokens.Text.fontWeight
    ? `
    -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
  `
    : ''}
`;
export const BaseText = styled.span `
  ${baseTextTypographyCss}
`;
export const baseCodeTypographyCss = css `
  all: unset;
  font-family: ${tokens.Code.fontFamily};
  font-size: ${tokens.Code.level.sm13.fontSize};
  line-height: ${tokens.Code.level.sm13.lineHeight};
  letter-spacing: ${tokens.Code.letterSpacing};
  font-weight: ${({ weight }) => (weight ? weight : tokens.Code.fontWeight)};
  color: ${({ tone }) => (tone ? tone : 'inherit')};
`;
export const BaseCode = styled.span `
  ${baseCodeTypographyCss}
`;
export const StyledCodeBlock = styled.code `
  /** EMs are specifically given so it depends on parent font size */
  padding: 0.2em 0.4em 0.1em;
  margin: 0 0.2em;
  font-size: 0.85em;
  background: ${tokens.CodeBlock.backgroundColor};
  border: 1px solid ${tokens.CodeBlock.borderColor};
  border-radius: ${tokens.CodeBlock.borderRadius};
`;
