import styled from 'styled-components';
import { Stack, StackItem } from '../Layout';
import { List } from '../Table';
import { tabsContainerStyle, tabsTriggerStyle } from '../Tabs/styled';
import { theme } from '../theme';
export const StyledList = styled(List) `
  &&&& {
    .ui-table-container {
      padding: 0;
    }
    td,
    th {
      padding: 0;
    }
    .anchorSearch {
      margin-right: 0;
    }
    .container {
      td,
      th {
        padding: 0 ${theme.spaces.x12} ${theme.spaces.x32} 0;
      }
    }
    .anchor-nav-container {
      min-height: 100px;
    }
  }
`;
export const StyledAnchor = styled(Stack) `
  ${tabsTriggerStyle}
  justify-content: start;
`;
export const AnchorContentContainer = styled(StackItem) `
  overflow: auto;
  min-height: auto;
  height: 100%;
`;
export const StyledAnchorContainer = styled(Stack) `
  position: relative;
  ${tabsContainerStyle}
  padding: 0;
`;
export const StyledFooterAction = styled(Stack) `
  position: sticky;
  bottom: 0;
  padding: ${theme.spaces.x4};
`;
