import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createGlobalStyle } from 'styled-components';
import { SELECT_ITEM_CLASS, SELECT_VIRTUAL_LIST_HOLDER_INNER } from '../Select/tokens';
import { RangeSelector } from './styled';
import { tokens } from './tokens';
import { DaysInMonth, renderRangeTag } from './utils';
const DatePickerPopupStyles = createGlobalStyle `
  .ui-cron-date-picker-popup {
    .${SELECT_VIRTUAL_LIST_HOLDER_INNER} {
      display: grid !important; // to override inline style
      grid-template-columns: repeat(7, 1fr);
      gap: ${tokens.DatePicker.Option.gap};
    }

    .${SELECT_ITEM_CLASS} {
      width: 32px;
    }
  }
`;
export function DatePicker(_a) {
    var { mode, value } = _a, restProps = __rest(_a, ["mode", "value"]);
    return (_jsxs(_Fragment, { children: [_jsx(RangeSelector, Object.assign({ mode: mode, allowClear: true, value: value }, restProps, { options: DaysInMonth.map((date) => ({ label: date, value: date })), dropdownClassName: 'ui-cron-date-picker-popup ui-cron-center-aligned-content', virtual: false, showSearch: false, tagRender: ({ value: currentItem }) => renderRangeTag(currentItem, value, DaysInMonth) })), _jsx(DatePickerPopupStyles, {})] }));
}
