// copied from their site so no need to handle types and lint rules. they give compressed script

/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-unused-expressions */
// @ts-nocheck
export function initializeFactorsAi() {
  window.factors =
    window.factors ||
    (function () {
      const factors = {};
      factors.q = [];
      var i = new CustomEvent('FACTORS_QUEUED_EVENT'),
        n = function (t, e) {
          factors.q.push({ k: t, a: e }), window.dispatchEvent(i);
        };
      return (
        (factors.track = function (t, e, i) {
          n('track', arguments);
        }),
        (factors.init = function (t, e, i) {
          (factors.TOKEN = t),
            (factors.INIT_PARAMS = e),
            (factors.INIT_CALLBACK = i),
            window.dispatchEvent(new CustomEvent('FACTORS_INIT_EVENT'));
        }),
        (factors.reset = function () {
          n('reset', arguments);
        }),
        (factors.page = function (t, e) {
          n('page', arguments);
        }),
        (factors.updateEventProperties = function (t, e) {
          n('updateEventProperties', arguments);
        }),
        (factors.identify = function (t, e) {
          n('identify', arguments);
        }),
        (factors.addUserProperties = function (t) {
          n('addUserProperties', arguments);
        }),
        (factors.getUserId = function () {
          n('getUserId', arguments);
        }),
        (factors.call = function () {
          var t = { k: '', a: [] };
          if (arguments && 1 <= arguments.length) {
            for (var e = 1; e < arguments.length; e++) t.a.push(arguments[e]);
            t.k = arguments[0];
          }
          factors.q.push(t), window.dispatchEvent(i);
        }),
        factors.init('9vd2y90u2cv286uyvmohu5l8llppegyw'),
        factors
      );
    })();
}
