import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { StyledSearchInput } from '../Table/ColumnFilters';
import { theme } from '../theme';
import { Table } from './TableBase';
import { TABLE_CONTAINER, TABLE_THEAD_CLASS } from './tokens';
const StyledTable = styled(Table) `
  .${TABLE_CONTAINER} {
    height: 100%;
    overflow: auto;
  }
  .ui-table-body {
    overflow-y: auto !important;
  }
  .ui-table-cell-row-hover {
    background-color: transparent;
  }
  .${TABLE_CONTAINER} {
    border: none;
    padding-right: ${theme.spaces.x12};
  }
  && .ui-table-row td {
    border-width: 0;
    padding: ${theme.spaces.x12} ${theme.spaces.x8};
  }

  .ui-table-placeholder {
    height: auto;
  }

  .${TABLE_THEAD_CLASS} {
    height: ${theme.sizes.x48};

    * {
      position: static;
    }
  }

  ${StyledSearchInput} {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    padding: ${theme.spaces.x4};
  }
`;
export function List(_a) {
    var { data, onReorder, emptyText, renderItem, className, searchFilter } = _a, rest = __rest(_a, ["data", "onReorder", "emptyText", "renderItem", "className", "searchFilter"]);
    const showSearchInput = !!(searchFilter === null || searchFilter === void 0 ? void 0 : searchFilter.searchColumnKey);
    return (_jsx(StyledTable, Object.assign({ className: className, showEmptyMessage: emptyText ? { description: emptyText } : false, dataSource: data, height: '100%', showHeader: showSearchInput, fixedHeader: showSearchInput, placeholderRows: 0, tableLayout: 'fixed', reorder: onReorder ? { onReorder } : undefined }, rest, { children: _jsx(Table.Column, { width: '100%', dataIndex: 'rowData', title: '', 
            // @ts-ignore //TODO type fix for rcTable column to support filter and sort
            filter: searchFilter
                ? {
                    type: 'search',
                    condition: (data, value) => {
                        var _a, _b;
                        const searchColumnKey = searchFilter.searchColumnKey;
                        const columnValue = (_a = data.rowData) === null || _a === void 0 ? void 0 : _a[searchColumnKey];
                        if (columnValue) {
                            (_b = searchFilter === null || searchFilter === void 0 ? void 0 : searchFilter.onChange) === null || _b === void 0 ? void 0 : _b.call(searchFilter, value);
                            return (columnValue === null || columnValue === void 0 ? void 0 : columnValue.toLowerCase().indexOf(value === null || value === void 0 ? void 0 : value.toLowerCase())) > -1;
                        }
                        return false;
                    },
                    forceUncontrolledFilter: searchFilter.forceUncontrolledFilter,
                    value: searchFilter === null || searchFilter === void 0 ? void 0 : searchFilter.value,
                    onChange: searchFilter === null || searchFilter === void 0 ? void 0 : searchFilter.onChange,
                    showSearchInput: showSearchInput,
                    searchInputProps: {
                        placeholder: 'Search...',
                        inputSize: 'm',
                        className: searchFilter.className
                    }
                }
                : null, render: (value, record, index) => {
                return renderItem(record, index);
            } }, 'rowData') })));
}
