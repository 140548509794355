export const ide = {
  inActivityDisconnect: 'ide.sql.inactivity.disconnect',
  errorDisconnect: 'ide.sql.error.disconnect',
  netWorkChangeDisconnect: 'ide.sql.networkchange.disconnect',
  dependencyListOpen: 'subgraph.dependencies.list.open',
  dependencySave: 'subgraph.dependency.save',
  ideOpen: 'sql.ide.open',
  fabricChange: 'sql.ide.fabric.change',
  connectionStart: 'sql.ide.cluster.connection.start',
  inferSchema: 'sql.ide.infer.schema',
  interactiveRun: 'sql.ide.interactive.run',
  fullRun: 'sql.ide.full.run',
  sourceAdd: 'sql.ide.source.add',
  macroAdd: 'sql.ide.macro.add',
  componentAdd: 'sql.ide.component.add',
  interimOpen: 'sql.ide.interim.open',
  createSchedule: 'Sql editor create schedule',
  createDialogOpen: 'sql.ide.entity.create.dialog.open',
  getEntityKindCreateSuccess: (kind: string) => `sql.ide.${kind}.create.success`,
  getEntityGraphOpen: (entityType = '') => `sql.ide.${entityType?.toLowerCase()}.open`
};
